import React from 'react'
import FormGroup from '../FormGroup/FormGroup'
import FormInput from '../FormInput/FormInput'

function FormGroupInput({
    className,
    errors,
    id,
    label,
    name,
    required,
    ...rest
}) {
    return (
        <FormGroup
            className={className}
            errors={errors}
            id={id}
            label={label}
            required={required}
        >
            <FormInput id={id} name={name} errors={errors} {...rest} />
        </FormGroup>
    )
}

export default FormGroupInput
