import React from 'react'
import t from '../../../i18n/translations/translations'
import HartuzFacilityCard from '../HartuzFacilityCard/HartuzFacilityCard'
import CardGrid from '../../../atoms/CardGrid/CardGrid'
import './HartuzFacilityGrid.css'

function HartuzFacilityGrid({ data, lang, selectedDate, isLoading }) {
    let content

    if (data?.length) {
        content = data.map((card) => 
        (
            <HartuzFacilityCard
                card={card}
                censed={card.censed}
                description={card.description}
                disability={card.disability}
                image={card.image}
                key={card.id}
                lang={lang}
                selectedDate={selectedDate}
                canBeBooked={card.can_be_booked}
            />
        ))
    } else if (isLoading) {
        content = (
            <div className="hartuzFacilityGrid__message">
                <p>{t.loading[lang]}</p>
            </div>
        )
    } else {
        content = (
            <div className="hartuzFacilityGrid__message">
                <p>{t.no_facilities_found[lang]}</p>
            </div>
        )
    }

    return <CardGrid className="hartuzFacilityGrid">{content}</CardGrid>
}

export default HartuzFacilityGrid
