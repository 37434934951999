import React from 'react'

export default function Contrata({ className }) {
    return (
        <svg viewBox="0 0 40 37" className={className}>
            <g id="Udala-Zabaltzen-0">
                <g
                    id="Transparencia_desktop"
                    transform="translate(-613.000000, -1023.000000)"
                >
                    <g
                        id="Group-2"
                        transform="translate(613.000000, 1023.000000)"
                    >
                        <path d="" id="Path-9-Copy-28" strokeWidth="2.04" />
                        <path
                            d="M5.95744681,13.7674419 C5.48741723,13.7674419 5.10638298,13.3821985 5.10638298,12.9069767 C5.10638298,12.431755 5.48741723,12.0465116 5.95744681,12.0465116 L24.3788607,12.0465116 C24.8488902,12.0465116 25.2299245,12.431755 25.2299245,12.9069767 C25.2299245,13.3821985 24.8488902,13.7674419 24.3788607,13.7674419 L5.95744681,13.7674419 Z"
                            id="Path-9-Copy-28"
                        />
                        <path
                            d="M5.95744681,18.9302326 C5.48741723,18.9302326 5.10638298,18.5449892 5.10638298,18.0697674 C5.10638298,17.5945457 5.48741723,17.2093023 5.95744681,17.2093023 L22.6382979,17.2093023 C23.1083274,17.2093023 23.4893617,17.5945457 23.4893617,18.0697674 C23.4893617,18.5449892 23.1083274,18.9302326 22.6382979,18.9302326 L5.95744681,18.9302326 Z"
                            id="Path-9-Copy-30"
                        />
                        <path
                            d="M5.95744681,24.0930233 C5.48741723,24.0930233 5.10638298,23.7077799 5.10638298,23.2325581 C5.10638298,22.7573364 5.48741723,22.372093 5.95744681,22.372093 L19.4612657,22.372093 C19.9312953,22.372093 20.3123295,22.7573364 20.3123295,23.2325581 C20.3123295,23.7077799 19.9312953,24.0930233 19.4612657,24.0930233 L5.95744681,24.0930233 Z"
                            id="Path-9-Copy-32"
                        />
                        <path
                            d="M5.9600764,29.2903713 C5.49004907,29.2918396 5.10783936,28.9077883 5.10638713,28.4325688 C5.10493489,27.9573493 5.48479006,27.5709175 5.95481739,27.5694493 L17.0186472,27.5348879 C17.4886745,27.5334196 17.8708842,27.9174709 17.8723365,28.3926904 C17.8737887,28.8679099 17.4939335,29.2543417 17.0239062,29.2558099 L5.9600764,29.2903713 Z"
                            id="Path-9-Copy-34"
                        />
                        <path
                            d="M22.9787234,7.74418605 L28.9361702,7.74418605 C29.4061998,7.74418605 29.787234,8.1294294 29.787234,8.60465116 C29.787234,9.07987292 29.4061998,9.46511628 28.9361702,9.46511628 L21.2765957,9.46511628 L21.2765957,1.72093023 C21.2765957,1.24570847 21.65763,0.860465116 22.1276596,0.860465116 C22.5976891,0.860465116 22.9787234,1.24570847 22.9787234,1.72093023 L22.9787234,7.74418605 Z"
                            id="Path-7-Copy-11"
                        />
                        <path
                            d="M27.0543811,26.7626191 L34.8828543,18.8574628 C37.0966044,16.6220281 37.0966044,16.6220281 37.5337078,16.180697 C38.4714251,15.2157816 38.4532904,14.1377456 37.7235111,13.4210136 C36.9987238,12.7091845 35.9283877,12.7042166 35.0529049,13.6165167 L24.5769748,24.193382 L23.283416,28.0458785 L27.0543811,26.7626191 Z M38.9095056,12.1866027 C40.3133933,13.5653909 40.3499451,15.7382481 38.742195,17.3925757 L27.9751673,28.265071 L22.8481671,30.0097891 C22.2245047,30.2220214 21.5487579,29.8829065 21.3388444,29.2523548 C21.2555077,29.0020227 21.2558565,28.7309368 21.339837,28.4808247 L23.0929685,23.2596221 L33.8456164,12.4033729 C35.376686,10.8124774 37.5219906,10.8238943 38.9095056,12.1866027 Z"
                            id="Path-11-Copy-2"
                        />
                        <path
                            d="M28.9361702,31.4236954 C28.9361702,30.9484736 29.3172045,30.5632302 29.787234,30.5632302 C30.2572636,30.5632302 30.6382979,30.9484736 30.6382979,31.4236954 L30.6382979,35.7953488 C30.6382979,36.4606593 30.1048499,37 29.4468085,37 L1.19148936,37 C0.533447958,37 0,36.4606593 0,35.7953488 L0,1.20465116 C0,0.539340697 0.533447958,-5.68434189e-14 1.19148936,-5.68434189e-14 L22.2988244,4.32274371e-13 C22.5988345,4.32274371e-13 22.8878025,0.114427107 23.1080126,0.320426758 L30.2559967,7.00714296 C30.4997294,7.23514727 30.6382979,7.55564237 30.6382979,7.89136736 L30.6382979,11.2114612 C30.6382979,11.686683 30.2572636,12.0719264 29.787234,12.0719264 C29.3172045,12.0719264 28.9361702,11.686683 28.9361702,11.2114612 L28.9361702,8.11705033 L22.0988283,1.72093023 L1.70212766,1.72093023 L1.70212766,35.2790698 L28.9361702,35.2790698 L28.9361702,31.4236954 Z"
                            id="Path-12-Copy-2"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
