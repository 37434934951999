import { Map, Marker } from '@vis.gl/react-google-maps'
import Moment from 'moment'
import React, { useState } from 'react'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import Imagen from '../../assets/img/Placeholder.png'
import Ubi from '../../assets/img/map-marker-alt-solid.svg'
import CalendarioInput from '../../assets/svg/CalendarioInput'
import Category from '../../assets/svg/Category'
import AbiertaMarker from '../../assets/svg/Markers_Kalezaindu/Abierta.svg'
import EnProcesoMarker from '../../assets/svg/Markers_Kalezaindu/EnProceso.svg'
import RechazadaMarker from '../../assets/svg/Markers_Kalezaindu/Rechazada.svg'
import ResueltaMarker from '../../assets/svg/Markers_Kalezaindu/Resuelta.svg'
import UnificadaMarker from '../../assets/svg/Markers_Kalezaindu/Unificada.svg'
import FatherIncidenceButton from '../../atoms/FatherIncidenceButton/FatherIncidenceButton'
import PageRow from '../../atoms/PageRow/PageRow'
import IncidenciaInfoBoxStatusProcess from '../../components/IncidenciaInfoBoxStatusProcess/IncidenciaInfoBoxStatusProcess'
import IncidenciaInfoboxHeader from '../../components/IncidenciaInfoboxHeader/IncidenciaInfoboxHeader'
import { DATE_FORMAT } from '../../constants'
import useSetIframeTitle from '../../hooks/useSetIframeTitle'
import t from '../../i18n/translations/translations'
import '../../styles/lightbox-custom.css'
import getImagesRoute from '../../utils/getImagesRoute'
import './IncidenciaDetail.css'

const STATES = {
    OPEN: {
        marker: AbiertaMarker,
        name: {
            es: 'Abierta',
            eu: 'Zabalik',
        },
    },
    PROCESSING: {
        marker: EnProcesoMarker,
        name: {
            es: 'En proceso',
            eu: 'Prozesatzen',
        },
    },
    RESOLVED: {
        marker: ResueltaMarker,
        name: {
            es: 'Resuelta',
            eu: 'Konponduta',
        },
    },
    REJECTED: {
        marker: RechazadaMarker,
        name: {
            es: 'Rechazada',
            eu: 'Ukatuta',
        },
    },
    UNIFIED: {
        marker: UnificadaMarker,
        name: {
            es: 'Unificada',
            eu: 'Bateratuta',
        },
    },
    TRAMITED: {
        marker: ResueltaMarker,
        name: {
            es: 'Tramitada',
            eu: 'Bideratua',
        },
    },
}

export const isOpen = (name) =>
    name === STATES.OPEN.name.es || name === STATES.OPEN.name.eu
export const isProcessing = (name) =>
    name === STATES.PROCESSING.name.es || name === STATES.PROCESSING.name.eu
export const isResolved = (name) =>
    name === STATES.RESOLVED.name.es || name === STATES.RESOLVED.name.eu
export const isTramitted = (name) =>
    name === STATES.TRAMITED.name.es || name === STATES.TRAMITED.name.eu
export const isRejected = (name) =>
    name === STATES.REJECTED.name.es || name === STATES.REJECTED.name.eu
export const isUnified = (name) =>
    name === STATES.UNIFIED.name.es || name === STATES.UNIFIED.name.eu

const getIcon = ({ nameEs, nameEu }) => {
    let marker = ''

    if (
        isOpen(nameEs) ||
        isOpen(nameEu) ||
        isProcessing(nameEs) ||
        isProcessing(nameEu)
    ) {
        marker = STATES.PROCESSING.marker
    } else if (isResolved(nameEs) || isResolved(nameEu)) {
        marker = STATES.RESOLVED.marker
    } else if (isRejected(nameEs) || isRejected(nameEu)) {
        marker = STATES.REJECTED.marker
    } else if (isUnified(nameEs) || isUnified(nameEu)) {
        marker = STATES.UNIFIED.marker
    } else if (isTramitted(nameEs) || isTramitted(nameEu)) {
        marker = STATES.TRAMITED.marker
    }

    return marker
}

function UpdateText({ data }) {
    return (
        <li className="texto_actualizacion">
            <div>
                <CalendarioInput className="calendar" />
                {Moment(data.updated_at).format(DATE_FORMAT)}
            </div>
            <p>{data.comentario}</p>
        </li>
    )
}

function InfoBoxHeaderCategory({ category }) {
    return (
        <div className="categoria">
            <Category />
            <p>{category}</p>
        </div>
    )
}

function IncidenciaDetail({
    actualizaciones,
    address,
    category,
    color,
    date,
    description,
    idFatherIncidence,
    isMobile,
    isTablet,
    images,
    lang,
    latitude,
    longitude,
    stateName,
    stateNameConfig,
    title,
    translations,
}) {
    const [open, setOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)
    useSetIframeTitle(t.incidencia_map[lang])

    const listaimagenes = []
    images.forEach((image) => {
        if (image.url === '-') {
            listaimagenes.push({ url: Imagen, visible: image.visible })
        } else {
            listaimagenes.push({
                alt: image.alt,
                url: `${getImagesRoute() + image.instance}/${image.url}`,
                visible: image.visible,
            })
        }
    })

    const processedImages = listaimagenes
        .filter((item) => item.visible === 1)
        .map((item) => ({
            src: item.url,
            alt: item.alt,
        }))

    const handleOpenLightbox = (index) => {
        setCurrentIndex(index)
        setOpen(true)
    }

    const texts = {
        open: translations.KALEZAINDU.KALEZAINDU_ESTADO_INCIDENCIA_ABIERTA,
        inProgress:
            translations.KALEZAINDU.KALEZAINDU_ESTADO_INCIDENCIA_EN_PROCESO,
        resolved: translations.KALEZAINDU.KALEZAINDU_ESTADO_INCIDENCIA_RESUELTA,
        tramitted:
            translations.KALEZAINDU.KALEZAINDU_ESTADO_INCIDENCIA_TRAMITADA,
        rejected:
            translations.KALEZAINDU.KALEZAINDU_ESTADO_INCIDENCIA_RECHAZADA,
        unified: translations.KALEZAINDU.KALEZAINDU_ESTADO_INCIDENCIA_UNIFICADA,
        updateOrder: translations.KALEZAINDU.KALEZAINDU_ORDEN_ACTUALIZACIONES,
    }

    return (
        <PageRow className="indicenciaDetail">
            <div className="indicenciaDetail_mapContainer">
                <Map
                    defaultZoom={15}
                    defaultCenter={{
                        lat: latitude,
                        lng: longitude,
                    }}
                >
                    <Marker
                        name="Current location"
                        position={{
                            lat: latitude,
                            lng: longitude,
                        }}
                        icon={{
                            url: getIcon({
                                nameEs: stateNameConfig,
                                nameEu: stateName,
                            }),
                            // set marker width and height
                            anchor: new window.google.maps.Point(12, 12),
                            scaledSize: new window.google.maps.Size(27, 27),
                        }}
                    />
                </Map>
            </div>
            <div className="indicenciaDetail-infobox">
                <IncidenciaInfoboxHeader
                    isMobile={isMobile}
                    isTablet={isTablet}
                    title={title}
                    color={color}
                    stateName={stateName}
                    category={category}
                    date={date}
                />

                <IncidenciaInfoBoxStatusProcess
                    texts={texts}
                    state={stateNameConfig}
                    isMobile={isMobile}
                    date={date}
                    actualizaciones={actualizaciones}
                />

                {!idFatherIncidence ? null : (
                    <FatherIncidenceButton id={idFatherIncidence} lang={lang} />
                )}

                <div className="infobox_status-description">
                    <div className="infobox_status-description-text">
                        {!isTablet && !isMobile ? (
                            <InfoBoxHeaderCategory category={category} />
                        ) : null}

                        <div className="direccion">
                            <div className="direccion_location">
                                <img
                                    src={Ubi}
                                    alt=""
                                    height="20px"
                                    width="20px"
                                />
                                <p>{address}</p>
                            </div>
                        </div>
                        <div className="descripcion_incidencia">
                            <p>{description}</p>
                        </div>
                    </div>
                    <div className="infobox_status-description-photo">
                        <div className="imagenIncidenciaGaleria">
                            {listaimagenes.map((item, index) => {
                                if (item.visible === 1) {
                                    if (processedImages.length > 0) {
                                        return (
                                            <div
                                                className="imagenIncidencia"
                                                key={item.url}
                                            >
                                                <button
                                                    onClick={() =>
                                                        handleOpenLightbox(
                                                            index
                                                        )
                                                    }
                                                    onKeyDown={(event) => {
                                                        if (
                                                            event.key ===
                                                            'Enter'
                                                        ) {
                                                            handleOpenLightbox(
                                                                index
                                                            )
                                                        }
                                                    }}
                                                    style={{
                                                        background: 'none',
                                                        border: 'none',
                                                        padding: 0,
                                                        cursor: 'pointer',
                                                    }}
                                                    tabIndex={0}
                                                    type="button"
                                                >
                                                    <img
                                                        src={item.url}
                                                        alt={title}
                                                    />
                                                </button>
                                            </div>
                                        )
                                    }
                                    return (
                                        <img
                                            src={Imagen}
                                            alt=""
                                            key={item.url}
                                        />
                                    )
                                }
                                return null
                            })}
                        </div>

                        {open && (
                            <Lightbox
                                open={open}
                                close={() => setOpen(false)}
                                slides={processedImages.map((img) => ({
                                    src: img.src,
                                    alt: img.alt,
                                }))}
                                index={currentIndex}
                                onIndexChange={setCurrentIndex}
                            />
                        )}
                    </div>
                </div>
                {actualizaciones.length === 0 ? (
                    ''
                ) : (
                    <div className="incidenciaDetail-infobox_state">
                        <div className="actualizaciones">
                            <h2 className="actualizaciones-title">
                                {texts.updateOrder}
                            </h2>
                        </div>
                        <ul>
                            {actualizaciones.length > 1 ? (
                                actualizaciones.map((actualizacion) => (
                                    <UpdateText data={actualizacion} />
                                ))
                            ) : (
                                <UpdateText data={actualizaciones} />
                            )}
                        </ul>
                    </div>
                )}
            </div>
        </PageRow>
    )
}

export default IncidenciaDetail
