import React from 'react'
import t from '../../../i18n/translations/translations'
import openingClosingTimeMessage from '../../../utils/openingClosingTimeMessage'
import AurretikStatusIcon from '../AurretikStatusIcon/AurretikStatusIcon'

import './AurretikFacilityState.css'

function AurretikFacilityState({ open, lang, time = '-' }) {
    return (
        <div className="aurretikFacilityState">
            <AurretikStatusIcon
                open={open}
                className="aurretikFacilityState--icon"
            />
            <p>
                <span className="aurretikFacilityState--uppercase">
                    {' '}
                    {open ? t.opened[lang] : t.closed[lang]}
                </span>{' '}
                | {openingClosingTimeMessage({ time, open, lang })}
            </p>
        </div>
    )
}

export default AurretikFacilityState
