import React from 'react'
import Info from '../../../assets/svg/aurretik/Info'
import t from '../../../i18n/translations/translations'

import './AurretikFacilityInfo.css'

function AurretikFacilityInfo({ data, lang }) {
    return (
        <div className="aurretikFacilityInfo">
            <div>
                <Info className="aurretikFacilityInfo_info-icon" />
                <span>{t.information[lang]}</span>
            </div>
            <div
                className="aurretikFacilityInfo__content"
                dangerouslySetInnerHTML={{ __html: data.description[lang] }}
            />
        </div>
    )
}

export default AurretikFacilityInfo
