import PropTypes from 'prop-types'
import React from 'react'
import ExternalLink from '../../atoms/ExternalLink/ExternalLink'
import './SocialCard.css'

function SocialIcon({ link, title, text, className }) {
    return (
        <ExternalLink url={link} title={title}>
            <span className="hidden-title">{text}</span>
            <i className={className} aria-hidden="true" />
        </ExternalLink>
    )
}

function SocialCard({ toggleComponent, ig, fb, tw, yt, android, apple }) {
    const fbIsLoaded = fb !== null && fb !== ''
    const twIsLoaded = tw !== null && tw !== ''
    const igIsLoaded = ig !== null && ig !== ''
    const ytIsLoaded = yt !== null && yt !== ''
    const appleIsLoaded = apple !== null && apple !== ''
    const androidIsLoaded = android !== null && android !== ''

    const iconClas = toggleComponent ? 'fa-3x' : 'fa-2x'

    return (
        <div className="icons-container">
            <div className="social-box">
                {fbIsLoaded ? (
                    <SocialIcon
                        link={fb}
                        title="facebook"
                        text="Facebook"
                        className={`fa fa-facebook-official ${iconClas}`}
                    />
                ) : null}
                {twIsLoaded ? (
                    <SocialIcon
                        link={tw}
                        title="twitter"
                        text="Twitter"
                        className={`fa fa-twitter ${iconClas}`}
                    />
                ) : null}
                {igIsLoaded ? (
                    <SocialIcon
                        link={ig}
                        title="instagram"
                        text="Instagram"
                        className={`fa fa-instagram ${iconClas}`}
                    />
                ) : null}
                {ytIsLoaded ? (
                    <SocialIcon
                        link={yt}
                        title="youtube"
                        text="YouTube"
                        className={`fa fa-youtube-play ${iconClas}`}
                    />
                ) : null}
                {appleIsLoaded ? (
                    <SocialIcon
                        link={apple}
                        title="apple"
                        text="Apple Store"
                        className={`fa fa-apple ${iconClas}`}
                    />
                ) : null}
                {androidIsLoaded ? (
                    <SocialIcon
                        link={android}
                        title="android"
                        text="Google Play"
                        className={`fa fa-android ${iconClas}`}
                    />
                ) : null}
            </div>
        </div>
    )
}

SocialCard.propTypes = {
    toggleComponent: PropTypes.bool.isRequired,
    ig: PropTypes.string,
    fb: PropTypes.string,
    tw: PropTypes.string,
}

SocialCard.defaultProps = {
    ig: null,
    fb: null,
    tw: null,
}

export default SocialCard
