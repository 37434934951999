import React from 'react'

function InternalLink({ url, children, className }) {
    return (
        <a
            href={url}
            target="_self"
            rel="noopener noreferrer"
            className={className}
        >
            {children}
        </a>
    )
}

export default InternalLink
