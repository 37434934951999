import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import getIcon from '../../helpers/switchTransparencyIcons'
import IndicatorCard from '../../atoms/IndicatorCard/IndicatorCard'
import './CategoryDetail.css'

const renderThirdLevelIndicators = (categoria) => {
    let thirdLevelIndicators = null
    if (categoria.indicadores.length > 0) {
        thirdLevelIndicators = (
            <div className="category-render__indicators-wrapper--third">
                {categoria.indicadores.map((indicador, index1) => (
                    <IndicatorCard
                        indicador={indicador.titulo}
                        status={indicador.status}
                        slug={indicador.slug}
                        level={categoria.nivel}
                        isOwnLink={indicador.es_propio}
                        url={indicador.url_externa}
                        key={index1}
                    />
                ))}
            </div>
        )
    }

    return thirdLevelIndicators
}

const renderThirdLevel = (categoria) => {
    let thirdLevelCaetgory
    if (categoria.categorias.length > 0) {
        thirdLevelCaetgory = categoria.categorias.map(
            (categoria3Nivel, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index}>
                    <div
                        className={classNames(
                            'category-render__inner-content',
                            'category-render__inner-content--third'
                        )}
                    >
                        <div
                            className={classNames(
                                'category-render__first-level-title'
                            )}
                        >
                            {getIcon(categoria3Nivel.icono)}
                            <h3>{categoria3Nivel.titulo}</h3>
                        </div>
                        <div className="category-render__first-level-subtitle">
                            <span>{categoria3Nivel.descripcion}</span>
                        </div>
                    </div>
                    <div>{renderThirdLevelIndicators(categoria3Nivel)}</div>
                </div>
            )
        )
    } else {
        thirdLevelCaetgory = null
    }

    return thirdLevelCaetgory
}

function CategoryDetail({ categoria }) {
    const categoryLevel = categoria?.nivel
    return (
        <section
            className={classNames('category-render__container', {
                'category-render__container--child': categoryLevel !== 0,
            })}
        >
            <div
                className={classNames('category-render__inner-content', {
                    'category-render__inner-content--child':
                        categoryLevel !== 0,
                })}
            >
                <div className="category-render__first-level-title">
                    {categoryLevel === 0 ? (
                        <h1>
                            {' '}
                            {getIcon(categoria.icono)}
                            {categoria.titulo}
                        </h1>
                    ) : (
                        <h2>
                            {' '}
                            {getIcon(categoria.icono)}
                            {categoria.titulo}
                        </h2>
                    )}
                </div>
                <div className="category-render__first-level-subtitle">
                    <span>{categoria.descripcion}</span>
                </div>
            </div>
            {categoria.indicadores.length > 0 ? (
                <div className="category-render__indicators-wrapper">
                    {categoria.indicadores.map((indicador, index1) => (
                        <IndicatorCard
                            indicador={indicador.titulo}
                            status={indicador.status}
                            slug={indicador.slug}
                            level={categoria.nivel}
                            isOwnLink={indicador.es_propio}
                            url={indicador.url_externa}
                            key={index1}
                        />
                    ))}
                </div>
            ) : null}
            {categoryLevel !== 0 && renderThirdLevel(categoria)}
        </section>
    )
}

CategoryDetail.propTypes = {
    categoria: PropTypes.shape({
        titulo: PropTypes.string.isRequired,
        icono: PropTypes.string.isRequired,
        descripcion: PropTypes.string.isRequired,
    }).isRequired,
}

export default CategoryDetail
