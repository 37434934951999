import React from 'react'
import { Link } from 'react-router-dom'
import './LanguageButton.css'

function LanguageButton({ link, text, title }) {
    return (
        <div className="language-button">
            <Link to={link} title={title}>
                {text}
            </Link>
        </div>
    )
}

export default LanguageButton
