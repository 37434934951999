import React from 'react'
import './SearchInput.css'

function SearchInput({ disabled, onChange, placeholder, value }) {
    const handleInputChange = event => {
        onChange(event)
    }

    return (
        <div className="searchInput__Container">
            <input
                aria-label={placeholder}
                type="text"
                value={value}
                onChange={handleInputChange}
                placeholder={placeholder}
                disabled={disabled}
            />
        </div>
    )
}

export default SearchInput
