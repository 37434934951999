import { MDBBreadcrumb, MDBBreadcrumbItem } from 'mdb-react-ui-kit'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import isAurretikPage from '../../utils/isAurretikPage'
import shortenTitle from '../../utils/shortenTitle'
import PageRow from '../PageRow/PageRow'

import './Breadcrumbs.css'

const checkIsAurretik = (slug) =>
    isAurretikPage(slug) || slug.includes('aurretik')

function BreadcrumbItemContentLinked({ item, maxLength }) {
    return (
        <Link to={item.slug}>
            {item.titulo.length < maxLength
                ? item.titulo
                : shortenTitle({
                      title: item.titulo,
                      index: maxLength,
                  })}
        </Link>
    )
}

const BreadcrumbItemContent = ({ item, maxLength }) =>
    item.titulo.length < maxLength
        ? item.titulo
        : shortenTitle({
              title: item.titulo,
              index: maxLength,
          })

function Breadcrumbs({ linkList }) {
    const arrayLength = linkList !== undefined ? linkList.length : 0
    const maxLengthActiveItem = 109
    const maxLengthRegularItem = 100

    return (
        <PageRow className="breadcrumb-content">
            <div className="breadcrumb-bluemargin" />
            <div className="breadcrumb-container">
                <MDBBreadcrumb>
                    {linkList && linkList.length
                        ? linkList.map((item, index) =>
                              arrayLength === index + 1 ? (
                                  // eslint-disable-next-line react/no-array-index-key
                                  <MDBBreadcrumbItem active key={index}>
                                      {!checkIsAurretik(item.slug) ? (
                                          <BreadcrumbItemContent
                                              item={item}
                                              maxLength={maxLengthActiveItem}
                                          />
                                      ) : (
                                          <BreadcrumbItemContentLinked
                                              item={item}
                                              maxLength={maxLengthActiveItem}
                                          />
                                      )}
                                  </MDBBreadcrumbItem>
                              ) : (
                                  // eslint-disable-next-line react/no-array-index-key
                                  <MDBBreadcrumbItem key={index}>
                                      <BreadcrumbItemContentLinked
                                          item={item}
                                          maxLength={maxLengthRegularItem}
                                      />
                                  </MDBBreadcrumbItem>
                              )
                          )
                        : null}
                </MDBBreadcrumb>
            </div>
        </PageRow>
    )
}

Breadcrumbs.propTypes = {
    linkList: PropTypes.arrayOf(
        PropTypes.shape({
            titulo: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
}

export default Breadcrumbs
