import React from 'react'

/* eslint-disable max-len */
export default function Cookie() {
    return (
        <svg viewBox="0 0 16 16">
            <defs>
                <polygon
                    id="path-1"
                    points="0 0.99 16.0109 0.99 16.0109 17 0 17"
                />
            </defs>
            <g
                id="Symbols"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Footer-/-desktop-/-grey"
                    transform="translate(-941.000000, -59.000000)"
                >
                    <g
                        id="Group-6"
                        transform="translate(941.000000, 58.000000)"
                    >
                        <g
                            id="Group-3"
                            transform="translate(0.000000, 0.009800)"
                        >
                            <mask id="mask-2" fill="white">
                                <use xlinkHref="#path-1" />
                            </mask>
                            <g id="Clip-2" />
                            <path
                                d="M10.5959,12.501 C10.0889,12.501 9.6789,12.09 9.6789,11.583 C9.6789,11.076 10.0889,10.666 10.5959,10.666 C11.1029,10.666 11.5139,11.076 11.5139,11.583 C11.5139,12.09 11.1029,12.501 10.5959,12.501 M5.3609,7.442 C4.8739,7.442 4.4809,7.048 4.4809,6.563 C4.4809,6.077 4.8739,5.683 5.3609,5.683 C5.8459,5.683 6.2409,6.077 6.2409,6.563 C6.2409,7.048 5.8459,7.442 5.3609,7.442 M5.3609,12.463 C4.8739,12.463 4.4809,12.069 4.4809,11.583 C4.4809,11.098 4.8739,10.704 5.3609,10.704 C5.8459,10.704 6.2409,11.098 6.2409,11.583 C6.2409,12.069 5.8459,12.463 5.3609,12.463 M11.5139,6.563 C11.5139,7.048 11.1029,7.442 10.5959,7.442 C10.0889,7.442 9.6789,7.048 9.6789,6.563 C9.6789,6.077 10.0889,5.683 10.5959,5.683 C10.8429,5.683 11.0659,5.779 11.2309,5.931 C9.9999,4.741 9.2189,3.098 9.1459,1.269 C8.8039,1.093 8.4189,0.99 8.0059,0.99 C7.2379,0.99 6.5529,1.324 6.0989,1.879 C5.4299,1.624 4.6669,1.678 4.0019,2.063 C3.3359,2.447 2.9109,3.079 2.7959,3.785 C2.0889,3.901 1.4569,4.327 1.0729,4.992 C0.6889,5.658 0.6359,6.419 0.8889,7.088 C0.3339,7.542 -0.0001,8.227 -0.0001,8.995 C-0.0001,9.764 0.3339,10.448 0.8899,10.902 C0.6359,11.572 0.6889,12.332 1.0729,12.998 C1.4569,13.664 2.0889,14.09 2.7959,14.205 C2.9109,14.911 3.3359,15.543 4.0019,15.928 C4.6679,16.313 5.4279,16.366 6.0989,16.111 C6.5519,16.666 7.2369,17 8.0059,17 C8.7739,17 9.4579,16.666 9.9119,16.111 C10.5809,16.366 11.3439,16.313 12.0089,15.928 C12.6749,15.543 13.0999,14.911 13.2149,14.205 C13.9219,14.089 14.5539,13.663 14.9389,12.998 C15.3229,12.332 15.3749,11.571 15.1219,10.902 C15.6769,10.448 16.0109,9.764 16.0109,8.995 C16.0109,8.582 15.9079,8.197 15.7329,7.855 C13.9949,7.786 12.4259,7.077 11.2519,5.951 C11.4129,6.109 11.5139,6.324 11.5139,6.563"
                                id="Fill-1"
                                fill="#FEFEFE"
                                mask="url(#mask-2)"
                            />
                        </g>
                        <path
                            d="M11.2307,5.9405 C11.2377,5.9485 11.2457,5.9545 11.2517,5.9615 C11.2457,5.9545 11.2377,5.9485 11.2307,5.9405"
                            id="Fill-4"
                            fill="#FEFEFE"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
