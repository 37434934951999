import React from 'react'
import { Link } from 'react-router-dom'
import ActionStar from '../../assets/svg/ActionStar'

function PlanEjeActionsContainer({ translations, actions }) {
    return (
        <div className="ejes-acciones-container">
            <div className="ejes-acciones-container-title">
                <ActionStar />
                <p>{translations?.ACCOUNTABILITY?.PLAN_DETAIL_ACTIONS_TITLE}</p>
            </div>
            <div className="acciones">
                {actions.map((ejeAction, index) => (
                    <div className="acciones-link" key={index}>
                        <Link to={ejeAction.slug}>{ejeAction.title}</Link>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PlanEjeActionsContainer
