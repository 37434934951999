import React from 'react'
import PlanState from '../../../atoms/PlanState/PlanState'
import PlanDetailChart from '../PlanDetailChart/PlanDetailChart'
import WysiwygFunctionality from '../../WysiwygFunctionality/WysiwygFunctionality'
import './PlanDetailDescriptionChart.css'
import PlanDetailSectionTitle from '../PlanDetailSectionTitle/PlanDetailSectionTitle'

function PlanDetailDescriptionChart({
    description,
    isActive,
    translations,
    percentage,
    percentageColor,
    presentation,
    text,
}) {
    return (
        <div className="planDetail-box_container-descrip-chart">
            <div className="planDetail-box_container-description">
                <WysiwygFunctionality
                    content={{
                        descripcion: description,
                    }}
                />
            </div>
            <div className="planDetail-box_container-chart">
                <div className="planDetail-box_container-chart_titl__container">
                    <PlanDetailSectionTitle
                        text={translations?.ACCOUNTABILITY?.PLAN_DETAIL_STATE}
                        className="planDetail-box_container-chart_title"
                    />
                    <div className="separador-chart" />
                    <PlanState active={isActive} translations={translations} />
                </div>
                {presentation !== 0 && (
                    <PlanDetailChart
                        value={percentage}
                        className="CircularProgressbar-path-orange"
                        text={text}
                        pathColor={percentageColor}
                        presentation={presentation}
                        translations={translations}
                    />
                )}
            </div>
            <div className="planDetail-box_container-description-responsive">
                <WysiwygFunctionality
                    content={{
                        descripcion: description,
                    }}
                />
            </div>
        </div>
    )
}

export default PlanDetailDescriptionChart
