import React from 'react'

/* eslint-disable max-len */
export default function Pdf() {
    return (
        <svg viewBox="0 0 42 42">
            <defs>
                <path
                    id="path-1"
                    d="M20.933 26.6a5.667 5.667 0 100-11.333 5.667 5.667 0 000 11.333zm6.346-.904l3.147 3.27a1.133 1.133 0 01-1.602 1.603l-3.15-3.274a7.933 7.933 0 111.605-1.6z"
                />
            </defs>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g>
                    <path
                        id="pdf-contorno"
                        // fill="#1C3561"
                        fillRule="nonzero"
                        d="M24.543 11.174l-.116-.11-9.377-.013V29.12h13.86V15.37h-4.367v-4.195zM12.95 8.949l12.316.016 5.745 5.448V31.22H12.95V8.95z"
                    />
                    <path
                        id="pdf-letras"
                        fill="#1C3561"
                        fillRule="nonzero"
                        stroke="#1C3561"
                        strokeWidth="0.2"
                        d="M16.486 19.052h1.578c.22 0 .428.02.624.06.196.04.367.109.513.207.146.098.262.23.348.396.086.166.129.375.129.627 0 .248-.04.456-.12.624-.08.168-.19.302-.33.402-.14.1-.306.171-.498.213-.192.042-.4.063-.624.063h-.684V23.3h-.936v-4.248zm.936 1.8h.624c.084 0 .165-.008.243-.024a.647.647 0 00.21-.081.432.432 0 00.15-.156.493.493 0 00.057-.249.421.421 0 00-.075-.261.499.499 0 00-.192-.153.822.822 0 00-.261-.069 2.667 2.667 0 00-.276-.015h-.48v1.008zm2.844-1.8h1.404c.34 0 .663.038.969.114.306.076.573.199.801.369.228.17.409.391.543.663.134.272.201.602.201.99 0 .344-.065.647-.195.909s-.305.482-.525.66c-.22.178-.474.313-.762.405a2.96 2.96 0 01-.906.138h-1.53v-4.248zm.936 3.384h.486c.216 0 .417-.022.603-.066.186-.044.347-.117.483-.219a1.05 1.05 0 00.321-.402c.078-.166.117-.369.117-.609 0-.208-.039-.389-.117-.543a1.086 1.086 0 00-.315-.381 1.379 1.379 0 00-.465-.225 2.065 2.065 0 00-.561-.075h-.552v2.52zm3.654-3.384h2.85v.864h-1.914v.864h1.77v.864h-1.77V23.3h-.936v-4.248z"
                    />
                </g>
            </g>
        </svg>
    )
}
