import getClosingMessage from './getClosingMessage'
import getOpeningMessage from './getOpeningMessage'

const openingClosingTimeMessage = ({ time, open, lang }) => {
    const message = open
        ? getClosingMessage({ time, lang })
        : getOpeningMessage({ time, lang })

    return message
}

export default openingClosingTimeMessage;