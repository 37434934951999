import { SUCCESS_CODE } from '../../../constants'
import {
    PAGE_CONTAINER,
    ACTION_CONTAINER,
    CATEGORIES_CONTAINER,
    INCIDENCE_CONTAINER,
    INDICATORS_CONTAINER,
    NEW_INCIDENCE_CONTAINER,
    NEWS_CONTAINER,
    PLAN_CONTAINER,
    PLAN_PROGRESS_CONTAINER,
    PLAN_SUMMARY_CONTAINER,
    FINDER_CONTAINER,
    AURRETIK_CONTAINER,
    IRUDIZ_CONTAINER,
} from '../../../constants/containersId'
import checkObjectIsNotEmpty from '../../../utils/checkObjectIsNotEmpty'
import getIrudizIdParam from '../../../utils/getIrudizIdParam'
import getPageInstanceId from '../../../utils/getPageInstanceId'
import paramIdIsCurrentPageId from '../../../utils/paramIdIsCurrentPageId'

const createResponseData = data => ({
    code: data.code,
    error: data.error,
    message: data.message,
})

const getFetchDataResponse = ({ data, containerId }) => {
    const { pages: page } = data
    const pageId = page && getPageInstanceId(page)

    switch (containerId) {
        case PAGE_CONTAINER:
            return createResponseData(data.pages)
        case ACTION_CONTAINER:
            return createResponseData(data.actions)
        case CATEGORIES_CONTAINER:
            return createResponseData(data.categories)
        case INCIDENCE_CONTAINER:
            return createResponseData(data.incidencias)
        case INDICATORS_CONTAINER:
            return createResponseData(data.indicators)
        case NEW_INCIDENCE_CONTAINER:
            return createResponseData(data.incidencias)
        case NEWS_CONTAINER:
            return createResponseData(data.news)
        case PLAN_CONTAINER:
            return createResponseData(data.plans)
        case PLAN_PROGRESS_CONTAINER:
            return createResponseData(data.plans)
        case PLAN_SUMMARY_CONTAINER:
            return createResponseData(data.plans)
        case FINDER_CONTAINER: {
            let response = {
                code: SUCCESS_CODE,
                error: false,
                message: '',
            }

            if (data.buscador.code !== SUCCESS_CODE) {
                response = createResponseData(data.buscador)
            } else if (data.tematicas.code !== SUCCESS_CODE) {
                response = createResponseData(data.tematicas)
            } else if (data.zonas.code !== SUCCESS_CODE) {
                response = createResponseData(data.zonas)
            }

            return response
        }
        case IRUDIZ_CONTAINER:
            if (
                data.irudizPostals &&
                checkObjectIsNotEmpty(data.irudizPostals) &&
                paramIdIsCurrentPageId({
                    paramId: getIrudizIdParam(data),
                    pageId,
                })
            ) {
                return createResponseData({
                    code: SUCCESS_CODE,
                    error: false,
                    message: '',
                })
            }

            return createResponseData({
                code: 404,
                error: false,
                message: '',
            })

        case AURRETIK_CONTAINER:
            if (
                data.infrastructures &&
                data.infrastructures.code === SUCCESS_CODE &&
                data.infrastructures.data &&
                data.infrastructures.data.length &&
                paramIdIsCurrentPageId({
                    paramId: data.instanceId,
                    pageId,
                })
            ) {
                return createResponseData(data.infrastructures)
            }

            return createResponseData(data.pages)

        default:
            return createResponseData(data.pages)
    }
}


export default getFetchDataResponse