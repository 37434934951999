import PropTypes from 'prop-types'
import React from 'react'
import Clip from '../../assets/svg/Clip'
import './NewsAttachments.css'

function NewsAttachments({ adjuntos, trads }) {
    return (
        <div className="news-attachments-container ">
            <div className="news-attachments-content">
                <p className="indicator-detail__attachments-title">
                    {adjuntos.length ? trads.adjuntos : ''}
                </p>
                {adjuntos.length
                    ? adjuntos.map((adjunto, index) => (
                          <div key={index}>
                              <Clip className="clip__icon" />
                              <span className="indicator-detail__attachments-subtitle">
                                  {adjunto.titulo}
                              </span>
                          </div>
                      ))
                    : null}
            </div>
        </div>
    )
}

NewsAttachments.propTypes = {
    adjuntos: PropTypes.arrayOf(
        PropTypes.shape({
            titulo: PropTypes.string.isRequired,
            documento: PropTypes.string.isRequired,
        })
    ).isRequired,
    trads: PropTypes.shape({
        lang: PropTypes.string.isRequired,
        buscar: PropTypes.string.isRequired,
        castellano: PropTypes.string.isRequired,
        idioma: PropTypes.string.isRequired,
        adjuntos: PropTypes.string.isRequired,
    }).isRequired,
}

export default NewsAttachments
