import React from 'react'
import Hitos from '../../../assets/svg/Hitos'
import './ActionDetailHitosTitle.css'

function ActionDetailHitosTitle({ title }) {
    return (
        <div className="actionDetail_action-descriptionContainer-hitos-title">
            <div className="hitos-title">
                <Hitos />
                <p>{title}</p>
            </div>
        </div>
    )
}

export default ActionDetailHitosTitle
