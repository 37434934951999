import PropTypes from 'prop-types'
import React from 'react'
import './NewsDate.css'

function NewsDate({ Day, Year }) {
    return (
        <div className="news-date-container">
            <span>{Day}</span>
            <span className="news-date_year">{Year}</span>
        </div>
    )
}

NewsDate.propTypes = {
    Day: PropTypes.string.isRequired,
    Year: PropTypes.string.isRequired,
}

export default NewsDate
