const paginatedTableEs = {
    page: 'Página',
    more: 'Más',
    to: 'a',
    of: 'de',
    next: 'Siguiente',
    last: 'Última',
    first: 'Primera',
    previous: 'Anterior',
    loadingOoo: 'Cargando...',
    noRowsToShow: 'No se encontraron filas',
    andCondition: 'Y',
    orCondition: 'O',
    applyFilter: 'Aplicar',
    resetFilter: 'Restablecer',
    clearFilter: 'Limpiar',
    filterOoo: 'Filtrando...',
    equals: 'Igual',
    notEqual: 'Diferente',
    lessThan: 'Menor que',
    lessThanOrEqual: 'Menor o igual que',
    greaterThan: 'Mayor que',
    greaterThanOrEqual: 'Mayor o igual que',
    inRange: 'En rango',
    contains: 'Contiene',
    notContains: 'No contiene',
    startsWith: 'Empieza con',
    endsWith: 'Termina con',
    selectAll: 'Seleccionar todo',
    searchOoo: 'Buscando...',
    blanks: 'Blancos',
    group: 'Grupo',
    columns: 'Columnas',
    rowGroupColumns: 'Columnas de agrupación de filas',
    rowGroupColumnsEmptyMessage: 'Arrastre columnas aquí para agrupar',
    valueColumns: 'Columnas de valor',
    pivotMode: 'Modo Pivote',
    groups: 'Grupos',
    values: 'Valores',
    pivots: 'Pivotes',
    valueColumnsEmptyMessage: 'Arrastre columnas aquí para agregar',
    pivotColumnsEmptyMessage: 'Arrastre aquí para establecer pivotes',
    toolPanelButton: 'Panel de herramientas',
    export: 'Exportar',
    csvExport: 'Exportar a CSV',
    excelExport: 'Exportar a Excel',
    pinColumn: 'Fijar columna',
    pinLeft: 'Fijar a la izquierda',
    pinRight: 'Fijar a la derecha',
    noPin: 'No fijar',
    autosizeThiscolumn: 'Ajustar tamaño de esta columna',
    autosizeAllColumns: 'Ajustar tamaño de todas las columnas',
    resetColumns: 'Restablecer columnas',
    collapseAll: 'Colapsar todo',
    expandAll: 'Expandir todo',
    toolPanel: 'Panel de herramientas',
    pageSizeSelectorLabel: 'Tamaño página:',
}

export default paginatedTableEs
