import { InfoWindow, Map, Marker } from '@vis.gl/react-google-maps'
import $ from 'jquery'
import Moment from 'moment'
import 'moment/locale/es'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Etiqueta from '../../assets/svg/Etiqueta'
import IncidenciaCalendar1 from '../../assets/svg/Incidencia-calendar1'
import KalezainduNoResultMessage from '../../atoms/KalezainduNoResultMessage/KalezainduNoResultMessage'
import IncidenceCard from '../../components/Kalezaindu/IncidenceCard/IncidenceCard'
import IncidenceFilters from '../../components/Kalezaindu/IncidenceFilters/IncidenceFilters'
import KalezainduForm from '../../forms/KalezainduForm'
import {
    setDefaultCoordenates as setDefaultCoordenatesAction,
    setSelectedCategories as setSelectedCategoriesAction,
    setSelectedIncidenciasList as setSelectedIncidenciasListAction,
} from '../../store/actions'
import getMarkerByState from './utils/getMarkerByState'

import './Kalezaindu.css'
import useSetIframeTitle from '../../hooks/useSetIframeTitle'
import t from '../../i18n/translations/translations'

function Kalezaindu({ translations }) {
    const [status, setStatus] = useState(false)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [lat, setLat] = useState(0)
    const [lng, setLng] = useState(0)
    const [date, setDate] = useState('')
    const [showingInfoWindow, setShowingInfoWindow] = useState(false)
    const [searchBar, setSearchBar] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [category, setCategory] = useState('')
    const [sort, setSort] = useState('created')
    const [enProceso, setEnProceso] = useState(false)
    const [resueltas, setResueltas] = useState(false)
    const [todas, setTodas] = useState(true)
    const [id, setId] = useState('')
    const [coordenadasActualizadas, setCoordenadasActualizadas] =
        useState(false)
    const [zoom, setZoom] = useState(15)

    const dispatch = useDispatch()

    const setSelectedIncidenciasList = (payload) =>
        dispatch(setSelectedIncidenciasListAction(payload))
    const setSelectedCategories = (payload) =>
        dispatch(setSelectedCategoriesAction(payload))
    const setDefaultCoordenates = (payload) =>
        dispatch(setDefaultCoordenatesAction(payload))

    const { categories, page, plans, incidencias } = useSelector((state) => ({
        categories: state.categories.undefined,
        page: state.page,
        plans: state.plans.undefined,
        incidencias: state.incidencias[state.page.slug],
    }))

    const { lang } = page

    useSetIframeTitle(t.kalezaindu_map[lang])

    useEffect(() => {
        const { domain, slug } = page

        const items = {
            lang,
            domain,
            slug,
            text: searchBar,
            startDate,
            endDate,
            category,
            sort,
            enProceso,
            resueltas,
            todas,
        }

        setSelectedIncidenciasList(items)

        const items2 = { lang, domain }
        setSelectedCategories(items2)
        setDefaultCoordenates(items2)

        $('.kalezainduMapContainer').parents('div').css('width', '100%')
    }, [enProceso, resueltas, todas])

    useEffect(() => {
        if (plans !== undefined) {
            const { latitude, longitude, zoom: zoomPlans } = plans.data[0]

            if (!coordenadasActualizadas) {
                if (lat !== latitude) {
                    setLat(parseFloat(latitude))
                    setCoordenadasActualizadas(true)
                }
                if (lng !== longitude) {
                    setLng(parseFloat(longitude))
                    setCoordenadasActualizadas(true)
                    setZoom(zoomPlans)
                }
            }
        }
    }, [coordenadasActualizadas, lat, lng, plans])

    const handleChange = (event) => {
        const { name, value } = event.target
        switch (name) {
            case 'searchBar':
                setSearchBar(value)
                break
            case 'startDate':
                setStartDate(value)
                break
            case 'endDate':
                setEndDate(value)
                break
            case 'category':
                setCategory(value)
                break
            case 'sort':
                setSort(value)
                break
            default:
                break
        }
    }

    const handleSubmit = useCallback(
        (event) => {
            const { domain, slug } = page

            const items = {
                lang,
                domain,
                slug,
                text: searchBar,
                startDate,
                endDate,
                category,
                sort,
                enProceso,
                resueltas,
                todas,
            }
            setSelectedIncidenciasList(items)

            if (event) {
                event.preventDefault()
            }
        },
        [
            page,
            searchBar,
            startDate,
            endDate,
            category,
            sort,
            enProceso,
            resueltas,
            todas,
        ]
    )

    const stateFilter = (event) => {
        event.preventDefault()
        const { value } = event.currentTarget
        setEnProceso(value === 'enProceso')
        setResueltas(value === 'resueltas')
        setTodas(value !== 'enProceso' && value !== 'resueltas')
    }

    useEffect(() => {
        handleSubmit()
    }, [enProceso, handleSubmit, resueltas, todas])

    const clickedPointUpdater = (
        categoryname,
        incidenciaid,
        newdate,
        newdescription,
        newlat,
        newlng,
        newStatus,
        newtitle
    ) => {
        setCategory(categoryname)
        setDate(newdate)
        setDescription(newdescription)
        setId(incidenciaid)
        setLat(newlat)
        setLng(newlng)
        setShowingInfoWindow(true)
        setStatus(newStatus)
        setTitle(newtitle)
    }

    const detalleIncidenciaUrl = `incidencias/${id}`

    let isMobile = false
    if (window.innerWidth <= 576) {
        isMobile = true
    }

    let cargado
    if (
        incidencias !== undefined &&
        categories !== undefined &&
        plans !== undefined
    ) {
        if (
            incidencias.data.length === 0 &&
            categories.data.length === 0 &&
            plans.data.length === 0
        ) {
            cargado = false
        } else {
            cargado = true
        }
    }

    let classNameIncidenciasGalleryContainer = ''
    if (incidencias?.data?.length > 3) {
        classNameIncidenciasGalleryContainer = 'incidencias-galleryContainer'
    } else if (incidencias?.data?.length === 3) {
        classNameIncidenciasGalleryContainer = 'incidencias-galleryContainer-3'
    } else {
        classNameIncidenciasGalleryContainer = 'incidencias-gallery-container-2'
    }

    return (
        <div>
            <div className="kalezainduMapContainer">
                <div className="map-container">
                    {lat !== 0 ? (
                        <Map
                            defaultCenter={{ lat, lng }}
                            defaultZoom={zoom}
                            style={{ width: '100vw', height: '100vh' }}
                            mapId="kalezaindu-map"
                            id="kalezaindu-map"
                        >
                            {cargado
                                ? incidencias.data.map((incidencia) => (
                                      <Marker
                                          key={incidencia.id}
                                          onClick={() =>
                                              clickedPointUpdater(
                                                  incidencia.category.name,
                                                  incidencia.id,
                                                  incidencia.created_at,
                                                  incidencia.description,
                                                  incidencia.latitude,
                                                  incidencia.longitude,
                                                  true,
                                                  incidencia.title
                                              )
                                          }
                                          icon={{
                                              url:
                                                  incidencia !== null &&
                                                  getMarkerByState(
                                                      incidencia.state
                                                  ),
                                              // set marker width and height
                                              anchor:
                                                  incidencia.state_name ===
                                                  'En proceso'
                                                      ? new window.google.maps.Point(
                                                            1.1,
                                                            1.1
                                                        )
                                                      : new window.google.maps.Point(
                                                            1,
                                                            1
                                                        ),
                                              scaledSize:
                                                  new window.google.maps.Size(
                                                      25,
                                                      25
                                                  ),
                                          }}
                                          name="Current location"
                                          position={{
                                              lat: incidencia.latitude,
                                              lng: incidencia.longitude,
                                          }}
                                      />
                                  ))
                                : ''}

                            {status && (
                                <InfoWindow
                                    position={{
                                        lat,
                                        lng,
                                    }}
                                    onCloseClick={() => {
                                        setStatus(false)
                                    }}
                                    visible={showingInfoWindow}
                                >
                                    <div className="combined-shape-container">
                                        <div className="container-popUp">
                                            <p className="plan">{title}</p>
                                            <p className="description">
                                                {description}
                                            </p>
                                        </div>
                                        <div className="details-container">
                                            <div className="details-container_detail">
                                                <Etiqueta />
                                                {category !== null
                                                    ? category
                                                    : 'Categoria'}
                                            </div>
                                            <div className="details-container_detail">
                                                <IncidenciaCalendar1 />
                                                {Moment(date).format(
                                                    'D-MMM-YYYY'
                                                )}
                                            </div>
                                        </div>
                                        <div className="ver-incidencia-container">
                                            <div>
                                                <a
                                                    className="ver-incidencia"
                                                    href={detalleIncidenciaUrl}
                                                >
                                                    {
                                                        translations.KALEZAINDU
                                                            .KALEZAINDU_VIEW_INCIDENCE
                                                    }
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </InfoWindow>
                            )}
                        </Map>
                    ) : (
                        ''
                    )}
                </div>
                <div className="indicencias-infobox">
                    <div className="incidencias-infobox_header">
                        <IncidenceFilters
                            action={stateFilter}
                            enProceso={enProceso}
                            resueltas={resueltas}
                            translations={translations}
                            todas={todas}
                        />

                        <Link
                            to={
                                page.lang === 'es'
                                    ? '/es/kalezaindu/nueva-incidencia'
                                    : '/eu/kalezaindu/intzidentzia-berria'
                            }
                        >
                            <div className="incidencias_reportBoton">
                                {
                                    translations.KALEZAINDU
                                        .KALEZAINDU_REPORT_INCIDENCE
                                }
                            </div>
                        </Link>
                    </div>
                    <KalezainduForm
                        handleSubmit={handleSubmit}
                        translations={translations}
                        handleChange={handleChange}
                        startDate={startDate}
                        endDate={endDate}
                        lang={page.lang}
                        categories={categories}
                        cargado={cargado}
                        searchBar={searchBar}
                        isMobile={isMobile}
                    />
                </div>
            </div>
            {cargado && incidencias.data.length > 0 ? (
                <ul className={classNameIncidenciasGalleryContainer}>
                    {incidencias.data.map((card) => (
                        <li
                            className={
                                incidencias.data.length > 2
                                    ? 'incidencia-card'
                                    : 'incidencia-card-2'
                            }
                            key={card.id}
                        >
                            <IncidenceCard
                                card={card}
                                incidencias={incidencias}
                                translations={translations}
                            />
                        </li>
                    ))}
                    {incidencias.data.length % 3 !== 0 ? (
                        <div className="fake-card" />
                    ) : (
                        ''
                    )}
                </ul>
            ) : (
                <div className="container-no-result">
                    <KalezainduNoResultMessage
                        translations={translations}
                        className="no-result"
                    />
                </div>
            )}
        </div>
    )
}

export default Kalezaindu
