import React from 'react'

/* eslint-disable max-len */
export default function Linkedin({ className }) {
    return (
        <svg className={className} viewBox="0 0 24 24">
            <g id="Icon-/-Solid-/-Linkedin">
                <g
                    id="boton-del-logo-de-linkedin"
                    transform="translate(2.000000, 2.000000)"
                >
                    <path
                        d="M3.02121502,0 C1.35339314,0 0,1.35339314 0,3.02121502 L0,16.9786011 C0,18.6466069 1.35339314,20 3.02121502,20 L16.9812119,20 C18.6494015,20 20,18.6466069 20,16.978785 L20,3.02121502 C19.9999632,1.35339314 18.6491808,0 16.9811751,0 L3.02121502,0 Z M5.4628089,2.48939617 C6.4905949,2.48939617 7.12419586,3.16598242 7.14361095,4.05319292 C7.14361095,4.92120896 6.48901375,5.61437893 5.44140818,5.61437893 L5.42280206,5.61437893 C4.41380604,5.61437893 3.76060613,4.92117219 3.76060613,4.05319292 C3.76060613,3.16598242 4.43399331,2.48939617 5.4628089,2.48939617 Z M14.3245812,6.63819366 C16.3025664,6.63819366 17.7845845,7.92940705 17.7845845,10.707382 L17.7845845,15.8937833 L14.779181,15.8937833 L14.779181,11.0557871 C14.779181,9.84017488 14.3431873,9.01058453 13.2551705,9.01058453 C12.424955,9.01058453 11.9321869,9.56858434 11.7153852,10.1089708 C11.6359966,10.3021656 11.6143753,10.5725795 11.6143753,10.8429566 L11.6143753,15.8937465 L8.60897175,15.8937833 C8.60897175,15.8937833 8.64875797,7.69955158 8.60897175,6.85117125 L11.6143753,6.85117125 L11.6143753,8.13315512 C12.0133773,7.51657912 12.7278009,6.63819366 14.3245812,6.63819366 Z M3.93879836,6.85098739 L6.94398123,6.85098739 L6.94398123,15.8935627 L3.93879836,15.8935627 L3.93879836,6.85098739 Z"
                        id="Shape"
                    />
                </g>
            </g>
        </svg>
    )
}
