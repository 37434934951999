import React from 'react'
import PlanDownloadsContainer from '../../../atoms/PlanDownloadsContainer/PlanDownloadsContainer'
import PlanEjeActionsContainer from '../../../atoms/PlanEjeActionsContainer/PlanEjeActionsContainer'
import PlanDetailEjeDescriptionChart from '../PlanDetailEjeDescriptionChart/PlanDetailEjeDescriptionChart'
import './PlanDetailEjesDescriptionContainer.css'

export default function PlanDetailEjesDescriptionContainer({
    eje,
    translations,
}) {
    return (
        <div
            className={
                eje.isActive
                    ? 'ejes-description-shown'
                    : 'ejes-description-hidden'
            }
        >
            <div className="ejes-description-containter">
                <PlanDetailEjeDescriptionChart
                    eje={eje}
                    translations={translations}
                />

                {eje.links?.length ? (
                    <PlanDownloadsContainer
                        translations={translations}
                        links={eje.links}
                    />
                ) : null}
                {eje.actions?.length ? (
                    <PlanEjeActionsContainer
                        translations={translations}
                        actions={eje.actions}
                    />
                ) : null}
            </div>
        </div>
    )
}
