import classNames from 'classnames'
import React from 'react'

import './Button.css'

function Button({
    className,
    disabled,
    secondary,
    inverted,
    transparent,
    children,
    ...rest
}) {
    return (
        <button
            className={classNames(`button ${className}`, {
                'button--disabled': disabled,
            })}
            disabled={disabled}
            type="button"
            {...rest}
        >
            {children}
        </button>
    )
}

export default Button
