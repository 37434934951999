import mapLocalityTransformer from './mapLocalityTransformer'

const mapResourceTransformer = resource => ({
    id: resource.idrecurso,
    locality: mapLocalityTransformer(resource.municipio),
    description: {
        es: resource.descripcion_es,
        eu: resource.descripcion_eu,
    },
    contact: {
        email: resource.email,
        phone: resource.telefono,
        web: resource.web,
    },
    address: {
        street: {
            es: resource.direccion_es,
            eu: resource.direccion_eu,
        },
        locality: {
            es: mapLocalityTransformer(resource.municipio).name.es
                ? mapLocalityTransformer(resource.municipio).name.es
                : null,
            eu: mapLocalityTransformer(resource.municipio).name.eu
                ? mapLocalityTransformer(resource.municipio).name.eu
                : null,
        },
        postalCode: resource.codigo_postal,
    },
    name: {
        es: resource.nombre_es,
        eu: resource.nombre_eu,
    },
    coordinates: {
        lat: parseFloat(resource.lat, 0),
        lng: parseFloat(resource.lng, 0),
    },
    area: {
        coordinates: resource.coordenadas_area,
        resources: resource.recursoarea,
    },
    visible: resource.is_visible,
    external: resource.is_external,
})


export default mapResourceTransformer;