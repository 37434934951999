import React from 'react'

/* eslint-disable max-len */
export default function ArrowUp({ alt }) {
    return (
        <svg alt={alt} viewBox="0 0 15 10">
            <title>Cerrar</title>
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Rendicion_accion_desktop_up"
                    transform="translate(-1112.000000, -2781.000000)"
                    fill="#979797"
                    fillRule="nonzero"
                >
                    <g
                        id="Group-5-Copy"
                        transform="translate(218.000000, 2663.000000)"
                    >
                        <path
                            d="M895.643187,118.403518 C895.313759,118.048296 894.75874,118.027385 894.403518,118.356813 C894.048296,118.686241 894.027385,119.24126 894.356813,119.596482 L901.490875,127.289145 L908.642426,119.597301 C908.972306,119.242499 908.952103,118.687454 908.597301,118.357574 C908.242499,118.027694 907.687454,118.047897 907.357574,118.402699 L901.492517,124.710855 L895.643187,118.403518 Z"
                            id="Path-10-Copy"
                            transform="translate(901.500000, 122.705971) rotate(-180.000000) translate(-901.500000, -122.705971) "
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
