import React from 'react'

function ActionMapSearchForm({
    translations,
    handleChange,
    texto,
    handleSearchBar,
    blockSearch,
    actionUrl,
}) {
    return (
        <form action={actionUrl} method="GET">
            <div
                id="searchbar"
                className={
                    !blockSearch
                        ? 'am-search-form-plan'
                        : 'am-search-form-hidden-plan'
                }
            >
                <input
                    aria-label="Search"
                    className={
                        !blockSearch
                            ? 'am-search-input-plan'
                            : 'am-search-hidden-plan'
                    }
                    id="busqueda"
                    placeholder={translations.ACCOUNTABILITY.PLAN_LIST_SEARCH}
                    type="text"
                    name="busqueda"
                    onChange={handleChange}
                    value={texto}
                />
                <button
                    className="button-no-style"
                    onClick={handleSearchBar}
                    title="search"
                    type="button"
                >
                    <i
                        title="search"
                        className={
                            !blockSearch
                                ? 'fa fa-search search-icon-plan-am'
                                : 'fa fa-search search-hidden-icon-plan-am'
                        }
                        aria-hidden="true"
                    />
                    <p className="hiddenParagraph-plan">Search</p>
                </button>
            </div>
        </form>
    )
}

export default ActionMapSearchForm
