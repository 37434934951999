import moment from 'moment'
import React from 'react'
import { CalendarIcon } from '../../../assets/svg/hartuz'
import PageRow from '../../../atoms/PageRow/PageRow'
import { DATE_FORMAT } from '../../../constants'
import t from '../../../i18n/translations/translations'
import './HartuzSearchInput.css'

function HartuzSearchInput({ disabled, lang, selectedDate, openModal }) {
    const date =
        selectedDate && lang === 'es'
            ? moment(selectedDate).format(DATE_FORMAT)
            : selectedDate

    return (
        <section className="hartuzSearchInput">
            <PageRow>
                <button
                    aria-haspopup="true"
                    disabled={disabled}
                    className={`hartuzSearchInput__button${
                        selectedDate ? ' active' : ''
                    }`}
                    onClick={() => openModal()}
                    type="button"
                >
                    <CalendarIcon />
                    {date || t.see_calendar[lang]}
                </button>
            </PageRow>
        </section>
    )
}

export default HartuzSearchInput
