import classNames from 'classnames'
import React from 'react'
import ActionDetailHitoState from '../../../atoms/ActionDetails/ActionDetailHitoState/ActionDetailHitoState'
import Separator from '../../../atoms/Separator/Separator'
import ActionDetailHitosDatesContainer from '../ActionDetailHitosDatesContainer/ActionDetailHitosDatesContainer'
import WysiwygFunctionality from '../../WysiwygFunctionality/WysiwygFunctionality'
import './ActionDetailHitosDescription.css'

function ActionDetailHitosDescription({ hito, translations }) {
    return (
        <div
            className={classNames('hitos-description__wrapper', {
                'hitos-description-visible': hito.isActive,
                'hitos-description-hidden': !hito.isActive,
            })}
        >
            <div className="hitos-description-containter">
                <div className="hitos-description-estado">
                    <div className="hitos-description">
                        <WysiwygFunctionality
                            className="description"
                            content={{
                                descripcion: hito.description,
                            }}
                        />
                    </div>
                    <div className="hitos-estado">
                        <div className="hitos-estado-title">
                            <p>
                                {translations.ACCOUNTABILITY.PLAN_DETAIL_STATE}
                            </p>
                            <Separator className="separador-hitos" />
                        </div>

                        <ActionDetailHitoState
                            state={hito.state}
                            translations={translations}
                        />
                    </div>
                    <div className="hitos-description-responsive">
                        <WysiwygFunctionality
                            className="description"
                            content={{
                                descripcion: hito.description,
                            }}
                        />
                    </div>
                </div>
                <ActionDetailHitosDatesContainer
                    translations={translations}
                    hito={hito}
                />
                <div />
            </div>
        </div>
    )
}

export default ActionDetailHitosDescription
