import PropTypes from 'prop-types'
import React from 'react'
import './NewsTitle.css'

function NewsTitle({ title }) {
    return (
        <div className="news-title-container">
            <div className="news-title-content">
                <span className="news-title_text">{title}</span>
            </div>
        </div>
    )
}

NewsTitle.propTypes = {
    title: PropTypes.string.isRequired,
}

export default NewsTitle
