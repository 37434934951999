import React from 'react'
import Objetivos from '../../../assets/svg/Objetivos'
import './ActionDetailObjectivesTitle.css'

function ActionDetailObjectivesTitle({ title }) {
    return (
        <div className="actionDetail_action-descriptionContainer-objetivos-title">
            <div className="objetivos-title">
                <Objetivos />
                <p>{title}</p>
            </div>
        </div>
    )
}

export default ActionDetailObjectivesTitle
