/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types'
import React from 'react'
import './FullScreenError.css'

function Error({ content }) {
    const backgroundErrorImage = {
        background: `url('/img/generic_background.png') no-repeat center center fixed`,
        height: '100vh',
        WebkitBackgroundSize: 'cover',
        MozBackgroundSize: 'cover',
        OBackgroundSize: 'cover',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }

    return (
        <div className="fullscreenerror_cont">
            <div
                className="fullscreenerror_container"
                style={backgroundErrorImage}
            >
                <div className="fullscreenerror_content">
                    <div className="fullscreenerror_text">
                        <div>
                            <p className="fullscreenerror_title">
                                {content.error_titulo}
                            </p>
                            <p className="fullscreenerror_subtitle">
                                {content.error_subtitulo}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Error
