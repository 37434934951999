import React from 'react'
import PlanDetailDate from '../PlanDetailDate/PlanDetailDate'

function PlanDetailDatesContainer({ translations, startDate, endDate }) {
    return (
        <div className="planDetail-box_container-dates">
            {startDate !== null ? (
                <PlanDetailDate
                    title={translations?.ACCOUNTABILITY?.PLAN_DETAIL_DATE_START}
                    date={startDate}
                />
            ) : (
                ''
            )}
            {endDate !== null ? (
                <PlanDetailDate
                    title={translations?.ACCOUNTABILITY?.PLAN_DETAIL_DATE_END}
                    date={endDate}
                />
            ) : (
                ''
            )}
        </div>
    )
}

export default PlanDetailDatesContainer
