import { InfoWindow, Map, Marker } from '@vis.gl/react-google-maps'
import $ from 'jquery'
import React, { useEffect, useState } from 'react'
import './ActionMap.css'
import ActionMapBox from './ActionMap_Box/ActionMapBox'
import t from '../../../i18n/translations/translations'
import useSetIframeTitle from '../../../hooks/useSetIframeTitle'

function ActionMap({ contenido, translations, lang }) {
    const [status, setStatus] = useState(false)
    const [plan, setPlan] = useState('')
    const [lat, setLat] = useState('')
    const [lng, setLng] = useState('')
    const [action, setAction] = useState('')
    const [planSlug, setPlanSlug] = useState('')
    const [actionSlug, setActionSlug] = useState('')
    const [blockSearch, setBlockSearch] = useState(true)
    const [texto, setTexto] = useState('')

    useSetIframeTitle(t.kalezaindu_map[lang])

    useEffect(() => {
        $('.actionMap__category-box').parents('div').css('width', '100%')
    }, [])

    const handleChange = (event) => {
        const { value } = event.target
        setTexto(value)
    }

    const clickedPointUpdater = (
        newPlan,
        newlat,
        newlng,
        newStatus,
        newAction,
        newPlanSlug,
        newActionSlug
    ) => {
        setStatus(newStatus)
        setPlan(newPlan)
        setLat(newlat)
        setLng(newlng)
        setAction(newAction)
        setPlanSlug(newPlanSlug)
        setActionSlug(newActionSlug)
    }

    const handleSearchBar = () => {
        setBlockSearch(!blockSearch)
    }

    const hasWindow = typeof window !== 'undefined'
    const width = hasWindow ? window.innerWidth : null
    const height = hasWindow ? window.innerHeight : null
    const texts = {
        viewPlan: translations.ACCOUNTABILITY.ACTION_MAP_VIEW_PLAN,
        viewAction: translations.ACCOUNTABILITY.ACTION_MAP_VIEW_ACTION,
    }

    return (
        <div className="actionMap__category-box">
            <div className="actionMap_map_container">
                <Map
                    defaultCenter={{
                        lat: contenido.default_coordenates.latitude,
                        lng: contenido.default_coordenates.longitude,
                    }}
                    defaultZoom={15}
                    style={{ width: '100vw', height: '100vh' }}
                >
                    {contenido.actions.map((actionContenido) => (
                        <Marker
                            key={actionContenido.index}
                            onClick={() =>
                                clickedPointUpdater(
                                    actionContenido.plan_title,
                                    actionContenido.latitude,
                                    actionContenido.longitude,
                                    true,
                                    actionContenido.title,
                                    actionContenido.plan_slug,
                                    actionContenido.slug
                                )
                            }
                            position={{
                                lat: actionContenido.latitude,
                                lng: actionContenido.longitude,
                            }}
                            visible={actionContenido.show_in_map}
                        />
                    ))}

                    {status && (
                        <InfoWindow
                            position={{
                                lat,
                                lng,
                            }}
                            onCloseClick={() => setStatus(false)}
                        >
                            <div className="combined-shape-container">
                                <div className="container-popUp">
                                    <p className="plan">{plan}</p>
                                    <p className="accion">► {action}</p>
                                </div>
                                <div className="opciones">
                                    <div className="ver-plan">
                                        <a href={planSlug}>{texts.viewPlan}</a>
                                    </div>
                                    <div>|</div>
                                    <div className="ver-accion">
                                        <a href={actionSlug}>
                                            {texts.viewAction}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </InfoWindow>
                    )}
                </Map>
            </div>
            {contenido.presentacion === 0 ? (
                <ActionMapBox
                    resizewidth={width}
                    resizeheigth={height}
                    sumary={contenido.sumary}
                    translations={translations}
                    handleChange={handleChange}
                    handleSearchBar={handleSearchBar}
                    blockSearch={blockSearch}
                    texto={texto}
                    lang={lang}
                />
            ) : null}
        </div>
    )
}

export default ActionMap
