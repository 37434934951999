import { Field, withFormik } from 'formik'
import React from 'react'
import { bool, date, object, string } from 'yup'
import Button from '../../atoms/Button/Button'
import FormCheckbox from '../../atoms/FormCheckBox/FormCheckBox'
import FormError from '../../atoms/FormError/FormError'
import FormGroup from '../../atoms/FormGroup/FormGroup'
import FormGroupInput from '../../atoms/FormGroupInput/FormGroupInput'
import { EMAIL_REGEX, PASSPORT_REGEX } from '../../constants/regex'
import STATES from '../../constants/states'
import { validateDNI } from '../../constants/validateDNI'
import t from '../../i18n/translations/translations'
import getCapitalizedFirstWord from '../../utils/texts/getCapitalized'
import AcceptPolicyLabel from '../AcceptPolicyLabel'
import './HartuzCancelationForm.css'

const HartuzCancelationFormHourFilter = withFormik({
    displayName: 'HartuzCancelationFormHourFilter',
    handleSubmit: (values, formikBag) => {
        if (typeof formikBag.props.handleSubmit !== 'function') {
            return
        }
        formikBag.props.handleSubmit(values)
    },
    mapPropsToValues: (props) => ({
        dni: '',
        email: '',
        date: '',
        hour: '',
        facility: '',
        accept_policy: false,
        instanceID: props.instanceID,
        lang: props.lang,
    }),

    validationSchema: (props) => {
        const { lang } = props
        return object().shape({
            dni: string()
                .test('dni', t.error_valid_id[lang], (value) => {
                    if (value) {
                        const result = validateDNI(value)
                        if (result.valid) {
                            return true
                        }
                    }
                    return false
                })
                .required(t.error_required_id[lang]),
            email: string()
                .matches(EMAIL_REGEX, t.error_email[lang])
                .required(t.error_required_email[lang]),
            date: date().required(t.error_required_email[lang]),
            hour: string().required(t.error_required_time[lang]),
            facility: string().required(t.error_required_facility[lang]),
            accept_policy: bool().oneOf([true], t.error_accept_policy[lang]),
        })
    },
})(
    ({
        errors,
        touched,
        handleChange,
        handleSubmit,
        values,
        lang,
        state,
        facilities,
        setFieldValue,
    }) => (
        <form onSubmit={handleSubmit}>
            <div className="hartuzCancellationformHourFilter">
                <div className="hartuzCancellationform__input--wrapper">
                    <FormGroupInput
                        autoComplete="dni"
                        errors={touched.dni && errors.dni ? errors.dni : null}
                        aria-invalid={
                            touched.dni && errors.dni ? errors.dni : null
                        }
                        id="dni"
                        label={t.dni[lang]}
                        onChange={handleChange}
                        placeholder={t.dni[lang]}
                        required
                        value={values.dni}
                    />
                </div>

                <div className="hartuzCancellationform__input--wrapper">
                    <FormGroupInput
                        autoComplete="email"
                        errors={
                            touched.email && errors.email ? errors.email : null
                        }
                        aria-invalid={
                            touched.email && errors.email ? errors.email : null
                        }
                        id="email"
                        label={t.email[lang]}
                        onChange={handleChange}
                        placeholder={t.email[lang]}
                        required
                        type="email"
                        value={values.email}
                    />
                </div>
                <div className="hartuzCancellationform__input--wrapper">
                    <FormGroupInput
                        autoComplete="date"
                        errors={
                            touched.date && errors.date ? errors.date : null
                        }
                        aria-invalid={
                            touched.date && errors.date ? errors.date : null
                        }
                        id="date"
                        label={t.date[lang]}
                        onChange={handleChange}
                        required
                        type="date"
                        value={values.date}
                    />
                </div>
                <div className="hartuzCancellationform__input--wrapper">
                    <FormGroupInput
                        autoComplete="hour"
                        errors={
                            touched.hour && errors.hour ? errors.hour : null
                        }
                        aria-invalid={
                            touched.hour && errors.hour ? errors.hour : null
                        }
                        id="hour"
                        label={t.hour[lang]}
                        onChange={handleChange}
                        required
                        type="time"
                        value={values.hour}
                    />
                </div>

                <div className="hartuzCancellationform__input--wrapper">
                    <label
                        className="form-group__facilitySelect"
                        htmlFor="facility"
                    >
                        {t.facility[lang]}*
                    </label>
                    <Field
                        as="select"
                        className="hartuzCancellationform__facilitySelect"
                        id="facility"
                        name="facility"
                        required
                    >
                        <option value=""> -- {t.pick_option[lang]} -- </option>
                        {facilities?.map((facility) => (
                            <option key={facility.id} value={facility.id}>
                                {getCapitalizedFirstWord(facility.name[lang])}
                            </option>
                        ))}
                    </Field>
                    {touched.facility && errors.facility && (
                        <div className="form-error form-group__errors">
                            {errors.facility}
                        </div>
                    )}
                </div>

                <FormGroup
                    className="hartuzCancellationform__checkbox"
                    errors={
                        touched.accept_policy && errors.accept_policy
                            ? errors.accept_policy
                            : null
                    }
                    id="accept_policy"
                    label=""
                    required
                >
                    <FormCheckbox
                        errors={
                            touched.accept_policy && errors.accept_policy
                                ? errors.accept_policy
                                : null
                        }
                        aria-invalid={
                            touched.accept_policy && errors.accept_policy
                                ? errors.accept_policy
                                : null
                        }
                        label={AcceptPolicyLabel(lang)}
                        name="politicsButtonCheck"
                        onChange={() =>
                            setFieldValue(
                                'accept_policy',
                                !values.accept_policy
                            )
                        }
                        value={values.accept_policy}
                    />
                </FormGroup>
                <div className="hartuzCancellationform__submit">
                    <Button
                        disabled={state === STATES.SUBMITTING}
                        type="submit"
                    >
                        {t.action_cancel_booking[lang]}
                    </Button>
                </div>
            </div>
            <div className="hartuzCancellationform__errors">
                {state === STATES.ERROR && (
                    <FormError className="form-group__errors">
                        {t.error_cancelation_no_booking[lang]}
                    </FormError>
                )}

                {state === STATES.ERROR_TIME_EXPIRED && (
                    <FormError className="form-group__errors">
                        {t.error_cancelation_no_booking_time[lang]}
                    </FormError>
                )}
            </div>
        </form>
    )
)

export default HartuzCancelationFormHourFilter
