import AbiertaMarker from '../../../assets/svg/Markers_Kalezaindu/Abierta.svg'
import EnProcesoMarker from '../../../assets/svg/Markers_Kalezaindu/EnProceso.svg'
import RechazadaMarker from '../../../assets/svg/Markers_Kalezaindu/Rechazada.svg'
import ResueltaMarker from '../../../assets/svg/Markers_Kalezaindu/Resuelta.svg'
import UnificadaMarker from '../../../assets/svg/Markers_Kalezaindu/Unificada.svg'

const incidenceStates = {
    0: {
        marker: AbiertaMarker,
        state: 'OPEN',
    },
    1: {
        marker: EnProcesoMarker,
        state: 'IN_PROGRESS',
    },
    2: {
        marker: ResueltaMarker,
        state: 'RESOLVED',
    },
    3: {
        marker: RechazadaMarker,
        state: 'REJECTED',
    },
    4: {
        marker: UnificadaMarker,
        state: 'UNIFIED',
    },
}

const getMarkerByState = state =>
    incidenceStates[state].marker ? incidenceStates[state].marker : ''

export default getMarkerByState