import React from 'react'
import Finder from '../components/Finder/Finder'

export default class FinderContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            busqueda: '',
            tematica: '',
            zona: '',
            textoBusqueda: '',
            mostrarResultado: false,
            justAction: false,
        }
    }

    handleChange = async (event) => {
        const { name, value } = event.target
        await this.setState({ [name]: value })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        const { match, searchAction } = this.props
        const { lang } = match
        const { tematica, zona, busqueda } = this.state
        const items = {
            lang,
            domain: window.location.origin,
            slug: 'planes',
        }

        searchAction(items)

        const setStateBasedOnSearch = (mostrarResultado, justAction) => {
            this.setState({
                mostrarResultado,
                textoBusqueda: busqueda,
                justAction,
            })
        }

        const isBusquedaValid = busqueda !== null && busqueda !== ''

        if ((tematica === null && zona === null) || isBusquedaValid) {
            setStateBasedOnSearch(
                isBusquedaValid,
                !(tematica === null && zona === null)
            )
        } else {
            setStateBasedOnSearch(false, true)
        }
    }

    render() {
        const { data, match, isMobile, isTablet } = this.props
        const { lang } = match
        const {
            busqueda,
            justAction,
            mostrarResultado,
            tematica,
            textoBusqueda,
            zona,
        } = this.state

        const sortedArray = []
        const justDataRequired = []
        const plans = []

        // definicion de plans que se pasa en plandetail

        if (Array.isArray(data.buscador.data[0]) === false) {
            data.buscador.data.map((resultados, index) =>
                index === 0 || index === 1
                    ? sortedArray.push(resultados.actions)
                    : sortedArray.push(resultados)
            )
            sortedArray.forEach((p) => {
                if (p.length > 0) {
                    p.forEach((r) => {
                        if (
                            r.plan_title !== undefined &&
                            r.axis_title !== undefined
                        ) {
                            justDataRequired.push({
                                tituloPlan: r.plan_title,
                                tituloEje: r.axis_title,
                                tituloAccion: r.title,
                                porcentajePlan: r.plan_percentage,
                                porcentajeEje: r.axis_percentage,
                                porcentajeAccion: r.percentage,
                                planSi: r.plan_show_percentage,
                                ejeSi: r.axis_show_percentage,
                                accionSi: r.show_percentage,
                                planSlug: r.plan_slug,
                                accionSlug: r.slug,
                            })
                        } else if (r.axis !== undefined) {
                            justDataRequired.push({
                                tituloPlan: r.title,
                                tituloEje: null,
                                tituloAccion: null,
                                porcentajePlan: r.percentage,
                                porcentajeEje: null,
                                porcentajeAccion: null,
                                planSi: r.show_plan_percentage,
                                ejeSi: null,
                                accionSi: null,
                                planSlug: r.slug,
                                accionSlug: null,
                            })
                        } else {
                            justDataRequired.push({
                                tituloPlan: r.plan_title,
                                tituloEje: r.title,
                                tituloAccion: null,
                                porcentajePlan: r.plan_percentage,
                                porcentajeEje: r.percentage,
                                porcentajeAccion: null,
                                planSi: r.show_plan_percentage,
                                ejeSi: r.show_percentage,
                                accionSi: null,
                                planSlug: r.plan_slug,
                                accionSlug: null,
                            })
                        }
                    })
                }
            })
            const obj = {}
            justDataRequired.forEach((s) => {
                if (!(s.tituloPlan in obj)) {
                    obj[s.tituloPlan] = true
                    plans.push({
                        tituloPlan: s.tituloPlan,
                        tituloEjes: [],
                        tituloAcciones: [],
                        porcentajePlan: s.porcentajePlan,
                        porcentajeEjes: [],
                        porcentajeAcciones: [],
                        planesSi: s.planSi,
                        ejesSi: [],
                        accionesSi: [],
                        planSlug: s.planSlug,
                        accionSlugs: [],
                    })
                }
            })
        }

        return (
            <Finder
                isMobile={isMobile}
                isTablet={isTablet}
                translations={data.plans.data[0].translations}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                busqueda={busqueda}
                textoBusqueda={textoBusqueda}
                tematica={tematica}
                zona={zona}
                mostrarResultado={mostrarResultado}
                tematicas={data.tematicas}
                zonas={data.zonas}
                lang={lang}
                buscador={data.buscador}
                justAction={justAction}
                justDataRequired={justDataRequired}
                plans={plans}
            />
        )
    }
}
