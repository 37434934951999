import { useState, useEffect } from 'react'

const useImageExists = (imageUrl) => {
    const [imageExists, setImageExists] = useState(true)

    useEffect(() => {
        const img = new Image()
        img.src = imageUrl

        img.onload = () => {
            setImageExists(true)
        }

        img.onerror = () => {
            setImageExists(false)
        }
    }, [imageUrl])

    return imageExists
}

export default useImageExists
