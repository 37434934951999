import { withFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { bool, number, object, string } from 'yup'
import { CalendarIcon, Time } from '../../assets/svg/hartuz'
import Button from '../../atoms/Button/Button'
import FormCheckbox from '../../atoms/FormCheckBox/FormCheckBox'
import FormError from '../../atoms/FormError/FormError'
import FormGroup from '../../atoms/FormGroup/FormGroup'
import FormGroupInput from '../../atoms/FormGroupInput/FormGroupInput'
import ScrollToError from '../../behaviours/ScrollToError'
import Modal from '../../components/Modal/Modal'
import {
    EMAIL_REGEX,
    ONLY_LETTERS_REGEX,
    PHONE_REGEX,
    POSTCODE_REGEX,
} from '../../constants/regex'
import STATES from '../../constants/states'
import { validateDNI } from '../../constants/validateDNI'
import t from '../../i18n/translations/translations'
import convertDateToString from '../../utils/dates/convertDateToString'
import AcceptPolicyLabel from '../AcceptPolicyLabel'
import './HartuzFacilityBookingForm.css'

const HartuzFacilityBookingForm = withFormik({
    displayName: 'HartuzFacilityBookingForm',
    handleSubmit: (values, formikBag) => {
        if (typeof formikBag.props.handleSubmit !== 'function') {
            return
        }

        formikBag.props.handleSubmit(values)
    },
    mapPropsToValues: (props) => ({
        accept_policy: false,
        date: props.date,
        dni: props.initialValues?.dni || '',
        email: props.initialValues?.email || '',
        facilityName: props.facilityName,
        id_facility: props.facilityID,
        id_instancia: props.instanceID,
        username: props.initialValues?.username || '',
        phone: props.initialValues?.phone || '',
        people: props.people || 1,
        postalCode: props.initialValues?.postalCode || '',
        time: props.time,
        type: props.facilityType,
        surnames: props.initialValues?.surnames || '',
        lang: props.lang,
        prize: props.initialValues?.prize || 0,
        prize_with_light: props.initialValues?.lightPrize || 0,
        has_light: props.has_light,
        hasLightCheck : false,
    }),

    validationSchema: (props) => {
        console.log(props);
        const { lang } = props
        return object().shape({
            username: string()
                .matches(ONLY_LETTERS_REGEX, t.error_name[lang])
                .required(t.error_required_name[lang]),
            surnames: string()
                .matches(ONLY_LETTERS_REGEX, t.error_surname[lang])
                .required(t.error_required_surname[lang]),
            dni: string()
                .test('dni', t.error_valid_id[lang], (value) => {
                    if (value) {
                        const result = validateDNI(value)
                        if (result.valid) {
                            return true
                        }
                    }
                    return false
                })
                .required(t.error_required_id[lang]),
            email: string()
                .matches(EMAIL_REGEX, t.error_email[lang])
                .required(t.error_required_email[lang]),
            phone: string().matches(PHONE_REGEX, t.error_phone[lang]),
            postalCode: string().matches(
                POSTCODE_REGEX,
                t.error_postal_code[lang]
            ),
            people: number()
                .max(props.maxPeople, t.error_capacity_persons[lang].replace('%s', props.maxPeople))
                .required(t.error_required_people[lang]),
            accept_policy: bool().oneOf([true], t.error_accept_policy[lang]),
        })
    },
})(({
    closeForm,
    date,
    errorBookingCode,
    errors,
    facilityName,
    handleChange,
    handleSubmit,
    lang,
    maxPeople,
    setFieldValue,
    setTouched,
    state,
    time,
    touched,
    validateForm,
    values,
}) => {
    const [isModalOpen, setModalOpen] = useState(false)
    const [totalPrize, setTotalPrize] = useState(0)
    const [showHasLightCheckbox, setShowHasLightCheckbox] = useState(false)

    useEffect(() => {
        const calculatedPrize = values.has_light && values.hasLightCheck
            ? values.prize_with_light
            : values.prize

        setTotalPrize(calculatedPrize.toFixed(2))

        if (values.has_light) {
            setShowHasLightCheckbox(true)
        } else {
            setShowHasLightCheckbox(false)
        }
    }, [values.hasLightCheck, values.prize, values.prize_with_light])

    return (
        <form className="hartuzBookingform" onSubmit={handleSubmit}>
            <ScrollToError />
            <div
                className="hartuzBookingForm__day-time-card"
                aria-label={t.booking_form_change_date_data[lang]}
                onClick={() => closeForm()}
            >
                <div className="hartuzBookingForm__day-time-card--content">
                    <div>
                        <CalendarIcon />
                        <p>
                            {convertDateToString({
                                date: values.date,
                                lang,
                            })}
                        </p>
                    </div>
                    <div>
                        <Time />
                        <p>{values.time}</p>
                    </div>
                </div>
            </div>
            <div className="hartuzBookingform__form">
                <div className="hartuzBookingform__inputs--wrapper">
                    <FormGroupInput
                        autoComplete="username"
                        errors={
                            touched.username && errors.username
                                ? errors.username
                                : null
                        }
                        aria-invalid={
                            touched.username && errors.username
                                ? errors.username
                                : null
                        }
                        id="username"
                        label={t.name[lang]}
                        onChange={handleChange}
                        placeholder={t.name[lang]}
                        required
                        value={values.username}
                    />
                    <FormGroupInput
                        autoComplete="family-name"
                        errors={
                            touched.surnames && errors.surnames
                                ? errors.surnames
                                : null
                        }
                        aria-invalid={
                            touched.surnames && errors.surnames
                                ? errors.surnames
                                : null
                        }
                        id="surnames"
                        label={t.surnames[lang]}
                        onChange={handleChange}
                        placeholder={t.surnames[lang]}
                        required
                        value={values.surnames}
                    />
                    <FormGroupInput
                        autoComplete="id"
                        errors={touched.dni && errors.dni ? errors.dni : null}
                        aria-invalid={
                            touched.dni && errors.dni ? errors.dni : null
                        }
                        id="dni"
                        label={t.dni[lang]}
                        onChange={handleChange}
                        placeholder={t.dni[lang]}
                        required
                        value={values.dni}
                    />

                    <FormGroupInput
                        autoComplete="email"
                        errors={
                            touched.email && errors.email ? errors.email : null
                        }
                        aria-invalid={
                            touched.email && errors.email ? errors.email : null
                        }
                        id="email"
                        label={t.email[lang]}
                        onChange={handleChange}
                        placeholder={t.email[lang]}
                        required
                        type="email"
                        value={values.email}
                    />
                    <FormGroupInput
                        autoComplete="phone"
                        errors={
                            touched.phone && errors.phone ? errors.phone : null
                        }
                        aria-invalid={
                            touched.phone && errors.phone ? errors.phone : null
                        }
                        id="phone"
                        label={t.phone[lang]}
                        onChange={handleChange}
                        placeholder={t.phone[lang]}
                        value={values.phone}
                    />
                    <FormGroupInput
                        autoComplete="postal-code"
                        errors={
                            touched.postalCode && errors.postalCode
                                ? errors.postalCode
                                : null
                        }
                        aria-invalid={
                            touched.postalCode && errors.postalCode
                                ? errors.postalCode
                                : null
                        }
                        id="postalCode"
                        label={t.postal_code_label[lang]}
                        onChange={handleChange}
                        placeholder={t.postal_code[lang]}
                        value={values.postalCode}
                    />
                    <FormGroupInput
                        aria-invalid={
                            touched.people && errors.people
                                ? errors.people
                                : null
                        }
                        autoComplete="people"
                        errors={
                            touched.people && errors.people
                                ? errors.people
                                : null
                        }
                        id="people"
                        label={t.people_label[lang]}
                        max={maxPeople}
                        min="1"
                        onChange={handleChange}
                        placeholder={t.people[lang]}
                        required
                        type="number"
                        value={values.people}
                    />
                    <FormGroupInput
                        errors={
                            touched.prize && errors.prize ? errors.prize : null
                        }
                        aria-invalid={
                            touched.prize && errors.prize ? errors.prize : null
                        }
                        id="prize"
                        className="bookingDataContainer input"
                        disabled
                        label={t.prize[lang]}
                        value={`${totalPrize} €`}
                    />
                    {showHasLightCheckbox && (
                        <FormGroup
                            errors={
                                touched.has_light && errors.has_light
                                    ? errors.has_light
                                    : null
                            }
                            aria-invalid={
                                touched.has_light && errors.has_light
                                    ? errors.has_light
                                    : null
                            }
                            id="has_light"
                            label=""
                            required
                            className="hartuzBookingform__checkbox"
                        >
                            <FormCheckbox
                                errors={
                                    touched.has_light && errors.has_light
                                        ? errors.has_light
                                        : null
                                }
                                label={t.has_light_label[lang]}
                                name="hasLightButtonCheck"
                                onChange={() =>
                                    setFieldValue(
                                        'hasLightCheck',
                                        !values.hasLightCheck
                                    )
                                }
                                value={values.hasLightCheck}
                            />
                        </FormGroup>
                    )}
                    <FormGroup
                        errors={
                            touched.accept_policy && errors.accept_policy
                                ? errors.accept_policy
                                : null
                        }
                        aria-invalid={
                            touched.accept_policy && errors.accept_policy
                                ? errors.accept_policy
                                : null
                        }
                        id="accept_policy"
                        label=""
                        required
                        className="hartuzBookingform__checkbox"
                    >
                        <FormCheckbox
                            errors={
                                touched.accept_policy && errors.accept_policy
                                    ? errors.accept_policy
                                    : null
                            }
                            label={AcceptPolicyLabel(lang)}
                            name="politicsButtonCheck"
                            onChange={() =>
                                setFieldValue(
                                    'accept_policy',
                                    !values.accept_policy
                                )
                            }
                            value={values.accept_policy}
                        />
                    </FormGroup>
                    {state === STATES.ERROR && (
                        <FormError className="form-group__errors server-group__errors">
                            {errorBookingCode === 703
                                ? t.facility_disabled[lang]
                                : t.error_submit_default[lang]}
                        </FormError>
                    )}
                </div>

                <div className="hartuzBookingform__submit">
                    <Button
                        disabled={state === STATES.SUBMITTING}
                        onClick={() => {
                            setTouched({
                                username: true,
                                surnames: true,
                                dni: true,
                                email: true,
                                phone: true,
                                postalCode: true,
                                people: true,
                                accept_policy: true,
                                prize: true,
                            })
                            validateForm().then((validationErrors) => {
                                if (
                                    Object.keys(validationErrors).length === 0
                                ) {
                                    setModalOpen(true)
                                } else {
                                    console.log(
                                        'Validation errors:',
                                        validationErrors
                                    )
                                }
                            })
                        }}
                        type="button"
                    >
                        {t.action_book[lang]}
                    </Button>
                </div>
            </div>
            {isModalOpen && (
                <Modal
                    closeModal={() => setModalOpen(false)}
                    isOpened={isModalOpen}
                    title={t.confirmacion_reserva[lang]}
                >
                    <div className="hartuzBookingform__confirmation">
                        <h3>{t.datos_reserva[lang]}</h3>
                        <div className="hartuzBookingform__confirmation_content">
                            <div>
                                <i className="fa fa-map-marker fa-lg" />
                                <p>{facilityName}</p>
                            </div>
                            <div>
                                <i className="fa fa-calendar fa-lg" />
                                <p>{date}</p>
                            </div>
                            <div>
                                <i className="fa fa-clock-o fa-lg" />
                                <p>{time}</p>
                            </div>
                            <div>
                                <i class="fa fa-credit-card fa-lg" />
                                <p>{`${totalPrize} €`}</p>
                            </div>
                        </div>

                        <Button
                            className="hartuzBookingform__confirmationButton"
                            type="submit"
                        >
                            {t.hartuz_confirm_booking[lang]}
                        </Button>
                    </div>
                </Modal>
            )}
        </form>
    )
})

export default HartuzFacilityBookingForm
