import React from 'react'
import classNames from 'classnames'
import AurretikFacilityInfo from '../AurretikFacilityInfo/AurretikFacilityInfo'
import AurretikFacilityOccupancy from '../AurretikFacilityOccupancy/AurretikFacilityOccupancy'
import AurretikFacilitySeason from '../AurretikFacilitySeason/AurretikFacilitySeason'
import AurretikFacilityState from '../AurretikFacilityState/AurretikFacilityState'
import { facilityTransformer } from '../../../transformers/aurretikDataTransformer'
import './AurretikFacility.css'

function AurretikFacility({ data, lang }) {
    const dataTransformed = data && facilityTransformer(data)
    const { image, occupancy, info, season, open, theme, title } =
        dataTransformed

    const time = open ? season.closing_time : season.opening_time

    return dataTransformed ? (
        <div
            className={classNames('aurretikFacility', {
                'aurretikFacility--no-image': !image,
            })}
        >
            <div className="aurretikFacility__image-container">
                <img src={image} alt="" />
            </div>

            <div className="aurretikFacility__data">
                <div className="aurretikFacility__left">
                    <div
                        className="aurretikFacility__bg"
                        style={{
                            backgroundColor: theme.backgroundColorInDetail,
                        }}
                    />
                    <div
                        className="aurretikFacility__left-title"
                        style={{
                            color: theme.letterColor,
                            backgroundColor: theme.backgroundColorInDetail,
                        }}
                    >
                        <span>{title[lang]}</span>
                    </div>
                    <div className="aurretikFacility__left-content">
                        <AurretikFacilityState
                            open={open}
                            lang={lang}
                            time={time}
                        />
                        <AurretikFacilityOccupancy
                            data={occupancy}
                            lang={lang}
                        />
                        <AurretikFacilitySeason data={season} lang={lang} />
                    </div>
                </div>
                <div className="aurretikFacility__right">
                    <AurretikFacilityInfo data={info} lang={lang} />
                </div>
            </div>
        </div>
    ) : null
}

export default AurretikFacility
