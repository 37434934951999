import React from 'react'

export default function IncidenciaCalendar1() {
    return (
        <svg viewBox="0 0 20 17">
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Kalezaindu_home_desktop"
                    transform="translate(-744.000000, -676.000000)"
                    stroke="#E1E4E8"
                    strokeWidth="1.69999993"
                >
                    <g
                        id="Group-22"
                        transform="translate(560.000000, 513.000000)"
                    >
                        <g id="Group-18">
                            <g
                                id="icons/calendar/black"
                                transform="translate(183.829787, 163.400000)"
                            >
                                <rect
                                    id="Rectangle"
                                    x="1.60650115"
                                    y="2.1833333"
                                    width="17.2125296"
                                    height="13.3000001"
                                    rx="2.10799993"
                                />
                                <path
                                    d="M18.7234043,6.33333333 L1.70212766,6.33333333"
                                    id="Path-2"
                                    strokeLinecap="round"
                                />
                                <path
                                    d="M6.43026005,3.83333333 L6.43026005,0.5"
                                    id="Path-17"
                                    strokeLinecap="round"
                                />
                                <path
                                    d="M13.9952719,3.83333333 L13.9952719,0.5"
                                    id="Path-17"
                                    strokeLinecap="round"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
