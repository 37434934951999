import React, { useEffect } from 'react'
import {useDispatch, useSelector } from 'react-redux'
import {
    setUserInfo as setUserInfoAction,
} from '../../store/actions'

function HartuzLoginCheckContainer({ match }) {
    const dispatch = useDispatch()

    const {
        instanceID,
    } = useSelector((state) => ({
        instanceID: state.pages.hartuz?.data[0]?.idinstancia,
    }))

    const encriptedText = location.search.replace('?LOG_OK=', '');    

    useEffect(() => {
        dispatch(setUserInfoAction(encriptedText));
    }, [])

    return ("");
}

export default HartuzLoginCheckContainer
