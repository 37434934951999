import React from 'react'
import t from '../../../i18n/translations/translations'
import './InputField.css'

function InputField({
    ariaRequired,
    categoria,
    lang,
    name,
    isValid,
    onChange,
    placeholder,
    required,
    title,
    type,
    value,
    invalidError
}) {
    switch (type) {
        case 'email':
            return (
                <div className="campo">
                    <label htmlFor={name} className="titulo-field">
                        {title}
                    </label>
                    <input
                        aria-required={ariaRequired}
                        className="field"
                        id={name}
                        name={name}
                        onBlur={onChange}
                        onChange={onChange}
                        placeholder={placeholder}
                        required={required}
                        type="email"
                        value={value}
                        onInvalid={e => e.target.setCustomValidity(invalidError)}
                        onInput={e => e.target.setCustomValidity('')}
                    />
                    {!isValid && (
                        <span className="error-message">
                            {t.error_valid_email[lang]}
                        </span>
                    )}
                </div>
            )
        case 'text':
            return (
                <div className="campo">
                    <label htmlFor={name} className="titulo-field">
                        {title}
                    </label>
                    <input
                        aria-required={ariaRequired}
                        className="field"
                        id={name}
                        name={name}
                        onBlur={onChange}
                        onChange={onChange}
                        placeholder={placeholder}
                        required={required}
                        type="text"
                        value={value}
                        onInvalid={e => e.target.setCustomValidity(invalidError)}
                        onInput={e => e.target.setCustomValidity('')}
                    />
                    {!isValid && (
                        <span className="error-message">
                            {t.error_valid_text[lang]}
                        </span>
                    )}
                </div>
            )
        case 'textarea':
            return (
                <div className="campo">
                    <label htmlFor={name} className="titulo-field">
                        {title}
                    </label>
                    <textarea
                        aria-required={ariaRequired}
                        className="field descripcion"
                        cols="30"
                        id={name}
                        name={name}
                        onBlur={onChange}
                        onChange={onChange}
                        placeholder={placeholder}
                        required={required}
                        rows="10"
                        value={value}
                        onInvalid={e => e.target.setCustomValidity(invalidError)}
                        onInput={e => e.target.setCustomValidity('')}
                    />
                    {!isValid && (
                        <span className="error-message">
                            {t.error_valid_description[lang]}
                        </span>
                    )}
                </div>
            )
        case 'file':
            return (
                <div className="campo">
                    <label htmlFor={name} className="titulo-field">
                        {title}
                    </label>
                    <input
                        aria-required={ariaRequired}
                        className="field image"
                        id={name}
                        multiple
                        name={name}
                        onChange={onChange}
                        placeholder={placeholder}
                        required={required}
                        type="file"
                        value={value}
                    />
                </div>
            )
        case 'Categoria':
            return (
                <div className="campo">
                    <label htmlFor={name} className="titulo-field">
                        {title}
                    </label>
                    <select
                        aria-required={ariaRequired}
                        className="field"
                        id={name}
                        name={name}
                        onBlur={onChange}
                        onChange={onChange}
                        onMouseDown={onChange}
                        placeholder={placeholder}
                        required={required}
                        value={value}
                        onInvalid={e => e.target.setCustomValidity(invalidError)}
                        onInput={e => e.target.setCustomValidity('')}
                    >
                        <option value="" disabled hidden>
                            {t.select_category[lang]}
                        </option>
                        {categoria !== undefined ? (
                            categoria.data.map((category) => (
                                <option
                                    key={category.idCategoria}
                                    value={category.idCategoria}
                                >
                                    {lang === 'es'
                                        ? category.tituloEs
                                        : category.tituloEu}
                                </option>
                            ))
                        ) : (
                            <option value="0">Otro</option>
                        )}
                    </select>
                    {!isValid && (
                        <span className="error-message">
                            {t.error_valid_category[lang]}
                        </span>
                    )}
                </div>
            )
        default:
            return <div>You are a User.</div>
    }
}

export default InputField
