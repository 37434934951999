import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-material.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'
import React, { useEffect, useMemo, useRef } from 'react'
import './PaginatedTable.css'
import paginatedTableEs from '../../i18n/translations/paginatedTableEs'
import paginatedTableEu from '../../i18n/translations/paginatedTableEu'

function PaginatedTable({ columnDefs, lang, rowData }) {
    const defaultColDef = {
        flex: 1,
        resizable: true,
        sortable: true,
    }

    const localeText = lang === 'es' ? paginatedTableEs : paginatedTableEu
    const paginationPageSizeSelector = useMemo(() => [10, 20, 50, 100], [])

    return (
        <div className="ag-theme-material" style={{ width: '100%' }}>
            <AgGridReact
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                domLayout="autoHeight"
                localeText={localeText}
                pagination
                paginationPageSize={10}
                paginationPageSizeSelector={paginationPageSizeSelector}
                rowData={rowData}
                suppressMovableColumns
            />
        </div>
    )
}

export default PaginatedTable
