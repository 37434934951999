import checkObjectIsNotEmpty from '../utils/checkObjectIsNotEmpty'

const getValue = ({ page, key }) => {
    if(!page) return null
    const value = page[key] ?? (page?.[0]?.[key])
    return value ?? null
}

const pageDataTransfomer = page => {
    if (!page) {
        return false
    }
    if (!checkObjectIsNotEmpty(page)) {
        return false
    }

    const pageTransfomed = {
        ...page,
        breadcrums: getValue({ page, key: 'breadcrums' }),
        logo: getValue({ page, key: 'logo' }),
        menu: getValue({ page, key: 'menu' }),
        macromenu: getValue({ page, key: 'macromenu' }),
        headbackground: getValue({ page, key: 'headbackground' }),
    }
    return pageTransfomed
}

export default pageDataTransfomer
