import PropTypes from 'prop-types'
import React from 'react'
import {
    FACEBOOK_SHARE,
    LINKEDIN_SHARE,
    SHARE_EXTENSION,
    TWITTER_SHARE,
    WHATSAPP_SHARE,
} from '../../constants'
import Facebook from '../../assets/svg/social/Facebook'
import Linkedin from '../../assets/svg/social/Linkedin'
import Mail from '../../assets/svg/Mail'
import Twitter from '../../assets/svg/social/Twitter'
import Whatsapp from '../../assets/svg/social/Whatsapp'
import './SocialBar.css'

class SocialBar extends React.Component {
    constructor(props) {
        super(props)
        this.btnTapped = this.btnTapped.bind(this)
    }

    // eslint-disable-next-line class-methods-use-this
    btnTapped(url, name) {
        const left = window.screen.width / 2 - 500 / 2
        const top = window.screen.height / 2 - 500 / 2
        window.open(
            url,
            name,
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=500px, height=500px, top=${top}, left=${left}`
        )
        return false
    }

    render() {
        const { slug, titulo, url, params } = this.props
        const urlEncoded = encodeURIComponent(url)
        const actualDomain = window.location.hostname
        const shareURLConstant = `${actualDomain}${SHARE_EXTENSION}${slug}`

        let mailSubject
        let mailBody

        if (params.lang === 'es') {
            mailSubject = `${titulo}`
            mailBody = `¡Mira lo que he encontrado -> ${url}`
        } else if (params.lang === 'eu') {
            mailSubject = `${titulo}`
            mailBody = `¡Mira lo que he encontrado_EU -> ${url}`
        }

        const tweetText = titulo
        const tweetUrl = url
        const whatsAppText = titulo
        const LinkedInShareURL = `${LINKEDIN_SHARE}${shareURLConstant}`
        const FacebookShareURL = `${FACEBOOK_SHARE}${shareURLConstant}`
        const MailShareURL = `mailto:?subject=${mailSubject}&body=${mailBody}`
        const TwitterShareURL = `${TWITTER_SHARE}text=${tweetText}&url${tweetUrl}`
        const WhatsAppShareURL = `${WHATSAPP_SHARE}${whatsAppText} - ${urlEncoded}`

        return (
            <div className="social-bar__container">
                <div className="social-bar__content">
                    <div>
                        <button
                            className="button-no-style"
                            onClick={() => this.btnTapped(MailShareURL, 'Mail')}
                            type="button"
                        >
                            <Mail className="social-icon" />
                        </button>
                    </div>
                    <div>
                        <button
                            className="button-no-style"
                            onClick={() =>
                                this.btnTapped(FacebookShareURL, 'Facebook')
                            }
                            type="button"
                        >
                            <Facebook className="social-icon" />
                        </button>
                    </div>
                    <div>
                        <button
                            className="button-no-style"
                            onClick={() =>
                                this.btnTapped(TwitterShareURL, 'Twitter')
                            }
                            type="button"
                        >
                            <Twitter className="social-icon" />
                        </button>
                    </div>
                    <div>
                        <button
                            className="button-no-style"
                            onClick={() =>
                                this.btnTapped(LinkedInShareURL, 'LinkedIn')
                            }
                            type="button"
                        >
                            <Linkedin className="social-icon" />
                        </button>
                    </div>
                    <div>
                        <button
                            className="button-no-style"
                            onClick={() =>
                                this.btnTapped(WhatsAppShareURL, 'Whatsapp')
                            }
                            type="button"
                        >
                            <Whatsapp className="social-icon" />
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

SocialBar.propTypes = {
    slug: PropTypes.string.isRequired,
    titulo: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
        lang: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        page: PropTypes.string.isRequired,
    }).isRequired,
}

export default SocialBar
