import React from 'react'
import Globe from '../../assets/svg/Globe'

function CurrentLanguage({ trads }) {
    return (
        <div className="toolBox">
            <span className="credits">
                <Globe />
            </span>
            <span className="button_name">{trads.idioma}</span>
        </div>
    )
}

export default CurrentLanguage
