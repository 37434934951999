const paginatedTableEu = {
    page: 'Orrialdea',
    more: 'Gehiago',
    to: 'tik',
    of: 'ko',
    next: 'Hurrengoa',
    last: 'Azkena',
    first: 'Lehena',
    previous: 'Aurrekoa',
    loadingOoo: 'Kargatzen...',
    noRowsToShow: 'Ez dira errenkadak aurkitu',
    andCondition: 'ETA',
    orCondition: 'EDO',
    applyFilter: 'Aplikatu',
    resetFilter: 'Berrezarri',
    clearFilter: 'Garbitu',
    filterOoo: 'Iragazten...',
    equals: 'Berdina',
    notEqual: 'Desberdina',
    lessThan: 'Txikiagoa',
    lessThanOrEqual: 'Txikiago edo berdina',
    greaterThan: 'Handiagoa',
    greaterThanOrEqual: 'Handiago edo berdina',
    inRange: 'Tartean',
    contains: 'Dauka',
    notContains: 'Ez dauka',
    startsWith: 'Horrela hasten da',
    endsWith: 'Horrela amaitzen da',
    selectAll: 'Hautatu dena',
    searchOoo: 'Bilatzen...',
    blanks: 'Zuriak',
    group: 'Taldea',
    columns: 'Zutabeak',
    rowGroupColumns: 'Errenkada taldeen zutabeak',
    rowGroupColumnsEmptyMessage: 'Arrastatu zutabeak hemen taldeka',
    valueColumns: 'Balio zutabeak',
    pivotMode: 'Pibot modua',
    groups: 'Taldeak',
    values: 'Balioak',
    pivots: 'Pibotak',
    valueColumnsEmptyMessage: 'Arrastatu zutabeak hemen gehitzeko',
    pivotColumnsEmptyMessage: 'Arrastatu hemen pibotak ezartzeko',
    toolPanelButton: 'Tresna panela',
    export: 'Esportatu',
    csvExport: 'CSVra esportatu',
    excelExport: 'Excelera esportatu',
    pinColumn: 'Zutabea pinzatu',
    pinLeft: 'Ezkerrean pinzatu',
    pinRight: 'Eskuinean pinzatu',
    noPin: 'Ez pinzatu',
    autosizeThiscolumn: 'Zutabe honen tamaina doitzea',
    autosizeAllColumns: 'Zutabe guztien tamaina doitzea',
    resetColumns: 'Zutabeak berrezarri',
    collapseAll: 'Denak kolapsatu',
    expandAll: 'Denak zabaldu',
    toolPanel: 'Tresna panela',
    pageSizeSelectorLabel: 'Orrialdearen tamaina:',
}

export default paginatedTableEu
