import React from 'react'

/* eslint-disable max-len */
export default function ActionStar() {
    return (
        <svg viewBox="0 0 38 36">
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Rendicion_accion_desktop"
                    transform="translate(-218.000000, -643.000000)"
                    fillRule="nonzero"
                >
                    <g
                        id="Group-3"
                        transform="translate(218.000000, 635.000000)"
                    >
                        <g id="Group" transform="translate(0.000000, 7.000000)">
                            <g id="Group-11-Copy-2" fill="#1C3561">
                                <path
                                    d="M23.7502081,6.01492537 C23.2728984,6.01492537 22.8859624,5.65408025 22.8859624,5.20895522 C22.8859624,4.7638302 23.2728984,4.40298507 23.7502081,4.40298507 L30.4913248,4.40298507 C30.9686345,4.40298507 31.3555705,4.7638302 31.3555705,5.20895522 C31.3555705,5.65408025 30.9686345,6.01492537 30.4913248,6.01492537 L23.7502081,6.01492537 Z"
                                    id="Path-9-Copy-24"
                                    transform="translate(27.120766, 5.208955) rotate(-270.000000) translate(-27.120766, -5.208955) "
                                />
                                <path
                                    d="M6.450583,6.01492537 C5.97327327,6.01492537 5.58633727,5.65408025 5.58633727,5.20895522 C5.58633727,4.7638302 5.97327327,4.40298507 6.450583,4.40298507 L13.1916997,4.40298507 C13.6690094,4.40298507 14.0559454,4.7638302 14.0559454,5.20895522 C14.0559454,5.65408025 13.6690094,6.01492537 13.1916997,6.01492537 L6.450583,6.01492537 Z"
                                    id="Path-9-Copy-26"
                                    transform="translate(9.821141, 5.208955) rotate(-270.000000) translate(-9.821141, -5.208955) "
                                />
                                <path
                                    d="M36.2588073,35.3283582 C36.7355974,35.3283582 37.1221122,35.702568 37.1221122,36.1641791 C37.1221122,36.6257902 36.7355974,37 36.2588073,37 L1.20862691,37 C0.541120699,37 0,36.4761063 0,35.8298507 L0,5.57313433 C0,4.92687874 0.541120699,4.40298507 1.20862691,4.40298507 L35.9134853,4.40298507 C36.5809915,4.40298507 37.1221122,4.92687874 37.1221122,5.57313433 L37.1221122,36.1641791 C37.1221122,36.6257902 36.7355974,37 36.2588073,37 C35.7820171,37 35.3955023,36.6257902 35.3955023,36.1641791 L35.3955023,6.07462687 L1.72660987,6.07462687 L1.72660987,35.3283582 L36.2588073,35.3283582 Z"
                                    id="Path-10-Copy"
                                />
                            </g>
                            <path
                                d="M14.3353596,22.9909961 L13.5822679,27.0540553 C13.5524774,27.2283364 13.5819476,27.4073614 13.666274,27.5639111 C13.8804935,27.9615458 14.3861317,28.1152958 14.7955912,27.9072628 L18.5610497,25.9976366 L22.3265082,27.9072628 L22.703054,27.9885317 C22.8846001,27.9916425 23.0618217,27.9342487 23.2050885,27.8259938 C23.4551847,27.6486019 23.5838766,27.3516786 23.5398315,27.0539776 L22.7867398,22.9909183 L25.8409895,20.1468157 C26.0595334,19.917474 26.1388147,19.5939535 26.0501638,19.2936083 C25.9374884,19.0159718 25.6836282,18.8157157 25.3807579,18.7653989 L21.1968706,18.1559206 L19.3141414,14.4585328 C19.2320573,14.2939729 19.0952771,14.1610651 18.9258235,14.0814293 C18.5098773,13.8858394 18.0094445,14.0546766 17.807958,14.4585328 L15.9252288,18.1559206 L11.7413415,18.7653989 C11.4384712,18.8156379 11.184611,19.0159718 11.0719356,19.2936083 C10.9832847,19.5939535 11.0626461,19.917474 11.2811099,20.1468157 L14.3353596,22.9909961 Z"
                                id="Shape-Copy"
                                stroke="#1C3561"
                                strokeWidth="1.5"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
