import React from 'react'
import FinderForm from '../../forms/FinderForm'
import FinderContentLink from '../../atoms/FinderContentLink/FinderContentLink'
import FinderPercentageContent from '../../atoms/FinderPercentageContent/FinderPercentageContent'
import './Finder.css'
import PageRow from '../../atoms/PageRow/PageRow'

function NoResultsMessage({ translations }) {
  return <p className="finder-no-results">
        {translations.ACCOUNTABILITY.FINDER_NO_RESULTS}
    </p>
}

const createActionsUrl = ({ data, lang }) =>
    lang === 'es'
        ? `/es/acciones/${data.slug_es}`
        : `/eu/ekintzak/${data.slug_eu}`

class Finder extends React.Component {
    render() {
        const {
            translations,
            isMobile,
            isTablet,
            tematica,
            justAction,
            lang,
            tematicas,
            buscador,
            zonas,
            zona,
            busqueda,
            textoBusqueda,
            mostrarResultado,
            handleChange,
            handleSubmit,
        } = this.props

        const finalTopics = []

        tematicas &&
            tematicas.data &&
            tematicas.data.length &&
            tematicas.data.map((topicArrays) =>
                topicArrays.map((topic) =>
                    finalTopics.push(
                        lang === 'es' ? topic.nombre_es : topic.nombre_eu
                    )
                )
            )

        const filterComplete = finalTopics.filter(
            (elem, index, self) => index === self.indexOf(elem)
        )

        return (
            <PageRow className="finderDetail">
                <FinderForm
                    isMobile={isMobile}
                    isTablet={isTablet}
                    translations={translations}
                    handleChange={handleChange}
                    busqueda={busqueda}
                    filterComplete={filterComplete}
                    lang={lang}
                    zonas={zonas}
                    handleSubmit={handleSubmit}
                    tematica={tematica}
                    zona={zona}
                />

                <div className="finder-container">
                    <div className="finder-container-title">
                        {mostrarResultado ? (
                            <p>
                                {translations.ACCOUNTABILITY.FINDER_RESULTS}:{' '}
                                <strong>{textoBusqueda}</strong>{' '}
                            </p>
                        ) : null}
                    </div>

                    <div className="finder-container-content">
                        {!justAction && !Array.isArray(buscador.data[0])
                            ? buscador.data[3].length > 0
                                ? buscador.data[3].map((plan) => (
                                      <table className="rendicion-tabla">
                                          <tr>
                                              <th className="plan-content">
                                                  {plan.title}
                                              </th>
                                              <FinderPercentageContent
                                                  show={
                                                      plan.show_plan_percentage
                                                  }
                                                  isTablet={isTablet}
                                                  isMobile={isMobile}
                                                  percent={plan.percentage}
                                                  translations={translations}
                                              />
                                              <FinderContentLink
                                                  url={plan.slug}
                                                  translations={translations}
                                                  type='plan'
                                              />
                                          </tr>
                                      </table>
                                  ))
                                : null
                            : null}

                        {!justAction && !Array.isArray(buscador.data[0])
                            ? buscador.data[2].length > 0
                                ? buscador.data[2].map((ejePlan) => (
                                      <table className="rendicion-tabla">
                                          <tr>
                                              <th className="plan-content">
                                                  {ejePlan.plan_title}
                                              </th>
                                              <FinderPercentageContent
                                                  show={
                                                      ejePlan.show_plan_percentage
                                                  }
                                                  isTablet={isTablet}
                                                  isMobile={isMobile}
                                                  percent={
                                                      ejePlan.plan_percentage
                                                  }
                                                  translations={translations}
                                              />

                                              <FinderContentLink
                                                  url={ejePlan.plan_slug}
                                                  translations={translations}
                                                  type='plan'
                                              />
                                          </tr>
                                          <tr>
                                              <th className="axis-content">
                                                  <em>{ejePlan.title}</em>
                                              </th>
                                              <FinderPercentageContent
                                                  show={ejePlan.show_percentage}
                                                  isTablet={isTablet}
                                                  isMobile={isMobile}
                                                  percent={ejePlan.percentage}
                                                  translations={translations}
                                                  bold
                                              />
                                          </tr>
                                      </table>
                                  ))
                                : null
                            : null}
                        {!justAction && !Array.isArray(buscador.data[0])
                            ? buscador.data[1].actions.length > 0
                                ? buscador.data[1].actions.map((accionPlan) => (
                                      <table className="rendicion-tabla">
                                          <tr>
                                              <th className="plan-content">
                                                  {accionPlan.plan_title}
                                              </th>
                                              <FinderPercentageContent
                                                  show={
                                                      accionPlan.plan_show_percentage
                                                  }
                                                  isTablet={isTablet}
                                                  isMobile={isMobile}
                                                  percent={
                                                      accionPlan.plan_percentage
                                                  }
                                                  translations={translations}
                                              />

                                              <FinderContentLink
                                                  url={accionPlan.plan_slug}
                                                  translations={translations}
                                                  type='plan'
                                              />
                                          </tr>
                                          <tr>
                                              <th className="action-content">
                                                  <em>{accionPlan.title}</em>
                                              </th>
                                              <FinderPercentageContent
                                                  show={
                                                      accionPlan.show_percentage
                                                  }
                                                  isTablet={isTablet}
                                                  isMobile={isMobile}
                                                  percent={
                                                      accionPlan.percentage
                                                  }
                                                  translations={translations}
                                                  bold
                                              />

                                              <FinderContentLink
                                                  url={accionPlan.slug}
                                                  translations={translations}
                                                  type='action'
                                              />
                                          </tr>
                                      </table>
                                  ))
                                : null
                            : null}
                        {!justAction && !Array.isArray(buscador.data[0])
                            ? buscador.data[0].actions.length > 0
                                ? buscador.data[0].actions.map((axisPlan) => (
                                      <table className="rendicion-tabla">
                                          <tr>
                                              <th className="plan-content">
                                                  {axisPlan.plan_title}
                                              </th>
                                              <FinderPercentageContent
                                                  show={
                                                      axisPlan.plan_show_percentage
                                                  }
                                                  isTablet={isTablet}
                                                  isMobile={isMobile}
                                                  percent={
                                                      axisPlan.plan_percentage
                                                  }
                                                  translations={translations}
                                              />

                                              <FinderContentLink
                                                  url={axisPlan.plan_slug}
                                                  translations={translations}
                                              />
                                          </tr>
                                          <tr>
                                              <th className="axis-content">
                                                  <em>{axisPlan.axis_title}</em>
                                              </th>
                                              <FinderPercentageContent
                                                  show={
                                                      axisPlan.axis_show_percentage
                                                  }
                                                  isTablet={isTablet}
                                                  isMobile={isMobile}
                                                  percent={
                                                      axisPlan.axis_percentage
                                                  }
                                                  translations={translations}
                                                  bold
                                              />
                                          </tr>
                                          <tr>
                                              <th className="action-content">
                                                  <em>{axisPlan.title}</em>
                                              </th>
                                              <FinderPercentageContent
                                                  show={
                                                      axisPlan.show_percentage
                                                  }
                                                  isTablet={isTablet}
                                                  isMobile={isMobile}
                                                  percent={axisPlan.percentage}
                                                  translations={translations}
                                                  bold
                                              />

                                              <FinderContentLink
                                                  url={axisPlan.slug}
                                                  translations={translations}
                                                  type='action'
                                              />
                                          </tr>
                                      </table>
                                  ))
                                : null
                            : null}

                        {justAction === true
                            ? buscador.data.length > 0 &&
                              Array.isArray(buscador.data[0]) === true
                                ? buscador.data.map((actionArrays) =>
                                      actionArrays.map((action) => (
                                          <table className="rendicion-tabla">
                                              <tr>
                                                  <th className="just-action-content">
                                                      <em>
                                                          {lang === 'es'
                                                              ? action.titulo_es
                                                              : action.titulo_eu}
                                                      </em>
                                                  </th>
                                                  <FinderPercentageContent
                                                      show={
                                                          action.mostrarPorcentaje ===
                                                          '1'
                                                      }
                                                      isTablet={isTablet}
                                                      isMobile={isMobile}
                                                      percent={
                                                          action.porcentaje
                                                      }
                                                      translations={
                                                          translations
                                                      }
                                                      bold
                                                  />
                                                  <FinderContentLink
                                                      url={createActionsUrl({
                                                          data: action,
                                                          lang,
                                                      })}
                                                      translations={
                                                          translations
                                                      }
                                                      type='action'
                                                  />
                                              </tr>
                                          </table>
                                      ))
                                  )
                                : null
                            : null}

                        {!justAction && !Array.isArray(buscador.data[0]) ? (
                            buscador.data[2].length === 0 &&
                            buscador.data[3].length === 0 &&
                            buscador.data[1].actions.length === 0 &&
                            buscador.data[0].actions.length === 0 ? (
                                <NoResultsMessage translations={translations} />
                            ) : null
                        ) : null}

                        {justAction && Array.isArray(buscador.data[0]) ? (
                            buscador.data[0].length === 0 &&
                            buscador.data[1].length === 0 ? (
                                <NoResultsMessage translations={translations} />
                            ) : null
                        ) : null}
                    </div>
                </div>
            </PageRow>
        )
    }
}

export default Finder
