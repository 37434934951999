import React from 'react'
import './HartuzRemoveFiltersButton.css'
import t from '../../../i18n/translations/translations'

function HartuzRemoveFiltersButton({ lang, onClick }) {
    return (
        <button className="removeFiltersButton" onClick={onClick} type="button">
            {t.remove_filters[lang]}
        </button>
    )
}

export default HartuzRemoveFiltersButton
