import React from 'react'
import PlanDetailEjeDescription from '../../../atoms/PlanDetail/PlanDetailEjeDescription/PlanDetailEjeDescription'
import PlanDetailChart from '../PlanDetailChart/PlanDetailChart'
import './PlanDetailEjeDescriptionChart.css'

function PlanDetailEjeDescriptionChart({ eje, translations }) {
    return (
        <div className="ejes-description-chart">
            <PlanDetailEjeDescription description={eje.description} />

            {eje.presentation && eje.presentation !== 0 ? (
                <PlanDetailChart
                    value={eje.percentage}
                    className="CircularProgressbar-path "
                    text={eje.presentation === 1 ? `` : `${eje.percentage}%`}
                    pathColor={eje.axistrafficlight}
                    presentation={eje.presentation}
                    translations={translations}
                />
            ) : null}

            <PlanDetailEjeDescription
                description={eje.description}
                responsive
            />
        </div>
    )
}

export default PlanDetailEjeDescriptionChart
