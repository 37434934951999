import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import PlanListBoxTitle from '../../atoms/PlanListBoxTitle/PlanListBoxTitle'
import PlanListSearch from '../../atoms/PlanListSearch/PlanListSearch'
import PlanListStateMessage from '../../atoms/PlanListStateMessage/PlanListStateMessage'
import PlansGrouped from '../../atoms/PlansGrouped/PlansGrouped'
import PlansNotGrouped from '../../atoms/PlansNotGrouped/PlansNotGrouped'
import PlanListForm from '../../forms/PlanListForm'
import PageTitle from '../../atoms/PageTitle/PageTitle'
import './PlanList.css'

const circularProgressPlanHidden = true

export const getPlanSearchUrl = (lang) =>
    lang === 'es'
        ? '/es/planes-de-legislatura/buscador'
        : '/eu/legealdiko-planak/bilatzaile'

class PlanList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            blockSearch: true,
            texto: '',
        }
        this.handleSearchBar = this.handleSearchBar.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const { value } = event.target

        this.setState({ texto: value })
    }

    handleSearchBar = () => {
        const { blockSearch } = this.state

        this.setState(
            {
                blockSearch: !blockSearch,
            },
            () => {
                if (blockSearch) {
                    document.getElementById('busquedaPlan').focus()
                }
            }
        )
    }

    render() {
        const { contenido, translations, lang, isMobile } = this.props
        const { blockSearch } = this.state
        const { presentacion } = contenido
        let showPercentage = ''

        if (contenido.plans.length > 0) {
            if (contenido.plans.length !== undefined) {
                showPercentage = contenido.plans[0].show_plan_percentage
            } else {
                if (contenido.plans.notValid.length > 0) {
                    showPercentage =
                        contenido.plans.notValid[0].show_plan_percentage
                }
                if (contenido.plans.valid.length > 0) {
                    showPercentage =
                        contenido.plans.valid[0].show_plan_percentage
                }
            }
        }

        const { texto } = this.state

        const texts = {
            planPercentage: translations.ACCOUNTABILITY.PLAN_DETAIL_PERCENTAGE,
            planListTitle: translations.ACCOUNTABILITY.PLAN_LIST_TITLE,
        }

        return (
            <div className="planList">
                <div className="planList-box-title__container">
                    <PageTitle
                        className="planList-box-title"
                        text={contenido.title}
                    />
                    <div className="planList-box-title_chart-search">
                        {!isMobile ? (
                            <div className="planList-box-title_search-plan">
                                <PlanListForm
                                    translations={translations}
                                    handleChange={this.handleChange}
                                    handleSearchBar={this.handleSearchBar}
                                    texto={texto}
                                    blockSearch={blockSearch}
                                    actionUrl={getPlanSearchUrl(lang)}
                                />
                            </div>
                        ) : (
                            <PlanListSearch lang={lang} />
                        )}
                        <div className="chart-title">
                            {!circularProgressPlanHidden ? (
                                <CircularProgressbar
                                    className="CircularProgressbar-text CircularProgressbar-trail CircularProgressbar-path "
                                    value={contenido.globalPercentage}
                                    text={
                                        !showPercentage
                                            ? ``
                                            : `${contenido.globalPercentage}%`
                                    }
                                />
                            ) : (
                                ''
                            )}

                            {!showPercentage || circularProgressPlanHidden ? (
                                ``
                            ) : (
                                <div className="planList-done-text">
                                    <p>{texts.planPercentage.toUpperCase()}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="planList-box">
                    {presentacion === 2 ? (
                        <div className="planList-box_container">
                            <div className="planList-box-subtitle__container">
                                <h2 className="planList-box-subtitle">
                                    {texts.planListTitle}
                                </h2>
                            </div>

                            {contenido.plans.map((plan, index) => (
                                <PlansNotGrouped
                                    title={plan.title}
                                    start_date={plan.start_date}
                                    endDate={plan.end_date}
                                    status={plan.is_active}
                                    slug={plan.slug}
                                    description={plan.description}
                                    percentage={plan.percentage}
                                    key={index}
                                    translations={translations}
                                    showPercentage={plan.show_plan_percentage}
                                    showProgress={plan.show_plan_progress}
                                    lang={lang}
                                />
                            ))}
                        </div>
                    ) : null}

                    {presentacion === 1 ? (
                        <div className="planList-box_container">
                            <PlanListStateMessage
                                translations={translations}
                                content={contenido}
                            />

                            {contenido.plans.notValid.map((plan, i) => (
                                <PlansGrouped
                                    key={i}
                                    title={plan.title}
                                    startDate={plan.start_date}
                                    endDate={plan.end_date}
                                    status={plan.is_active}
                                    slug={plan.slug}
                                    description={plan.description}
                                    percentage={plan.percentage}
                                    translations={translations}
                                    showPercentage={plan.show_plan_percentage}
                                    showProgress={plan.show_plan_progress}
                                    lang={lang}
                                />
                            ))}
                            {contenido.plans.valid.length > 0 ? (
                                <PlanListBoxTitle title={texts.stateVigente} />
                            ) : (
                                ''
                            )}

                            {contenido.plans.valid.map((plan, i) => (
                                <PlansGrouped
                                    key={i}
                                    title={plan.title}
                                    startDate={plan.start_date}
                                    endDate={plan.end_date}
                                    status={plan.is_active}
                                    slug={plan.slug}
                                    description={plan.description}
                                    percentage={plan.percentage}
                                    translations={translations}
                                    showPercentage={plan.show_plan_percentage}
                                    showProgress={plan.show_plan_progress}
                                    lang={lang}
                                />
                            ))}
                        </div>
                    ) : null}

                    {presentacion === 0 ? (
                        <div className="planList-box_container">
                            <PlanListStateMessage
                                translations={translations}
                                content={contenido}
                                vigente
                            />

                            {contenido.plans.valid.map((plan, i) => (
                                <PlansGrouped
                                    key={i}
                                    title={plan.title}
                                    startDate={plan.start_date}
                                    endDate={plan.end_date}
                                    status={plan.is_active}
                                    slug={plan.slug}
                                    description={plan.description}
                                    percentage={plan.percentage}
                                    translations={translations}
                                    showPercentage={plan.show_plan_percentage}
                                    showProgress={plan.show_plan_progress}
                                    lang={lang}
                                />
                            ))}
                            <PlanListStateMessage
                                translations={translations}
                                content={contenido}
                            />

                            {contenido.plans.notValid.map((plan, i) => (
                                <PlansGrouped
                                    key={i}
                                    title={plan.title}
                                    startDate={plan.start_date}
                                    endDate={plan.end_date}
                                    status={plan.is_active}
                                    slug={plan.slug}
                                    description={plan.description}
                                    percentage={plan.percentage}
                                    translations={translations}
                                    showPercentage={plan.show_plan_percentage}
                                    showProgress={plan.show_plan_progress}
                                    lang={lang}
                                />
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        )
    }
}

export default PlanList
