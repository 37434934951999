import React from 'react'
import PropTypes from 'prop-types'
import PlanResumen from '../atoms/PlanResumen/PlanResumen'

function PlanProgressContainer(props) {
    const { data } = props

    return <PlanResumen plan={data.plans.data} />
}

PlanProgressContainer.propTypes = {
    data: PropTypes.shape({
        plans: PropTypes.shape({
            data: PropTypes.any,
        }).isRequired,
    }),
}

PlanProgressContainer.defaultProps = {
    data: {
        plans: {
            data: null,
        },
    },
}

export default PlanProgressContainer
