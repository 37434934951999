import { SET_CATEGORY_DATA } from '../constants/constants'

// eslint-disable-next-line default-param-last
const categories = (state = {}, action) => {
    if (action.type === SET_CATEGORY_DATA) {
        const { category, data } = action.payload
        return {
            ...state,
            [category]: data,
        }
    }

    return state
}

export default categories
