import React from 'react'
import './BudgetTitle.css'

function BudgetTitle({ text }) {
    return (
        <div className="presupuesto-title">
            <p>{text}</p>
        </div>
    )
}

export default BudgetTitle
