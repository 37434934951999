import React from 'react'
import CategoryDetail from '../../compositions/CategoryDetail/CategoryDetail'
import './CategoryRender.css'

function CategoryRender({ categorias }) {
    return categorias?.length
        ? categorias.map((categoria, index) => (
              <div key={index}>
                  <CategoryDetail categoria={categoria} key={index} />
                  {categoria.categorias?.length
                      ? categoria.categorias.map((categorySecondLevel, i) => (
                            <CategoryDetail
                                categoria={categorySecondLevel}
                                key={i}
                            />
                        ))
                      : null}
              </div>
          ))
        : null
}

export default CategoryRender
