import React from 'react'

/* eslint-disable max-len */
export default function Objetivos() {
    return (
        <svg viewBox="0 0 40 40">
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g
                    id="lineas-mundo"
                    stroke="#1C3561"
                    strokeWidth="1.5"
                    transform="translate(-7 -7)"
                >
                    <path
                        d="M28.627 29.48c.421 0 1.843 1.782 1.918 3.42.075 1.64.992 4.657 2.81 5.285 1.817.628 4.11 2.446 6.822 2.8 2.712.354 4.433-1.66 4.726-2.844.294-1.183 1.09-2.887-.054-4.368-2.958-2.526-2.53-5.916-1.93-7.611.961-1.663 2.126-1.523 3.542-4.574.285-.615.394-1.323.326-2.124"
                        transform="rotate(150 37.717 30.245)"
                    />
                    <path
                        d="M25.142 18.4a86.618 86.618 0 01-6.538-4.51c-2.953-2.258-5.203-3.134-6.207-1.567-1.004 1.567-.706 2.652-1.929 3.255-1.223.603-2.11 1.22-2.11 2.47 0 1.25 1.663 3.99 2.11 4.883"
                        transform="rotate(150 16.75 17.23)"
                    />
                    <circle
                        cx="27"
                        cy="27"
                        r="19.286"
                        transform="rotate(150 27 27)"
                    />
                    <path d="M10 34.75S12.835 33 14.848 33s2.988.374 3.395 1.75c.364 1.216.946 3.3 2.238 3.896C21.77 39.244 22 40.647 22 41.75c0 .735-.197 1.819-.59 3.25" />
                </g>
            </g>
        </svg>
    )
}
