import React from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import PlanDoneText from '../../../atoms/PlanDoneText/PlanDoneText'
import './PlanDetailChart.css'

function PlanDetailChart({
    value,
    className,
    text,
    pathColor,
    presentation,
    translations,
}) {
    return (
        <div className="chart">
            <CircularProgressbar
                className={`CircularProgressbar-text CircularProgressbar-trail ${className}`}
                value={value}
                text={text}
                styles={buildStyles({
                    pathColor,
                })}
            />
            {presentation === 2 && <PlanDoneText translations={translations} />}
        </div>
    )
}

export default PlanDetailChart
