import PropTypes from 'prop-types'
import React from 'react'
import './NewsBody.css'

function NewsBody({ body }) {
    return (
        <div className="news-body-container ">
            <div className="news-body-content">
                <p
                    className="news-body_text wysiwyg"
                    dangerouslySetInnerHTML={{ __html: body }}
                />
            </div>
        </div>
    )
}

NewsBody.propTypes = {
    body: PropTypes.string.isRequired,
}

export default NewsBody
