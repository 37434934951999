import React from 'react'

/* eslint-disable max-len */
export default function Relaciones({ className }) {
    return (
        <svg className={className} viewBox="0 0 38 31">
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fillRule="evenodd"
            >
                <g
                    id="Transparencia_desktop"
                    transform="translate(-613.000000, -851.000000)"
                    fillRule="nonzero"
                >
                    <g id="Group" transform="translate(613.000000, 851.000000)">
                        <path
                            d="M18.5777778,5.02702703 C19.5105254,5.02702703 20.2666667,4.27680147 20.2666667,3.35135135 C20.2666667,2.42590123 19.5105254,1.67567568 18.5777778,1.67567568 C17.6450302,1.67567568 16.8888889,2.42590123 16.8888889,3.35135135 C16.8888889,4.27680147 17.6450302,5.02702703 18.5777778,5.02702703 Z M18.5777778,6.7027027 C16.7122826,6.7027027 15.2,5.20225159 15.2,3.35135135 C15.2,1.50045111 16.7122826,0 18.5777778,0 C20.4432729,0 21.9555556,1.50045111 21.9555556,3.35135135 C21.9555556,5.20225159 20.4432729,6.7027027 18.5777778,6.7027027 Z"
                            id="Oval-Copy-22"
                        />
                        <path
                            d="M31.2444444,21.7837838 C32.177192,21.7837838 32.9333333,21.0335582 32.9333333,20.1081081 C32.9333333,19.182658 32.177192,18.4324324 31.2444444,18.4324324 C30.3116969,18.4324324 29.5555556,19.182658 29.5555556,20.1081081 C29.5555556,21.0335582 30.3116969,21.7837838 31.2444444,21.7837838 Z M31.2444444,23.4594595 C29.3789493,23.4594595 27.8666667,21.9590084 27.8666667,20.1081081 C27.8666667,18.2572079 29.3789493,16.7567568 31.2444444,16.7567568 C33.1099396,16.7567568 34.6222222,18.2572079 34.6222222,20.1081081 C34.6222222,21.9590084 33.1099396,23.4594595 31.2444444,23.4594595 Z"
                            id="Oval-Copy-23"
                        />
                        <path
                            d="M5.91111111,21.7837838 C6.84385869,21.7837838 7.6,21.0335582 7.6,20.1081081 C7.6,19.182658 6.84385869,18.4324324 5.91111111,18.4324324 C4.97836353,18.4324324 4.22222222,19.182658 4.22222222,20.1081081 C4.22222222,21.0335582 4.97836353,21.7837838 5.91111111,21.7837838 Z M5.91111111,23.4594595 C4.04561596,23.4594595 2.53333333,21.9590084 2.53333333,20.1081081 C2.53333333,18.2572079 4.04561596,16.7567568 5.91111111,16.7567568 C7.77660627,16.7567568 9.28888889,18.2572079 9.28888889,20.1081081 C9.28888889,21.9590084 7.77660627,23.4594595 5.91111111,23.4594595 Z"
                            id="Oval-Copy-24"
                        />
                        <path
                            d="M11.8222222,14.2432432 L11.8222222,13.4054054 C11.8222222,9.5602014 14.1970731,7.54054054 18.5777778,7.54054054 C22.9584824,7.54054054 25.3333333,9.5602014 25.3333333,13.4054054 L25.3333333,14.2432432 L11.8222222,14.2432432 Z M23.5937896,12.5675676 C23.2998542,10.2866987 21.7164977,9.21621622 18.5777778,9.21621622 C15.4390578,9.21621622 13.8557014,10.2866987 13.561766,12.5675676 L23.5937896,12.5675676 Z"
                            id="Path-18-Copy-6"
                        />
                        <path
                            d="M24.4888889,31 L24.4888889,30.1621622 C24.4888889,26.3169582 26.8637398,24.2972973 31.2444444,24.2972973 C35.6251491,24.2972973 38,26.3169582 38,30.1621622 L38,31 L24.4888889,31 Z M36.2604563,29.3243243 C35.9665209,27.0434555 34.3831644,25.972973 31.2444444,25.972973 C28.1057245,25.972973 26.522368,27.0434555 26.2284326,29.3243243 L36.2604563,29.3243243 Z"
                            id="Path-18-Copy-7"
                        />
                        <path
                            d="M0,31 L0,30.1621622 C0,26.3169582 2.37485092,24.2972973 6.75555556,24.2972973 C11.1362602,24.2972973 13.5111111,26.3169582 13.5111111,30.1621622 L13.5111111,31 L0,31 Z M11.7715674,29.3243243 C11.477632,27.0434555 9.89427549,25.972973 6.75555556,25.972973 C3.61683562,25.972973 2.03347913,27.0434555 1.73954375,29.3243243 L11.7715674,29.3243243 Z"
                            id="Path-18-Copy-8"
                        />
                        <polygon
                            id="Path-19-Copy-2"
                            points="17.7333333 16.7567568 19.4222222 16.7567568 19.4222222 21.7837838 17.7333333 21.7837838"
                        />
                        <polygon
                            id="Path-20-Copy-2"
                            points="14.5737341 24.3458095 13.5111111 23.0433808 18.2089329 19.2702703 23.0106328 23.0373909 21.9631013 24.3517994 18.2213087 21.4162173"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
