import { MDBCard, MDBCardText, MDBCardTitle } from 'mdb-react-ui-kit'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import './LastNews.css'

const renderLatestNews = ({ lastNews }) =>
    lastNews.map((news, key) => (
        <MDBCardText key={key}>
            <Link to={news.slug}>{news.titulo}</Link>
        </MDBCardText>
    ))

function LastNews({ lastNews, title, footer, customClass, lang }) {
  return <div className={customClass}>
        <MDBCard className={footer ? 'last-news__card_footer' : 'last-news__card'}>
            <MDBCardTitle className="last-news__title">{title}</MDBCardTitle>
            {renderLatestNews({ lastNews, lang })}
        </MDBCard>
    </div>
}

LastNews.propTypes = {
    lastNews: PropTypes.arrayOf(
        PropTypes.shape({
            ano: PropTypes.string.isRequired,
            contenido: PropTypes.string.isRequired,
            diames: PropTypes.string.isRequired,
            entradilla: PropTypes.string.isRequired,
            fecha: PropTypes.string.isRequired,
            imagen: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            titulo: PropTypes.string.isRequired,
        })
    ).isRequired,
    title: PropTypes.string.isRequired,
    footer: PropTypes.bool,
    customClass: PropTypes.string,
    lang: PropTypes.string.isRequired,
}

LastNews.defaultProps = {
    footer: false,
    customClass: '',
}

export default LastNews
