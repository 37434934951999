import React from 'react'

import './FormInput.css'

function FormInput({ className, id, errors, name, ...rest }) {
    return (
        <input
            className={`form-input ${errors ? 'form-input-error ' : null} ${className}`}
            id={id}
            name={name || id}
            {...rest}
        />
    )
}

export default FormInput
