import { SET_INFRASTRUCTURE_DATA } from '../constants/constants'

// eslint-disable-next-line default-param-last
const infrastructures = (state = {}, action) => {
    if (action.type === SET_INFRASTRUCTURE_DATA) {
        const { infrastructure, data } = action.payload
        return {
            ...state,
            [infrastructure]: data,
        }
    }
    return state
}

export default infrastructures
