import React from 'react'
import ActionDetail from '../compositions/ActionDetail/ActionDetail'

export default function ActionContainer(props) {
    // const { data, match } = props
    // const { data: actions } = data
    const { data, match } = props
    const { actions } = data
    const { params: lang } = match

    return (
        <ActionDetail
            actions={actions.data.action}
            area={actions.data.action.area}
            budgedBreakdown={actions.data.action.budged_breakdown}
            budget={actions.data.action.budget}
            colorPorcentaje={actions.data.action.plantrafficlight}
            description={actions.data.action.description}
            endPlannedDate={actions.data.action.end_planned_date}
            endRealDate={actions.data.action.end_real_date}
            expenditure={actions.data.action.expenditure}
            files={actions.data.action.files}
            imagen={actions.data.action.image}
            lang={lang}
            links={actions.data.action.links}
            milestones={actions.data.action.milestones}
            ods={actions.data.action.ods}
            percentage={actions.data.action.percentage}
            planTitle={actions.data.action.plan_title}
            presentation={actions.data.action.presentation}
            sectoralThemes={actions.data.action.sectoral_themes}
            showInMap={actions.data.action.show_in_map}
            showPercentage={actions.data.action.show_percentage}
            startPlannedDate={actions.data.action.start_planned_date}
            startRealDate={actions.data.action.start_real_date}
            title={actions.data.action.title}
            translations={actions.data.translations}
            zones={actions.data.action.zones}
        />
    )
}
