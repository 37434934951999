import React from 'react'
import classNames from 'classnames'
import t from '../../i18n/translations/translations'

function HeaderToggleButton({ action, lang, macromenuCollapsed }) {
    const toggleMacromenuButton = classNames({
        button_container: true,
        'macromenu--opened': !macromenuCollapsed,
    })

    return (
        <div
            aria-label={t.open_macromenu[lang]}
            aria-haspopup
            className={toggleMacromenuButton}
            id="toggle"
            onClick={() => action()}
            onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                    event.preventDefault()
                    action()
                }
            }}
            role="button"
            tabIndex="0"
        >
            <span className="top" />
            <span className="middle" />
            <span className="bottom" />
        </div>
    )
}

export default HeaderToggleButton
