import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import t from '../../i18n/translations/translations'
import createPlanProgressUrlBySlug from '../../utils/createPlanProgressUrlBySlug'

import './PlansNotGrouped.css'

function PlanStatus({ text }) {
    return (
        <div className="plans_status">
            <p>{text}</p>
        </div>
    )
}

function PlansNotGrouped(props) {
    const [isFocused, setIsFocused] = useState(false)
    const [isProgressFocused, setIsProgressFocused] = useState(false)

    const handleFocus = () => {
        setIsFocused(true)
    }

    const handleBlur = () => {
        setIsFocused(false)
    }

    const handleProgressFocus = () => {
        setIsProgressFocused(true)
    }

    const handleProgressBlur = () => {
        setIsProgressFocused(false)
    }

    const {
        title,
        startDate,
        endDate,
        status,
        slug,
        percentage,
        translations,
        showPercentage,
        showProgress,
        lang,
    } = props

    const texts = {
        vigente: translations.ACCOUNTABILITY.PLAN_LIST_PLANS_STAGE_VIGENTE_1,
        no_vigente:
            translations.ACCOUNTABILITY.PLAN_LIST_PLANS_STATE_NO_VIGENTE_1,
    }

    return (
        <Link onBlur={handleBlur} onFocus={handleFocus} to={slug}>
            <div className="planList_plans">
                <h3
                    className="plansNotGrouped_title"
                    style={
                        isFocused && !isProgressFocused
                            ? { outline: '2px solid black' }
                            : {}
                    }
                >
                    {title}
                </h3>
                <p className="plansNotGrouped_start_date">
                    {startDate?.toLowerCase()}
                </p>
                <p className="plansNotGrouped_end_date">
                    {endDate?.toLowerCase()}
                </p>
                <PlanStatus text={status ? texts.vigente : texts.no_vigente} />
                {showPercentage === false ? (
                    ''
                ) : (
                    <div className="plans-percentage">
                        <p className="percentage">{percentage}%</p>
                        <p className="cumplido">
                            {translations.ACCOUNTABILITY.PLAN_DETAIL_PERCENTAGE.toUpperCase()}
                        </p>
                    </div>
                )}
                {showProgress ? (
                    <Link
                        onBlur={handleProgressBlur}
                        onFocus={handleProgressFocus}
                        to={createPlanProgressUrlBySlug(slug, lang)}
                    >
                        <i
                            className="fa fa-bar-chart"
                            aria-hidden="true"
                            alt={t.plan_progress_alt[lang]}
                        />
                    </Link>
                ) : (
                    ''
                )}
            </div>
        </Link>
    )
}

export default PlansNotGrouped
