import PropTypes from 'prop-types'
import React from 'react'
import Swiper from 'react-id-swiper'
import { Link } from 'react-router-dom'
import RelatedNews from '../RelatedNews/RelatedNews'
import './Swiper.css'

function Swipe({ noticias, numero, customClass, isMobile, isTablet }) {
    let slides

    if (isMobile) {
        slides = 1
    } else if (isTablet && numero >= 4) {
        slides = 1
    } else {
        slides = parseInt(numero, 10)
    }

    const params = {
        slidesPerView: slides,
        spaceBetween: 30,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
        },
        rebuildOnUpdate: true,
        shouldSwiperUpdate: true,
    }

    const swiperClass = `swiper__container ${customClass}`

    return (
        <div className={swiperClass}>
            <Swiper
                pagination={params.pagination}
                rebuildOnUpdate={params.rebuildOnUpdate}
                shouldSwiperUpdate={params.shouldSwiperUpdate}
                slidesPerView={params.slidesPerView}
                spaceBetween={params.spaceBetween}
            >
                {noticias.map((noticia, index) => (
                    <Link to={noticia.slug} key={index}>
                        <div className="swiper__related-news">
                            <RelatedNews
                                title={noticia.titulo}
                                date={noticia.fecha}
                                body={noticia.entradilla}
                            />
                        </div>
                    </Link>
                ))}
            </Swiper>
        </div>
    )
}

Swipe.propTypes = {
    noticias: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    numero: PropTypes.string.isRequired,
    customClass: PropTypes.string.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isTablet: PropTypes.bool.isRequired,
}

export default Swipe
