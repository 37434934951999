import React from 'react'

export default function Calendar() {
    return (
        <svg
            width="34px"
            height="31px"
            viewBox="0 0 34 31"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="AurreTIK_desktop_detalle"
                    transform="translate(-643.000000, -743.000000)"
                >
                    <g
                        id="Group-4"
                        transform="translate(595.000000, 512.000000)"
                    >
                        <g
                            id="Group-9-Copy-5"
                            transform="translate(30.000000, 216.000000)"
                        >
                            <g
                                id="Group"
                                transform="translate(15.000000, 10.000000)"
                            >
                                <g
                                    id="Group-3"
                                    transform="translate(3.000000, 5.000000)"
                                >
                                    <rect
                                        id="Rectangle"
                                        stroke="#065464"
                                        strokeWidth="1.34999996"
                                        x="0.674999982"
                                        y="5.10357141"
                                        width="32.65"
                                        height="25.2214286"
                                        rx="2.69999993"
                                    />
                                    <rect
                                        id="Rectangle-Copy-19"
                                        fill="#1AAFCC"
                                        x="4.47368421"
                                        y="23.9142857"
                                        width="3.57894737"
                                        height="3.54285714"
                                        rx="0.899999976"
                                    />
                                    <rect
                                        id="Rectangle-Copy-25"
                                        fill="#1AAFCC"
                                        x="4.47368421"
                                        y="18.6"
                                        width="3.57894737"
                                        height="3.54285714"
                                        rx="0.899999976"
                                    />
                                    <rect
                                        id="Rectangle-Copy-21"
                                        fill="#1AAFCC"
                                        x="9.84210526"
                                        y="23.9142857"
                                        width="3.57894737"
                                        height="3.54285714"
                                        rx="0.899999976"
                                    />
                                    <rect
                                        id="Rectangle-Copy-26"
                                        fill="#1AAFCC"
                                        x="9.84210526"
                                        y="18.6"
                                        width="3.57894737"
                                        height="3.54285714"
                                        rx="0.899999976"
                                    />
                                    <rect
                                        id="Rectangle-Copy-22"
                                        fill="#1AAFCC"
                                        x="15.2105263"
                                        y="23.9142857"
                                        width="3.57894737"
                                        height="3.54285714"
                                        rx="0.899999976"
                                    />
                                    <rect
                                        id="Rectangle-Copy-27"
                                        fill="#1AAFCC"
                                        x="15.2105263"
                                        y="18.6"
                                        width="3.57894737"
                                        height="3.54285714"
                                        rx="0.899999976"
                                    />
                                    <rect
                                        id="Rectangle-Copy-32"
                                        fill="#1AAFCC"
                                        x="15.2105263"
                                        y="13.2857143"
                                        width="3.57894737"
                                        height="3.54285714"
                                        rx="0.899999976"
                                    />
                                    <rect
                                        id="Rectangle-Copy-23"
                                        fill="#1AAFCC"
                                        x="20.5789474"
                                        y="23.9142857"
                                        width="3.57894737"
                                        height="3.54285714"
                                        rx="0.899999976"
                                    />
                                    <rect
                                        id="Rectangle-Copy-28"
                                        fill="#1AAFCC"
                                        x="20.5789474"
                                        y="18.6"
                                        width="3.57894737"
                                        height="3.54285714"
                                        rx="0.899999976"
                                    />
                                    <rect
                                        id="Rectangle-Copy-34"
                                        fill="#1AAFCC"
                                        x="20.5789474"
                                        y="13.2857143"
                                        width="3.57894737"
                                        height="3.54285714"
                                        rx="0.899999976"
                                    />
                                    <rect
                                        id="Rectangle-Copy-24"
                                        fill="#1AAFCC"
                                        x="25.9473684"
                                        y="23.9142857"
                                        width="3.57894737"
                                        height="3.54285714"
                                        rx="0.899999976"
                                    />
                                    <rect
                                        id="Rectangle-Copy-29"
                                        fill="#1AAFCC"
                                        x="25.9473684"
                                        y="18.6"
                                        width="3.57894737"
                                        height="3.54285714"
                                        rx="0.899999976"
                                    />
                                    <rect
                                        id="Rectangle-Copy-33"
                                        fill="#1AAFCC"
                                        x="25.9473684"
                                        y="13.2857143"
                                        width="3.57894737"
                                        height="3.54285714"
                                        rx="0.899999976"
                                    />
                                    <path
                                        d="M7.15789474,0.674999982 C7.46564815,0.674999982 7.74426658,0.7997417 7.94594665,1.00142177 C8.14762672,1.20310184 8.27236844,1.48172027 8.27236844,1.78947368 L8.27236844,1.78947368 L8.27236844,4.63928573 L6.04342103,4.63928573 L6.04342103,1.78947368 C6.04342103,1.48172027 6.16816275,1.20310184 6.36984282,1.00142177 C6.5715229,0.7997417 6.85014132,0.674999982 7.15789474,0.674999982 Z"
                                        id="Rectangle-Copy-17"
                                        stroke="#065464"
                                        strokeWidth="1.34999996"
                                    />
                                    <path
                                        d="M26.8421053,0.674999982 C27.1498587,0.674999982 27.4284771,0.7997417 27.6301572,1.00142177 C27.8318372,1.20310184 27.956579,1.48172027 27.956579,1.78947368 L27.956579,1.78947368 L27.956579,4.63928573 L25.7276316,4.63928573 L25.7276316,1.78947368 C25.7276316,1.48172027 25.8523733,1.20310184 26.0540534,1.00142177 C26.2557334,0.7997417 26.5343518,0.674999982 26.8421053,0.674999982 Z"
                                        id="Rectangle-Copy-18"
                                        stroke="#065464"
                                        strokeWidth="1.34999996"
                                    />
                                    <line
                                        x1="0.894736842"
                                        y1="9.74285714"
                                        x2="33.5755735"
                                        y2="9.74285714"
                                        id="Path-17"
                                        stroke="#065464"
                                        strokeWidth="1.34999996"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
