import React from 'react'

function CustomButton({ className, action, text }) {
    return (
        <div className={className} role="button" onClick={action}>
            {text}
        </div>
    )
}

export default CustomButton
