import React from 'react'
import './FinderPercentageContent.css'

function FinderPercentageContent({
    show,
    isTablet,
    isMobile,
    percent,
    translations,
    bold,
}) {
    const tabletMessage = `${percent}%`

    let message = null

    if (show) {
        if (isTablet) {
            message = tabletMessage
        } else if (isMobile) {
            message = ''
        } else {
            message =
                tabletMessage +
                translations.ACCOUNTABILITY.FINDER_PERCENTAGE_DONE
        }
    }
    return (
        <th className="porcentaje-content">
            {bold ? <em>{message}</em> : message}
        </th>
    )
}

export default FinderPercentageContent
