import React from 'react'
import ActionDetailClasificationSectors from '../../../atoms/ActionDetails/ActionDetailClasificationSectors/ActionDetailClasificationSectors'
import ActionDetailClasificationZonas from '../../../atoms/ActionDetails/ActionDetailClasificationZonas/ActionDetailClasificationZonas'
import ActionDetailClassificationTitle from '../../../atoms/ActionDetails/ActionDetailClassificationTitle/ActionDetailClassificationTitle'

function ActionDetailAreasContent({
    area,
    zones,
    lang,
    translations,
    sectoralThemes,
}) {
    const titles = {
        area: translations.ACCOUNTABILITY.ACTION_DETAIL_AREA,
        zones: translations.ACCOUNTABILITY.ACTION_DETAIL_ZONES,
        sectoral: translations.ACCOUNTABILITY.ACTION_DETAIL_SECTORAL_THEMES,
        classification:
            translations.ACCOUNTABILITY.ACTION_DETAIL_CLASSIFICATION_DATA,
    }

    return (
        <div className="actionDetail_action-descriptionContainer-clasificacion">
            <ActionDetailClassificationTitle title={titles.classification} />
            <div className="actionDetail_action-descriptionContainer-clasificacion-content">
                <div className="clasification-content_tematica-area">
                    <ActionDetailClasificationSectors
                        titles={titles.sectoral}
                        lang={lang}
                        data={sectoralThemes}
                    />
                    {area ? (
                        <div className="clasificacion-content">
                            <span>{titles.area}</span>
                            <p>{area}</p>
                        </div>
                    ) : null}
                </div>
                {zones && zones.length ? (
                    <ActionDetailClasificationZonas
                        zonas={zones}
                        lang={lang}
                        title={titles.zones}
                    />
                ) : null}
            </div>
        </div>
    )
}

export default ActionDetailAreasContent
