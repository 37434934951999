import classNames from 'classnames'
import React from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import BudgetTitle from '../../BudgetTitle/BudgetTitle'
import './ActionDetailChart.css'

function Separator() {
    return <div className="separador" />
}

function ActionDetailChart({
    translations,
    percentage,
    presentation,
    colorPorcentaje,
}) {
    return (
        <div className="chart">
            <BudgetTitle
                text={translations.ACCOUNTABILITY.ACTION_DETAIL_PROGRESS}
            />
            <Separator />
            <CircularProgressbar
                className={classNames(
                    'CircularProgressbar-text CircularProgressbar-trail CircularProgressbar-path-orange ',
                    {
                        'CircularProgressbar-text CircularProgressbar-trail CircularProgressbar-path-orange':
                            percentage < 33,
                    }
                )}
                value={percentage}
                text={presentation === 1 ? `` : `${percentage}%`}
                styles={buildStyles({
                    pathColor: colorPorcentaje,
                })}
            />
            {presentation === 2 && (
                <div className="done-text">
                    <p>
                        {translations.ACCOUNTABILITY.PLAN_LIST_PORCENTAJE.toUpperCase()}
                    </p>
                </div>
            )}
        </div>
    )
}

export default ActionDetailChart
