import React from 'react'

/* eslint-disable max-len */
export default function ArrowSlider({ className, title }) {
    return (
        <svg className={className} title={title} viewBox="0 0 23 23">
            <g
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <polyline
                    id="right_arrow"
                    strokeWidth="2"
                    transform="translate(11.500000, 11.500000) rotate(-135.000000) translate(-11.500000, -11.500000) "
                    points="19 19 4 19 4 4"
                />
            </g>
        </svg>
    )
}
