import React from 'react'
import Ayuntamiento from '../assets/svg/Ayuntamiento'
import Contrata from '../assets/svg/Contrata'
import Controles from '../assets/svg/Controles'
import InfoEco from '../assets/svg/InfoEco'
import Nivel2 from '../assets/svg/Nivel2'
import Nivel3 from '../assets/svg/Nivel3'
import Normativa from '../assets/svg/Normativa'
import Planificacion from '../assets/svg/Planificacion'
import Relaciones from '../assets/svg/Relaciones'
import './switchTransparencyIcons.css'

export default function getIcon(icon) {
    let categoryCardIcon

    switch (icon) {
        case 'tra_ayunta':
            categoryCardIcon = <Ayuntamiento className="category-icon" />
            break
        case 'tra_contratacion':
            categoryCardIcon = <Contrata className="category-icon" />
            break
        case 'tra_controles':
            categoryCardIcon = <Controles className="category-icon" />
            break
        case 'tra_info':
            categoryCardIcon = <InfoEco className="category-icon" />
            break
        case 'tra_normativa':
            categoryCardIcon = <Normativa className="category-icon" />
            break
        case 'tra_plani':
            categoryCardIcon = <Planificacion className="category-icon" />
            break
        case 'tra_relaciones':
            categoryCardIcon = <Relaciones className="category-icon" />
            break
        case 'tra_nivel2':
            categoryCardIcon = <Nivel2 className="category-icon--little" />
            break
        case 'tra_nivel3':
            categoryCardIcon = <Nivel3 className="category-icon--little" />
            break
        default:
            categoryCardIcon = ''
            break
    }
    return categoryCardIcon
}
