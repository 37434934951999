import React from 'react'
import createImageRoute from '../../../utils/createImageRoute'
import './ActionDetailBudgetImages.css'

function ActionDetailBudgetImages({ image, imgId }) {
    return (
        <>
            <div className="presupuesto-image">
                {image ? <img src={createImageRoute(image)} alt="" /> : ''}
            </div>
            <div>
                {imgId ? <img src={createImageRoute(imgId)} alt="" /> : ''}
            </div>
        </>
    )
}

export default ActionDetailBudgetImages