import React from 'react'

export default function Cross({ width }) {
    return (
        <svg viewBox="0 0 35 35" width={width} fill="none" xmlns="http://www.w3.org/2000/svg">
            <title id="crossTitle">Cerrar</title>
            <path
                d="M9.21385 25.8647L26.2671 8.81153"
                stroke="#383B41"
                strokeWidth="2.418"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.21385 8.81447L26.2671 25.8677"
                stroke="#383B41"
                strokeWidth="2.418"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
