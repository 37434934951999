import React from 'react'
import ActionDetailObjectivesTitle from '../../../atoms/ActionDetails/ActionDetailObjectivesTitle/ActionDetailObjectivesTitle'
import './ActionDetailObjectivesContent.css'

function ActionDetailObjectivesContent({ title, objectives }) {
    return (
        <div className="actionDetail_action-descriptionContainer-objetivos">
            <ActionDetailObjectivesTitle title={title} />

            <div className="actionDetail_action-descriptionContainer-objetivos-content">
                <div>
                    {objectives.map((od, i) => (
                        <p key={i}>{od}</p>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ActionDetailObjectivesContent
