import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import './Macromenu.css'

function Macromenu({ handleMacromenuCollapse, macromenuCollapsed, menuItems }) {
    const menuLength = menuItems?.length

    // 3 items per column
    const numberOfColumns = Math.ceil(menuLength / 3)

    const menu = []
    let menuAux = []

    for (let i = 0; i < numberOfColumns; i += 1) {
        menuAux = []
        for (let j = 0; j < 3; j += 1) {
            if (menuItems[i * 3 + j] !== undefined) {
                menuAux[j] = menuItems[i * 3 + j]
            }
        }
        menu[i] = menuAux
    }

    const classesMacromenu = classNames({
        'macromenu-container': true,
        'macromenu--opened': !macromenuCollapsed,
    })

    return (
        <div className={classesMacromenu}>
            <div className="row macromenu-content">
                {menu.map((item, index) => {
                    const classesColumns = classNames({
                        'macromenu-column': true,
                        'col-10': true,
                        'col-10': numberOfColumns === 2 && index === 0,
                        'col-10': numberOfColumns === 3 && index === 0,
                        'col-10': numberOfColumns === 1,
                    })
                    return (
                        <div className={classesColumns} key={index}>
                            {item.map((child, key) => (
                                <div className="macromenu-link" key={key}>
                                    <Link
                                        onClick={handleMacromenuCollapse}
                                        to={child.slug}
                                    >
                                        <p className="macromenu-item__parent">
                                            {child.titulo}
                                        </p>
                                    </Link>
                                    {child !== undefined
                                        ? child.paginas.map(
                                              (childPage, childPageKey) => (
                                                  <Link
                                                      onClick={
                                                          handleMacromenuCollapse
                                                      }
                                                      to={childPage.slug}
                                                      key={childPageKey}
                                                  >
                                                      <p className="macromenu-item__child">
                                                          {childPage.titulo}
                                                      </p>
                                                  </Link>
                                              )
                                          )
                                        : null}
                                </div>
                            ))}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

Macromenu.propTypes = {
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            titulo: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            paginas: PropTypes.arrayOf(
                PropTypes.shape({
                    titulo: PropTypes.string.isRequired,
                    slug: PropTypes.string.isRequired,
                }).isRequired
            ),
        }).isRequired
    ).isRequired,
    macromenuCollapsed: PropTypes.bool.isRequired,
}

export default Macromenu
