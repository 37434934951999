import $ from 'jquery'
import React, { useEffect, useRef } from 'react'
import CategoryRender from '../CategoryRender/CategoryRender'
import MenuVertical from '../MenuVertical/MenuVertical'
import './DetailRender.css'

function DetailRender({ contenido, menuSecondary }) {
    const sidebarRef = useRef(null)
    const contentRef = useRef(null)

    useEffect(() => {
        if (sidebarRef.current && contentRef.current) {
            const sidebar = sidebarRef.current.offsetHeight
            const content = contentRef.current.offsetHeight

            if (content < sidebar) {
                contentRef.current.style.height = `${sidebar}px`
            } else {
                contentRef.current.style.height = ''
            }
        }
    }, [])

    return (
        <div className="detail">
            <div className="detail__wrapper">
                <div className="detail__sidebar overlay blue">
                    <MenuVertical menuSecondary={menuSecondary} />
                </div>
            </div>
            <div className="detail__content">
                <div className="detail__content-box">
                    <CategoryRender categorias={contenido} />
                </div>
            </div>
        </div>
    )
}

export default DetailRender
