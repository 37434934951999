import React from 'react'
import Ubication from '../../../assets/svg/Ubication'

function PlanDetailMapTitle({ translations }) {
    return (
        <div className="container-map-title_container">
            <div className="planDetail-box_container-map_title">
                <p>
                    <Ubication className="UbicationIcon" />
                    {translations.ACCOUNTABILITY.PLAN_DETAIL_MAP_TITLE}
                </p>
            </div>
        </div>
    )
}

export default PlanDetailMapTitle
