import getInstanciaId  from './getInstanciaId'

const getCssCustomPath = ({ match, pageData }) => {
    const idInstancia =
        pageData && getInstanciaId({ match, page: pageData })

    if (idInstancia && idInstancia === 102) {
        if (process.env.REACT_APP_ACTIVE_ENVIRONMENT && process.env.REACT_APP_ACTIVE_ENVIRONMENT === 'PRE') {
            return `/css_instancias/102_pre.css`
        }
    }

    return `/css_instancias/${idInstancia}.css`
}

export default getCssCustomPath;
