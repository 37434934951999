import React from 'react'

export default function LockApp() {
    return (
        <svg viewBox="0 0 384 512">
            <path
                fill="#FFF"
                d="M16 64C16 28.7 44.7 0 80 0H304c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H80c-35.3 0-64-28.7-64-64V64zM224 448c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32zM304 64H80V384H304V64z"
            />
        </svg>
    )
}
