import classNames from 'classnames'
import React from 'react'
import ArrowsUpDown from '../../../atoms/ArrowsUpDown/ArrowsUpDown'
import BudgetNumber from '../../../atoms/BudgetNumber/BudgetNumber'
import Separator from '../../../atoms/Separator/Separator'
import './ActionDetailBudgetBreakdown.css'

function SpanWithColon(text) {
    return <span>{`${text}: `} </span>
}

function ActionDetailBudgetBreakdown({
    breakdown,
    isActive,
    translations,
    stateDesglose,
    title,
}) {
    return (
        <div className="actionDetail_action-desgloseContainer">
            <div
                className="actionDetail_action-desglose-title"
                onClick={stateDesglose}
            >
                <p>{title}</p>

                <ArrowsUpDown
                    className="arrows-budget"
                    isActive={isActive}
                    isBudget
                />
            </div>
            <div
                className={classNames('desglose-detail-hidden', {
                    'actionDetail_action-desglose-detail': isActive,
                })}
            >
                {breakdown.map((detail, i) => (
                    <div className="detail-container" key={i}>
                        <p>{detail.title}</p>
                        <Separator />
                        <div className="detail-numbers">
                            {detail.percentage ? (
                                <p>
                                    <SpanWithColon
                                        text={
                                            translations.ACCOUNTABILITY
                                                .ACTION_DETAIL_BUDGET_BREAKDOWN_PERCENTAGE
                                        }
                                    />{' '}
                                    {detail.percentage}%
                                </p>
                            ) : (
                                ''
                            )}
                            {detail.import ? (
                                <p>
                                    <SpanWithColon
                                        text={
                                            translations.ACCOUNTABILITY
                                                .ACTION_DETAIL_BUDGET_BREAKDOWN_IMPORT
                                        }
                                    />{' '}
                                    <BudgetNumber num={detail.import} />
                                </p>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ActionDetailBudgetBreakdown
