import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { FUNCTIONALITY } from '../../constants'
import FunctionalitiesRender from '../FunctionalitiesRender/FunctionalitiesRender'
import ModulesRender from '../ModulesRender/ModulesRender'
import './ColumnsRender.css'

function ColumnsRender({ columns, lang, isMobile, isTablet, isAurretik }) {
    return Object.values(columns.columnas).map((modulos, index) => {
        let divCustomClass
        modulos.clasecss_div !== undefined
            ? (divCustomClass = modulos.clasecss_div)
            : (divCustomClass = '')
        const columnClass = classNames({
            'col-12': true,
            fullWidth: true,
            'column-rendered': true,
            'no-tablet': modulos.showtablet === 0 && modulos.showmobile === 1,
            'no-mobile': modulos.showmobile === 0 && modulos.showtablet === 1,
            'no-render': modulos.showmobile === 0 && modulos.showtablet === 0,
            'col-xl-3': modulos.tamano === 1 || modulos.tamano === 'undefined',
            'col-xl-6': modulos.tamano === 2,
            'col-xl-9': modulos.tamano === 3,
            divCustomClass: true,
        })
        if (modulos.tipo_caja === FUNCTIONALITY && modulos.render === 1) {
            return (
                <div className={columnClass} key={index}>
                    <FunctionalitiesRender
                        tipo={modulos.tipo}
                        contenido={modulos.contenido}
                        opciones={modulos}
                        lang={lang}
                        isAurretik={isAurretik}
                    />
                </div>
            )
        }

        if (modulos.tipo_caja !== FUNCTIONALITY) {
            return (
                <div className={columnClass} key={index}>
                    <ModulesRender
                        tipo={modulos.tipo}
                        contenido={modulos.contenido}
                        opciones={modulos}
                        lang={lang}
                        customClass={divCustomClass}
                        isMobile={isMobile}
                        isTablet={isTablet}
                        isAurretik={isAurretik}
                    />
                </div>
            )
        }
    })
}

ColumnsRender.propTypes = {
    lang: PropTypes.string.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isTablet: PropTypes.bool.isRequired,
}

export default ColumnsRender
