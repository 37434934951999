import { SET_IRUDIZ } from '../constants/constants'

// eslint-disable-next-line default-param-last
const irudiz = (state = {}, action) => {
    if (action.type === SET_IRUDIZ) {
        return action.payload
    }

    return state
}

export default irudiz
