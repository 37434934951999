import hartuzDataTransformer from '../../transformers/hartuz/hartuzDataTransformer'
import hartuzDatesTransformer from '../../transformers/hartuz/hartuzDatesTransformer'
import hartuzFacilityDataTransformer from '../../transformers/hartuz/hartuzFacilityDataTransformer'
import { transformInstances } from '../../transformers/instancesTransformer'
import mapDataTransformer from '../../transformers/mapDataTransformer'
import mapsDataTransformer from '../../transformers/mapsDataTransformer'
import {
    SET_ACCION,
    SET_ACCION_DATA,
    SET_CATEGORY,
    SET_CATEGORY_DATA,
    SET_HARTUZ,
    SET_HARTUZ_AVAILABLE_DATES_DATE_TYPE,
    SET_HARTUZ_AVAILABLE_DATES_DATE_TYPE_DATA,
    SET_HARTUZ_AVAILABLE_HOURS_DATE_TYPE,
    SET_HARTUZ_AVAILABLE_HOURS_DATE_TYPE_DATA,
    SET_HARTUZ_DATA,
    SET_HARTUZ_FACILITY,
    SET_HARTUZ_FACILITY_BOOKING_ERROR,
    SET_HARTUZ_FACILITY_BOOKING_ERROR_CONCURRENCE,
    SET_HARTUZ_FACILITY_BOOKING_ERROR_TIME_EXPIRED,
    SET_HARTUZ_FACILITY_BOOKING_SUBMITTING,
    SET_HARTUZ_FACILITY_BOOKING_SUCCESS,
    SET_HARTUZ_FACILITY_DATA,
    SET_HARTU_USER_BOOKINGS,
    SET_HARTU_USER_BOOKINGS_DATA,
    SET_INCIDENCIA,
    SET_INCIDENCIA_DATA,
    SET_INDICATOR,
    SET_INDICATOR_DATA,
    SET_INFRASTRUCTURE,
    SET_INFRASTRUCTURE_DATA,
    SET_INSTANCES_LIST,
    SET_INSTANCES_LIST_DATA,
    SET_IRUDIZ,
    SET_IRUDIZ_DATA,
    SET_MAP,
    SET_MAPS,
    SET_MAPS_DATA,
    SET_MAP_DATA,
    SET_NEWS,
    SET_NEWS_DATA,
    SET_PAGE,
    SET_PAGE_DATA,
    SET_PLAN,
    SET_PLAN_DATA,
    SET_UA,
    SET_UA_DATA,
    SET_USER_UZ_INFO,
    SET_USER_UZ_INFO_DATA,
    SET_USER_UZ_UPDATE_ERROR,
    SET_USER_UZ_UPDATE_SUBMITTING,
    SET_USER_UZ_UPDATE_SUCCESS,
} from '../constants/constants'

import {setCookie, getCookie} from '../../utils/cookiesFunctions'

const setPage = (payload) => ({
    type: SET_PAGE,
    payload,
})
const setPageData = (payload) => ({
    type: SET_PAGE_DATA,
    payload,
})

const setNews = (payload) => ({
    type: SET_NEWS,
    payload,
})

const setNewsData = (payload) => ({
    type: SET_NEWS_DATA,
    payload,
})

const setPlan = (payload) => ({
    type: SET_PLAN,
    payload,
})

const setPlanData = (payload) => ({
    type: SET_PLAN_DATA,
    payload,
})

const setAccion = (payload) => ({
    type: SET_ACCION,
    payload,
})

const setAccionData = (payload) => ({
    type: SET_ACCION_DATA,
    payload,
})

const setIncidencia = (payload) => ({
    type: SET_INCIDENCIA,
    payload,
})

const setIncidenciaData = (payload) => ({
    type: SET_INCIDENCIA_DATA,
    payload,
})

const setCategory = (payload) => ({
    type: SET_CATEGORY,
    payload,
})

const setCategoryData = (payload) => ({
    type: SET_CATEGORY_DATA,
    payload,
})

const setIndicator = (payload) => ({
    type: SET_INDICATOR,
    payload,
})

const setIndicatorData = (payload) => ({
    type: SET_INDICATOR_DATA,
    payload,
})

const setUA = (payload) => ({
    type: SET_UA,
    payload,
})

const setUAData = (payload) => ({
    type: SET_UA_DATA,
    payload,
})

const setCategories = (payload) => ({
    type: SET_CATEGORY,
    payload,
})

const setCategoriesData = (payload) => ({
    type: SET_CATEGORY_DATA,
    payload,
})

const setInfrastructure = (payload) => ({
    type: SET_INFRASTRUCTURE,
    payload,
})

const setInfrastructureData = (payload) => ({
    type: SET_INFRASTRUCTURE_DATA,
    payload,
})

const setIrudizData = (payload) => ({
    type: SET_IRUDIZ_DATA,
    payload,
})

const setIrudiz = (payload) => ({
    type: SET_IRUDIZ,
    payload,
})

const setMaps = (payload) => ({
    type: SET_MAPS,
    payload,
})
const setMapsData = (payload) => ({
    type: SET_MAPS_DATA,
    payload,
})
const setMap = (payload) => ({
    type: SET_MAP,
    payload,
})

const setMapData = (payload) => ({
    type: SET_MAP_DATA,
    payload,
})

const setHartuz = (payload) => ({
    type: SET_HARTUZ,
    payload,
})

const setHartuzData = (payload) => ({
    type: SET_HARTUZ_DATA,
    payload,
})

const setHartuzFacility = (payload) => ({
    type: SET_HARTUZ_FACILITY,
    payload,
})

const setHartuzFacilityData = (payload) => ({
    type: SET_HARTUZ_FACILITY_DATA,
    payload,
})

const setHartuzAvailableDatesByFacilityAndType = (payload) => ({
    type: SET_HARTUZ_AVAILABLE_DATES_DATE_TYPE,
    payload,
})

const setHartuzAvailableDatesByFacilityAndTypeData = (payload) => ({
    type: SET_HARTUZ_AVAILABLE_DATES_DATE_TYPE_DATA,
    payload,
})

const setHartuzAvailableHoursByFacilityAndType = (payload) => ({
    type: SET_HARTUZ_AVAILABLE_HOURS_DATE_TYPE,
    payload,
})

const setHartuzAvailableHoursByFacilityAndTypeData = (payload) => ({
    type: SET_HARTUZ_AVAILABLE_HOURS_DATE_TYPE_DATA,
    payload,
})

const setHartuzFacilityBookingSubmitting = (payload) => ({
    type: SET_HARTUZ_FACILITY_BOOKING_SUBMITTING,
    payload,
})

const setHartuzFacilityBookingSuccess = (payload) => ({
    type: SET_HARTUZ_FACILITY_BOOKING_SUCCESS,
    payload,
})

const setHartuzFacilityBookingError = (payload) => ({
    type: SET_HARTUZ_FACILITY_BOOKING_ERROR,
    payload,
})

const setHartuzFacilityBookingErrorTimeExpired = (payload) => ({
    type: SET_HARTUZ_FACILITY_BOOKING_ERROR_TIME_EXPIRED,
    payload,
})

const setHartuzFacilityBookingErrorConcurrence = (payload) => ({
    type: SET_HARTUZ_FACILITY_BOOKING_ERROR_CONCURRENCE,
    payload,
})

const setHartuUserBookings = (payload) => ({
    type: SET_HARTU_USER_BOOKINGS,
    payload,
})

const setHartuUserBookingsData = (payload) => ({
    type: SET_HARTU_USER_BOOKINGS_DATA,
    payload,
})
const setUserUzInfo = (payload) => ({
    type: SET_USER_UZ_INFO,
    payload,
})

const setUserUzInfoData = (payload) => ({
    type: SET_USER_UZ_INFO_DATA,
    payload,
})

const setUserUzUpdateSubmitting = (payload) => ({
    type: SET_USER_UZ_UPDATE_SUBMITTING,
    payload,
})

const setUserUzUpdateSuccess = (payload) => ({
    type: SET_USER_UZ_UPDATE_SUCCESS,
    payload,
})

const setUserUzUpdateError = (payload) => ({
    type: SET_USER_UZ_UPDATE_ERROR,
    payload,
})

const setInstancesList = (payload) => ({
    type: SET_INSTANCES_LIST,
    payload,
})

const setInstancesListData = (payload) => ({
    type: SET_INSTANCES_LIST_DATA,
    payload,
})

const urlBase = process.env.REACT_APP_API_URL

const getLangAndDomainParam = (payload) => {
    const lang = `_lang=${payload.lang}`
    const domain = `_dominio=${payload.domain}`

    return `${lang}&${domain}`
}

export const setSelectedPage = (payload) => (dispatch) => {
    const langAndDomain = getLangAndDomainParam(payload)
    const booking = payload.booking ? `&_path=${payload.booking}` : ``
    const urlPage = `${urlBase}page?${langAndDomain}&_slug=${payload.slug}${booking}`
    dispatch(setPage(payload))
    fetch(urlPage)
        .then((response) => response.json())
        .then((data) => {
            dispatch(
                setPageData({
                    page: payload.slug,
                    data,
                })
            )
        })
}

export const setSelectedNews = (payload) => (dispatch) => {
    const langAndDomain = getLangAndDomainParam(payload)
    const urlNews = `${urlBase}noticiadetalle?${langAndDomain}&_slug=${payload.slug}`
    dispatch(setNews(payload))
    fetch(urlNews)
        .then((data) => data.json())
        .then((data) => {
            dispatch(
                setNewsData({
                    news: payload.slug,
                    data,
                })
            )
        })
}

export const setSelectedPlans = (payload) => (dispatch) => {
    const langAndDomain = getLangAndDomainParam(payload)
    const urlPage = `${urlBase}accountability/plan?${langAndDomain}&_slug=${payload.slug}`
    dispatch(setPlan(payload))

    fetch(urlPage)
        .then((data) => data.json())
        .then((data) => {
            dispatch(
                setPlanData({
                    plan: payload.slug,
                    data,
                })
            )
        })
}

export const setSelectedAcciones = (payload) => (dispatch) => {
    const langAndDomain = getLangAndDomainParam(payload)
    const urlPage = `${urlBase}accountability/action?${langAndDomain}&_slug=${payload.slug}`
    dispatch(setAccion(payload))
    fetch(urlPage)
        .then((data) => data.json())
        .then((data) => {
            dispatch(
                setAccionData({
                    accion: payload.slug,
                    data,
                })
            )
        })
}

export const setSelectedIncidencias = (payload) => (dispatch) => {
    const langAndDomain = getLangAndDomainParam(payload)
    const urlPage = `${urlBase}kalezaindu/incidencia?${langAndDomain}&idincidencia=${payload.slug}`
    dispatch(setIncidencia(payload))
    fetch(urlPage)
        .then((data) => data.json())
        .then((data) => {
            dispatch(
                setIncidenciaData({
                    incidencia: payload.slug,
                    data,
                })
            )
        })
}

export const setSelectedIncidenciasList = (payload) => (dispatch) => {
    const langAndDomain = getLangAndDomainParam(payload)
    let stateParam = null

    if (payload.enProceso === true) {
        stateParam = 1
    } else if (payload.resueltas === true) {
        stateParam = 2
    }
    const dateParam = `startDate=${payload.startDate}&endDate=${payload.endDate}`
    const urlPage = `${urlBase}kalezaindu/incidences/?${langAndDomain}&text=${payload.text}&${dateParam}&category=${payload.category}&sort=${payload.sort}&state=${stateParam}`

    dispatch(setIncidencia(payload))
    fetch(urlPage)
        .then((data) => data.json())
        .then((data) => {
            dispatch(
                setIncidenciaData({
                    incidencia: payload.slug,
                    data,
                })
            )
        })
}

export const setSelectedIncidencia = (payload) => (dispatch) => {
    const langAndDomain = getLangAndDomainParam(payload)
    const urlPage = `${urlBase}kalezaindu?${langAndDomain}&idincidencia=1`
    dispatch(setIncidencia(payload))
    fetch(urlPage)
        .then((data) => data.json())
        .then((data) => {
            dispatch(
                setIncidenciaData({
                    incidencias: payload.incidencias,
                    data,
                })
            )
        })
}

export const setSelectedCategory = (payload) => (dispatch) => {
    const langAndDomain = getLangAndDomainParam(payload)
    const urlPage = `${urlBase}transparencia/categoria?${langAndDomain}&_slug=${payload.slug}`
    dispatch(setCategory(payload))
    fetch(urlPage)
        .then((data) => data.json())
        .then((data) => {
            dispatch(
                setCategoryData({
                    category: payload.slug,
                    data,
                })
            )
        })
}

export const setSelectedIndicator = (payload) => (dispatch) => {
    const langAndDomain = getLangAndDomainParam(payload)
    const urlPage = `${urlBase}transparencia/indicador?${langAndDomain}&_slug=${payload.slug}`
    dispatch(setIndicator(payload))
    fetch(urlPage)
        .then((data) => data.json())
        .then((data) => {
            dispatch(
                setIndicatorData({
                    indicator: payload.slug,
                    data,
                })
            )
        })
}

export const setSelectedUA = (payload) => (dispatch) => {
    const urlPage = `${urlBase}analitycs?_dominio=${payload.domain}`
    dispatch(setUA(payload))
    fetch(urlPage)
        .then((data) => data.json())
        .then((data) => {
            dispatch(
                setUAData({
                    analyticsUA: payload.domain,
                    data,
                })
            )
        })
}

export const setSelectedCategories = (payload) => (dispatch) => {
    const langAndDomain = getLangAndDomainParam(payload)

    const urlPage = `${urlBase}kalezaindu/categories/?${langAndDomain}`
    dispatch(setCategories(payload))
    fetch(urlPage)
        .then((data) => data.json())
        .then((data) => {
            dispatch(
                setCategoriesData({
                    categories: payload.domain,
                    data,
                })
            )
        })
}

export const setDefaultCoordenates = (payload) => (dispatch) => {
    const langAndDomain = getLangAndDomainParam(payload)
    const urlPage = `${urlBase}kalezaindu/default-coordinates/?${langAndDomain}&state=1`
    dispatch(setPlan(payload))
    fetch(urlPage)
        .then((data) => data.json())
        .then((data) => {
            dispatch(
                setPlanData({
                    coordenates: payload.domain,
                    data,
                })
            )
        })
}

export const setDefaultZones = (payload) => (dispatch) => {
    const langAndDomain = getLangAndDomainParam(payload)
    const urlPage = `${urlBase}buscador/zones/?${langAndDomain}`
    dispatch(setCategories(payload))
    fetch(urlPage)
        .then((data) => data.json())
        .then((data) => {
            dispatch(
                setCategoriesData({
                    zones: payload.domain,
                    data,
                })
            )
        })
}

export const setDefaultTopics = (payload) => (dispatch) => {
    const langAndDomain = getLangAndDomainParam(payload)
    const urlPage = `${urlBase}buscador/topics/?${langAndDomain}`
    dispatch(setIncidencia(payload))
    fetch(urlPage)
        .then((data) => data.json())
        .then((data) => {
            dispatch(
                setIncidenciaData({
                    topics: payload.domain,
                    data,
                })
            )
        })
}

export const setSelectedPlansAxisActionsList = (payload) => (dispatch) => {
    const langAndDomain = getLangAndDomainParam(payload)
    const searchTerm = payload.busqueda !== '' ? payload.busqueda : null
    const urlPage = `${urlBase}buscador/?${langAndDomain}&busqueda=${searchTerm}&tematica=${payload.tematica}&zona=${payload.zona}`
    dispatch(setAccion(payload))
    fetch(urlPage)
        .then((data) => data.json())
        .then((data) => {
            dispatch(
                setAccionData({
                    incidencia: payload.slug,
                    data,
                })
            )
        })
}

export const setSelectedAurretik = (payload) => (dispatch) => {
    const urlPage = `${urlBase}aurretik/${payload.slug}`
    dispatch(setInfrastructure(payload))
    fetch(urlPage)
        .then((data) => (data && data.status !== 404 ? data.json() : data))
        .then((data) => {
            dispatch(
                setInfrastructureData({
                    infrastructure: payload.slug,
                    data,
                })
            )
        })
        .catch((error) => {
            console.log('error', error)
        })
}

export const setSelectedIrudiz = (payload) => (dispatch) => {
    const urlPage = `${process.env.REACT_APP_API_URL_BASE}postcard/list/${payload.id}`
    dispatch(setInfrastructure(payload))
    dispatch(setIrudiz(payload))
    fetch(urlPage)
        .then((data) => data.json())
        .then((data) => {
            dispatch(
                setIrudizData({
                    postals: 4,
                    data,
                })
            )
        })
        .catch((error) => {
            console.log('error', error)
        })
}

export const setSelectedMaps = (payload) => (dispatch) => {
    const urlPage = `${process.env.REACT_APP_API_URL_BASE}mapa_recursos/mapas_front/${payload.id}`
    dispatch(setMaps(payload))
    fetch(urlPage)
        .then((data) => data.json())
        .then((data) => {
            const transformedData = mapsDataTransformer(data)
            dispatch(
                setMapsData({
                    maps: payload.id,
                    data: transformedData,
                })
            )
        })
        .catch((error) => {
            console.log('error', error)
        })
}

export const clearMaps = () => {
    const data = []
    return (dispatch) => {
        dispatch(setMap({ map: '', data }))
        dispatch(setMapsData({ maps: '', data }))
    }
}

export const setSelectedMapById = (payload) => {
    const id = payload.id ? payload.id : 11
    return (dispatch) => {
        const urlPage = `${process.env.REACT_APP_API_URL_BASE}mapa_recursos/datos_de_mapas_front/${id}`
        dispatch(setMap(payload))
        fetch(urlPage)
            .then((data) => data.json())
            .then((data) => {
                const transformedData = mapDataTransformer(data)
                dispatch(
                    setMapData({
                        map: id,
                        data: transformedData,
                    })
                )
            })
            .catch((error) => {
                console.log('error', error)
            })
    }
}

export const setSelectedHartuz = (payload) => (dispatch) =>
    new Promise((resolve, reject) => {
        const urlPage = `${process.env.REACT_APP_API_URL_BASE}hartuz/frontend/bookings/getInstanceFacilities`

        let userId = getCookie('idUser');
        userId = (userId.length == 0)?null:userId;

        const dataBody = {
            id_instancia: payload.instanceID,
            language: payload.lang,
            id_user: userId
        }
        
        dispatch(setHartuz(payload))
        fetch(urlPage, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataBody),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                return response.json()
            })
            .then((data) => {
                const transformedData = hartuzDataTransformer(data)
                dispatch(
                    setHartuzData({
                        facilities: payload,
                        data: transformedData,
                    })
                )
                resolve()
            })
            .catch((error) => {
                console.log('error', error)
                reject(error)
            })
    })

export const setHartuzFacilityDetail = (payload) => (dispatch) => {
    const urlPage = `${process.env.REACT_APP_API_URL_BASE}hartuz/frontend/bookings/getFacilityDetails`

    let userId = getCookie('idUser');
    userId = (userId.length == 0)?null:userId;

    const dataBody = {
        id_instancia: payload.idInstancia,
        language: payload.lang,
        id_facility: payload.facilityID,
        type: payload.facilityType,
        id_user: userId
    }

    dispatch(setHartuzFacility(payload))

    fetch(urlPage, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataBody),
    })
        .then((data) => data.json())
        .then((data) => {
            const transformedData = hartuzFacilityDataTransformer(data)
            dispatch(
                setHartuzFacilityData({
                    facility: payload,
                    data: transformedData,
                })
            )
        })
        .catch((error) => {
            console.log('error', error)
        })
}

export const getAvailableDatesByFacilityAndType = (payload) => (dispatch) => {
    const urlPage = `${process.env.REACT_APP_API_URL_BASE}hartuz/frontend/bookings/getFacilityDaysAvailableCalendar`

    const dataBody = {
        id_instancia: payload.instanceID,
        id_facility: payload.facilityID,
        id_facility_type: payload.facilityType,
    }

    dispatch(setHartuzAvailableDatesByFacilityAndType(payload))

    fetch(urlPage, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataBody),
    })
        .then((data) => data.json())
        .then((data) => {
            const transformedData = hartuzDatesTransformer(data)
            dispatch(
                setHartuzAvailableDatesByFacilityAndTypeData({
                    dates: payload,
                    data: transformedData,
                })
            )
        })
        .catch((error) => {
            console.log('error', error)
        })
}

export const getAvailableHoursByFacilityAndType = (payload) => (dispatch) => {
    const urlPage = `${process.env.REACT_APP_API_URL_BASE}hartuz/frontend/bookings/getFacilityHoursAvailableCalendar`

    const dataBody = {
        id_instancia: payload.instanceID,
        id_facility: payload.facilityID,
        id_facility_type: payload.facilityType,
        date: payload.date,
    }

    dispatch(setHartuzAvailableHoursByFacilityAndType(payload))

    fetch(urlPage, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataBody),
    })
        .then((data) => data.json())
        .then((data) => {
            dispatch(
                setHartuzAvailableHoursByFacilityAndTypeData({
                    hours: payload,
                    data,
                })
            )
        })
        .catch((error) => {
            console.log('error', error)
        })
}

export const saveHartuzFacilityBooking = (payload) => (dispatch) => {
    dispatch(setHartuzFacilityBookingSubmitting(payload))
    const urlPage = `${process.env.REACT_APP_API_URL_BASE}hartuz/frontend/bookings/saveBooking`
    let userId = getCookie('idUser');
    userId = (userId.length == 0)?null:userId;

    const dataBody = {
        id_instancia: parseInt(payload.id_instancia, 10),
        id_facility: parseInt(payload.id_facility, 10),
        id_facility_type: parseInt(payload.type, 10),
        language: payload.lang,
        bookingDate: payload.date,
        startBookingHour: payload.time,
        name: payload.username,
        lastname: payload.surnames,
        email: payload.email,
        dni: payload.dni,
        cp: payload.postalCode,
        phone: payload.phone,
        people: payload.people,
        id_user: userId,
        hasLightCheck: payload.hasLightCheck
    }

    fetch(urlPage, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataBody),
    })
        .then((data) => data.json())
        .then((data) => {
            if (data?.code && data.code === 200) {
                dispatch(
                    setHartuzFacilityBookingSuccess({
                        bookingSave: payload,
                        data,
                    })
                )
            } else if (data?.code && (data.code === 702 || data.code === 611)) {
                dispatch(
                    setHartuzFacilityBookingErrorConcurrence({
                        bookingSave: payload,
                        data,
                    })
                )
            } else {
                dispatch(
                    setHartuzFacilityBookingError({
                        bookingSave: payload,
                        data,
                    })
                )
            }
        })
        .catch((error) => {
            console.log('error', error)
        })
}

export const getUserUZInfo = (payload) => (dispatch) => {
    const urlPage = `${process.env.REACT_APP_API_URL_BASE}user_uz/info_view/${payload.userId}`

    dispatch(setUserUzInfo(payload))
    fetch(urlPage, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': payload.lang,
        },
    })
        .then((data) => data.json())
        .then((data) => {
            dispatch(
                setUserUzInfoData({
                    userInfo: payload,
                    data: data.data,
                })
            )
        })
        .catch((error) => {
            console.log('error', error)
        })
}

export const cancelHartuzFacilityBooking = (payload) => (dispatch) => {
    dispatch(setHartuzFacilityBookingSubmitting(payload))
    const urlPage = `${process.env.REACT_APP_API_URL_BASE}hartuz/frontend/bookings/cancelBooking`
    const dataBody = {
        id_instancia: payload.instanceID,
        booking_code: payload.booking_code,
        email: payload.email,
        dni: payload.dni,
        date: payload.date,
        hour: payload.hour,
        facility: payload.id_facility,
    }
    fetch(urlPage, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataBody),
    })
        .then((data) => data.json())
        .then((data) => {
            if (data?.code === 200) {
                dispatch(
                    setHartuzFacilityBookingSuccess({
                        bookingSave: payload,
                        data,
                    })
                )
            } else if (data?.code === 607) {
                dispatch(
                    setHartuzFacilityBookingErrorTimeExpired({
                        bookingSave: payload,
                        data,
                    })
                )
            } else {
                dispatch(
                    setHartuzFacilityBookingError({
                        bookingSave: payload,
                        data,
                    })
                )
            }
        })
        .catch((error) => {
            console.log('error', error)
        })
}

export const getBookingsByUserId = (payload) => (dispatch) => {
    const urlPage = `${process.env.REACT_APP_API_URL_BASE}hartuz/bookings/getBookingsByUserWithFilters`

    let userId = getCookie('idUser');
    userId = (userId.length == 0)?null:userId;

    const dataBody = {
        facility: null, 
        reserva_estado: null,
        type: null,
        id_user: userId,  
        language: payload.lang,
        search_filter: ''
    }

    dispatch(setHartuUserBookings(payload))

    fetch(urlPage, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataBody),
    })
        .then((data) => data.json())
        .then((data) => {
            dispatch(
                setHartuUserBookingsData({
                    user: payload,
                    data: data.data,
                })
            )
        })
        .catch((error) => {
            console.log('error', error)
        })
}

export const setUserInfo = (payload) => (dispatch) => {
    const urlPage = `${process.env.REACT_APP_API_URL_BASE}giltza/validation_user`
    const dataBody = {
        encrypted_text: payload,
    }

    fetch(urlPage, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': payload.lang,
        },
        body: JSON.stringify(dataBody),
    })
    .then((data) => data.json())
    .then((data) => {
        setCookie('idUser', data.data.id_user, 0.1);

        let slug = window.location.pathname;
        let lang = slug.split("/")[1];

        if (lang === "es") {
            slug = 'preferencias-usuario'
        } else {
            slug = 'erabiltzailearen-lehentasunak'
        }

        window.location.href = window.location.protocol + '//' + window.location.hostname + "/"+lang+"/" + slug;
    })
    .catch((error) => {
        console.log('error', error)
    })
}

export const updateUserUz = (payload) => (dispatch) => {
    dispatch(setUserUzUpdateSubmitting(payload))
    const urlPage = `${process.env.REACT_APP_API_URL_BASE}user_uzs/${payload.userId}`
    const dataBody = {
        id_instance_census: parseInt(payload.newCensus),
        email: payload.newEmail,
        birthday: payload.newBirthDate 
    }

    fetch(urlPage, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/merge-patch+json',
            'Accept-Language': payload.lang,
        },
        body: JSON.stringify(dataBody),
    })
        .then((response) =>
            response.json().then((data) => ({
                data,
                statusCode: response.status,
            }))
        )
        .then(({ data, statusCode }) => {
            if (statusCode === 200) {
                dispatch(
                    setUserUzUpdateSuccess({
                        userUpdate: payload,
                        data,
                    })
                )
            } else {
                dispatch(
                    setUserUzUpdateError({
                        userUpdate: payload,
                        data,
                    })
                )
            }
        })
        .catch((error) => {
            console.log('error', error)
        })
}

export const getInstancias = (payload) => (dispatch) => {
    const urlPage = `${process.env.REACT_APP_API_URL_BASE}instancias`

    dispatch(setInstancesList(payload))
    fetch(urlPage, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((data) => data.json())
        .then((data) => {
            const transformedData = transformInstances(data['hydra:member'])
            dispatch(
                setInstancesListData({
                    instancesList: payload,
                    data: transformedData,
                })
            )
        })
        .catch((error) => {
            console.log('error', error)
        })
}
