import { Map, Marker } from '@vis.gl/react-google-maps'
import React, { useEffect, useState } from 'react'
import ActionStar from '../../assets/svg/ActionStar'
import ActionDetailFiles from '../../atoms/ActionDetails/ActionDetailFiles/ActionDetailFiles'
import ActionDetailHitosTitle from '../../atoms/ActionDetails/ActionDetailHitosTitle/ActionDetailHitosTitle'
import ActionDetailLinks from '../../atoms/ActionDetails/ActionDetailLinks/ActionDetailLinks'
import ActionDetailMapTitle from '../../atoms/ActionDetails/ActionDetailMapTitle/ActionDetailMapTitle'
import ArrowsUpDown from '../../atoms/ArrowsUpDown/ArrowsUpDown'
import PageRow from '../../atoms/PageRow/PageRow'
import Separator from '../../atoms/Separator/Separator'
import ActionDetailAreasContent from '../../components/ActionDetails/ActionDetailAreasContent/ActionDetailAreasContent'
import ActionDetailBudgetBreakdown from '../../components/ActionDetails/ActionDetailBudgetBreakdown/ActionDetailBudgetBreakdown'
import ActionDetailBudgetChart from '../../components/ActionDetails/ActionDetailBudgetChart/ActionDetailBudgetChart'
import ActionDetailDatesContainer from '../../components/ActionDetails/ActionDetailDatesContainer/ActionDetailDatesContainer'
import ActionDetailHitosDescription from '../../components/ActionDetails/ActionDetailHitosDescription/ActionDetailHitosDescription'
import ActionDetailObjectivesContent from '../../components/ActionDetails/ActionDetailObjectivesContent/ActionDetailObjectivesContent'
import WysiwygFunctionality from '../../components/WysiwygFunctionality/WysiwygFunctionality'
import './ActionDetail.css'
import useSetIframeTitle from '../../hooks/useSetIframeTitle'
import t from '../../i18n/translations/translations'

const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '499px',
}

function ActionDetail({
    actions,
    area,
    budgedBreakdown,
    budget,
    colorPorcentaje,
    description,
    endPlannedDate,
    endRealDate,
    expenditure,
    files,
    idimagen,
    imagen,
    lang,
    links,
    milestones: milestonesProp,
    ods,
    percentage,
    planTitle,
    presentation,
    sectoralThemes,
    showInMap,
    startPlannedDate,
    startRealDate,
    title,
    translations,
    zones,
}) {
    const [desgloseIsActive, setDesgloseIsActive] = useState(false)
    const [milestones, setMilestones] = useState(milestonesProp)

    useSetIframeTitle(t.action_detail_map[lang])

    useEffect(() => {
        const updatedMilestones = milestones.map((hito) => ({
            ...hito,
            isActive: false,
        }))

        setMilestones(updatedMilestones)
    }, [])

    const stateDesglose = () => {
        setDesgloseIsActive(!desgloseIsActive)
    }

    const stateHito = (index) => {
        const updatedMilestones = milestones.map((hito, i) => {
            if (i === index) {
                return { ...hito, isActive: !hito.isActive }
            }

            return hito
        })

        setMilestones(updatedMilestones)
    }

    const showBudgetInfo = budget || expenditure || presentation !== 0

    const titles = {
        budget: translations.ACCOUNTABILITY.ACTION_DETAIL_BUDGET_BREAKDOWN,
        ods: translations.ACCOUNTABILITY.ACTION_ODS,
        map: translations.ACCOUNTABILITY.ACTION_DETAIL_MAP_ACTION,
        milestones: translations.ACCOUNTABILITY.ACTION_DETAIL_MILESTONES,
    }

    return (
        <PageRow className="actionDetail">
            <div className="actionDetail_plan-title">
                <h1>{planTitle}</h1>
            </div>
            <div className="actionDetail_action-title">
                <ActionStar />
                <p>{title}</p>
            </div>
            <div className="actionDetail_action-descriptionContainer">
                <div className="container">
                    <div className="container-Description-Chart">
                        <div className="actionDetail_action-descriptionContainer-description">
                            <WysiwygFunctionality
                                className="description"
                                content={{ descripcion: description }}
                            />
                            <ActionDetailDatesContainer
                                responsive
                                startPlannedDate={startPlannedDate}
                                endPlannedDate={endPlannedDate}
                                startRealDate={startRealDate}
                                endRealDate={endRealDate}
                                translations={translations}
                            />
                        </div>

                        {showBudgetInfo ? (
                            <div className="actionDetail_action-descriptionContainer-chart">
                                <ActionDetailBudgetChart
                                    translations={translations}
                                    budget={budget}
                                    expenditure={expenditure}
                                    percentage={percentage}
                                    presentation={presentation}
                                    colorPorcentaje={colorPorcentaje}
                                    imagen={imagen}
                                    idimagen={idimagen}
                                />

                                {budgedBreakdown?.length ? (
                                    <ActionDetailBudgetBreakdown
                                        title={titles.budget}
                                        stateDesglose={stateDesglose}
                                        breakdown={budgedBreakdown}
                                        isActive={desgloseIsActive}
                                        translations={translations}
                                    />
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                    <ActionDetailDatesContainer
                        startPlannedDate={startPlannedDate}
                        endPlannedDate={endPlannedDate}
                        startRealDate={startRealDate}
                        endRealDate={endRealDate}
                        translations={translations}
                    />

                    {files.length ? (
                        <ActionDetailFiles
                            files={files}
                            translations={translations}
                        />
                    ) : null}

                    {links.length ? (
                        <ActionDetailLinks
                            translations={translations}
                            links={links}
                        />
                    ) : null}

                    {milestones?.length ? (
                        <div className="actionDetail_action-descriptionContainer-hitos">
                            <ActionDetailHitosTitle title={titles.milestones} />
                            <div className="actionDetail_action-descriptionContainer-hitos-content">
                                {milestones.map((hito, index) => (
                                    <div key={index}>
                                        <button
                                            className="hitos-content-title"
                                            id={index}
                                            onClick={() => stateHito(index)}
                                            type="button"
                                        >
                                            <p>{hito.title}</p>
                                            <ArrowsUpDown
                                                className="arrows"
                                                isActive={hito.isActive}
                                            />
                                        </button>
                                        <Separator className="separador-hitos" />
                                        <ActionDetailHitosDescription
                                            hito={hito}
                                            translations={translations}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}

                    {showInMap && (
                        <div className="actionDetail-box_container-map">
                            <ActionDetailMapTitle title={titles.map} />

                            <div className="map_container">
                                <Map
                                    defaultCenter={{
                                        lat: actions.latitude,
                                        lng: actions.longitude,
                                    }}
                                    style={containerStyle}
                                    zoom={15}
                                >
                                    <Marker
                                        position={{
                                            lat: actions.latitude,
                                            lng: actions.longitude,
                                        }}
                                    />
                                </Map>
                            </div>
                        </div>
                    )}
                    {sectoralThemes?.length ? (
                        <ActionDetailAreasContent
                            lang={lang}
                            translations={translations}
                            zones={zones}
                            area={area}
                            sectoralThemes={sectoralThemes}
                        />
                    ) : null}

                    {ods?.length ? (
                        <ActionDetailObjectivesContent
                            title={titles.ods}
                            objectives={ods}
                        />
                    ) : (
                        <div />
                    )}
                </div>
            </div>
        </PageRow>
    )
}

export default ActionDetail
