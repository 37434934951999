const getLangByLocation = location => {
    const path = location?.pathname
    const pathLang = path?.slice(1, 3)
    if (pathLang === 'eu') {
        return 'eu'
    }

    return 'es'
}

export default getLangByLocation