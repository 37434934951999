import React from 'react'
import Capacity from '../../../assets/svg/aurretik/Capacity'
import getOccupancyRate from '../../../utils/getOccupancyRate'
import OccupancyIcon from '../../../atoms/OccupancyIcon/OccupancyIcon'
import getOccupancyMessage from '../../../utils/getOccupancyMessage'
import t from '../../../i18n/translations/translations'

import './AurretikFacilityOccupancy.css'

function AurretikFacilityOccupancy({ data, lang }) {
    const { percent, occupancy, capacity } = data
    const occupancyState = getOccupancyRate(percent)
    const occupancyMessage = getOccupancyMessage({
        occupancyState,
        lang,
    })

    return (
        <div className="aurretikFacilityOccupancy">
            <div className="aurretikFacilityOccupancy_occupancy">
                <OccupancyIcon occupancy={occupancyState} />
                <div className="aurretikFacilityOccupancy_data-wrapper">
                    <span className="aurretikFacilityOccupancy_description">
                        {t.current_capacity[lang]}:
                    </span>
                    <span className="aurretikFacilityOccupancy_occupancy--data">
                        {occupancyMessage || '-'}
                    </span>
                </div>
            </div>
            <div className="aurretikFacilityOccupancy_capacity">
                <Capacity />
                <div className="aurretikFacilityOccupancy_data-wrapper">
                    <span className="aurretikFacilityOccupancy_description">
                        {t.current_capacity[lang]}:
                    </span>
                    <div className="aurretikFacilityOccupancy_capacity--data">
                        <span className="aurretikFacilityOccupancy_capacity--data-num">
                            {' '}
                            {occupancy}{' '}
                        </span>{' '}
                        / {capacity}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AurretikFacilityOccupancy
