import React, { useEffect } from 'react'
import Cross from '../../assets/svg/Cross'
import BODY_OVERFLOW_HIDDEN from '../../constants/styles'
import t from '../../i18n/translations/translations'
import './Modal.css'

function Modal({ children, closeModal, isOpened, lang, title }) {
    useEffect(() => {
        if (isOpened) {
            document.body.classList.add(BODY_OVERFLOW_HIDDEN)
        } else {
            document.body.classList.remove(BODY_OVERFLOW_HIDDEN)
        }
    }, [isOpened])

    const handleCloseModal = () => {
        closeModal()
        document.body.classList.remove(BODY_OVERFLOW_HIDDEN)
    }
    return (
        <div className="modal" aria-modal="true" role="dialog">
            <div className="modal__content_wrapper">
                <div className="modal__content_top">
                    <h2>{title}</h2>
                    <button
                        aria-label={t.modal_close_screen[lang]}
                        className="modal__content-close"
                        onClick={() => handleCloseModal()}
                        type="button"
                    >
                        <Cross />
                    </button>
                </div>
                <div className="modal__content">{children}</div>
            </div>
        </div>
    )
}

export default Modal
