import { SET_INDICATOR_DATA } from '../constants/constants'

// eslint-disable-next-line default-param-last
const indicators = (state = {}, action) => {
    if (action.type === SET_INDICATOR_DATA) {
        const { indicator, data } = action.payload

        const dataTransformed = data
        dataTransformed.code = 200
        return { ...state, [indicator]: dataTransformed }
    }
    return state
}

export default indicators
