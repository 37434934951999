import React from 'react'

/* eslint-disable max-len */
export default function SearchIcon({ onClick }) {
    return (
        <svg viewBox="0 0 21 21" onClick={onClick}>
            <defs>
                <path
                    id="path-1"
                    d="M1022.8 37.8a7 7 0 100-14 7 7 0 000 14zm7.849-1.131l2.941 2.941a1.4 1.4 0 01-1.98 1.98l-2.941-2.941a9.757 9.757 0 01-5.869 1.951c-5.412 0-9.8-4.388-9.8-9.8 0-5.412 4.388-9.8 9.8-9.8 5.412 0 9.8 4.388 9.8 9.8a9.757 9.757 0 01-1.951 5.869z"
                />
            </defs>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(-1203 -1152)">
                    <g transform="translate(190 1131)">
                        <g>
                            <mask fill="#fff">
                                <use xlinkHref="#path-1" />
                            </mask>
                            <use
                                fill="#B5BBC6"
                                fillRule="nonzero"
                                xlinkHref="#path-1"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
