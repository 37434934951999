import React from 'react'
import CalendarioInput from '../assets/svg/CalendarioInput'
import SearchIcon from '../assets/svg/SearchIcon'
import KalezainduNoResultMessage from '../atoms/KalezainduNoResultMessage/KalezainduNoResultMessage'

function TitlesWithHiddenSpan({ htmlFor, text, translations }) {
    return (
        <label className="titles" htmlFor={htmlFor}>
            {`${text} `}
            <HiddenPeriodSpan translations={translations} />
        </label>
    )
}

function KalezainduDatesSelect({ action, translations }) {
    return (
        <div className="dates">
            <label htmlFor="search_select">
                {translations.KALEZAINDU.KALEZAINDU_ORDEN}
            </label>
            <select
                className="search_select"
                id="search_select"
                name="sort"
                onChange={action}
            >
                <option value="created">
                    {translations.KALEZAINDU.KALEZAINDU_ORDEN_CREADAS}
                </option>

                <option value="updated">
                    {translations.KALEZAINDU.KALEZAINDU_ORDEN_ACTUALIZADAS}
                </option>

                <option value="Alphabetically">
                    {translations.KALEZAINDU.KALEZAINDU_ORDEN_ALFABETICAMENTE}
                </option>
            </select>
        </div>
    )
}

function HiddenPeriodSpan({ translations }) {
    return (
        <span className="ocultar">
            {translations.KALEZAINDU.KALEZAINDU_PERIODO}
        </span>
    )
}

export default function KalezainduForm({
    handleSubmit,
    translations,
    handleChange,
    startDate,
    endDate,
    lang,
    categories,
    cargado,
    searchBar,
    isMobile,
}) {
    return (
        <form onSubmit={handleSubmit}>
            <div className="incidencias-infobox_search">
                <div className="incidencias-infobox_searchInput">
                    <input
                        aria-label={translations.KALEZAINDU.KALEZAINDU_SEARCH}
                        className="kalezaindu_search_bar"
                        type="text"
                        placeholder={translations.KALEZAINDU.KALEZAINDU_SEARCH}
                        value={searchBar}
                        name="searchBar"
                        onChange={handleChange}
                    />
                    <SearchIcon onClick={handleSubmit} />
                </div>
                {isMobile ? (
                    <div className="incidencias-infobox_searchDates">
                        <div className="mobile_dates">
                            <div className="dates_calendar">
                                <TitlesWithHiddenSpan
                                    htmlFor="inicio"
                                    text={
                                        translations.KALEZAINDU
                                            .KALEZAINDU_INICIO
                                    }
                                    translations={translations}
                                />
                                <div className="datesInput">
                                    <input
                                        aria-label={
                                            translations.KALEZAINDU
                                                .KALEZAINDU_INICIO
                                        }
                                        className="search_date"
                                        type="date"
                                        id="inicio"
                                        name="startDate"
                                        value={startDate}
                                        onChange={handleChange}
                                    />
                                    <CalendarioInput />
                                </div>
                            </div>
                            <div className="dates_calendar">
                                <TitlesWithHiddenSpan
                                    htmlFor="fin"
                                    text={
                                        translations.KALEZAINDU.KALEZAINDU_FIN
                                    }
                                    translations={translations}
                                />
                                <div className="datesInput">
                                    <input
                                        aria-label={
                                            translations.KALEZAINDU
                                                .KALEZAINDU_FIN
                                        }
                                        className="search_date"
                                        type="date"
                                        id="fin"
                                        name="endDate"
                                        value={endDate}
                                        onChange={handleChange}
                                    />
                                    <CalendarioInput />
                                </div>
                            </div>
                        </div>
                        <div className="dates">
                            <div className="titles">
                                {translations.KALEZAINDU.KALEZAINDU_CATEGORIA}
                            </div>
                            <select
                                className="search_select"
                                name="category"
                                onChange={handleChange}
                            >
                                <option value="" selected>
                                    {
                                        translations.KALEZAINDU
                                            .KALEZAINDU_ALL_INCIDENCE
                                    }
                                </option>
                                {cargado ? (
                                    categories.data.map((category) => (
                                        <option
                                            key={category.idCategoria}
                                            value={category.idCategoria}
                                        >
                                            {lang === 'es'
                                                ? category.tituloEs
                                                : category.tituloEu}
                                        </option>
                                    ))
                                ) : (
                                    <KalezainduNoResultMessage
                                        translations={translations}
                                    />
                                )}
                            </select>
                        </div>

                        <KalezainduDatesSelect
                            action={handleChange}
                            translations={translations}
                        />
                    </div>
                ) : (
                    <div className="incidencias-infobox_searchDates">
                        <div className="dates">
                            <TitlesWithHiddenSpan
                                htmlFor="inicio"
                                text={translations.KALEZAINDU.KALEZAINDU_INICIO}
                                translations={translations}
                            />
                            <div className="datesInput">
                                <input
                                    aria-label={
                                        translations.KALEZAINDU
                                            .KALEZAINDU_INICIO
                                    }
                                    className="search_date"
                                    type="date"
                                    id="inicio"
                                    name="startDate"
                                    value={startDate}
                                    onChange={handleChange}
                                />
                                <CalendarioInput />
                            </div>
                        </div>
                        <div className="dates">
                            <TitlesWithHiddenSpan
                                htmlFor="fin"
                                text={translations.KALEZAINDU.KALEZAINDU_FIN}
                                translations={translations}
                            />
                            <div className="datesInput">
                                <input
                                    aria-label={
                                        translations.KALEZAINDU.KALEZAINDU_FIN
                                    }
                                    className="search_date"
                                    type="date"
                                    id="fin"
                                    name="endDate"
                                    value={endDate}
                                    onChange={handleChange}
                                />
                                <CalendarioInput />
                            </div>
                        </div>
                        <div className="dates">
                            <label htmlFor="category">
                                {translations.KALEZAINDU.KALEZAINDU_CATEGORIA}
                            </label>
                            <select
                                className="search_select"
                                id="category"
                                name="category"
                                onChange={handleChange}
                            >
                                <option value="" selected>
                                    {
                                        translations.KALEZAINDU
                                            .KALEZAINDU_ALL_INCIDENCE
                                    }
                                </option>
                                {cargado ? (
                                    categories.data.map((category) => (
                                        <option
                                            key={category.idCategoria}
                                            value={category.idCategoria}
                                        >
                                            {lang === 'es'
                                                ? category.tituloEs
                                                : category.tituloEu}
                                        </option>
                                    ))
                                ) : (
                                    <KalezainduNoResultMessage
                                        translations={translations}
                                    />
                                )}
                            </select>
                        </div>
                        <KalezainduDatesSelect
                            action={handleChange}
                            translations={translations}
                        />
                    </div>
                )}
            </div>
        </form>
    )
}
