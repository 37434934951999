import React from 'react'
import { connect } from 'react-redux'
import { setSelectedMaps, setSelectedMapById } from '../../store/actions'
import {
    findCategoryById,
    getFirstMapType,
    getInstanceIdByPage,
    getPageData,
} from './utils/mapUtils'
import MapDetail from '../../compositions/MapDetail/MapDetail'
import MapsSelectors from '../../components/MapsSelectors/MapsSelectors'
import './MapsContainer.css'
import PageRow from "../../atoms/PageRow/PageRow";

class MapsContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            maps: [],
            selectedMap: null,
            categories: [],
            resources: [],
            baseMap: null,
            defaultProps: null,
        }

        this.handleTypeSelect = this.handleTypeSelect.bind(this)
        this.handleCategorySelect = this.handleCategorySelect.bind(this)
    }

    // TO DO CHANGE MAP TYPE HANDLING SINCE type selector has been removed
    handleTypeSelect(e) {
        this.setState({ mapType: e.target.value })
        this.props.setMapById({ id: e.target.value })
    }

    handleCategorySelect(e) {
        const { categories } = this.state
        const categoryData = findCategoryById(categories, e.target.value)
        if (categoryData?.resources) {
            this.setState({ resources: categoryData.resources })
        }
    }
    
    componentDidMount() {
        const { page: pageProps, setSelectedMaps } = this.props

        const page = getPageData(pageProps)

        const idInstancia = page && getInstanceIdByPage(page)
        if (idInstancia) {
            setSelectedMaps({ id: idInstancia })
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.maps !== nextProps.maps) {
            const baseMap = nextProps && getFirstMapType(nextProps)
            const defaultProps = baseMap && baseMap.defaultProps
            return {
                maps: nextProps.maps,
                baseMap,
                defaultProps,
            }
        }

        if (prevState.selectedMap !== nextProps.map) {
            const categories =
                nextProps.map &&
                nextProps.map.categories &&
                nextProps.map.categories
            const baseResources =
                categories && categories[0] && categories[0].resources
            return {
                selectedMap: nextProps.map,
                categories,
                resources: baseResources,
            }
        }

        return null
    }

    render() {
        const {
            setMapById,
            page: pageProps,
            setSelectedMaps,
            lang,
            maps: mapsProps,
        } = this.props

        const page = getPageData(pageProps)
        const {
            resources,
            selectedMap,
            baseMap,
            defaultProps,
            maps,
        } = this.state

        const mapsData = maps?.maps?.length && maps.maps
        const idInstancia = page && getInstanceIdByPage(page)

        if (!maps && idInstancia && !mapsProps) {
            setSelectedMaps({ id: idInstancia })
        }

        if (mapsData && baseMap && !selectedMap) {
            setMapById({ id: baseMap.id })
        }

        return (
            <PageRow className="mapsContainer">
                {selectedMap && mapsData && (
                    <MapsSelectors
                        lang={lang}
                        categories={selectedMap.categories}
                        handleCategorySelect={this.handleCategorySelect}
                    />
                )}

                {selectedMap && defaultProps && resources ? (
                    <MapDetail
                        defaultProps={defaultProps}
                        resources={resources}
                        lang={lang}
                    />
                ) : null}
            </PageRow>
        )
    }
}

const mapStateToProps = ({ page, pages, maps }) => ({
    maps: maps.maps,
    map: maps.map,
    page: pages[page.slug],
    lang: page.lang,
})

const mapDispatchToProps = dispatch => ({
    setSelectedMaps: payload => dispatch(setSelectedMaps(payload)),
    setMapById: payload => dispatch(setSelectedMapById(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapsContainer)
