
const getCssVars = (document) => ({
  rootElement: document,
  shadowDOM: false,
  include: 'link[rel=stylesheet],style',
  exclude: '',
  onlyLegacy: true,
  preserveStatic: true,
  preserveVars: false,
  silent: false,
  updateDOM: true,
  updateURLs: true,
  watch: false,
})

export default getCssVars