import React from 'react'
import classNames from 'classnames'
import Status from '../../../assets/svg/aurretik/Status'
import './AurretikStatusIcon.css'

function AurretikStatusIcon({ open, className }) {
    return (
        <Status
            className={classNames('aurretikStatusIcon', className, {
                'aurretikStatusIcon--green': open,
                'aurretikStatusIcon--red': !open,
            })}
        />
    )
}

export default AurretikStatusIcon
