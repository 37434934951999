import classNames from 'classnames'
import { MDBFooter } from 'mdb-react-ui-kit'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import BiscaytikLogo from '../../atoms/BiscaytikLogo/BiscaytikLogo'
import UdalaZabaltzenLogo from '../../atoms/UdalaZabaltzenLogo/UdalaZabaltzenLogo'
import getImagesRoute from '../../utils/getImagesRoute'
import InfoCard from '../InfoCard/InfoCard'
import PoliciesCard from '../PoliciesCard/PoliciesCard'
import SocialCard from '../SocialCard/SocialCard'
import './Footer.css'

function FooterPage({ toggleComponent, isMobile, footerOptions, logo, lang }) {
    const imagesRoute = getImagesRoute()
    const logoFooter = `${imagesRoute}${logo !== null ? logo.imagen : ''}`

    const secondBoxSecondPart = classNames({
        'col-xl-2': footerOptions.hasRRSS,
        'col-xl-0': !footerOptions.hasRRSS,
        'footer-second': true,
        social: true,
    })

    return (
        <MDBFooter className="footer" color="white">
            <div className="footer-logo">
                <a href={footerOptions.urlLogo}>
                    <img
                        src={logoFooter}
                        alt={logo !== null ? logo.alt : 'altpordefecto'}
                        className="logo-footer"
                    />
                </a>
                {toggleComponent && !isMobile ? (
                    <SocialCard
                        toggleComponent={toggleComponent}
                        ig={footerOptions.instagram}
                        fb={footerOptions.facebook}
                        tw={footerOptions.twitter}
                        yt={footerOptions.youtube}
                        android={footerOptions.android}
                        apple={footerOptions.apple}
                    />
                ) : null}
            </div>
            <div className="content-info">
                <div className="footer-second col-xl-12">
                    <InfoCard
                        address={footerOptions.direccion}
                        className={
                            footerOptions.hasRRSS ? 'col-xl-10' : 'col-xl-12'
                        }
                        email={footerOptions.email}
                        lang={lang}
                        phone={footerOptions.telefono}
                        schedule={footerOptions.horario}
                        style={{ paddingLeft: '20px', paddingRight: '100px' }}
                    />
                    <div className={secondBoxSecondPart}>
                        <SocialCard
                            toggleComponent={toggleComponent}
                            ig={footerOptions.instagram}
                            fb={footerOptions.facebook}
                            tw={footerOptions.twitter}
                            yt={footerOptions.youtube}
                            android={footerOptions.android}
                            apple={footerOptions.apple}
                        />
                    </div>
                </div>
            </div>
            <div className="politics-sponsors">
                <div className="footerVariableContainer">
                    {toggleComponent ? (
                        <InfoCard
                            address={footerOptions.direccion}
                            phone={footerOptions.telefono}
                            schedule={footerOptions.horario}
                            email={footerOptions.email}
                            isMobile={isMobile}
                            lang={lang}
                        />
                    ) : (
                        <PoliciesCard
                            policiesContent={footerOptions.enlaces_pie}
                            backToTop={footerOptions.backtotop}
                        />
                    )}
                </div>
                <div className="politics-link mobile">
                    {footerOptions.enlaces_pie.map((item, index) => (
                        <p key={index}>
                            <Link to={item.slug}>{item.titulo}</Link>
                        </p>
                    ))}
                </div>
                <div className="footerLogos">
                    <UdalaZabaltzenLogo className="footer-udala" />
                    <BiscaytikLogo className="footer-biscaytik" />
                </div>
                <div className="politics-link tablet">
                    <p>
                        {footerOptions.enlaces_pie.map((item, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Link to={item.slug} key={index}>
                                {index < footerOptions.enlaces_pie.length - 1
                                    ? `${item.titulo} | `
                                    : item.titulo}
                            </Link>
                        ))}
                    </p>
                </div>
            </div>
        </MDBFooter>
    )
}

FooterPage.propTypes = {
    toggleComponent: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    footerOptions: PropTypes.shape({
        direccion: PropTypes.string,
        facebook: PropTypes.string,
        googleanalitycs: PropTypes.string,
        horario: PropTypes.string,
        instagram: PropTypes.string,
        telefono: PropTypes.string,
        twitter: PropTypes.string,
        footbackground: PropTypes.shape({
            idimagen: PropTypes.number.isRequired,
            imagen: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired,
            descripcion: PropTypes.string,
        }).isRequired,
    }).isRequired,
    logo: PropTypes.shape({
        idimagen: PropTypes.number.isRequired,
        imagen: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        descripcion: PropTypes.string,
    }).isRequired,
    lang: PropTypes.string.isRequired,
}

export default FooterPage
