import { sanitize } from 'dompurify'
import $ from 'jquery'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import MenuVertical from '../../components/MenuVertical/MenuVertical'
import Clip from '../../assets/svg/Clip'
import StatusLabel from '../StatusLabel/StatusLabel'
import './IndicatorDetail.css'

class IndicatorDetail extends Component {
    componentDidMount() {
        const windowWidht = $(window).width()
        const sidebar = $('.indicator-detail__sidebar').outerHeight(true)
        const content = $('.indicator-detail__content').outerHeight(true)
        const attachments = $('.indicator-detail__attachments').outerHeight(
            true
        )

        if (windowWidht > 1200) {
            if (content < sidebar) {
                $('.indicator-detail__content').height(sidebar)
                const contentAfterRedim = $(
                    '.indicator-detail__content'
                ).outerHeight(true)
                $('.indicator-detail__content').height(
                    contentAfterRedim + attachments
                )
            } else {
                $('.indicator-detail__content').height(content + attachments)
            }
        }
    }

    UNSAFE_UNSAFE_componentWillUpdate() {
        $('.indicator-detail__content').css('height', '')
        const windowWidht = $(window).width()
        const sidebar = $('.indicator-detail__sidebar').outerHeight(true)
        const content = $('.indicator-detail__content').outerHeight(true)
        const attachments = $('.indicator-detail__attachments').outerHeight(
            true
        )

        if (windowWidht > 1200) {
            if (content < sidebar) {
                $('.indicator-detail__content').height(sidebar)
                const contentAfterRedim = $(
                    '.indicator-detail__content'
                ).outerHeight(true)
                $('.indicator-detail__content').height(
                    contentAfterRedim + attachments
                )
            } else {
                $('.indicator-detail__content').height(content + attachments)
            }
        }
    }

    render() {
        const { contenido, menuSecondary, trads } = this.props

        return (
            <div className="indicator-detail">
                <div className="indicator-detail__wrapper">
                    <div className="indicator-detail__sidebar overlay blue">
                        <MenuVertical menuSecondary={menuSecondary} />
                    </div>
                </div>
                <div className="indicator-detail__content">
                    <div className="indicator-detail__content-box">
                        <StatusLabel status={contenido.etiqueta} />
                        <div className="indicator-detail__title">
                            <span>{contenido.titulo}</span>
                        </div>
                        <p
                            className="indicator-detail__subtitle wysiwyg"
                            dangerouslySetInnerHTML={{
                                __html: sanitize(contenido.descripcion)
                                    .replace(
                                        /href/g,
                                        "target='_blank' rel='noopener noreferrer' href"
                                    )
                                    .replace(
                                        /href="www./g,
                                        'href="http://www.'
                                    ),
                            }}
                        />
                    </div>
                    <div className="indicator-detail__attachments">
                        <p className="indicator-detail__attachments-title">
                            {contenido.adjuntos.length > 0
                                ? trads.adjuntos
                                : ''}
                        </p>
                        {contenido.adjuntos.map((adjunto, index) => (
                            <div key={index}>
                                <Clip className="clip__icon" />
                                <span className="indicator-detail__attachments-subtitle">
                                    {adjunto.titulo}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}

IndicatorDetail.propTypes = {
    menuSecondary: PropTypes.arrayOf(
        PropTypes.shape({
            titulo: PropTypes.string.isRequired,
            descripcion: PropTypes.string,
            icono: PropTypes.string,
            slug: PropTypes.string.isRequired,
        })
    ).isRequired,
    contenido: PropTypes.shape({
        titulo: PropTypes.string.isRequired,
        descripcion: PropTypes.string,
        adjuntos: PropTypes.arrayOf(
            PropTypes.shape({
                titulo: PropTypes.string,
                documento: PropTypes.string,
            })
        ),
        status: PropTypes.string,
    }).isRequired,
    trads: PropTypes.shape({
        lang: PropTypes.string,
        buscar: PropTypes.string,
        castellano: PropTypes.string,
        idioma: PropTypes.string,
        adjuntos: PropTypes.string,
    }).isRequired,
}

export default IndicatorDetail
