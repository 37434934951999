import React from 'react'

export default function Capacity() {
    return (
        <svg
            width="13px"
            height="31px"
            viewBox="0 0 13 31"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>monigote-rojo copy 2</title>
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="AurreTIK_desktop_detalle"
                    transform="translate(-806.000000, -663.000000)"
                    fill="#065464"
                    stroke="#FFFFFF"
                    strokeWidth="1.48499999"
                >
                    <g
                        id="Group-4"
                        transform="translate(595.000000, 512.000000)"
                    >
                        <g
                            id="Group-8-Copy-8"
                            transform="translate(30.000000, 136.000000)"
                        >
                            <path
                                d="M191.523114,26.6842105 C192.343257,26.6842105 193.008114,27.3490677 193.008114,28.1692105 C193.008114,28.2464486 193.002088,28.3235689 192.990091,28.3998695 L192.003133,34.676823 C191.37493,34.7857387 190.873913,35.2907185 190.786188,35.9416272 L190.786188,35.9416272 L189.576231,44.9252153 C189.429155,44.9738282 189.272367,45 189.11023,45 L185.889995,45 C185.730437,45 185.576059,44.9746543 185.431021,44.9275184 L184.221313,35.9416272 C184.133273,35.2883765 183.62896,34.7821066 182.997582,34.6756627 L182.010134,28.3998695 C181.882744,27.5896805 182.436263,26.829623 183.246452,26.7022336 C183.322752,26.6902365 183.399873,26.6842105 183.477111,26.6842105 L191.523114,26.6842105 Z M187.500112,16 C189.885874,16 191.819917,18.0500646 191.819917,20.5789474 C191.819917,23.1078302 189.885874,25.1578947 187.500112,25.1578947 C185.11435,25.1578947 183.180308,23.1078302 183.180308,20.5789474 C183.180308,18.0500646 185.11435,16 187.500112,16 Z"
                                id="monigote-rojo-copy-2"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
