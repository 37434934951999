import React from 'react'

export default function Paper() {
    return (
        <svg viewBox="0 0 18 22">
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Rendicion_plan_v1_desktop_paper"
                    transform="translate(-217.000000, -1018.000000)"
                    stroke="#1C3561"
                    strokeWidth="1.75439988"
                >
                    <g
                        id="Group-16"
                        transform="translate(218.000000, 892.000000)"
                    >
                        <g
                            id="Group-6-Copy"
                            transform="translate(0.000000, 127.000000)"
                        >
                            <polygon
                                id="Path-13"
                                points="0.719559216 0.0557822438 0.719559216 19.9608013 15.7987421 19.9608013 15.7987421 4.85667755 10.5839599 0.0701809417"
                            />
                            <polyline
                                id="Path-14"
                                points="9.94271663 0.768130315 9.94271663 5.30867162 15.7987421 5.30867162"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
