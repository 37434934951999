import React from 'react'
import t from '../i18n/translations/translations'

export default function AcceptPolicyLabel(lang) {
  return lang === 'es' ? (
        <p>
            {t.accept_privacy_i_have_read.es}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="/es/politica-privacidad"
            >
                {' '}
                {t.accept_privacy_privacy.es}.
            </a>
        </p>
    ) : (
        <p>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="/eu/pribatasun-politika"
            >
                {t.accept_privacy_privacy.eu}{' '}
            </a>
            {t.accept_privacy_i_have_read.eu}.
        </p>
    )
}
