import React from 'react'
import CardGrid from '../../atoms/CardGrid/CardGrid'
import AurretikCard from '../../components/Aurretik/AurretikCard/AurretikCard'
import AurretikFacility from '../../components/Aurretik/AurretikFacility/AurretikFacility'
import RowsRender from '../../components/RowsRender/RowsRender'
import paramIdIsCurrentPageId from '../../utils/paramIdIsCurrentPageId'

import './AurretikContainer.css'

const findCardById = (cards, facilityID) =>
    cards.find((item) => item.id_instalacion === parseInt(facilityID, 10))

const extractPageData = (page) => page.data?.[0]
function AurretikContainer({ data, isMobile, isTablet, match }) {
    const { infrastructures, pages: page } = data
    const { lang, facilityID: id, instanceID } = match
    const cards = infrastructures && infrastructures.data
    const facilityData = cards && cards.length && findCardById(cards, id)
    const pageData = page && extractPageData(page)
    const isValidData = paramIdIsCurrentPageId({
        paramId: +instanceID,
        pageId: pageData.idinstancia,
    })
    const facilitySelected = id && facilityData

    return isValidData ? (
        <div className="aurretikContainer">
            {facilitySelected ? (
                <AurretikFacility
                    facility={id}
                    data={facilityData}
                    lang={lang}
                />
            ) : (
                <>
                    {pageData ? (
                        <RowsRender
                            isAurretik
                            translations={pageData.translations}
                            filas={pageData.filas}
                            lang={lang}
                            isMobile={isMobile}
                            isTablet={isTablet}
                            menuSecondary={pageData.menu_secundario}
                            slug={pageData.slug}
                        />
                    ) : null}

                    {cards?.length ? (
                        <div className="aurretikContainer_row">
                            <CardGrid>
                                {cards.map((card, i) => (
                                    <AurretikCard
                                        data={card}
                                        key={i}
                                        lang={lang}
                                        instanceID={instanceID}
                                    />
                                ))}
                            </CardGrid>
                        </div>
                    ) : null}
                </>
            )}
        </div>
    ) : null
}

export default AurretikContainer
