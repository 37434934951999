import React from 'react'

export default function Hitos() {
    return (
        <svg viewBox="0 0 40 41">
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Rendicion_accion_desktop-hitos"
                    transform="translate(-312.000000, -2569.000000)"
                    stroke="#1C3561"
                    strokeWidth="1.5"
                >
                    <g
                        id="Group-17"
                        transform="translate(306.000000, 2564.000000)"
                    >
                        <path
                            d="M26,45 C36.4934102,45 45,36.4934102 45,26 C45,22.3352588 43.9624506,18.9128503 42.1650749,16.0104977 C41.6946999,15.2509491 41.1722864,14.5270178 40.6028121,13.8436816 C39.0305576,11.957067 37.099582,10.3798877 34.9146401,9.21689829 C32.2561719,7.80186279 29.2217285,7 26,7 C15.5065898,7 7,15.5065898 7,26 C7,29.4485239 7.91873162,32.6824665 9.52478493,35.470418 C11.1680665,38.322994 13.5309032,40.7086631 16.3654171,42.3795472 L17.098415,42.7968463"
                            id="Oval"
                            transform="translate(26.000000, 26.000000) rotate(-150.000000) translate(-26.000000, -26.000000) "
                        />
                        <circle id="Oval-Copy" cx="26" cy="26" r="13" />
                        <circle id="Oval-Copy-4" cx="26" cy="26" r="6" />
                        <polygon
                            id="Path-31"
                            strokeLinejoin="round"
                            points="38.3956299 6 34 10.6315918 34.8981323 16.6428833 40.8719482 17.409668 45.232666 12.7852173 39.3328247 11.9640503"
                        />
                        <path
                            d="M26,26 L34.8981323,16.6428833"
                            id="Path-32"
                            strokeLinecap="round"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
