import React from 'react'
import $ from 'jquery'
import { Link } from 'react-router-dom'
import { MDBCol } from 'mdb-react-ui-kit'
import Lock from '../../assets/svg/Lock'
import Cookie from '../../assets/svg/Cookie'
import Accessibility from '../../assets/svg/Accessibility'
import LockApp from '../../assets/svg/LockApp'

import './PoliciesCard.css'

const toTop = () => {
    $('html, body').animate({ scrollTop: 0 }, 300)
}

function PoliciesCard({ policiesContent, backToTop }) {
    let icon
    return (
        <div className="flex-item policies-card__container">
            {policiesContent.map((item, index) => {
                switch (item.icono) {
                    case 'cookie':
                        icon = <Cookie />
                        break
                    case 'lock':
                        icon = <Lock />
                        break
                    case 'accessibility':
                        icon = <Accessibility />
                        break
                    case 'lockapp':
                        icon = <LockApp />
                        break
                    default:
                        icon = ''
                }
                
                return (
                    <div key={index}>
                        <Link to={item.slug}>
                            <MDBCol size="2" className="icon-footer">
                                {icon}
                            </MDBCol>
                            <MDBCol size="9" className="info-footer">
                                <span>{item.titulo}</span>
                            </MDBCol>
                        </Link>
                    </div>
                )
            })}
            <div className="icon-to-top">
                <MDBCol size="2" className="icon-footer">
                    <i className="fa fa-arrow-up" aria-hidden="true" />
                </MDBCol>
                <MDBCol size="9" className="info-footer">
                    <button
                        className="link-button"
                        onClick={toTop}
                        type="button"
                    >
                        {backToTop.texto}
                    </button>
                </MDBCol>
            </div>
        </div>
    )
}

export default PoliciesCard
