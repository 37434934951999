import React from 'react'
import t from '../../../i18n/translations/translations'
import Modal from '../../Modal/Modal'

function GiltzaForm({
    handleModal,
    isOpened,
    lang,
}) {
    return isOpened ? (
        <Modal
            closeModal={handleModal}
            title={t.availability_calendar[lang]}
            isOpened={isOpened}
            lang={lang}
        >
            
        </Modal>
    ) : null
}

export default GiltzaForm
