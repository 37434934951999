import React from 'react'
import ActionStar from '../../../assets/svg/ActionStar'

function PlanDetailActionsTitle({ translations }) {
    return (
        <div className="planDetail-box_container-actions_title">
            <ActionStar />
            <p>{translations.ACCOUNTABILITY.PLAN_DETAIL_ACTIONS_TITLE}</p>
        </div>
    )
}

export default PlanDetailActionsTitle
