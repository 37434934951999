import React from 'react'
import { OkWithCircle } from '../../assets/svg/hartuz'
import './ThankYouPage.css'

function ThankYouPage({
    bookingCode,
    children,
    date,
    facilityName,
    message,
    textDate,
    textId,
    textName,
}) {
    return (
        <div className="thankYouPage">
            <div className="thankYouPage__card">
                <OkWithCircle />
                <h1>{message}</h1>
                <div className="thankYouPage__container">
                    <ul>
                        <li>
                            <p>{textName}</p>
                            <p>{facilityName}</p>
                        </li>
                        <li>
                            <p>{textDate}</p>
                            <p>{date}</p>
                        </li>
                        <li>
                            <p>{textId}</p>
                            <p>{bookingCode}</p>
                        </li>
                    </ul>
                </div>
                {children}
            </div>
        </div>
    )
}

export default ThankYouPage
