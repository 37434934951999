import mapIconTransformer from './mapIconTransformer'
import mapResourceTransformer from './mapResourceTransformer'

const mapCategoryTransformer = category => ({
    color: category.color,
    icon: category.icon ? mapIconTransformer(category.icon) : null,
    id: category.idcategoria,
    visible: category.is_visible,
    link: {
        es: category.link_es,
        eu: category.link_eu,
    },
    name: {
        es: category.nombre_categoria_es,
        eu: category.nombre_categoria_eu,
    },
    resources: category?.recursos?.map(resource => mapResourceTransformer(resource)) ?? []
})

export default mapCategoryTransformer;