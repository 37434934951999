import { combineReducers } from 'redux'
import { reducer as reduxFrom } from 'redux-form'
import pages from './pages'
import page from './page'
import newsDetail from './newsDetail'
import news from './news'
import plans from './plans'
import plan from './plan'
import acciones from './acciones'
import accion from './accion'
import incidencia from './incidencia'
import incidencias from './incidencias'
import categories from './categories'
import category from './category'
import indicator from './indicator'
import indicators from './indicators'
import analyticsUA from './analyticsUA'
import analyticsUAs from './analyticsUAs'
import infrastructure from './infrastructure'
import infrastructures from './infrastructures'
import irudiz from './irudiz'
import irudizPostals from './irudizPostals'
import maps from './maps'
import hartuz from './hartuz'

export default combineReducers({
    pages,
    page,
    news,
    newsDetail,
    plans,
    plan,
    acciones,
    accion,
    incidencia,
    incidencias,
    categories,
    category,
    indicators,
    indicator,
    analyticsUAs,
    analyticsUA,
    infrastructure,
    infrastructures,
    irudiz,
    irudizPostals,
    maps,
    hartuz,
    form: reduxFrom,
})
