import getImagesRoute from './getImagesRoute'

const deleteFirstSlashIfAny = slug => (slug[0] === '/' ? slug.slice(1) : slug)

const createImageRoute = img => {
    const isSlug = !img.includes('http')
    const imagesRoute = getImagesRoute()
    return isSlug ? imagesRoute + deleteFirstSlashIfAny(img) : img
}

export default createImageRoute