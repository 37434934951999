import React from 'react'
import { Link } from 'react-router-dom'
import t from '../../i18n/translations/translations'

function FatherIncidenceButton({ id, lang }) {
    return (
        <div className="fatherIncidenceButton">
            <div className="incidencia_padre">
                <Link to={`/${lang}/incidencias/${id}`}>
                    {t.father_incidence[lang]}
                </Link>
            </div>
        </div>
    )
}

export default FatherIncidenceButton