export const SHARE_EXTENSION = '/compartir.php?url_share='
export const LINKEDIN_SHARE =
    'https://www.linkedin.com/sharing/share-offsite/?url='
export const FACEBOOK_SHARE = 'https://www.facebook.com/sharer/sharer.php?u='
export const WHATSAPP_SHARE = 'whatsapp://send?text='
export const TWITTER_SHARE = 'https://twitter.com/intent/tweet?'
export const FUNCTIONALITY = 'funcionalidad'
export const LOW_OCCUPANCY = 'LOW'
export const MEDIUM_OCCUPANCY = 'MEDIUM'
export const HIGH_OCCUPANCY = 'HIGH'

export const SUCCESS_CODE = 200
export const DATE_FORMAT = 'DD-MM-YYYY'
export const DATE_FORMAT_DATE_PICKER = 'YYYY/MM/DD'
export const DATE_HOUR_FORMAT_ES = 'DD-MM-YYYY HH:mm'
export const DATE_HOUR_FORMAT_EU = 'YYYY-MM-DD HH:mm'
