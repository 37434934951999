import React from 'react'
import Calendar from '../../../assets/svg/aurretik/Calendar'
import t from '../../../i18n/translations/translations'

import './AurretikFacilitySeason.css'

function AurretikFacilitySeason({ data, lang }) {
    return (
        <div className="aurretikFacilitySeason">
            <Calendar />
            <div>
                <span>{t.season[lang]} : </span>
                <span className="aurretikFacilitySeason__bold-text">
                    {data.dates[lang] ? data.dates[lang] : '-'}
                </span>
            </div>
        </div>
    )
}

export default AurretikFacilitySeason
