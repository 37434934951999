import { sanitize } from 'dompurify'
import React from 'react'
import CategoryCard from '../../atoms/CategoryCard/CategoryCard'
import PageTitle from '../../atoms/PageTitle/PageTitle'
import MenuVertical from '../MenuVertical/MenuVertical'
import './FunctionalityHome.css'

function FunctionalityHome({ contenido, menuSecondary }) {
    const { descripcion } = contenido
    return (
        <div className="functionality-home">
            <aside className="functionality-home__sidebar overlay blue">
                <MenuVertical menuSecondary={menuSecondary} />
            </aside>
            <div className="functionality-home__content">
                <div className="functionality-home__content-box">
                    <PageTitle text={contenido.titulo} />

                    <div className="functionality-home__subtitle">
                        <p
                            className="wysiwyg"
                            dangerouslySetInnerHTML={{
                                __html: sanitize(descripcion),
                            }}
                        />
                    </div>
                    <div className="functionality-home__category-box">
                        {contenido.categorias.map((itemCategory, index) => (
                            <CategoryCard
                                icon={itemCategory.icono}
                                title={itemCategory.titulo}
                                subtitle={itemCategory.descripcion}
                                slug={itemCategory.slug}
                                key={index}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FunctionalityHome
