import React from 'react'
import { getPlanSearchUrl } from '../../components/Accountability/PlanList'

function PlanListSearch({ lang }) {
    return (
        <a
            title="search"
            href={getPlanSearchUrl(lang)}
            className="fa fa-search finder-link"
        >
            <span className="planlist_hidden-item">search</span>
        </a>
    )
}

export default PlanListSearch
