import { SET_ACCION_DATA } from '../constants/constants'

// eslint-disable-next-line default-param-last
const acciones = (state = {}, action) => {
    if (action.type === SET_ACCION_DATA) {
        const { accion, data } = action.payload
        return {
            ...state,
            [accion]: data,
        }
    }

    return state
}

export default acciones
