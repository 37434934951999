import classNames from 'classnames'
import React from 'react'
import Occupancy from '../../assets/svg/aurretik/Occupancy'
import {
    HIGH_OCCUPANCY,
    LOW_OCCUPANCY,
    MEDIUM_OCCUPANCY,
} from '../../constants'
import './OccupancyIcon.css'

function OccupancyIcon({ occupancy }) {
    return (
        <Occupancy
            className={classNames('occupancy__icon', {
                'occupancy__icon-low': occupancy === LOW_OCCUPANCY,
                'occupancy__icon-medium': occupancy === MEDIUM_OCCUPANCY,
                'occupancy__icon-high': occupancy === HIGH_OCCUPANCY,
            })}
        />
    )
}

export default OccupancyIcon
