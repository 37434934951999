import React from 'react'

export default function Etiqueta() {
    return (
        <svg viewBox="0 0 21 11">
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Kalezaindu_home_desktop"
                    transform="translate(-587.000000, -679.000000)"
                    fill="#E1E4E8"
                >
                    <g
                        id="Group-22"
                        transform="translate(560.000000, 513.000000)"
                    >
                        <g id="Group-18">
                            <g
                                id="Group-4"
                                transform="translate(27.234043, 166.800000)"
                            >
                                <rect
                                    id="Rectangle"
                                    x="0"
                                    y="0"
                                    width="13.6170213"
                                    height="10.2"
                                 />
                                <polygon
                                    id="Path-7"
                                    points="13.6170213 2.55351296e-14 13.6170213 10.2 20.3633943 5.1"
                                 />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
