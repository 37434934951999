import { SET_INCIDENCIA_DATA } from '../constants/constants'

// eslint-disable-next-line default-param-last
const incidencias = (state = {}, action) => {
    if (action.type === SET_INCIDENCIA_DATA) {
        const { incidencia, data } = action.payload
        return {
            ...state,
            [incidencia]: data,
        }
    }
    return state
}

export default incidencias
