const STATES = {
    DEFAULT: 'DEFAULT',
    SUBMITTING: 'SUBMITTING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    ERROR_TIME_EXPIRED: 'ERROR_TIME_EXPIRED',
    ERROR_CONCURRENCE: 'ERROR_CONCURRENCE'
}

export default STATES
