import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import t from '../../i18n/translations/translations'
import createPlanProgressUrlBySlug from '../../utils/createPlanProgressUrlBySlug'
import './PlansGrouped.css'

function PlansGrouped(props) {
    const [isFocused, setIsFocused] = useState(false)
    const [isProgressFocused, setIsProgressFocused] = useState(false)

    const handleFocus = () => {
        setIsFocused(true)
    }

    const handleBlur = () => {
        setIsFocused(false)
    }

    const handleProgressFocus = () => {
        setIsProgressFocused(true)
    }

    const handleProgressBlur = () => {
        setIsProgressFocused(false)
    }

    const {
        title,
        startDate,
        endDate,
        slug,
        percentage,
        translations,
        showPercentage,
        showProgress,
        lang,
    } = props
    return (
        <Link onBlur={handleBlur} onFocus={handleFocus} to={slug}>
            <div className="plansGrouped_plans">
                <p
                    className="plansGrouped_title"
                    style={
                        isFocused && !isProgressFocused
                            ? { outline: '2px solid black' }
                            : {}
                    }
                >
                    {title}
                </p>
                <p className="plansGrouped_start_date">{startDate}</p>
                <p className="plansGrouped_end_date">{endDate}</p>
                <div
                    className={
                        showPercentage === false
                            ? 'plans-percentage--hidden'
                            : 'plans-percentage'
                    }
                >
                    <p className="percentage">{percentage}%</p>
                    <p className="cumplido">
                        {translations.ACCOUNTABILITY.PLAN_DETAIL_PERCENTAGE.toUpperCase()}
                    </p>
                </div>
                {showProgress ? (
                    <Link
                        onBlur={handleProgressBlur}
                        onFocus={handleProgressFocus}
                        to={createPlanProgressUrlBySlug(slug, lang)}
                    >
                        <i
                            className="fa fa-bar-chart"
                            aria-hidden="true"
                            alt={t.plan_progress_alt[lang]}
                        />
                    </Link>
                ) : (
                    ''
                )}
            </div>
        </Link>
    )
}

export default PlansGrouped
