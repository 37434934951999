import checkShouldShowBreadcrumbsByPageId from './checkShouldShowBreadcrumbsByPageId'

const pageHasBreadcrumbs = ({ breadcrumb, id, pageData }) => (
    breadcrumb &&
    breadcrumb.length !== 1 &&
    pageData &&
    checkShouldShowBreadcrumbsByPageId(id)
)


export default pageHasBreadcrumbs;