import React from 'react'

export default function Mail({ className }) {
    return (
        <svg className={className} viewBox="0 0 29 21">
            <g id="Symbols">
                <g
                    id="Icon-/-Solid-/-Mail"
                    transform="translate(0.000000, -2.000000)"
                >
                    <path
                        d="M3,2 L26,2 C27.6568542,2 29,3.34314575 29,5 L29,20 C29,21.6568542 27.6568542,23 26,23 L3,23 C1.34314575,23 2.02906125e-16,21.6568542 0,20 L0,5 C-2.02906125e-16,3.34314575 1.34314575,2 3,2 Z M2.83410645,3.99719238 L12.9152322,10.8482666 C13.3744438,11.2138163 13.8482819,11.3965912 14.3367462,11.3965912 C14.8252106,11.3965912 15.3126526,11.2138163 15.7990723,10.8482666 L25.6477063,3.99719238 L2.83410645,3.99719238 Z M27.003085,5.45687866 L16.7127991,12.6251831 C15.9676005,13.1405233 15.1766561,13.3981934 14.3399658,13.3981934 C13.5032756,13.3981934 12.7180681,13.1405233 11.9843435,12.6251831 L1.99647522,5.8821106 L1.99647522,20.0077113 C2.01281134,20.2930324 2.11445013,20.5274818 2.3013916,20.7110596 C2.48833307,20.8946373 2.72374567,20.9911746 3.00762939,21.0006714 L25.9999571,21.0006714 C26.2929138,21.0014038 26.5344798,20.9048665 26.7246552,20.7110596 C26.9148305,20.5172526 27.0075684,20.2828032 27.0028687,20.0077113 L27.003085,5.45687866 Z"
                        id="Combined-Shape"
                    />
                </g>
            </g>
        </svg>
    )
}
