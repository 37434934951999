import React from 'react'

/* eslint-disable max-len */
export default function ArrowDown() {
    return (
        <svg viewBox="0 0 15 10">
            <title>Desplegar</title>
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
            >
                <g
                    id="Rendicion_accion_desktop_down"
                    transform="translate(-1112.000000, -2686.000000)"
                    stroke="#979797"
                    strokeWidth="1.75439988"
                >
                    <g
                        id="Group-5-Copy"
                        transform="translate(218.000000, 2663.000000)"
                    >
                        <polyline
                            id="Path-10"
                            points="895 24 901.491696 31 908 24"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
