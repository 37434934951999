import classNames from 'classnames'
import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import getImagesRoute from '../../utils/getImagesRoute'
import './NewsList.css'

function NewsList({ noticias, data }) {
    const imgClass = classNames({
        'news-slider-img': true,
        corrected: noticias.importada,
    })
    const imagesRoute = getImagesRoute()

    return (
        <Link
            title={data.data[0].translations.OPEN_THIS_NEWS}
            to={noticias.slug}
        >
            <div className="list-news">
                <MDBRow className="over-text">
                    <MDBCol size="1" className="date">
                        <span className="date-text">{noticias.diames}</span>
                        <span className="year-text">{noticias.ano}</span>
                        <div className="left-triangle-shape-list" />
                    </MDBCol>

                    <MDBCol lg="11" size="12" className={imgClass}>
                        <img
                            src={`${imagesRoute}${noticias.imagen}`}
                            alt={noticias.imagen}
                        />
                    </MDBCol>
                </MDBRow>
                <div className="row news-content">
                    <div className="news-inner col-xs-11 offset-lg-1 ">
                        <div className="news-list-title-container row">
                            <MDBCol size="2" className="date-responsive">
                                <span className="date-text">
                                    {noticias.diames}
                                </span>
                                <span className="year-text">
                                    {noticias.ano}
                                </span>
                                <div className="left-triangle-shape-list" />
                            </MDBCol>
                            <MDBCol
                                size="12"
                                md="10"
                                lg="12"
                                className="news-list-title-container-responsive-small"
                            >
                                <small>
                                    {noticias.diames} {noticias.ano}
                                </small>
                            </MDBCol>
                            <MDBCol
                                size="12"
                                md="10"
                                lg="12"
                                className="news-list-title-container-responsive"
                            >
                                <h2 className="news-list-title">
                                    {noticias.titulo}
                                </h2>
                            </MDBCol>
                        </div>
                        <div className="news-list-body-content">
                            <p className="news-body_text">
                                {noticias.entradilla}
                            </p>
                        </div>
                    </div>
                </div>
                <MDBRow className="below-text">
                    <MDBCol
                        size="12"
                        md="10"
                        lg="12"
                        className="news-list-title-container-responsive-small"
                    >
                        <small>
                            {noticias.diames} {noticias.ano}
                        </small>
                    </MDBCol>
                    <MDBCol
                        size="12"
                        md="10"
                        lg="12"
                        className="news-list-title-container-responsive"
                    >
                        <h2 className="news-list-title">{noticias.titulo}</h2>
                    </MDBCol>
                    <MDBCol lg="11" size="12" className="news-slider-img-below">
                        <img
                            src={`${imagesRoute}${noticias.imagen}`}
                            alt={noticias.imagen}
                        />
                    </MDBCol>
                </MDBRow>
            </div>
        </Link>
    )
}

NewsList.propTypes = {
    noticias: PropTypes.shape({
        ano: PropTypes.string.isRequired,
        contenido: PropTypes.string.isRequired,
        diames: PropTypes.string.isRequired,
        entradilla: PropTypes.string.isRequired,
        fecha: PropTypes.string.isRequired,
        imagen: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        titulo: PropTypes.string.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
}

const mapStateToProps = ({ page, pages }) => ({
    page,
    data: pages[page.slug],
})

export default connect(mapStateToProps, null)(NewsList)
