import React from 'react'
/* eslint-disable max-len */
export default function Check({ className }) {
    return (
        <svg
            className={className}
            version="1.1"
            viewBox="0 0 30 30"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                fill="none"
                fillRule="evenodd"
                id="desktop"
                stroke="none"
                strokeWidth="1"
            >
                <g
                    fill="currentcolor"
                    fillRule="nonzero"
                    id="Txoko-desktop-chat-historial"
                    transform="translate(-233.000000, -1052.000000)"
                >
                    <g
                        id="compromisarios"
                        transform="translate(135.000000, 415.000000)"
                    >
                        <g
                            id="success-message"
                            transform="translate(98.000000, 632.000000)"
                        >
                            <g
                                id="check"
                                transform="translate(0.000000, 5.000000)"
                            >
                                <path
                                    d="M20.667,9.241 L12.108,17.54 L9.11,14.542 C8.798,14.23 8.292,14.23 7.979,14.542 C7.666,14.854 7.667,15.36 7.979,15.673 L11.534,19.228 C11.69,19.384 11.895,19.462 12.099,19.462 C12.299,19.462 12.5,19.387 12.655,19.237 L21.779,10.389 C22.096,10.081 22.104,9.575 21.797,9.258 C21.488,8.94 20.983,8.933 20.666,9.24 L20.667,9.241 Z"
                                    id="Shape"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
