import moment from "moment"

const isValidDay = (day) => {
    const today = moment().endOf('day')
    const isBefore = moment(day).isBefore(today, 'day')

    return !isBefore
}


const hartuzDatesTransformer = data => {
    if (!data || !data.data || !data.data.available_days) {
        return [];
    }
    let datesTransformed = []

    Object.keys(data.data.available_days).forEach(key => {
        datesTransformed = datesTransformed.concat(data.data.available_days[key]);
    });

    datesTransformed = datesTransformed.filter(day => isValidDay(day))

    return datesTransformed
}

export default hartuzDatesTransformer