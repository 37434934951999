import React from 'react'
import RowsRender from '../components/RowsRender/RowsRender'
import PageRow from '../atoms/PageRow/PageRow'

function PageContainer(props) {
    const { data, match, isMobile, isTablet } = props
    const pageData = data?.pages?.data?.[0]
    const { lang } = match

    return (
        <PageRow className="fix-footer">
            {pageData ? (
                <RowsRender
                    translations={pageData.translations}
                    filas={pageData.filas}
                    lang={lang}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    menuSecondary={pageData.menu_secundario}
                    slug={pageData.slug}
                />
            ) : null}
        </PageRow>
    )
}

export default PageContainer
