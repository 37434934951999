// TO DO , UNIFY TRANSLATIONS WITH I18N

const t = {
    download: {
        es: 'Descargar',
        eu: 'Jeitsi',
    },
    opened: {
        es: 'Abierto',
        eu: 'Irekita',
    },
    closed: {
        es: 'Cerrado',
        eu: 'Itxita',
    },
    see_facility: {
        es: 'Ver instalación',
        eu: 'Instalazioa ikusi',
    },
    season: {
        es: 'Temporada',
        eu: 'Denboraldia',
    },
    occupancy: {
        es: 'Ocupación',
        eu: 'Okupazioa',
    },
    current_capacity: {
        es: 'Aforo actual',
        eu: 'Egungo edukiera',
    },
    percentage: {
        es: 'Porcentaje',
        eu: 'Ehunekoa',
    },
    low: {
        es: 'Baja',
        eu: 'Baxua',
    },
    medium: {
        es: 'Media',
        eu: 'Ertaina',
    },
    high: {
        es: 'Alta',
        eu: 'Altua',
    },
    aurretik_title: {
        es: 'Listado de instalaciones',
        eu: 'Instalazio zerrenda',
    },
    information: {
        es: 'Información',
        eu: 'Informazioa',
    },
    father_incidence: {
        es: 'Incidencia padre',
        eu: 'Aita intzidentzia',
    },
    plan_progress_alt: {
        es: 'Ir al progreso del plan',
        eu: 'Planaren aurrerapena ikusi',
    },
    image_types: {
        es: 'Tipos de imagen: .jpg, .gif, .png, .jpeg',
        eu: 'Irudi-motak: .jpg, .gif, .png, .jpeg',
    },
    image_quantity: {
        es: 'Cantidad de imagenes: 6',
        eu: 'Irudi-kopurua: 6',
    },
    image_size: {
        es: 'Tamaño máximo: 10MB',
        eu: 'Gehienezko tamaina: 10MB',
    },
    image_upload_title: {
        es: 'Selecciona una imagen',
        eu: 'Irudi bat hautatu',
    },
    map_resource_description: {
        es: 'Descripción',
        eu: 'Deskribapena',
    },
    contact: {
        es: 'Contacto',
        eu: 'Harrenana',
    },
    phone: {
        es: 'Teléfono',
        eu: 'Telefonoa',
    },
    email: {
        es: 'Email',
        eu: 'E-posta',
    },
    date: {
        es: 'Fecha',
        eu: 'Data',
    },
    hour: {
        es: 'Hora',
        eu: 'Ordua',
    },
    facility: {
        es: 'Instalación',
        eu: 'Instalazioa',
    },
    web: {
        es: 'Web',
        eu: 'Web-orria',
    },
    address: {
        es: 'Dirección',
        eu: 'Helbidea',
    },
    postal_code: {
        es: 'Cp',
        eu: 'Pk',
    },
    map_resources: {
        es: 'Recursos',
        eu: 'Baliabideak',
    },
    category: {
        es: 'Categoria',
        eu: 'Kategoria',
    },
    map_type: {
        es: 'Tipo de mapa',
        eu: 'Mapa mota',
    },
    map_resource_no_results: {
        es: 'No se han encontrado datos de este recurso',
        eu: 'Ez da baliabide honen daturik aurkitu',
    },
    back_to_top: {
        es: 'Volver arriba',
        eu: 'Itzuli gora',
    },
    see_calendar: {
        es: 'Ver Calendario',
        eu: 'Egutegia ikusi',
    },
    hartuz_calendar_search: {
        es: 'Busca las instalaciones que tengan disponibilidad en la fecha que desea',
        eu: 'Bilatu zuk nahi duzun datan erabilgarri dauden instalazioak ',
    },
    no_facilities_found: {
        es: 'No se han encontrado instalaciones',
        eu: 'Ez da instalaziorik aurkitu',
    },
    you_can_make_booking: {
        es: 'Puede realizar su reserva',
        eu: 'Zure erreserba egin dezakezu',
    },
    make_booking: {
        es: 'Hacer la reserva',
        eu: 'Erreserba egin',
    },
    select_this_day: {
        es: 'Seleccionar este día',
        eu: 'Egun hau aukeratu',
    },
    select_one_day: {
        es: 'Seleccionar un día',
        eu: 'Egun bat aukeratu',
    },
    select_a_timetable: {
        es: 'Selecciona un horario',
        eu: 'Ordutegi bat aukeratu',
    },
    error_required_email: {
        es: 'El campo email es obligatorio',
        eu: 'E-postaren eremua nahitaezkoa da',
    },
    error_required_id: {
        es: 'El campo DNI es obligatorio',
        eu: 'NAN-aren eremua eremua nahitaezkoa da',
    },
    error_required_name: {
        es: 'El campo de nombre es obligatorio',
        eu: 'Izenaren eremua nahitaezkoa da',
    },
    error_required_people: {
        es: 'El campo de cantidad de personas es obligatorio',
        eu: 'Pertsona-kopuruaren eremua nahitaezkoa da',
    },
    error_required_surname: {
        es: 'El campo de apellidos es obligatorio',
        eu: 'Abizenen eremua nahitaezkoa da',
    },
    error_valid_id: {
        es: 'Introduzca un DNI válido',
        eu: 'Sartu baliozko NAN bat',
    },
    error_valid_email: {
        es: 'Introduzca un email válido',
        eu: 'Sartu baliozko email bat',
    },
    error_valid_category: {
        es: 'Seleccione una categoría',
        eu: 'Aukeratu kategoria bat',
    },
    error_valid_text: {
        es: 'Introduzca un texto válido',
        eu: 'Sartu baliozko testu bat',
    },
    error_valid_description: {
        es: 'Introduzca una descripción válida',
        eu: 'Sartu baliozko deskribapen bat',
    },
    error_no_available_timetables: {
        es: 'No existen horarios disponibles',
        eu: 'Ez dago ordutegirik eskuragarri',
    },
    error_booking_code: {
        es: 'Introduzca un código de reserva válido',
        eu: 'Sartu baliozko erreserba kodea',
    },
    error_email: {
        es: 'Introduzca un email válido',
        eu: 'Sartu baliozko email bat',
    },
    error_name: {
        es: 'Introduzca un nombre válido',
        eu: 'Sartu baliozko izen bat',
    },
    error_surname: {
        es: 'Introduzca un apellido válido',
        eu: 'Sartu baliozko abizen bat',
    },
    error_phone: {
        es: 'Introduzca un número de teléfono válido',
        eu: 'Sartu baliozko telefono zenbaki bat',
    },
    error_postal_code: {
        es: 'Introduzca un código postal válido',
        eu: 'Sartu baliozko posta kode bat',
    },
    error_required_facility: {
        es: 'Este campo es obligatorio',
        eu: 'Eremu hau nahitaezkoa da',
    },
    error_required_field: {
        es: 'Este campo es obligatorio',
        eu: 'Eremu hau nahitaezkoa da',
    },
    error_select_a_date: {
        es: 'Selecciona una fecha para ver los horarios disponibles',
        eu: 'Aukeratu data bat eskuragarri dauden ordutegiak ikusteko',
    },
    error_capacity_persons: {
        es: 'El número de asistentes no puede ser mayor a %s',
        eu: 'Parte-hartzaileen kopurua ezin da %s baino handiagoa izan.',
    },
    availability_calendar: {
        es: 'Calendario de disponibilidad',
        eu: 'Eskuragarritasun egutegia',
    },
    modal_close_screen: {
        es: 'Cerrar ventana',
        eu: 'Leihoa itxi',
    },
    modal_message: {
        es: '¿Está seguro de que quiere cancelar la reserva?',
        eu: 'Ziur zaude erreserba bertan behera utzi nahi duzula?',
    },
    cancel: {
        es: 'Cancelar',
        eu: 'Utzi',
    },
    accept: {
        es: 'Aceptar',
        eu: 'Onartu',
    },
    name: {
        es: 'Nombre',
        eu: 'Izena',
    },
    surname: {
        es: 'Apellido',
        eu: 'Abizena',
    },
    surnames: {
        es: 'Apellidos',
        eu: 'Abizenak',
    },
    dni: {
        es: 'DNI/CIF/NIE',
        eu: 'NAN/IFZ/AIZ',
    },
    postal_code_label: {
        es: 'Código postal',
        eu: 'Posta kodea',
    },
    prize: {
        es: 'Precio',
        eu: 'Prezioa',
    },
    people: {
        es: 'Asistentes',
        eu: 'Partaideak',
    },
    people_label: {
        es: 'Número de asistentes',
        eu: 'Partaide kopurua',
    },
    action_book: {
        es: 'Reservar',
        eu: 'Erreserbatu',
    },
    action_cancel_booking: {
        es: 'Cancelar reserva',
        eu: 'Erreserba baliogabetu',
    },
    booking_code: {
        es: 'Código de reserva',
        eu: 'Erreserba kodea',
    },
    booking_cancelled: {
        es: 'La reserva se ha cancelado correctamente',
        eu: 'Erreserba zuzen baliogabetu da',
    },
    booking_success_thank_you: {
        es: 'Gracias por realizar la reserva',
        eu: 'Eskerrik asko erreserbagatik',
    },
    cancel_success_thank_you: {
        es: 'Cancelación realizada con éxito',
        eu: 'Ezeztapena ondo egin da',
    },
    error_submit_default: {
        es: 'Lo sentimos, ha ocurrido un error inesperado. Por favor, inténtelo de nuevo más tarde.',
        eu: 'Sentitzen dugu, ezusteko arazo bat gertatu da. Mesedez, saiatu berriro beranduago.',
    },
    booking_cancelation_title: {
        es: 'Cancelación de la reserva',
        eu: 'Erreserbaren baliogabetzea',
    },
    booking_cancelation_description: {
        es: 'Rellenando los campos de DNI, email y  codigo de reserva, que se envío por correo electrónico al realizar esta, podrás cancelar tu reserva.',
        eu: 'NAN zenbakia, posta elektroniko helbidea eta erreserba egitean helbide horretara bidali zitzaizun erreserba kodea idatziz, zure erreserba baliogabetu ahal izango duzu',
    },
    booking_cancelation_description_hour: {
        es: 'Rellenando los campos de DNI, email, fecha, hora y nombre de instalación, podrás cancelar tu reserva.',
        eu: 'NAN zenbakia, posta elektroniko helbidea eta erreserba egitean helbide horretara bidali zitzaizun erreserba kodea idatziz, zure erreserba baliogabetu ahal izango duzu',
    },
    booking_cancelation_radio_reservation_number: {
        es: 'Número de reserva',
        eu: 'Erreserba-zenbaki',
    },
    booking_cancelation_radio_date: {
        es: 'Fecha, hora e instalación',
        eu: 'Data, ordua eta instalazioa',
    },
    category_selector: {
        es: 'Selecciona una categoria',
        eu: 'Hautatu kategoria bat',
    },
    category_selector_title: {
        es: 'Selecciona una categoria para comenzar a filtrar',
        eu: 'Hautatu kategoria bat iragazten hasteko',
    },
    category_selected: {
        es: 'Has seleccionado la categoria',
        eu: 'Kategoria hautatu duzu',
    },
    date_picker_close: {
        es: 'Volver',
        eu: 'Itzuli',
    },
    booking_form_change_date_data: {
        es: 'Cambiar fecha y hora',
        eu: 'Data eta ordua aldatu',
    },
    filter_selector: {
        es: 'Escribe para filtrar',
        eu: 'Idatzi iragazteko',
    },
    go_back: {
        es: 'Volver',
        eu: 'Itzuli',
    },
    change_date: {
        es: 'Cambiar fecha',
        eu: 'Data aldatu',
    },
    pick_a_day_to_book: {
        es: 'Selecciona un día para la reserva',
        eu: 'Aukeratu erreserbarentzako egun bat',
    },
    pick_a_hour_interval_to_book: {
        es: 'Usted debe seleccionar un intervalo para su reserva',
        eu: 'Erreserba egiteko ordu-tartea aukeratu behar duzu',
    },
    loading: {
        es: 'Cargando...',
        eu: 'Kargatzen...',
    },
    error_no_available_dates: {
        es: 'No hay días disponibles',
        eu: 'Ez dago egun librerik',
    },
    error_no_available_hours: {
        es: 'No hay horarios disponibles',
        eu: 'Ez dago ordutegirik libre',
    },
    error_accept_policy: {
        es: 'Por favor, acepta nuestra política de privacidad.',
        eu: 'Mesedez, gure pribatasun politika onartu',
    },
    accept_privacy_privacy: {
        es: 'la politica de privacidad',
        eu: 'Pribatasun politika',
    },
    accept_privacy_i_have_read: {
        es: 'He leído y acepto',
        eu: 'irakurri eta onartzen dut',
    },
    error_required_booking_code: {
        es: 'El código de reserva es obligatorio',
        eu: 'Erreserba kodearen eremua nahitaezkoa da',
    },
    error_required_date: {
        es: 'El campo fecha es obligatorio',
        eu: 'Dataren eremua nahitaezkoa da',
    },
    error_required_time: {
        es: 'El campo de horario es obligatorio',
        eu: 'Ordutegiaren eremua nahitaezkoa da',
    },
    horario: {
        es: 'Horario',
        eu: 'Ordutegia',
    },
    error_cancelation_no_booking: {
        es: 'No se ha localizado una reserva con los datos proporcionados',
        eu: 'Emandako datuak dituen erreserbarik ez da aurkitu.',
    },
    make_cancelation_title: {
        es: 'Hacer una cancelación',
        eu: 'Erreserba baliogabetu',
    },
    error_cancelation_no_booking_time: {
        es: 'Limite de tiempo para cancelar la reserva ha expirado',
        eu: 'Erreserba bertan behera uzteko denbora-muga amaitu da',
    },
    cancelation: {
        es: 'Cancelación',
        eu: 'Baliogabetzea',
    },
    transparency: {
        es: 'Transparencia',
        eu: 'Gardentasuna',
    },
    booking: {
        es: 'Reserva',
        eu: 'Erreserba',
    },
    facility_disabled: {
        es: 'Esta instalación ya no está disponible',
        eu: 'Instalazio hau jada ez dago eskuragarri',
    },
    dates_no_availables: {
        es: 'Esta fecha y hora ya no está disponible',
        eu: 'Data eta ordu hau jada ez dago eskuragarri',
    },
    thank_you_instalation_name: {
        es: 'Nombre de instalación:',
        eu: 'Instalazioaren izena',
    },
    thank_you_reservation_date: {
        es: 'Fecha y hora de reserva:',
        eu: 'Erreserbaren eguna eta ordua:',
    },
    thank_you_id: {
        es: 'Id reserva:',
        eu: 'Erreserbaren id:',
    },
    remove_filters: {
        es: 'Borrar filtros',
        eu: 'Iragazkiak ezabatu',
    },
    pick_option: {
        es: 'Por favor, selecciona una opción',
        eu: 'Mesedez, hautatu aukera bat',
    },
    limit_date_for_payment: {
        es: 'Fecha límite para realizar el pago: ',
        eu: 'Ordainketa egiteko azken eguna: ',
    },
    actual_capacity: {
        es: 'Reservas realizadas',
        eu: 'Egindako erreserbak',
    },
    select_category: {
        es: 'Seleccione una categoría',
        eu: 'Kategoria bat aukeratu',
    },
    search_button: {
        es: 'Botón buscar',
        eu: 'Bilatu botoia',
    },
    open_macromenu: {
        es: 'Abrir macromenu',
        eu: 'Makromenua ireki',
    },
    language_menu: {
        es: 'Menú idioma',
        eu: 'Hizkuntza menua',
    },
    logo_header: {
        es: 'cabecera',
        eu: 'goiburua',
    },
    back_to_main_screen: {
        es: 'Volver a la pantalla de inicio',
        eu: 'Hasierako pantailara itzuli',
    },
    header_background_image: {
        es: 'Vista de la ciudad',
        eu: 'Hiriaren ikuspegia',
    },
    title: {
        es: 'Introduce un título',
        eu: 'Izenburua idatzi',
    },
    email_example: {
        es: 'alguien@example.com',
        eu: 'norbait@example.com',
    },
    more_info: {
        es: 'Introduce la descripción del lugar',
        eu: 'Lekuaren deskribapena idatzi',
    },
    kalezaindu_map: {
        es: 'Mapa Kalezaindu',
        eu: 'Kalezaindu mapa',
    },
    new_incidence_map: {
        es: 'Mapa nueva incidencia',
        eu: 'Intzidentzia berria mapa',
    },
    action_map: {
        es: 'Mapa acción',
        eu: 'Akzioa mapa',
    },
    incidencia_map: {
        es: 'Mapa incidencia',
        eu: 'Intzidentzia mapa',
    },
    map: {
        es: 'Mapa',
        eu: 'Mapa',
    },
    plan_map: {
        es: 'Mapa de plan',
        eu: 'Plan mapa',
    },
    action_detail_map: {
        es: 'Mapa de detalle de acción',
        eu: 'Akzio detaile mapa',
    },
    report_incidence: {
        es: 'Reportar incidencia',
        eu: 'Intzidentzia ',
    },
    theme: {
        es: 'Temática',
        eu: 'Gaia',
    },
    zone: {
        es: 'Zona',
        eu: 'Zona',
    },
    hartuz_confirm_booking: {
        es: 'Confirmar reserva',
        eu: 'Erreserba berretsi',
    },
    location_remember: {
        es: 'Recuerde indicar la ubicación',
        eu: 'Gogoratu kokapena adierazi behar duzula',
    },
    user_login_button: {
        es: 'Iniciar sesión',
        eu: 'Hasi saioa',
    },
    search: {
        es: 'Buscar',
        eu: 'Bilatu',
    },
    active: {
        es: 'Activa',
        eu: 'Aktibo',
    },
    passed: {
        es: 'Pasada',
        eu: 'Iragana',
    },
    cancelled: {
        es: 'Cancelada',
        eu: 'Indargabetua',
    },
    outstanding: {
        es: 'Pendiente de pago',
        eu: 'Ordaintzeko',
    },
    no_filter: {
        es: 'Sin filtro',
        eu: 'Filtro barik',
    },
    booking_status: {
        es: 'Estado de la reserva',
        eu: 'Erreserbaren egoera',
    },
    facility_type: {
        es: 'Tipo de la instalación',
        eu: 'Instalazio mota',
    },
    booking_search: {
        es: 'Buscador de reservas',
        eu: 'Erreserba bilatzailea',
    },
    booking_search_header: {
        es: 'Introduce un texto para buscar un nombre de instalación o código de cancelación',
        eu: 'Sartu testu bat instalazioaren izenaren edo baliogabetze-kodearen arabera bilatzeko',
    },
    booking_list: {
        es: 'Listado de reservas',
        eu: 'Erreserba zerrenda',
    },
    text_booking_placeholder: {
        es: 'Introduce texto o código de reserva para buscar',
        eu: 'Idatzi testua edo erreserba-kodea bilatzeko',
    },
    actions: {
        es: 'Acciones',
        eu: 'Ekintzak',
    },
    facility_name: {
        es: 'Nombre instalación',
        eu: 'Instalazioaren izena',
    },
    status: {
        es: 'Estado',
        eu: 'Egoera',
    },
    total_prize: {
        es: 'Precio',
        eu: 'Prezioa',
    },
    number_people: {
        es: 'Número de personas',
        eu: 'Pertsona-kopurua'
    },
    start_date: {
        es: 'Fecha Inicio',
        eu: 'Hasiera data',
    },
    end_date: {
        es: 'Fecha Fin',
        eu: 'Amaiera data',
    },
    booking_date: {
        es: 'Fecha de reserva',
        eu: 'Erreserba data',
    },
    delete_booking: {
        es: 'Eliminar reserva',
        eu: 'Erreserba ezabatu'
    },
    has_light_label: {
        es: '¿Quieres luz en la instalación?',
        eu: 'Argia nahi duzu instalazioan?'
    },
    user_info: {
        es: 'Información del usuario',
        eu: 'Erabiltzailearen informazioa',
    },
    save: {
        es: 'Guardar',
        eu: 'Gorde',
    },
    census: {
        es: 'Censo',
        eu: 'Errolda',
    },
    idperson: {
        es: 'ID Persona',
        eu: 'Pertsonaren IDa',
    },
    birthday: {
        es: 'Fecha de nacimiento',
        eu: 'Jaiotza data',
    },
    profile: {
        es: 'Perfil',
        eu: 'Profila',
    },
    booking: {
        es: 'Reserva',
        eu: 'Erreserba',
    },
    log_out: {
        es: 'Salir',
        eu: 'Irteera',
    },
    only_census: {
        es: 'Solo para empadronados',
        eu: 'Erroldatuentzat bakarrik',
    },
    moviliy_reduced: {
        es: 'Con acceso para personas de movilidad reducida',
        eu: 'Mugikortasun urriko pertsonentzat sarrera',
    },
    confirmacion_reserva: {
        es: 'Confirmación de Reserva',
        eu: 'Erreserbaren konfirmazioa',
    },
    datos_reserva: {
        es: 'Los datos de tu reserva',
        eu: 'Zure erreserbaren datuak',
    },
    datos_usuario: {
        es: 'Los datos del usuario',
        eu: 'Erabiltzailearen datuak',
    },
    error_requerido: {
        es: 'Completa este campo',
        eu: 'bete eremu hau',
    },
    error_requerido_select: {
        es: 'Selecciona un elemento de la lista',
        eu: 'Aukeratu zerrendako elementu bat',
    },
}

export default t
