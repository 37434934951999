import { MDBCol } from 'mdb-react-ui-kit'
import PropTypes from 'prop-types'
import React from 'react'
import './InfoCard.css'
import t from '../../i18n/translations/translations'

function InfoFooter({ data, size, lang }) {
    return (
        <MDBCol size={size} className="info-footer">
            {data.includes('www.') ? (
                <span>
                    <a href={`http://${data}`}>{t.horario[lang]}</a>
                </span>
            ) : (
                <span>{data}</span>
            )}
        </MDBCol>
    )
}

function IconFooter({ className, size }) {
    return (
        <MDBCol size={size} className="icon-footer">
            <i className={className} aria-hidden="true" />
        </MDBCol>
    )
}

function InfoCard({ address, className, email, isMobile, lang, phone, schedule, style }) {
    const addressIsLoaded = address !== null && address !== ''
    const phoneIsLoaded = phone !== null && phone !== ''
    const scheduleIsLoaded = schedule !== null && schedule !== ''
    const emailIsLoaded = email !== null && email !== ''

    return !isMobile ? (
        <div className={`flex-item ${className}`} style={style}>
            {addressIsLoaded ? (
                <div>
                    <IconFooter className="fa fa-map-marker fa-lg" size="2" />
                    <InfoFooter data={address} size="9" lang={lang} />
                </div>
            ) : null}
            {phoneIsLoaded ? (
                <div>
                    <IconFooter className="fa fa-mobile fa-lg" size="2" />

                    <InfoFooter data={phone} size="9" lang={lang} />
                </div>
            ) : null}
            {scheduleIsLoaded ? (
                <div>
                    <IconFooter className="fa fa-clock-o fa-lg" size="6" />

                    <InfoFooter data={schedule} size="9" lang={lang} />
                </div>
            ) : null}
            {emailIsLoaded ? (
                <div>
                    <IconFooter className="fa fa-envelope fa-lg" size="2" />
                    <InfoFooter data={email} size="9" lang={lang} />
                </div>
            ) : null}
        </div>
    ) : (
        <div className="flex-item">
            {addressIsLoaded ? (
                <div>
                    <InfoFooter data={address} size="10" lang={lang} />
                </div>
            ) : null}
            {phoneIsLoaded ? (
                <div>
                    <InfoFooter data={phone} size="10" lang={lang} />
                </div>
            ) : null}
            {scheduleIsLoaded ? (
                <div>
                    <InfoFooter data={schedule} size="10" lang={lang} />
                </div>
            ) : null}
            {emailIsLoaded ? (
                <div>
                    <InfoFooter data={email} size="10" lang={lang} />
                </div>
            ) : null}
        </div>
    )
}

InfoCard.propTypes = {
    address: PropTypes.string,
    phone: PropTypes.string,
    schedule: PropTypes.string,
    isMobile: PropTypes.bool,
}

InfoCard.defaultProps = {
    isMobile: false,
    address: null,
    phone: null,
    schedule: null,
}

export default InfoCard
