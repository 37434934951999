import { useEffect } from 'react'

const useSetIframeTitle = (title) => {
    useEffect(() => {
        const intervalId = setInterval(() => {
            const iframe = document.querySelector('iframe')
            if (iframe) {
                iframe.title = title
                clearInterval(intervalId)
            }
        }, 1000)
        return () => clearInterval(intervalId)
    }, [title])
}

export default useSetIframeTitle
