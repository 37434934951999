import React from 'react'
import PlanListBoxTitle from '../PlanListBoxTitle/PlanListBoxTitle'

function MessageNoVigente({ content, texts }) {
    return content.plans.notValid.length > 0 ? (
        <PlanListBoxTitle title={texts.stateNoVigente} />
    ) : (
        ''
    )
}

function MessageVigente({ content, texts }) {
    return content.plans.valid.length > 0 ? (
        <PlanListBoxTitle title={texts.stateVigente} />
    ) : (
        ''
    )
}

function PlanListStateMessage({ content, translations, vigente }) {
    const texts = {
        stateNoVigente:
            translations.ACCOUNTABILITY.PLAN_LIST_PLANS_STATE_NO_VIGENTE,
        stateVigente: translations.ACCOUNTABILITY.PLAN_LIST_PLANS_STAGE_VIGENTE,
    }

    return vigente ? (
        <MessageVigente content={content} texts={texts} />
    ) : (
        <MessageNoVigente content={content} texts={texts} />
    )
}

export default PlanListStateMessage
