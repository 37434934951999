import React from 'react'
import './ActionDetailDates.css'
import Calendar from '../../../assets/svg/aurretik/Calendar'

function ActionDetailDates({ title, text, className }) {
    return (
        <div className={className || 'actionDetail-box_container-dates_svg'}>
            <Calendar />
            <p className="bold">{`${title}: `} </p>
            <p> {text}</p>
        </div>
    )
}

export default ActionDetailDates
