import React from 'react'
import DownloadFile from '../../DownloadFile/DownloadFile'
import './ActionDetailFiles.css'

function ActionDetailFiles({ translations, files }) {
    return (
        <div className="actionDetail_action-descriptionContainer-downloads">
            <div className="actionDetail_action-descriptionContainer-downloads_title">
                <p>{translations.ACCOUNTABILITY.PLAN_DETAIL_DOWNLOADS}</p>
            </div>
            <div>
                {files.map((file, index) => (
                    <DownloadFile
                        className="downloads_files"
                        file={file}
                        icon="paper"
                        key={index}
                    />
                ))}
            </div>
        </div>
    )
}

export default ActionDetailFiles
