import React from 'react'
import DownloadFile from '../../DownloadFile/DownloadFile'
import './ActionDetailLinks.css'

function ActionDetailLinks({ translations, links }) {
    return (
        <div className="actionDetail_action-descriptionContainer-links">
            <div className="actionDetail_action-descriptionContainer-links_title">
                <p>{translations.ACCOUNTABILITY.PLAN_DETAIL_LINKS}</p>
            </div>
            <div>
                {links.map((link, index) => (
                    <DownloadFile
                        className="downloads_files"
                        file={link}
                        icon="link"
                        key={index}
                    />
                ))}
            </div>
        </div>
    )
}

export default ActionDetailLinks
