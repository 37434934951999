import React from 'react'
import PageRow from '../atoms/PageRow/PageRow'
import DetailRender from '../components/DetailRender/DetailRender'

function CategoriesContainer({ data, isMobile, isTablet, match }) {
    const { lang } = match

    return (
        <PageRow className="fix-footer">
            <DetailRender
                contenido={data.categories.data[0].categorias}
                lang={lang}
                isMobile={isMobile}
                isTablet={isTablet}
                menuSecondary={data.categories.data[0].menu_secundario}
            />
        </PageRow>
    )
}

export default CategoriesContainer
