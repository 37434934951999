import React from 'react'
import { Link } from 'react-router-dom'
import ActionMapSearchForm from '../../../../forms/ActionMapSearchForm'
import './ActionMapBox.css'

let Box = ''

const getPlanSearchUrl = (lang) =>
    lang === 'es'
        ? '/es/planes-de-legislatura/buscador'
        : '/eu/legealdiko-planak/bilatzaile'

const getPlanSearchZoneUrl = ({ lang, zone }) =>
    lang === 'es'
        ? `/es/planes-de-legislatura/buscador?zona=${zone}`
        : `/eu/legealdiko-planak/bilatzaile?zona=${zone}`

const TABLET_SIZE = 960
const MOBILE_SIZE = 568

function ActionMapDataLine({ title, data }) {
    return title ? (
        <p>
            {`${title}: `} {data === 0 ? '          -' : `${data}€`}
        </p>
    ) : (
        <p>{data === 0 ? '          -' : `${data}€`}</p>
    )
}

function ActionMapTitle({ translations }) {
    return (
        <div>
            <p>{translations.ACCOUNTABILITY.ACTION_MAP_ACCTION_MAP_TITLE}</p>
        </div>
    )
}

function SearchLink({ zone, lang }) {
    return (
        <p>
            {zone ? (
                <Link
                    to={getPlanSearchZoneUrl({ lang, zone })}
                    className="enlace-buscador"
                >
                    {zone}
                </Link>
            ) : (
                '-'
            )}
        </p>
    )
}

function BoxStatement({ translations }) {
    return (
        <div className="box_statement">
            <div>
                <p>{translations.ACCOUNTABILITY.ACTION_MAP_ACCTION_MAP_ZONE}</p>
            </div>
            <div>
                <p>{translations.ACCOUNTABILITY.ACTION_DETAIL_BUDGET}</p>
            </div>
            <div>
                <p>{translations.ACCOUNTABILITY.ACTION_DETAIL_EXPENDITURE}</p>
            </div>
        </div>
    )
}

function BoxActions({ sumary, translations, lang, isDesktop }) {
    return isDesktop ? (
        <div className="box-projects">
            {sumary.map((data, index) => (
                <div className="project" key={index}>
                    <div>
                        <SearchLink zone={data.zone} lang={lang} />
                    </div>
                    <div>
                        <ActionMapDataLine data={data.budged} />
                    </div>
                    <div>
                        <ActionMapDataLine data={data.expenditure} />
                    </div>
                </div>
            ))}
        </div>
    ) : (
        <div>
            {sumary.map((data, index) => (
                <div className="box_action_tablet" key={index}>
                    <div className="box_statement_tablet">
                        <SearchLink zone={data.zone} lang={lang} />
                    </div>
                    <hr />
                    <div className="budget">
                        <ActionMapDataLine
                            title={
                                translations.ACCOUNTABILITY.ACTION_DETAIL_BUDGET
                            }
                            data={data.budged}
                        />
                        <ActionMapDataLine
                            title={
                                translations.ACCOUNTABILITY
                                    .ACTION_DETAIL_EXPENDITURE
                            }
                            data={data.expenditure}
                        />
                    </div>
                </div>
            ))}
        </div>
    )
}

function ActionMap({
    translations,
    handleChange,
    handleSearchBar,
    texto,
    lang,
    blockSearch,
    isDesktop,
}) {
    return (
        <div
            className={
                isDesktop
                    ? 'mapa-de-acciones-container_title'
                    : 'mapa-de-acciones'
            }
        >
            <ActionMapTitle translations={translations} />

            <div className="actionMap-box-tittle_search-plan">
                <ActionMapSearchForm
                    translations={translations}
                    handleChange={handleChange}
                    texto={texto}
                    handleSearchBar={handleSearchBar}
                    blockSearch={blockSearch}
                    actionUrl={getPlanSearchUrl(lang)}
                />
            </div>
        </div>
    )
}

function ActionMapBox(props) {
    const {
        sumary,
        translations,
        lang,
        handleSearchBar,
        handleChange,
        texto,
        resizewidth,
    } = props
    const { blockSearch } = props

    if (resizewidth <= TABLET_SIZE) {
        // Tablet
        Box = (
            <div>
                <div className="mapa-de-acciones-title">
                    <ActionMap
                        translations={translations}
                        handleChange={handleChange}
                        handleSearchBar={handleSearchBar}
                        texto={texto}
                        lang={lang}
                        blockSearch={blockSearch}
                    />
                    <div className="mapa-de-acciones">
                        <ActionMapTitle translations={translations} />

                        <div className="actionMap-box-tittle_search-plan">
                            <ActionMapSearchForm
                                translations={translations}
                                handleChange={handleChange}
                                texto={texto}
                                handleSearchBar={handleSearchBar}
                                blockSearch={blockSearch}
                                actionUrl={getPlanSearchUrl(lang)}
                            />
                        </div>
                    </div>
                </div>
                <BoxActions
                    sumary={sumary}
                    lang={lang}
                    translations={translations}
                />
            </div>
        )
    } else if (resizewidth <= MOBILE_SIZE) {
        // Mobile
        Box = (
            <div>
                <ActionMap
                    translations={translations}
                    handleChange={handleChange}
                    handleSearchBar={handleSearchBar}
                    texto={texto}
                    lang={lang}
                    blockSearch={blockSearch}
                />
                <BoxActions
                    sumary={sumary}
                    lang={lang}
                    translations={translations}
                />
            </div>
        )
    } else {
        Box = (
            <div className="mapa-de-acciones-container">
                <ActionMap
                    translations={translations}
                    handleChange={handleChange}
                    handleSearchBar={handleSearchBar}
                    texto={texto}
                    lang={lang}
                    blockSearch={blockSearch}
                    isDesktop
                />
                <div className="mapa-de-acciones-container_content">
                    <BoxStatement translations={translations} />
                    <hr />
                    <BoxActions
                        sumary={sumary}
                        lang={lang}
                        translations={translations}
                        isDesktop
                    />
                </div>
            </div>
        )
    }
    return <div className="action_map_list">{Box}</div>
}

export default ActionMapBox
