import { sanitize } from 'dompurify'
import React from 'react'
import './Postal.css'

function Postal({ postal, lang }) {
    const { img, title, description, id } = postal
    return (
        <div className="postal" id={id}>
            {title && (
                <p className="wysiwyg-functionality__title">{title[lang]}</p>
            )}
            <div>
                {description && (
                    <p
                        className="wysiwyg postal__description"
                        dangerouslySetInnerHTML={{
                            __html: sanitize(description[lang]),
                        }}
                    />
                )}
            </div>

            <div className="postal__img-container" id={img.id && img.id}>
                {img.original && <img alt="" src={img.original} />}
            </div>
        </div>
    )
}

export default Postal
