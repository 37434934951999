import React from 'react'

/* eslint-disable max-len */
export default function Ayuntamiento({ className }) {
    return (
        <svg className={className} viewBox="0 0 49 37">
            <g id="Udala-Zabaltzen-0">
                <g id="Group-12">
                    <path
                        d="M46.8036364,4.43725921 C47.4643698,4.43725921 48,4.97659991 48,5.64191037 L48,35.7953488 C48,36.4606593 47.4643698,37 46.8036364,37 L2.19636364,37 C1.53563024,37 1,36.4606593 1,35.7953488 L1,5.64191037 C1,4.97659991 1.53563024,4.43725921 2.19636364,4.43725921 L4.19877129,4.43725921 L4.19877129,1.20465116 C4.19877129,0.539340697 4.73440153,5.86941348e-13 5.39513492,5.86941348e-13 L43.5075316,0 C44.168265,0 44.7038952,0.539340697 44.7038952,1.20465116 L44.7038952,4.43725921 L46.8036364,4.43725921 Z M2.70909091,35.2790698 L46.2909091,35.2790698 L46.2909091,6.15818944 L44.1911679,6.15818944 C43.5304345,6.15818944 42.9948043,5.61884875 42.9948043,4.95353828 L42.9948043,1.72093023 L5.90786219,1.72093023 L5.90786219,4.95353828 C5.90786219,5.61884875 5.37223195,6.15818944 4.71149856,6.15818944 L2.70909091,6.15818944 L2.70909091,35.2790698 Z"
                        id="Path-7-Copy-5"
                    />
                    <path
                        d="M0.834276043,22.8802582 C0.372480167,22.8790899 -0.00103697149,22.4572331 2.16292381e-06,21.9380148 C0.00104129734,21.4187965 0.376243206,20.9988341 0.838039082,21.0000024 L48.165724,21.1197418 C48.6275198,21.1209101 49.001037,21.5427669 48.9999978,22.0619852 C48.9989587,22.5812035 48.6237568,23.0011659 48.1619609,22.9999976 L0.834276043,22.8802582 Z"
                        id="Path-9-Copy-15"
                    />
                    <path
                        d="M4.75816345,6.15205383 C4.291212,6.15205383 4.29382324,5.72605184 4.29382324,5.17376709 C4.29382324,4.62148234 4.21932306,4.17376709 4.68627451,4.17376709 L44.0200195,4 C44.486971,4 44.5575686,4.44771525 44.5575686,5 C44.5575686,5.55228475 44.5884757,6.17376709 44.1215243,6.17376709 L4.75816345,6.15205383 Z"
                        id="Path-9-Copy-27"
                    />
                    <path
                        d="M40.25,35.2666667 L40.25,27.0591859 C40.25,26.2611135 39.5391279,25.7333333 38.5,25.7333333 C37.4608721,25.7333333 36.75,26.2611135 36.75,27.0591859 L36.75,35.2666667 L40.25,35.2666667 Z M35,27.0591859 C35,25.1775126 36.5860029,24 38.5,24 C40.4139971,24 42,25.1775126 42,27.0591859 L42,37 L35,37 L35,27.0591859 Z"
                        id="Combined-Shape-Copy-4"
                    />
                    <path
                        d="M30.25,35.2666667 L30.25,27.0591859 C30.25,26.2611135 29.5391279,25.7333333 28.5,25.7333333 C27.4608721,25.7333333 26.75,26.2611135 26.75,27.0591859 L26.75,35.2666667 L30.25,35.2666667 Z M25,27.0591859 C25,25.1775126 26.5860029,24 28.5,24 C30.4139971,24 32,25.1775126 32,27.0591859 L32,37 L25,37 L25,27.0591859 Z"
                        id="Combined-Shape-Copy-5"
                    />
                    <path
                        d="M21.25,35.2666667 L21.25,27.0591859 C21.25,26.2611135 20.5391279,25.7333333 19.5,25.7333333 C18.4608721,25.7333333 17.75,26.2611135 17.75,27.0591859 L17.75,35.2666667 L21.25,35.2666667 Z M16,27.0591859 C16,25.1775126 17.5860029,24 19.5,24 C21.4139971,24 23,25.1775126 23,27.0591859 L23,37 L16,37 L16,27.0591859 Z"
                        id="Combined-Shape-Copy-9"
                    />
                    <path
                        d="M12.25,35.2666667 L12.25,27.0591859 C12.25,26.2611135 11.5391279,25.7333333 10.5,25.7333333 C9.46087208,25.7333333 8.75,26.2611135 8.75,27.0591859 L8.75,35.2666667 L12.25,35.2666667 Z M7,27.0591859 C7,25.1775126 8.58600292,24 10.5,24 C12.4139971,24 14,25.1775126 14,27.0591859 L14,37 L7,37 L7,27.0591859 Z"
                        id="Combined-Shape-Copy-10"
                    />
                    <path
                        d="M39.25,17.3333333 L39.25,11.8418353 C39.25,11.1465821 38.5443745,10.6666667 37.5,10.6666667 C36.4556255,10.6666667 35.75,11.1465821 35.75,11.8418353 L35.75,17.3333333 L39.25,17.3333333 Z M34,11.8419318 C34,10.0822528 35.5912495,9 37.5,9 C39.4087505,9 41,10.0822528 41,11.8418353 L41,19 L34,19 L34,11.8419318 Z"
                        id="Combined-Shape-Copy-11"
                    />
                    <path
                        d="M26.2498032,13.3161036 L26.2498032,11.91047 C26.2460229,11.8890931 26.2462795,11.8809849 26.246738,11.866494 C26.1378112,10.242705 22.7499344,10.3072631 22.7499344,11.959612 L22.7499344,13.3161036 L26.2498032,13.3161036 Z M27.9945812,11.7396861 C28.0003471,11.8297971 28.0003471,11.8297971 27.9997376,11.8481151 L27.9997376,14.7225342 L21,14.7225342 L21,11.959612 C21,8.14459085 27.6298808,7.98698564 27.9855139,11.666027 C28.0009207,11.7068304 27.998681,11.7184384 27.9945812,11.7396861 Z"
                        id="Combined-Shape-Copy-12"
                    />
                    <path
                        d="M13.25,17.3333333 L13.25,11.8493519 C13.25,11.1486347 12.5439986,10.6666667 11.5,10.6666667 C10.4560014,10.6666667 9.75,11.1486347 9.75,11.8493519 L9.75,17.3333333 L13.25,17.3333333 Z M8,11.8493519 C8,10.0860463 9.59087357,9 11.5,9 C13.4091264,9 15,10.0860463 15,11.8493519 L15,19 L8,19 L8,11.8493519 Z"
                        id="Combined-Shape-Copy-13"
                    />
                </g>
                <path
                    d="M18,13 L31,13 L31,19 L18,19 L18,13 Z M19.8,14.8 L19.8,17.2 L29.2,17.2 L29.2,14.8 L19.8,14.8 Z"
                    id="Combined-Shape"
                />
            </g>
        </svg>
    )
}
