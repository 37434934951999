import calculatePercentage from '../utils/calculatePercentage'

const getClosingTimeFromSeason = season => {
    const seasonArray = season.split('-')
    const closingHour = seasonArray[2].trim()
    return closingHour.slice(0, -3)
}
const getOpeningTimeFromSeason = season => {
    const seasonArray = season.split('-')
    const slice = seasonArray?.[1]?.split('\n')
    const openingHour = slice?.[1]?.trim()
    return openingHour.slice(0, -3)
}

const removeHoursFromSeasonDate = season => {
    const seasonDates = season.split('\n')
    const dates = seasonDates[0]
    return dates
}

export const facilityTransformer = data => ({
    open: data.open,
    occupancy: {
        capacity: data.current_capacity,
        occupancy: data.current_occupancy,
        percentage: data.occupancy_percentage,
        percent: calculatePercentage({
            num: data.current_occupancy,
            total: data.current_capacity,
        }),
    },
    season: {
        es: data.season_info_es,
        eu: data.season_info_eu,
        closing_time: getClosingTimeFromSeason(data.season_info_es),
        opening_time: getOpeningTimeFromSeason(data.season_info_es),
        dates: {
            es: removeHoursFromSeasonDate(data.season_info_es),
            eu: removeHoursFromSeasonDate(data.season_info_eu),
        },
    },
    id: data.id_instalacion,
    image: data.image,
    state: data.state,
    persons_in_graphic: data.persons_in_graphic,
    theme: data.theme,
    title: {
        es: data.title_es,
        eu: data.title_eu,
    },
    info: {
        description: {
            es: data.description_es,
            eu: data.description_eu,
        },
        title: {
            es: data.information_eu,
            eu: data.information_es,
        },
    },
})

export const transformAurretikFacilities = data =>
    data.map(item => facilityTransformer(item))

export const aurretikDataTransformer = data => ({
    code: data.code,
    message: data.message,
    data: data.data,
    facilites: transformAurretikFacilities(data.data),
})