import React from 'react'

/* eslint-disable max-len */
export default function Rechazada() {
    return (
        <svg aria-hidden="true" focusable="false" viewBox="0 0 933 28">
            <title>E7E56752-6E32-46E3-88EB-9C018C49968D</title>
            <desc>Created with sketchtool.</desc>
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Kalezaindu_incidencia_rechazada_desktop"
                    transform="translate(-254.000000, -1184.000000)"
                >
                    <g
                        id="Group-5"
                        transform="translate(254.000000, 1184.000000)"
                    >
                        <path
                            d="M906.872953,6.9999961 C909.293619,2.81538866 913.818025,0 919,0 C926.731986,0 933,6.2680135 933,14 C933,21.7319865 926.731986,28 919,28 C913.818025,28 909.293619,25.1846113 906.872953,21.0000039 L26.1270607,21 C23.7063805,25.1846113 19.1819749,28 14,28 C6.2680135,28 0,21.7319865 0,14 C0,6.2680135 6.2680135,0 14,0 C19.1819749,0 23.7063805,2.81538866 26.127047,6.9999961 L906.872939,7 Z"
                            id="Combined-Shape"
                            fill="#FFFFFF"
                        />
                        <path
                            d="M23.5421977,11 L909.457802,11 C910.732066,6.94289305 914.522358,4 919,4 C924.522847,4 929,8.4771525 929,14 C929,19.5228475 924.522847,24 919,24 C914.522358,24 910.732066,21.057107 909.457802,17 L23.5421977,17 C22.2679341,21.057107 18.4776418,24 14,24 C8.4771525,24 4,19.5228475 4,14 C4,8.4771525 8.4771525,4 14,4 C18.4776418,4 22.2679341,6.94289305 23.5421977,11 Z M919,19 C921.761424,19 924,16.7614237 924,14 C924,11.2385763 921.761424,9 919,9 C916.238576,9 914,11.2385763 914,14 C914,16.7614237 916.238576,19 919,19 Z M14,19 C16.7614237,19 19,16.7614237 19,14 C19,11.2385763 16.7614237,9 14,9 C11.2385763,9 9,11.2385763 9,14 C9,16.7614237 11.2385763,19 14,19 Z"
                            id="Combined-Shape"
                            fill="#D0021B"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
