import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageRow from '../../atoms/PageRow/PageRow'
import HartuzPageIntro from '../../components/Hartuz/HartuzPageIntro/HartuzPageIntro'
import ThankYouPage from '../../compositions/ThankYouPage/ThankYouPage'
import STATES from '../../constants/states'
import HartuzCancelationForm from '../../forms/HartuzCancelationForm/HartuzCancelationForm'
import HartuzCancelationFormHourFilter from '../../forms/HartuzCancelationForm/HartuzCancelationFormHourFilter'
import t from '../../i18n/translations/translations'
import {
    cancelHartuzFacilityBooking as cancelHartuzFacilityBookingAction,
    setSelectedHartuz as setSelectedHartuzAction,
} from '../../store/actions'
import './HartuzCancelationContainer.css'

function HartuzCancelationContainer({ match }) {
    const [selectedForm, setSelectedForm] = useState('HartuzCancelationForm')

    const dispatch = useDispatch()

    const {
        bookingFormState,
        instanceID,
        facilities,
        facilityName,
        date,
        bookingCode,
    } = useSelector((state) => ({
        bookingFormState: state.hartuz.bookingFormState,
        instanceID: state.pages.hartuz?.data[0]?.idinstancia,
        facilities: state.hartuz.facilities,
        facilityName: state.hartuz.booking?.data?.data?.facility_name,
        date: state.hartuz.booking?.data?.data?.date,
        bookingCode: state.hartuz.booking?.data?.data?.booking_code,
    }))

    const cancelHartuzFacilityBooking = (payload) =>
        dispatch(cancelHartuzFacilityBookingAction(payload))
    const setSelectedHartuz = (payload) =>
        dispatch(setSelectedHartuzAction(payload))

    useEffect(() => {
        const { lang } = match

        if (instanceID && lang) {
            setSelectedHartuz({
                instanceID,
                lang,
            })
        }
    }, [])

    const handleSubmit = (formData) => cancelHartuzFacilityBooking(formData)

    const handleFormChange = (event) => setSelectedForm(event.target.value)

    const { lang } = match

    return bookingFormState === STATES.SUCCESS ? (
        <ThankYouPage
            message={t.cancel_success_thank_you[lang]}
            textName={t.thank_you_instalation_name[lang]}
            facilityName={facilityName}
            textDate={t.thank_you_reservation_date[lang]}
            date={date}
            textId={t.thank_you_id[lang]}
            bookingCode={bookingCode}
        >
            <a href={`/${lang}/hartuz`}>{t.go_back[lang]}</a>
        </ThankYouPage>
    ) : (
        <div className="hartuzCancelationContainer">
            <HartuzPageIntro title={t.booking_cancelation_title[lang]}>
                <PageRow>
                    <p>{t.booking_cancelation_description[lang]}</p>
                </PageRow>
            </HartuzPageIntro>

            <PageRow>
                <div className="radio-container">
                    <label
                        className="radio-label"
                        htmlFor="HartuzCancelationForm"
                    >
                        <input
                            checked={selectedForm === 'HartuzCancelationForm'}
                            id="HartuzCancelationForm"
                            type="radio"
                            onChange={handleFormChange}
                            value="HartuzCancelationForm"
                        />
                        <p className="radio-text">
                            {
                                t.booking_cancelation_radio_reservation_number[
                                    lang
                                ]
                            }
                        </p>
                    </label>
                    <label
                        className="radio-label"
                        htmlFor="HartuzCancelationFormHourFilter"
                    >
                        <input
                            checked={
                                selectedForm ===
                                'HartuzCancelationFormHourFilter'
                            }
                            id="HartuzCancelationFormHourFilter"
                            onChange={handleFormChange}
                            type="radio"
                            value="HartuzCancelationFormHourFilter"
                        />
                        <p className="radio-text">
                            {t.booking_cancelation_radio_date[lang]}
                        </p>
                    </label>
                </div>
                {selectedForm === 'HartuzCancelationForm' && (
                    <p className="info-container">
                        {t.booking_cancelation_description[lang]}
                    </p>
                )}
                {selectedForm === 'HartuzCancelationFormHourFilter' && (
                    <p className="info-container">
                        {t.booking_cancelation_description_hour[lang]}
                    </p>
                )}
                {selectedForm === 'HartuzCancelationForm' && (
                    <HartuzCancelationForm
                        instanceID={instanceID}
                        lang={lang}
                        state={bookingFormState}
                        handleSubmit={handleSubmit}
                    />
                )}
                {selectedForm === 'HartuzCancelationFormHourFilter' && (
                    <HartuzCancelationFormHourFilter
                        instanceID={instanceID}
                        lang={lang}
                        state={bookingFormState}
                        facilities={facilities}
                        handleSubmit={handleSubmit}
                    />
                )}
            </PageRow>
        </div>
    )
}

export default HartuzCancelationContainer
