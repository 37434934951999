import React from 'react'
import PageRow from '../../atoms/PageRow/PageRow'
import AppModule from '../../components/AppModule/AppModule'
import Postal from '../../components/Postal/Postal'
import postalsTransformer from '../../transformers/postalsTransformer'
import getPageInstanceId from '../../utils/getPageInstanceId'
import paramIdIsCurrentPageId from '../../utils/paramIdIsCurrentPageId'

import './IrudizContainer.css'

const BALMASEDA_ID = '90'
function IrudizContainer({ data, match }) {
    const { irudizPostals: irudizData, pages: pageData } = data
    const { lang, instanceID: paramID } = match
    const page = pageData?.data?.[0]
    const postals =
        irudizData?.postals && postalsTransformer(irudizData.postals)
    const pageID = getPageInstanceId(page)
    const isValidData = paramIdIsCurrentPageId({
        paramId: paramID,
        pageId: pageID,
    })

    return isValidData ? (
        <PageRow className="irudizContainer">
            {postals?.length
                ? postals.map((postal) => (
                      <Postal key={postal} lang={lang} postal={postal} />
                  ))
                : null}

            {page && pageID === BALMASEDA_ID ? <AppModule lang={lang} /> : null}
        </PageRow>
    ) : null
}

export default IrudizContainer
