import React from 'react'
import Abierta from '../../../assets/svg/incidences-status/Abierta'

function IncidenceFilters({
    action,
    enProceso,
    resueltas,
    translations,
    todas,
}) {
    return (
        <div className="incidencias-filters">
            <div>
                <button
                    className={enProceso ? 'filter-select' : 'filter-noselect'}
                    onClick={action}
                    type="button"
                    value="enProceso"
                >
                    <Abierta
                        className={
                            enProceso ? 'oval-select-1' : 'oval-noselect-1'
                        }
                    />
                    {translations.KALEZAINDU.KALEZAINDU_IN_PROCESS}
                </button>
            </div>
            <div>
                <button
                    className={resueltas ? 'filter-select' : 'filter-noselect'}
                    onClick={action}
                    type="button"
                    value="resueltas"
                >
                    <Abierta
                        className={
                            resueltas ? 'oval-select-2' : 'oval-noselect-2'
                        }
                    />
                    {translations.KALEZAINDU.KALEZAINDU_DONE}
                </button>
            </div>
            <div>
                <button
                    className={todas ? 'filter-select' : 'filter-noselect'}
                    onClick={action}
                    type="button"
                    value="todas"
                >
                    <Abierta
                        className={todas ? 'oval-select-3' : 'oval-noselect-3'}
                    />
                    {translations.KALEZAINDU.KALEZAINDU_ALL_INCIDENCE}
                </button>
            </div>
        </div>
    )
}

export default IncidenceFilters
