import { useEffect } from 'react'
import { connect } from 'formik'

function ScrollToError(props) {
    const { formik } = props

    useEffect(() => {
        const { isSubmitting, isValidating, errors } = formik
        const keys = Object.keys(errors)
        if (keys.length > 0 && isSubmitting && !isValidating) {
            const selector = `[id="${keys[0]}"]`
            const errorElement = document.querySelector(selector)
            errorElement.focus()
        }
    }, [formik])

    return null
}

export default connect(ScrollToError)
