import React from 'react'
import NewsRender from '../components/NewsRender/NewsRender'
import PageRow from '../atoms/PageRow/PageRow'

function NewsContainer(props) {
    const { data, location, match, trads } = props
    const { paginas, news } = data
    const newsData = news && news.data && news.data[0] && news.data[0].noticia
    const pagina =
        (paginas && paginas.noticia) || (newsData && newsData) || null

    return pagina ? (
        <PageRow className="fix-footer">
            <NewsRender
                params={match}
                pagina={pagina}
                slug={location.pathname}
                trads={trads}
            />
        </PageRow>
    ) : null
}

export default NewsContainer
