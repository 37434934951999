import cssVars from 'css-vars-ponyfill'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import BackToTop from '../../atoms/BackToTop/BackToTop'
import BaseHelmet from '../../atoms/BaseHelmet/BaseHelmet'
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs'
import CookieBanner from '../../components/CookieBanner/CookieBanner'
import Error from '../../components/Error/Error'
import FooterPage from '../../components/Footer/Footer'
import GiltzaForm from '../../components/Giltza/GiltzaForm/GiltzaForm'
import Header from '../../components/Header/Header'
import { SUCCESS_CODE } from '../../constants'
import {
    ACTION_CONTAINER,
    AURRETIK_CONTAINER,
    CATEGORIES_CONTAINER,
    FINDER_CONTAINER,
    HARTUZ_BOOKING_CONTAINER,
    HARTUZ_CANCELATION_CONTAINER,
    HARTUZ_CONTAINER,
    HARTUZ_LOGIN_CHECK_CONTAINER,
    INCIDENCE_CONTAINER,
    INDICATORS_CONTAINER,
    IRUDIZ_CONTAINER,
    MAPS_CONTAINER,
    NEWS_CONTAINER,
    NEW_INCIDENCE_CONTAINER,
    PAGE_CONTAINER,
    PLAN_CONTAINER,
    PLAN_PROGRESS_CONTAINER,
    PLAN_SUMMARY_CONTAINER,
    USER_BOOKINGS_CONTAINER,
    USER_DATA_CONTAINER,
} from '../../constants/containersId'
import {
    mapsPageSlugs,
    userBookingsSlugs,
    userDataPageSlugs,
} from '../../routing/slugs'
import {
    setDefaultTopics as setDefaultTopicsDispatch,
    setDefaultZones as setDefaultZonesDispatch,
    setSelectedAcciones as setSelectedAccionesDispatch,
    setSelectedAurretik as setSelectedAurretikDispatch,
    setSelectedCategory as setSelectedCategoryDispatch,
    setSelectedIncidencias as setSelectedIncidenciasDispatch,
    setSelectedIndicator as setSelectedIndicatorDispatch,
    setSelectedIrudiz as setSelectedIrudizDispatch,
    setSelectedNews as setSelectedNewsDispatch,
    setSelectedPage as setSelectedPageDispatch,
    setSelectedPlansAxisActionsList as setSelectedPlansAxisActionsListDispatch,
    setSelectedPlans as setSelectedPlansDispatch,
} from '../../store/actions'
import pageDataTransfomer from '../../transformers/pageDataTransfomer'
import getCssCustomPath from '../../utils/getCssCustomPath'
import getCssVars from '../../utils/getCssVars'
import getTranslatesHome from '../../utils/getTranslatesHome'
import ActionContainer from '../ActionContainer'
import AurretikContainer from '../AurretikContainer/AurretikContainer'
import CategoriesContainer from '../CategoriesContainer'
import FinderContainer from '../FinderContainer'
import HartuzBookingContainer from '../HartuzBookingContainer/HartuzBookingContainer'
import HartuzCancelationContainer from '../HartuzCancelationContainer/HartuzCancelationContainer'
import HartuzContainer from '../HartuzContainer/HartuzContainer'
import HartuzLoginCheckContainer from '../HartuzLoginCheck/HartuzLoginCheckContainer'
import IncidenceContainer from '../IncidenceContainer'
import IndicatorsContainer from '../IndicatorsContainer'
import IrudizContainer from '../IrudizContainer/IrudizContainer'
import MapsContainer from '../MapsContainer/MapsContainer'
import NewIncidenceContainer from '../NewIncidenceContainer'
import NewsContainer from '../NewsContainer'
import PageContainer from '../PageContainer'
import PlanContainer from '../PlanContainer'
import PlanProgressContainer from '../PlanProgressContainer'
import PlanResumenContainer from '../PlanResumenContainer'
import UserBookingsContainer from '../UserBookingsContainer/UserBookingsContainer'
import UserDataContainer from '../UserDataContainer/UserDataContainer'
import './BaseContainer.css'
import getBreadcrumbsByPage from './utils/getBreadcrumbsByPage'
import getFetchDataResponse from './utils/getFetchDataResponse'
import selectData from './utils/selectData'
import selectPageDataById from './utils/selectPageDataById'

const fetchDataError = (fetchData) => fetchData.code !== SUCCESS_CODE
const getPageDomain = (location) =>
    encodeURIComponent(window.location.href.replace(location.pathname, ''))

function BaseContainer({
    containerId,
    pages,
    incidencias,
    plans,
    acciones,
    categories,
    indicators,
    news,
    zonas,
    tematicas,
    buscador,
    infrastructures,
    irudiz,
    irudizPostals,
    setSelectedPage,
    setSelectedIncidencias,
    setSelectedPlans,
    setSelectedAcciones,
    setSelectedCategory,
    setSelectedIndicator,
    setSelectedNews,
    setDefaultZones,
    setDefaultTopics,
    setSelectedPlansAxisActionsList,
    setSelectedAurretik,
    setSelectedIrudiz,
}) {
    const [collapseSearch, setCollapseSearch] = useState(true)
    const [visibleGiltzaForm, setVisibleGiltzaForm] = useState(false)
    const [macromenuCollapsed, setMacromenuCollapsed] = useState(true)
    const [toggleComponent, setToggleComponent] = useState(false)
    const [isTablet, setIsTablet] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const location = useLocation()
    const match = useParams()
    const { lang } = match

    const anchoVentana = window.innerWidth
    const contenido = document.querySelector('.pageRow')

    if (contenido) {
        const anchoContenido = contenido.offsetWidth
        const anchoMargen = (anchoVentana - anchoContenido) / 2
        const anchoMenu = anchoVentana - anchoMargen

        document.querySelector('.navbar-wrapper').style.width = `${anchoMenu}px`
    }

    const macromenuCollapsedRef = useRef(macromenuCollapsed)

    const selectTranslates = useCallback(() => getTranslatesHome(lang), [lang])
    const trads = selectTranslates()

    const data = selectData({
        acciones,
        buscador,
        categories,
        containerId,
        incidencias,
        indicators,
        infrastructures,
        irudiz,
        irudizPostals,
        match,
        news,
        pages,
        plans,
        tematicas,
        zonas,
    })

    const handleSearchBar = () => {
        setCollapseSearch(!collapseSearch)
    }

    const handleGiltzaForm = () => {
        let idInstancia  = (data.pages.data[0].idinstancia) ? data.pages?.data[0].idinstancia : 102
        window.open(
            `https://giltza.biscaytik.eu/Sender.aspx?lang=3082&urlRed=${process.env.REACT_APP_API_URL_BASE}giltza/validation_user/${idInstancia}`,
            '_parent'
        )
    }

    const handleMacromenuCollapse = () => {
        setMacromenuCollapsed(!macromenuCollapsed)
    }

    const resize = () => {
        setToggleComponent(window.innerWidth <= 1200)
        setIsTablet(window.innerWidth <= 768 && window.innerWidth > 576)
        setIsMobile(window.innerWidth <= 576)
    }

    const resizeSections = () => {
        const windowWidth = window.innerWidth
        const content = document.querySelector('.pageRow')

        if (content) {
            const anchoContenido = content.offsetWidth
            const anchoMargen = (windowWidth - anchoContenido) / 2
            const anchoMenu = windowWidth - anchoMargen

            document.querySelector('.navbar-wrapper').style.width =
                `${anchoMenu}px`
        }
    }

    const selectContainer = () => {
        switch (containerId) {
            case ACTION_CONTAINER:
                return (
                    <ActionContainer
                        data={data}
                        match={match}
                        isTablet={isTablet}
                        isMobile={isMobile}
                    />
                )
            case CATEGORIES_CONTAINER:
                return (
                    <CategoriesContainer
                        data={data}
                        match={match}
                        isTablet={isTablet}
                        isMobile={isMobile}
                    />
                )
            case INCIDENCE_CONTAINER:
                return (
                    <IncidenceContainer
                        data={data}
                        match={match}
                        isTablet={isTablet}
                        isMobile={isMobile}
                    />
                )
            case INDICATORS_CONTAINER:
                return (
                    <IndicatorsContainer
                        data={data}
                        match={match}
                        isTablet={isTablet}
                        isMobile={isMobile}
                        trads={trads}
                    />
                )
            case NEW_INCIDENCE_CONTAINER:
                return (
                    <NewIncidenceContainer
                        data={data}
                        match={match}
                        isTablet={isTablet}
                        isMobile={isMobile}
                    />
                )
            case NEWS_CONTAINER:
                return (
                    <NewsContainer
                        data={data}
                        match={match}
                        isTablet={isTablet}
                        isMobile={isMobile}
                        trads={trads}
                        location={location}
                    />
                )
            case PLAN_CONTAINER:
                return (
                    <PlanContainer
                        data={data}
                        lang={lang}
                        match={match}
                        isTablet={isTablet}
                        isMobile={isMobile}
                    />
                )
            case PLAN_PROGRESS_CONTAINER:
                return (
                    <PlanProgressContainer
                        data={data}
                        match={match}
                        isTablet={isTablet}
                        isMobile={isMobile}
                    />
                )
            case PLAN_SUMMARY_CONTAINER:
                return (
                    <PlanResumenContainer
                        data={data}
                        match={match}
                        isTablet={isTablet}
                        isMobile={isMobile}
                    />
                )
            case FINDER_CONTAINER:
                return (
                    <FinderContainer
                        data={data}
                        match={match}
                        isTablet={isTablet}
                        isMobile={isMobile}
                        searchAction={(items) =>
                            setSelectedPlansAxisActionsList(items)
                        }
                    />
                )
            case AURRETIK_CONTAINER:
                return (
                    <AurretikContainer
                        data={data}
                        match={match}
                        isTablet={isTablet}
                        isMobile={isMobile}
                    />
                )
            case IRUDIZ_CONTAINER:
                return (
                    <IrudizContainer
                        data={data}
                        match={match}
                        isTablet={isTablet}
                        isMobile={isMobile}
                    />
                )
            case MAPS_CONTAINER:
                return (
                    <MapsContainer
                        data={data}
                        match={match}
                        isTablet={isTablet}
                        isMobile={isMobile}
                    />
                )
            case HARTUZ_CONTAINER:
                return (
                    <HartuzContainer
                        data={data}
                        match={match}
                        isTablet={isTablet}
                        isMobile={isMobile}
                    />
                )
            case HARTUZ_CANCELATION_CONTAINER:
                return <HartuzCancelationContainer match={match} />
            case HARTUZ_BOOKING_CONTAINER:
                return (
                    <HartuzBookingContainer
                        data={data}
                        match={match}
                        isTablet={isTablet}
                        isMobile={isMobile}
                    />
                )
            case USER_BOOKINGS_CONTAINER:
                return (
                    <UserBookingsContainer
                        data={data}
                        match={match}
                        isMobile={isMobile}
                        isTablet={isTablet}
                    />
                )
            case HARTUZ_LOGIN_CHECK_CONTAINER:
                return <HartuzLoginCheckContainer match={match} />
            case USER_DATA_CONTAINER:
                return (
                    <UserDataContainer
                        data={data}
                        match={match}
                        isTablet={isTablet}
                        isMobile={isMobile}
                    />
                )
            case PAGE_CONTAINER:
            default:
                return (
                    <PageContainer
                        data={data}
                        match={match}
                        isTablet={isTablet}
                        isMobile={isMobile}
                    />
                )
        }
    }

    useEffect(() => {
        window.addEventListener('resize', resize)
        resize()

        const loadData = () => {
            const {
                page: pageMatch,
                slug,
                urlID,
                facilityID,
                instanceID,
            } = match
            const items = {
                lang,
                domain: getPageDomain(location),
            }

            switch (containerId) {
                case ACTION_CONTAINER:
                    items.slug = slug
                    setSelectedAcciones(items)
                    break
                case CATEGORIES_CONTAINER:
                    items.slug = urlID
                    setSelectedCategory(items)
                    break
                case INCIDENCE_CONTAINER:
                    items.slug = urlID
                    setSelectedIncidencias(items)
                    break
                case INDICATORS_CONTAINER:
                    items.slug = urlID
                    setSelectedIndicator(items)
                    break
                case NEW_INCIDENCE_CONTAINER:
                    items.slug = 1229
                    setSelectedIncidencias(items)
                    break
                case NEWS_CONTAINER:
                    items.slug = slug
                    setSelectedNews(items)
                    break
                case PLAN_CONTAINER:
                case PLAN_PROGRESS_CONTAINER:
                case PLAN_SUMMARY_CONTAINER:
                    items.slug = slug
                    setSelectedPlans(items)
                    break
                case FINDER_CONTAINER: {
                    items.domain = window.location.origin
                    items.slug = ''

                    setDefaultZones(items)
                    setDefaultTopics(items)
                    setSelectedPlans(items)
                    setSelectedPlansAxisActionsList(items)
                    break
                }
                case AURRETIK_CONTAINER: {
                    items.slug = instanceID
                    items.id = facilityID

                    const pageItems = {
                        lang,
                        domain: getPageDomain(location),
                        slug: `aurretik/${instanceID}`,
                    }
                    setSelectedPage(pageItems)
                    setSelectedAurretik(items)
                    break
                }
                case IRUDIZ_CONTAINER:
                    items.slug = 'irudiz'
                    items.id = instanceID
                    setSelectedPage(items)
                    setSelectedIrudiz(items)
                    break
                case MAPS_CONTAINER:
                    items.slug = mapsPageSlugs[lang]
                    setSelectedPage(items)
                    break
                case HARTUZ_CONTAINER:
                case HARTUZ_CANCELATION_CONTAINER:
                case HARTUZ_LOGIN_CHECK_CONTAINER:
                    items.idInstancia = instanceID
                    items.slug = 'hartuz'
                    setSelectedPage(items)
                    break
                case HARTUZ_BOOKING_CONTAINER:
                    items.idInstancia = instanceID
                    items.slug = 'hartuz'
                    items.booking = location.pathname
                    setSelectedPage(items)
                    break
                case USER_BOOKINGS_CONTAINER:
                    items.idInstancia = instanceID
                    items.slug = userBookingsSlugs[lang]
                    setSelectedPage(items)
                    break
                case USER_DATA_CONTAINER:;
                    items.slug = userDataPageSlugs[lang]
                    setSelectedPage(items)
                    break
                case PAGE_CONTAINER:
                default:
                    items.slug = pageMatch || ''
                    setSelectedPage(items)
                    break
            }
        }

        loadData()
        resizeSections()

        window.addEventListener('resize', resizeSections)
        window.addEventListener('load', resizeSections)

        return () => {
            window.removeEventListener('resize', resize)
            window.removeEventListener('resize', resizeSections)
            window.removeEventListener('load', resizeSections)
        }
    }, [match])

    useEffect(() => {
        if (!macromenuCollapsedRef.current) {
            setMacromenuCollapsed(true)
        }

        window.addEventListener('resize', resizeSections)
        window.addEventListener('load', resizeSections)
    }, [])

    const dataArray = Object.entries(data)
    let isFetching = false
    let cssCustom = ''
    let fetchData = {}
    let pageData = {}
    let breadcrumb

    dataArray.forEach((dataElement) => {
        if (dataElement[1] === undefined) {
            isFetching = true
        }
    })

    if (!isFetching) {
        fetchData = getFetchDataResponse({
            data,
            containerId,
        })

        pageData = selectPageDataById({
            data,
            containerId,
        })

        cssCustom = getCssCustomPath({ match, pageData })
        breadcrumb = getBreadcrumbsByPage({
            id: containerId,
            breadcrumb,
            lang,
            location,
            match,
            pageData,
        })
    }
    const pageTransfomed = pageData && pageDataTransfomer(pageData)
    const cookiesBanner = pageData?.cookies_banner

    return !isFetching ? (
        <div className="appBody">
            <BaseHelmet pageData={pageData} cssCustom={cssCustom} />

            {cssVars(getCssVars(document))}

            {pageTransfomed ? (
                <Header
                    match={match}
                    menu={pageTransfomed.menu}
                    isWideEnough={false}
                    handleSearchBar={handleSearchBar}
                    collapseSearch={collapseSearch}
                    handleGiltzaForm={handleGiltzaForm}
                    visibleGiltzaForm={visibleGiltzaForm}
                    logo={pageTransfomed.logo}
                    trads={trads}
                    headbackground={pageTransfomed.headbackground}
                    fromError={false}
                    macromenuCollapsed={macromenuCollapsed}
                    handleMacromenuCollapse={handleMacromenuCollapse}
                    menuItems={pageTransfomed.macromenu}
                />
            ) : (
                ''
            )}

            <main className="central-content">
                {fetchDataError(fetchData) && pageData && (
                    <Error
                        trads={trads}
                        content={pageData.error_data}
                        error={fetchData.code}
                    />
                )}

                {breadcrumb ? <Breadcrumbs linkList={breadcrumb} /> : ''}

                {!fetchDataError(fetchData) && selectContainer()}

                {!fetchDataError(fetchData) && cookiesBanner && (
                    <>
                        <BackToTop lang={lang} />
                        <CookieBanner
                            bannerMessage={cookiesBanner.texto}
                            buttonMessage={cookiesBanner.boton}
                        />
                    </>
                )}
            </main>
            {pageData?.logo ? (
                <FooterPage
                    isMobile={isMobile}
                    toggleComponent={toggleComponent}
                    footerOptions={pageData.footer}
                    lang={lang}
                    logo={pageData.logo}
                />
            ) : (
                ''
            )}
            <GiltzaForm
                isOpened={visibleGiltzaForm}
                handleModal={handleGiltzaForm}
                lang={lang}
            />
        </div>
    ) : (
        ''
    )
}

const mapStateToProps = ({
    page,
    pages,
    incidencia,
    incidencias,
    infrastructure,
    infrastructures,
    plan,
    plans,
    accion,
    acciones,
    category,
    categories,
    indicator,
    indicators,
    newsDetail,
    news,
    irudiz,
    irudizPostals,
}) => ({
    page,
    pages: pages[page.slug],
    incidencia,
    incidencias: incidencias[incidencia.slug],
    plan,
    plans: plans[plan.slug],
    accion,
    acciones: acciones[accion.slug],
    category,
    categories: categories[category.slug],
    indicator,
    indicators: indicators[indicator.slug],
    newsDetail,
    news: news[newsDetail.slug],
    zonas: categories.undefined,
    tematicas: incidencias.undefined,
    buscador: acciones.undefined,
    infrastructure,
    infrastructures: infrastructures[infrastructure.slug],
    irudiz,
    irudizPostals,
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedPage: (payload) => dispatch(setSelectedPageDispatch(payload)),
    setSelectedIncidencias: (payload) =>
        dispatch(setSelectedIncidenciasDispatch(payload)),
    setSelectedPlans: (payload) => dispatch(setSelectedPlansDispatch(payload)),
    setSelectedAcciones: (payload) =>
        dispatch(setSelectedAccionesDispatch(payload)),
    setSelectedCategory: (payload) =>
        dispatch(setSelectedCategoryDispatch(payload)),
    setSelectedIndicator: (payload) =>
        dispatch(setSelectedIndicatorDispatch(payload)),
    setSelectedNews: (payload) => dispatch(setSelectedNewsDispatch(payload)),
    setDefaultZones: (payload) => dispatch(setDefaultZonesDispatch(payload)),
    setDefaultTopics: (payload) => dispatch(setDefaultTopicsDispatch(payload)),
    setSelectedPlansAxisActionsList: (payload) =>
        dispatch(setSelectedPlansAxisActionsListDispatch(payload)),
    setSelectedAurretik: (payload) =>
        dispatch(setSelectedAurretikDispatch(payload)),
    setSelectedIrudiz: (payload) =>
        dispatch(setSelectedIrudizDispatch(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BaseContainer)
