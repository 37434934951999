import React from 'react'
import './FinderSearchInput.css'

function FinderSearchInput({ action, translations, value }) {
    return (
        <div className="finder-infobox_searchInput">
            <input
                aria-label='Search bar finder'
                className="search_bar_finder"
                type="text"
                placeholder={translations.ACCOUNTABILITY.FINDER_TEXT_INPUT}
                value={value}
                name="busqueda"
                onChange={action}
            />
        </div>
    )
}

export default FinderSearchInput
