import React from 'react'
import { Link } from 'react-router-dom'
import './FinderContentLink.css'

function FinderContentLink({ url, translations, type }) {
    return (
        <th className="enlace-content">
            <Link
                to={url}
                target="_blank"
                rel="noopener noreferrer"
                className="link-color"
            >
                {type === 'plan' && translations.ACCOUNTABILITY.FINDER_PLAN_GO}
                {type === 'action' && (
                    <em>{translations.ACCOUNTABILITY.FINDER_ACTION_GO}</em>
                )}
            </Link>
        </th>
    )
}

export default FinderContentLink
