import React from 'react'
import Ejes from '../../../assets/svg/Ejes'

function PlanDetailEjesTitle({ translations }) {
    return (
        <div className="container-ejes-title_container">
            <div className="planDetail-box_container-ejes_title">
                <Ejes />
                <p>{translations?.ACCOUNTABILITY?.PLAN_DETAIL_AXIS_TITLE}</p>
            </div>
        </div>
    )
}

export default PlanDetailEjesTitle
