import React from 'react'
import NewIncidence from '../components/NewIncidence/NewIncidence'

function NewIncidenceContainer(props) {
    const { data, isMobile, isTablet, match } = props
    let breadcrums = ''
    if (data.incidencias.data.breadcrums !== undefined) {
        breadcrums = data.incidencias.data.breadcrums[1].slug
    }
    if (data.incidencias.data.breadcrums === undefined) {
        breadcrums = data.incidencias.data[0].breadcrums[1].slug
    }
    return (
        <NewIncidence
            match={match}
            isMobile={isMobile}
            isTablet={isTablet}
            translations={
                data.incidencias.data.translations !== undefined
                    ? data.incidencias.data.translations
                    : data.incidencias.data[0].translations
            }
            link={breadcrums}
        />
    )
}

export default NewIncidenceContainer
