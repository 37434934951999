import React from 'react'
import PlanDetail from '../compositions/PlanDetail/PlanDetail'

function PlanContainer(props) {
    const { data, lang, isMobile, isTablet } = props
    const { plans } = data
    const { data: dataPlan } = plans
    const { plan } = dataPlan
    
    return plan ? (
        <PlanDetail
            actions={plan.actions}
            axis={plan.axis}
            defaultCoordenates={dataPlan.default_coordenates}
            description={plan.description}
            endDate={plan.end_date}
            files={plan.files}
            isActive={plan.is_active}
            isMobile={isMobile}
            isTablet={isTablet}
            lang={lang}
            links={plan.links}
            mapActions={plan.map_actions}
            percentage={plan.percentage}
            percentageColor={plan.trafficlight}
            presentation={plan.presentation}
            startDate={plan.start_date}
            showActionsMap={plan.show_actions_map}
            showPercentage={plan.show_plan_percentage}
            title={plan.title}
            translations={dataPlan.translations}
        />
    ) : null
}

export default PlanContainer
