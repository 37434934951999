import React from 'react'
import IncidenciaDate from '../../atoms/IncidenciaDate/IncidenciaDate'
import {
    isOpen,
    isProcessing,
    isRejected,
    isResolved,
    isTramitted,
    isUnified,
} from '../../compositions/IncidenciaDetail/IncidenciaDetail'
import IncidenciaInfoBoxStatusBar from '../../atoms/IncidenciaInfoBoxStatusBar/IncidenciaInfoBoxStatusBar'

const getLastUpdateDateByLength = (actualizaciones) =>
    actualizaciones.length > 2
        ? actualizaciones[actualizaciones.length - 1].updated_at
        : actualizaciones[1].updated_at

const getLastUpdateDate = (actualizaciones) =>
    actualizaciones[actualizaciones.length - 1].updated_at

function StateRotateMobile({ text, date }) {
    return (
        <div className="state-rotate">
            <p>{text}</p>
            <IncidenciaDate date={date} />
        </div>
    )
}
function MobileStatusContent({ state, date, texts, actualizaciones }) {
    return (
        <div className="infobox_status-proces">
            <IncidenciaInfoBoxStatusBar state={state} />

            <div className="infobox_status-bar-textsContainer">
                <div className="infobox_status-bar-texts">
                    <StateRotateMobile text={texts.open} date={date} />

                    {isProcessing(state) && (
                        <StateRotateMobile
                            text={texts.inProgress}
                            date={getLastUpdateDate(actualizaciones)}
                        />
                    )}
                    {isResolved(state) && (
                        <>
                            <StateRotateMobile
                                text={texts.inProgress}
                                date={actualizaciones[1].updated_at}
                            />
                            <StateRotateMobile
                                text={texts.resolved}
                                date={getLastUpdateDateByLength(
                                    actualizaciones
                                )}
                            />
                        </>
                    )}
                    {isTramitted(state) && (
                        <>
                            <StateRotateMobile
                                text={texts.inProgress}
                                date={actualizaciones[1].updated_at}
                            />
                            <StateRotateMobile
                                text={texts.tramitted}
                                date={getLastUpdateDateByLength(
                                    actualizaciones
                                )}
                            />
                        </>
                    )}
                    {isRejected(state) && (
                        <>
                            <div className="state-rotate" />
                            <StateRotateMobile
                                text={texts.rejected}
                                date={getLastUpdateDate(actualizaciones)}
                            />
                        </>
                    )}
                    {isUnified(state) && (
                        <>
                            <div className="state-rotate" />
                            <StateRotateMobile
                                text={texts.unified}
                                date={getLastUpdateDate(actualizaciones)}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

function StatusStateTextsDesktop({ texts, state }) {
    return (
        <div className="infobox_status-bar-textsContainer">
            <div
                className={
                    isProcessing(state)
                        ? 'infobox_status-bar-texts-proceso'
                        : 'infobox_status-bar-texts'
                }
            >
                <p>{texts.open}</p>

                {(isOpen(state) ||
                    isProcessing(state) ||
                    isResolved(state) ||
                    isTramitted(state)) && <p>{texts.inProgress}</p>}

                {(isOpen(state) ||
                    isProcessing(state) ||
                    isResolved(state)) && <p>{texts.resolved}</p>}

                {isTramitted(state) && <p>{texts.tramitted}</p>}
                {isRejected(state) && <p>{texts.rejected}</p>}
                {isUnified(state) && <p>{texts.unified}</p>}
            </div>
        </div>
    )
}
function StatusDateTextsDesktop({ actualizaciones, state, date }) {
    return (
        <div className="infobox_status-bar-textsContainer">
            <div
                className={
                    isProcessing(state)
                        ? 'infobox_status-bar-texts-proceso'
                        : 'infobox_status-bar-texts'
                }
            >
                <IncidenciaDate date={date} />
                {isProcessing(state) && (
                    <>
                        <IncidenciaDate
                            date={
                                actualizaciones.length === 0
                                    ? date
                                    : getLastUpdateDate(actualizaciones)
                            }
                        />
                        <p className="infobox_status-bar-textsContainer-date" />
                    </>
                )}
                {isResolved(state) && (
                    <>
                        <IncidenciaDate date={actualizaciones[1].updated_at} />
                        <IncidenciaDate
                            date={getLastUpdateDateByLength(actualizaciones)}
                        />
                    </>
                )}
                {isTramitted(state) && (
                    <>
                        <IncidenciaDate date={actualizaciones[1].updated_at} />
                        <IncidenciaDate
                            date={getLastUpdateDateByLength(actualizaciones)}
                        />
                    </>
                )}
                {isRejected(state) && (
                    <IncidenciaDate date={getLastUpdateDate(actualizaciones)} />
                )}
                {isUnified(state) && actualizaciones === true ? (
                    <IncidenciaDate date={getLastUpdateDate(actualizaciones)} />
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}

function DesktopStatusContent({ state, date, texts, actualizaciones }) {
    return (
        <div className="infobox_status-proces">
            <StatusStateTextsDesktop state={state} texts={texts} />

            <IncidenciaInfoBoxStatusBar state={state} />

            <StatusDateTextsDesktop
                date={date}
                state={state}
                actualizaciones={actualizaciones}
            />
        </div>
    )
}

function IncidenciaInfoBoxStatusProcess({
    texts,
    state,
    isMobile,
    date,
    actualizaciones,
}) {
    return !isMobile ? (
        <DesktopStatusContent
            state={state}
            date={date}
            actualizaciones={actualizaciones}
            texts={texts}
        />
    ) : (
        <MobileStatusContent
            state={state}
            date={date}
            actualizaciones={actualizaciones}
            texts={texts}
        />
    )
}

export default IncidenciaInfoBoxStatusProcess
