export const ACTION_CONTAINER = 'actionContainer'
export const AURRETIK_CONTAINER = 'aurretikContainer'
export const CATEGORIES_CONTAINER = 'categoriesContainer'
export const FINDER_CONTAINER = 'finderContainer'
export const HARTUZ_CANCELATION_CONTAINER = 'hartuzCancelationContainer'
export const HARTUZ_LOGIN_CHECK_CONTAINER = 'hartuzLoginCheckContainer'
export const HARTUZ_CONTAINER = 'hartuzContainer'
export const HARTUZ_BOOKING_CONTAINER = 'hartuzBookingContainer'
export const INCIDENCE_CONTAINER = 'incidenceContainer'
export const INDICATORS_CONTAINER = 'indicatorsContainer'
export const IRUDIZ_CONTAINER = 'irudizContainer'
export const MAPS_CONTAINER = 'mapsContainer'
export const NEWS_CONTAINER = 'newsContainer'
export const NEW_INCIDENCE_CONTAINER = 'newIncidenceContainer'
export const PAGE_CONTAINER = 'pageContainer'
export const PLAN_CONTAINER = 'planContainer'
export const PLAN_PROGRESS_CONTAINER = 'planProgressContainer'
export const PLAN_SUMMARY_CONTAINER = 'planResumenContainer'
export const USER_BOOKINGS_CONTAINER = 'userBookingsContainer'
export const USER_DATA_CONTAINER = 'userDataContainer'
