import React from 'react'

/* eslint-disable max-len */
export default function Whatsapp({ className }) {
    return (
        <svg className={className} viewBox="0 0 20 20">
            <g id="Symbols">
                <g
                    id="Icon-/-Solid-/-Whatsapp"
                    transform="translate(-2.000000, -2.000000)"
                >
                    <path
                        d="M12.0025,2 C17.51625,2 22,6.48625 22,12 C22,17.51375 17.51625,22 12.0025,22 C9.96875,22 8.0825,21.395 6.50125,20.3475 L2.6575,21.57625 L3.90375,17.86125 C2.705,16.215 2,14.1875 2,12 C2,6.485 6.48375,2 11.9975,2 L12.0025,2 Z M17.7855099,16.5255246 C18.0299595,15.8445955 18.0299595,15.2646834 17.9590311,15.1412182 C17.8868361,15.0177531 17.6905165,14.9479142 17.3928707,14.8007537 C17.0952248,14.6548403 15.6475259,13.9502159 15.3739451,13.8566817 C15.1054305,13.7569118 14.8495817,13.7918313 14.6469292,14.073681 C14.3619491,14.4665247 14.0820353,14.8656041 13.8553178,15.1062988 C13.6767303,15.2933672 13.3854173,15.3170626 13.1409677,15.2172927 C12.812924,15.0826035 11.8959214,14.7658342 10.7648671,13.7743715 C9.88839499,13.0061438 9.29310325,12.0496006 9.12084862,11.7627623 C8.94859398,11.4696884 9.10311652,11.3000797 9.23990696,11.1416951 C9.38936319,10.9596152 9.53121994,10.8299144 9.68067617,10.6603057 C9.83013239,10.490697 9.91372655,10.4021512 10.0087199,10.2026116 C10.1100462,10.0093075 10.0378512,9.80976787 9.96565626,9.66260737 C9.89472788,9.51544687 9.29943614,8.08374979 9.05498655,7.50383765 C8.85866693,7.04115506 8.70921071,7.02369533 8.41156484,7.01122411 C8.31023859,7.00623561 8.19751313,7 8.07212189,7 C7.68454897,7 7.28051053,7.11224106 7.03606094,7.35792427 C6.73841507,7.65723376 6,8.35437546 6,9.78607254 C6,11.2177696 7.06012593,12.602076 7.20324926,12.7953801 C7.35270549,12.9886841 9.27030484,15.9680607 12.2480301,17.1827584 C14.5760008,18.133066 15.2675525,18.0445203 15.7969822,17.9335264 C16.5708614,17.7689062 17.5410603,17.2052066 17.7855099,16.5255246 Z"
                        id="Combined-Shape"
                    />
                </g>
            </g>
        </svg>
    )
}
