export const validateDNI = (() => {
  const DNI_REGEX = /^(\d{8})([A-Z])$/;
  const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
  const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

  const validate = (str) => {
    const strTransformed = str.toUpperCase().replace(/\s/, '');

    let valid = false;


    const spainIdType = (strDoc) => {
      if (strDoc.match(DNI_REGEX)) {
        return 'dni';
      }
      if (strDoc.match(CIF_REGEX)) {
        return 'cif';
      }
      if (strDoc.match(NIE_REGEX)) {
        return 'nie';
      }

      return ''
    };

    const validDNI = (dni) => {

      const dniLetters = "TRWAGMYFPDXBNJZSQVHLCKE";
      const letter = dniLetters.charAt(parseInt(dni, 10) % 23);

      return letter === dni.charAt(8);
    };

    const validNIE = (nie) => {

      // Change the initial letter for the corresponding number and validate as DNI
      let niePrefix = nie.charAt(0);

      switch (niePrefix) {
        case 'X': niePrefix = 0; break;
        case 'Y': niePrefix = 1; break;
        case 'Z': niePrefix = 2; break;
        default: break;
      }

      return validDNI(niePrefix + nie.substr(1));

    };

    const validCIF = (cif) => {

      const match = cif.match(CIF_REGEX);
      const letter = match[1];
      const number = match[2];
      const control = match[3];

      let evenSum = 0;
      let oddSum = 0;
      let n;

      for (let i = 0; i < number.length; i += 1) {
        n = parseInt(number[i], 10);

        if (i % 2 === 0) {

          n *= 2;

          oddSum += n < 10 ? n : n - 9;

        } else {
          evenSum += n;
        }

      }

      const controlDigit = (10 - (evenSum + oddSum).toString().slice(-1));
      const controlLetter = 'JABCDEFGHI'.slice(controlDigit, controlDigit + 1);

      if (letter.match(/[ABEH]/)) {
        return control === controlDigit;

      }
      if (letter.match(/[KPQS]/)) {
        return control === controlLetter;

      }

      return control === controlDigit || control === controlLetter;
    };

    const type = spainIdType(strTransformed);

    switch (type) {
      case 'dni':
        valid = validDNI(strTransformed);
        break;
      case 'nie':
        valid = validNIE(strTransformed);
        break;
      case 'cif':
        valid = validCIF(strTransformed);
        break;
      default:
        break;
    }

    return { type, valid };

  };

  return validate;
})();

export default validateDNI