export const mapsPageSlugs = {
    es: 'mapas',
    eu: 'mapak',
}

export const userBookingsSlugs = {
    es: 'reservas',
    eu: 'erreserbak',
}

export const userDataPageSlugs = {
    es: 'preferencias-usuario',
    eu: 'erabiltzailearen-lehentasunak',
}

const slugs = {
    mapsPageSlugs,
    userBookingsSlugs,
    userDataPageSlugs,
}

export default slugs
