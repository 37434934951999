import { MDBCol } from 'mdb-react-ui-kit'
import PropTypes from 'prop-types'
import React from 'react'
import createPlainHTMLClassName from '../../utils/createPlainHTMLClassName'
import PageTitle from '../../atoms/PageTitle/PageTitle'
import './WysiwygFunctionality.css'

function WysiwygFunctionality({ content, customClass }) {
    return (
        <MDBCol size="12" className={createPlainHTMLClassName(customClass)}>
            <PageTitle
                text={content.titulo}
                className="wysiwyg-functionality__title"
            />
            <p
                className="wysiwyg-functionality__subtitle"
                dangerouslySetInnerHTML={{ __html: content.descripcion }}
            />
        </MDBCol>
    )
}

WysiwygFunctionality.propTypes = {
    content: PropTypes.shape({
        titulo: PropTypes.string.isRequired,
        descripcion: PropTypes.string.isRequired,
    }).isRequired,
    customClass: PropTypes.string,
}

WysiwygFunctionality.defaultProps = {
    customClass: '',
}

export default WysiwygFunctionality
