import React from 'react'

export default function Time() {
    return (
        <svg
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20 13H14V6"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="13.5"
                cy="13.5"
                r="12.5"
                transform="rotate(-180 13.5 13.5)"
                stroke="currentColor"
                strokeWidth="2"
            />
        </svg>
    )
}
