import React from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import './PlanResumen.css'
import PageRow from '../PageRow/PageRow'

function ActionsIcon() {
    return (
        <img
            src="http://www.udalak-zabaltzen.back/img/icons/acciones.svg"
            alt="iconoejes"
        />
    )
}

function EjesIcon() {
    return (
        <img
            src="http://www.udalak-zabaltzen.back/img/icons/eje.svg"
            alt="iconoejes"
        />
    )
}

export default function PlanProgress({ plan }) {
    const showAccionesEje = (e) => {
        const divContenedorAcciones =
            e.target.closest('.ejesAcciones').childNodes[1]

        if (
            divContenedorAcciones.style.display === 'none' ||
            divContenedorAcciones.style.display === ''
        ) {
            divContenedorAcciones.style.display = 'block'
            const icono = e.target
                .closest('.ejesAcciones')
                .childNodes[0].childNodes[2].getElementsByTagName('a')[0]
            icono.classList.remove('fa-angle-down')
            icono.classList.add('fa-angle-up')
        } else {
            divContenedorAcciones.style.display = 'none'
            const icono = e.target
                .closest('.ejesAcciones')
                .childNodes[0].childNodes[2].getElementsByTagName('a')[0]
            icono.classList.remove('fa-angle-up')
            icono.classList.add('fa-angle-down')
        }
    }

    const {
        axis: ejes,
        actions: accionesNivel2,
        percentage: valorGlobalPlan,
        slug,
        title
    } = plan.plan

    return (
        <PageRow className="planProgress">
            <div className="planProgress-box-title">
                <a href={slug}>
                    <h1 className="tituloPrincipalResumen">{title}</h1>
                </a>
                <div className="planProgress-box-title_chart-progress">
                    <div className="chart-title">
                        <CircularProgressbar
                            value={valorGlobalPlan}
                            text={`${valorGlobalPlan}%`}
                            styles={{
                                path: {
                                    stroke: getComputedStyle(
                                        document.documentElement
                                    ).getPropertyValue('--color-main'),
                                    strokeWidth: '5px',
                                    strokeLinecap: 'round',
                                },
                                trail: {
                                    stroke: '#f1f2f4',
                                    strokeWidth: '3px',
                                },
                                text: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    stroke: '0px',
                                    strokeWidth: '0px',
                                    fill: getComputedStyle(
                                        document.documentElement
                                    ).getPropertyValue('--color-main'),
                                    fontFamily: getComputedStyle(
                                        document.documentElement
                                    ).getPropertyValue('--font-main'),
                                    fontWeight: getComputedStyle(
                                        document.documentElement
                                    ).getPropertyValue('--font-weight-medium'),
                                    fontSize: '20px',
                                    dominantBaseline: 'middle',
                                    textAnchor: 'middle',
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="separador-ejes" />

            <div className="planList-box">
                <div className="planList-box_container">
                    <div className="planList-box_container_title">
                        <EjesIcon />
                        <p className="tituloEjes">Ejes</p>
                    </div>

                    {ejes &&
                        ejes.length &&
                        ejes.map((item, i) => (
                            <div key={i} className="ejesAcciones">
                                <div className="plansGrouped_plan_Resumen">
                                    <p className="plansGrouped_Eje_titulo">
                                        {item.title}
                                    </p>
                                    <CircularProgressbar
                                        className="CircularProgressbar-textoAcciones CircularProgressbar-trail CircularProgressbar-path"
                                        value={item.percentage}
                                        text={
                                            item.presentation === 1
                                                ? ``
                                                : `${item.percentage}%`
                                        }
                                        styles={buildStyles({
                                            pathColor: item.axistrafficlight,
                                        })}
                                    />

                                    <div className="alt_tab_icons">
                                        <a
                                            className="botonAccionesDeEje fa fa-angle-down"
                                            onClick={showAccionesEje}
                                        >
                                            <span clasName="planList-hidden-content" />
                                        </a>
                                    </div>
                                </div>

                                <div className="contenedorAccionesDeEje">
                                    <div className="planList-box_container_title">
                                        <ActionsIcon />
                                        <p className="tituloEjes">Acciones</p>
                                    </div>
                                    <div>
                                        {item.actions.map((item, i) => (
                                            <div
                                                key={i}
                                                className="accionesDeEje"
                                                data-index={i}
                                            >
                                                <div className="plansGrouped_plan_Resumen_actions">
                                                    <p className="plansGrouped_title">
                                                        {item.title}
                                                    </p>

                                                    {item.presentation !==
                                                        0 && (
                                                        <div className="chart">
                                                            <CircularProgressbar
                                                                className="CircularProgressbar-text CircularProgressbar-trail CircularProgressbar-path "
                                                                value={
                                                                    item.percentage
                                                                }
                                                                text={
                                                                    item.presentation ===
                                                                    1
                                                                        ? ``
                                                                        : `${item.percentage}%`
                                                                }
                                                                styles={buildStyles(
                                                                    {
                                                                        pathColor:
                                                                            item.plantrafficlight,
                                                                    }
                                                                )}
                                                            />
                                                            {/* {item.presentation ===
                                                                2 && (
                                                                <PlanDoneText
                                                                    translations={
                                                                        translations
                                                                    }
                                                                />
                                                            )} */}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="separador-ejes" />
                            </div>
                        ))}
                </div>
            </div>

            <div className="planList-box">
                <div className="planList-box_container">
                    <div className="planList-box_container_title">
                        <ActionsIcon />
                        <p className="tituloAcciones">Acciones</p>
                    </div>

                    {accionesNivel2 &&
                        accionesNivel2.length &&
                        accionesNivel2.map((item, i) => (
                            <div key={i} className="plansGrouped_plan_Resumen">
                                <p className="plansGrouped_titleAcciones">
                                    {item.title}
                                </p>

                                {item.presentation !== 0 && (
                                    <div className="chart">
                                        <CircularProgressbar
                                            className="CircularProgressbar-text CircularProgressbar-trail CircularProgressbar-path "
                                            value={item.percentage}
                                            text={
                                                item.presentation === 1
                                                    ? ``
                                                    : `${item.percentage}%`
                                            }
                                            styles={buildStyles({
                                                pathColor:
                                                    item.axistrafficlight,
                                            })}
                                        />
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
            </div>

            <div className="actions-content" />
        </PageRow>
    )
}
