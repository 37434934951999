import { MDBCol } from 'mdb-react-ui-kit'
import PropTypes from 'prop-types'
import React from 'react'
import Slider from 'react-animated-slider'
import 'react-animated-slider/build/horizontal.css'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ArrowSlider from '../../assets/svg/ArrowSlider'
import getImagesRoute from '../../utils/getImagesRoute'
import './NewsSlider.css'
import './slider-animations.css'

class NewsSlider extends React.Component {
    componentDidMount() {
        const { data } = this.props
        let item
        const arrowleft = document.getElementsByClassName('leftButton')

        for (item of arrowleft) {
            item.title = data.data[0].translations.PREVIOUS_ELEMENT
        }

        const arrowRight = document.getElementsByClassName('rightButton')

        for (item of arrowRight) {
            item.title = data.data[0].translations.NEXT_ELEMENT
        }
    }

    render() {
        const { content, data } = this.props
        const imagesRoute = getImagesRoute()

        return (
            <div>
                <Slider
                    className="news-slider-top news-slider"
                    classNames={{
                        previousButton: 'leftButton',
                        nextButton: 'rightButton',
                    }}
                    titles={{
                        previousButton: 'prueba',
                        nextButton: 'froga',
                    }}
                    duration={800}
                >
                    {content.map((item, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div className="wrp-sld" key={index}>
                            <Link
                                className="link-slider"
                                to={item.slug}
                                title={data.data[0].translations.OPEN_THIS_NEWS}
                            />
                            <div className="news-over-slider row">
                                <MDBCol size="2" lg="1" className="news-slider-date">
                                    <span className="date-text">
                                        {item.diames}
                                    </span>
                                    <span className="year-text">
                                        {item.ano}
                                    </span>
                                </MDBCol>
                                <div className="news-slider-left-triangle-shape" />
                                <MDBCol size="10" className="news-slider-title">
                                    <span>{item.titulo}</span>
                                </MDBCol>
                                <MDBCol className="arrows-container">
                                    <MDBCol size="6" className="arrow-left">
                                        <ArrowSlider
                                            className="arrow-slider__left"
                                            title={
                                                data.data[0].translations
                                                    .PREVIOUS_ELEMENT
                                            }
                                        />
                                    </MDBCol>
                                    <MDBCol size="6" className="arrow-right">
                                        <ArrowSlider
                                            className="arrow-slider__right"
                                            title={
                                                data.data[0].translations
                                                    .NEXT_ELEMENT
                                            }
                                        />
                                    </MDBCol>
                                </MDBCol>
                            </div>
                            <div className="news-slider-content">
                                <img
                                    src={`${imagesRoute}${item.imagen}`}
                                    alt="slide"
                                />
                                <div className="news-inner">
                                    {item.button !== undefined ? (
                                        <button
                                            type="button"
                                            className="news-slider-button"
                                        >
                                            {item.button}
                                        </button>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        )
    }
}

NewsSlider.propTypes = {
    content: PropTypes.arrayOf(
        PropTypes.shape({
            ano: PropTypes.string.isRequired,
            contenido: PropTypes.string.isRequired,
            diames: PropTypes.string.isRequired,
            entradilla: PropTypes.string.isRequired,
            fecha: PropTypes.string.isRequired,
            imagen: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            titulo: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
}

const mapStateToProps = ({ page, pages }) => ({
    page,
    data: pages[page.slug],
})

export default connect(mapStateToProps, null)(NewsSlider)
