import 'airbnb-js-shims'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'core-js/stable'
import "font-awesome-4/css/font-awesome.min.css"; 
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import React from 'react'
import { Cookies, CookiesProvider } from 'react-cookie-banner'
import { createRoot } from 'react-dom/client'
import ReactPiwik from 'react-piwik'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import 'regenerator-runtime/runtime'
import App from './App'
import './index.css'
import registerServiceWorker from './registerServiceWorker'
import store from './store'

const cookies = new Cookies(document.cookie)

const piwik = new ReactPiwik({
    url: 'https://estatistikak.biscaytik.eu',
    siteId: 130101010101010,
    trackErrors: true,
})

const rootComponent = (
    <Provider store={store}>
        <CookiesProvider cookies={cookies}>
            <Router>
                <App />
            </Router>
        </CookiesProvider>
    </Provider>
)

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)
root.render(rootComponent)

registerServiceWorker()
