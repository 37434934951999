import { MDBCol } from 'mdb-react-ui-kit'
import PropTypes from 'prop-types'
import React from 'react'
import Slider from 'react-animated-slider'
import 'react-animated-slider/build/horizontal.css'
import ExternalLink from '../../atoms/ExternalLink/ExternalLink'
import getImagesRoute from '../../utils/getImagesRoute'
import './ImageSlider.css'
import './slider-animations.css'

function ImageSlider({ content, customClass }) {
    return (
        <div className={`slider_images ${customClass}`}>
            <Slider className="slider-bot" autoplay={3000} duration={1500}>
                {content.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="wrp-sld-img" key={index}>
                        <div className="over-slider row">
                            <MDBCol size="9" className="title-slider">
                                <span>{item.titulo}</span>
                            </MDBCol>
                            {item.boton !== '' ? (
                                <MDBCol size="3" className="button-container">
                                    <button type="button">
                                        <ExternalLink url={item.enlace}>
                                            {item.boton}
                                        </ExternalLink>
                                    </button>
                                </MDBCol>
                            ) : null}
                        </div>
                        <div className="slider-content row">
                            <img
                                src={`${getImagesRoute()}${item.imagen}`}
                                alt="slide"
                            />
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    )
}

ImageSlider.propTypes = {
    content: PropTypes.arrayOf(
        PropTypes.shape({
            titulo: PropTypes.string.isRequired,
            imagen: PropTypes.string.isRequired,
            enlace: PropTypes.string.isRequired,
            boton: PropTypes.string.isRequired,
        })
    ).isRequired,
    customClass: PropTypes.string,
}

ImageSlider.defaultProps = {
    customClass: '',
}

export default ImageSlider
