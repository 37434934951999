import { MDBPopoverBody } from 'mdb-react-ui-kit'
import React from 'react'
import LanguageButton from '../LanguageButton/LanguageButton'

function HeaderLangButtons({ trads }) {
    const langButtons = [
        {
            link: '/es',
            title: trads.castellano,
            text: 'ES',
        },
        {
            link: '/eu',
            title: 'Euskera',
            text: 'EU',
        },
    ]

    return (
        <MDBPopoverBody tag="button">
            {langButtons.map((item, i) => (
                <span key={i}>
                    <LanguageButton
                        link={item.link}
                        title={item.title}
                        text={item.text}
                    />
                    {i < item.length - 1 && (
                        <hr className="horizontalSeparator" />
                    )}
                </span>
            ))}
        </MDBPopoverBody>
    )
}

export default HeaderLangButtons
