import React from 'react'

export default function Clasificaciones() {
    return (
        <svg viewBox="0 0 45 26">
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Rendicion_accion_desktop"
                    transform="translate(-308.000000, -2157.000000)"
                >
                    <g
                        id="Group-5"
                        transform="translate(309.000000, 2158.000000)"
                    >
                        <g id="Group-9">
                            <circle
                                id="Oval-clasificaciones"
                                fill="#1C3561"
                                cx="5.95019531"
                                cy="8.97045898"
                                r="1"
                            />
                            <polygon
                                id="Path-30-clasificaciones"
                                stroke="#1C3561"
                                strokeWidth="1.5"
                                strokeLinejoin="round"
                                points="-8.70414851e-13 8.98138428 6.95349121 17.9627686 34.9830322 17.9627686 34.9830322 1.72101222e-12 6.95349121 1.72101222e-12"
                            />
                            <polyline
                                id="Path-30-Copy-3-clasificaciones"
                                stroke="#1C3561"
                                strokeWidth="1.5"
                                strokeLinejoin="round"
                                points="10.4169922 17.9627686 14.9036865 23.9332275 42.9332275 23.9332275 42.9332275 5.97045898 34.9830322 5.97045898"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
