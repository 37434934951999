import React from 'react'

export default function InfoEco({ className }) {
    return (
        <svg className={className} viewBox="0 0 42 36">
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fillRule="evenodd"
            >
                <g
                    id="Transparencia_desktop"
                    transform="translate(-611.000000, -757.000000)"
                    fillRule="nonzero"
                >
                    <g
                        id="Group-5"
                        transform="translate(611.000000, 757.000000)"
                    >
                        <path
                            d="M1.71428571,30.8571429 L1.71428571,34.2857143 L5.14285714,34.2857143 L5.14285714,30.8571429 L1.71428571,30.8571429 Z M1.71428571,29.1428571 L5.14285714,29.1428571 C6.089631,29.1428571 6.85714286,29.910369 6.85714286,30.8571429 L6.85714286,34.2857143 C6.85714286,35.2324881 6.089631,36 5.14285714,36 L1.71428571,36 C0.767511857,36 0,35.2324881 0,34.2857143 L0,30.8571429 C0,29.910369 0.767511857,29.1428571 1.71428571,29.1428571 Z"
                            id="Rectangle-Copy-22"
                        />
                        <path
                            d="M10.2857143,24 L10.2857143,34.2857143 L13.7142857,34.2857143 L13.7142857,24 L10.2857143,24 Z M10.2857143,22.2857143 L13.7142857,22.2857143 C14.6610596,22.2857143 15.4285714,23.0532261 15.4285714,24 L15.4285714,34.2857143 C15.4285714,35.2324881 14.6610596,36 13.7142857,36 L10.2857143,36 C9.33894043,36 8.57142857,35.2324881 8.57142857,34.2857143 L8.57142857,24 C8.57142857,23.0532261 9.33894043,22.2857143 10.2857143,22.2857143 Z"
                            id="Rectangle-Copy-23"
                        />
                        <path
                            d="M18.8571429,22.2857143 L18.8571429,34.2857143 L22.2857143,34.2857143 L22.2857143,22.2857143 L18.8571429,22.2857143 Z M18.8571429,20.5714286 L22.2857143,20.5714286 C23.2324881,20.5714286 24,21.3389404 24,22.2857143 L24,34.2857143 C24,35.2324881 23.2324881,36 22.2857143,36 L18.8571429,36 C17.910369,36 17.1428571,35.2324881 17.1428571,34.2857143 L17.1428571,22.2857143 C17.1428571,21.3389404 17.910369,20.5714286 18.8571429,20.5714286 Z"
                            id="Rectangle-Copy-24"
                        />
                        <path
                            d="M27.4285714,17.1428571 L27.4285714,34.2857143 L30.8571429,34.2857143 L30.8571429,17.1428571 L27.4285714,17.1428571 Z M27.4285714,15.4285714 L30.8571429,15.4285714 C31.8039167,15.4285714 32.5714286,16.1960833 32.5714286,17.1428571 L32.5714286,34.2857143 C32.5714286,35.2324881 31.8039167,36 30.8571429,36 L27.4285714,36 C26.4817976,36 25.7142857,35.2324881 25.7142857,34.2857143 L25.7142857,17.1428571 C25.7142857,16.1960833 26.4817976,15.4285714 27.4285714,15.4285714 Z"
                            id="Rectangle-Copy-29"
                        />
                        <path
                            d="M36,10.2857143 L36,34.2857143 L39.4285714,34.2857143 L39.4285714,10.2857143 L36,10.2857143 Z M36,8.57142857 L39.4285714,8.57142857 C40.3753453,8.57142857 41.1428571,9.33894043 41.1428571,10.2857143 L41.1428571,34.2857143 C41.1428571,35.2324881 40.3753453,36 39.4285714,36 L36,36 C35.0532261,36 34.2857143,35.2324881 34.2857143,34.2857143 L34.2857143,10.2857143 C34.2857143,9.33894043 35.0532261,8.57142857 36,8.57142857 Z"
                            id="Rectangle-Copy-30"
                        />
                        <path
                            d="M40.4147115,4.03136242 C40.8880984,4.03136242 41.2718543,4.41511835 41.2718543,4.88850528 C41.2718543,5.3618922 40.8880984,5.74564813 40.4147115,5.74564813 L35,5.74564813 L36.4177165,0.628507151 C36.5441084,0.17230514 37.016394,-0.0950587912 37.472596,0.0313330967 C37.9287981,0.157724985 38.196162,0.630010655 38.0697701,1.08621267 L37.2538091,4.03136242 L40.4147115,4.03136242 Z"
                            id="Path-7-Copy-12"
                            transform="translate(38.135927, 2.872824) rotate(-180.000000) translate(-38.135927, -2.872824) "
                        />
                        <path
                            d="M1.56090508,24.2082006 C1.17402937,24.4810053 0.639252786,24.3885324 0.366448163,24.0016567 C0.0936435391,23.6147809 0.186116432,23.0800044 0.572992151,22.8071997 L25.1859425,5.45142797 L27.7600459,9.15356663 L39.1298293,0.907835166 C39.516705,0.635030542 40.0514816,0.727503435 40.3242862,1.11437915 C40.5970908,1.50125487 40.5046179,2.03603145 40.1177422,2.30883607 L27.3376711,11.5490291 L24.7635677,7.84689044 L1.56090508,24.2082006 Z"
                            id="Path-8-Copy-2"
                            transform="translate(20.345367, 12.558018) rotate(-1.000000) translate(-20.345367, -12.558018) "
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
