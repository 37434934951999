import React, { useEffect, useRef } from 'react'
import { CookieBannerUniversal } from 'react-cookie-banner'
import './CookieBanner.css'

const COOKIES_ACCEPTED = 'user-has-accepted-cookies'

function CookieBanner({ buttonMessage, bannerMessage }) {
    const bannerRef = useRef(null)

    useEffect(() => {
        if (bannerRef.current) {
            const bannerElement =
                bannerRef.current.querySelector('.cookie-banner')
            if (bannerElement) {
                bannerElement.focus()
            }
        }
    }, [])

    const handleOnAccept = () => {
        // window.scrollTo({ top: 0, behavior: 'smooth' })
        document.getElementById('searchButton').focus()
    }

    return (
        <div ref={bannerRef}>
            <CookieBannerUniversal
                buttonMessage={buttonMessage}
                className="cookie-banner"
                cookie={COOKIES_ACCEPTED}
                dismissOnScroll={false}
                disableStyle
                message={bannerMessage}
                onAccept={handleOnAccept}
                tabIndex={0}
            />
        </div>
    )
}

export default CookieBanner
