/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import Face from '../../assets/svg/Face'
import PageRow from '../../atoms/PageRow/PageRow'
import './Error.css'

function Error({ trads, content, error }) {
    const backgroundErrorImage = {
        background: `url('/img/${error}_background.png') no-repeat center center`,
        backgroundSize: 'contain',
        height: '550px',
        width: '100%',
        position: 'relative',
        zIndex: '10',
    }

    return (
        <PageRow className="cont">
            <div className="error_container" style={backgroundErrorImage}>
                <div className="error_overlay">
                    <div className="error_content">
                        <div className="error_text">
                            <div>
                                <Face className="face_icon" />
                            </div>
                            <div>
                                <p className="error_title">
                                    {content?.error_titulo
                                        ? content.error_titulo
                                        : null}
                                </p>
                                <p className="error_subtitle">
                                    {content?.error_subtitulo
                                        ? content.error_subtitulo
                                        : null}
                                </p>
                            </div>
                        </div>
                        <div className="error_list">
                            <p className="error_title">
                                {content?.error_list_titulo
                                    ? content.error_list_titulo
                                    : null}
                            </p>
                            <ul>
                                {content?.error_links
                                    ? content.error_links.map(
                                          (item, index) => (
                                              <li key={index}>
                                                  <Link
                                                      className="error_links"
                                                      to={`/${trads.lang}/${item.slug}`}
                                                  >
                                                      {item.titulo}
                                                  </Link>
                                              </li>
                                          ),
                                          this
                                      )
                                    : null}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </PageRow>
    )
}

Error.propTypes = {
    trads: PropTypes.shape({
        lang: PropTypes.string.isRequired,
        buscar: PropTypes.string.isRequired,
        castellano: PropTypes.string.isRequired,
        idioma: PropTypes.string.isRequired,
    }).isRequired,
    content: PropTypes.shape({
        error_titulo: PropTypes.string.isRequired,
        error_subtitulo: PropTypes.string.isRequired,
        error_list_titulo: PropTypes.string.isRequired,
        error_links: PropTypes.arrayOf(
            PropTypes.shape({
                titulo: PropTypes.string.isRequired,
                slug: PropTypes.string.isRequired,
            })
        ).isRequired,
    }).isRequired,
}

export default Error
