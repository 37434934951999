import React from 'react'
import LinkIcon from '../../assets/svg/LinkIcon'
import Paper from '../../assets/svg/Paper'
import ExternalLink from '../ExternalLink/ExternalLink'

function DownloadFile({ file, icon, className }) {
    return (
        <div className={className}>
            {icon === 'link' ? <LinkIcon /> : <Paper />}
            <ExternalLink url={file.url}>{file.text}</ExternalLink>
        </div>
    )
}

export default DownloadFile
