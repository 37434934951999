import React from 'react'
import t from '../../../i18n/translations/translations'
import { mapCustomStyles } from '../MapDetailStyles'

function AddressInfo({ address, lang }) {
    return (
        <p>
            <strong>{t.address[lang]}</strong>: {address.street[lang]}.{' '}
            {address.locality[lang] ? (
                <span style={mapCustomStyles.locality}>
                    {address.locality[lang]}.{' '}
                </span>
            ) : null}{' '}
            <span>
                {t.postal_code[lang]}: {address.postalCode}.{' '}
            </span>
        </p>
    )
}

function MapInfoWindowContent({ selectedPlace, lang }) {
    return (
        <div
            className="mapInfoWindowContent"
            style={mapCustomStyles.infoWindowContent}
        >
            {selectedPlace.name ? (
                <h3 style={mapCustomStyles.heading}>
                    {' '}
                    {selectedPlace.name[lang]}
                </h3>
            ) : null}
            {selectedPlace.description ? (
                <p>
                    {' '}
                    <strong>{t.map_resource_description[lang]}:</strong>{' '}
                    {selectedPlace.description[lang]}
                </p>
            ) : null}

            {selectedPlace.contact.phone ? (
                <p>
                    <strong> {t.phone[lang]}</strong>:{' '}
                    <a
                        style={mapCustomStyles.link}
                        href={`tel:${selectedPlace.contact.phone}`}
                    >
                        {selectedPlace.contact.phone}.
                    </a>
                </p>
            ) : null}
            {selectedPlace.contact.email ? (
                <p>
                    {' '}
                    <strong> {t.email[lang]}</strong>:{' '}
                    <a
                        style={mapCustomStyles.link}
                        href={`mailto: ${selectedPlace.contact.email}`}
                    >
                        {selectedPlace.contact.email}.
                    </a>
                </p>
            ) : null}
            {selectedPlace.contact.web ? (
                <p>
                    {' '}
                    <strong>{t.web[lang]}</strong>:{' '}
                    <a
                        style={mapCustomStyles.link}
                        href={selectedPlace.contact.web}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {selectedPlace.contact.web}.
                    </a>
                </p>
            ) : null}
            {selectedPlace.address.street[lang] ? (
                <AddressInfo address={selectedPlace.address} lang={lang} />
            ) : null}
        </div>
    )
}

export default MapInfoWindowContent
