import React from 'react'
import FullScreenError from '../components/FullScreenError/FullScreenError'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { error: null }
    }

    componentDidCatch(error) {
        this.setState({ error })
    }

    render() {
        const { error } = this.state
        const { children } = this.props
        const lang = window.location.href.slice(1, 3)

        const errorTitulo =
            lang === '/es' ? 'Algo ha ido mal' : 'Zerbait gaizki joan da'
        const errorSubtitulo =
            lang === '/es'
                ? 'Inténtelo de nuevo en unos minutos'
                : 'Saiatu berriro minutu batzuk barru'

        const content = {
            error_titulo: errorTitulo,
            error_subtitulo: errorSubtitulo,
        }

        if (error) return <FullScreenError content={content} />
        return children
    }
}

export default ErrorBoundary
