import React from 'react'
import ActionDetailBudgetImages from '../../../atoms/ActionDetails/ActionDetailBudgetImages/ActionDetailBudgetImages'
import ActionDetailBudgetOverview from '../../../atoms/ActionDetails/ActionDetailBudgetOverview/ActionDetailBudgetOverview'
import ActionDetailChart from '../../../atoms/ActionDetails/ActionDetailChart/ActionDetailChart'
import './ActionDetailBudgetChart.css'

function ActionDetailBudgetChart({
    translations,
    budget,
    expenditure,
    percentage,
    presentation,
    colorPorcentaje,
    imagen,
    idimagen,
}) {
    return (
        <div className="chart-presupuesto">
            <div className="presupuesto-chart-texts-containter">
                <div className="presupuesto-chart-texts-containter-div">
                    <ActionDetailBudgetOverview
                        translations={translations}
                        budget={budget}
                        expenditure={expenditure}
                    />

                    {presentation !== 0 && (
                        <ActionDetailChart
                            translations={translations}
                            percentage={percentage}
                            presentation={presentation}
                            colorPorcentaje={colorPorcentaje}
                        />
                    )}
                </div>
                <ActionDetailBudgetImages image={imagen} imgId={idimagen} />
            </div>
        </div>
    )
}

export default ActionDetailBudgetChart
