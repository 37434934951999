import { SET_INDICATOR } from '../constants/constants'

// eslint-disable-next-line default-param-last
const indicator = (state = {}, action) => {
    if (action.type === SET_INDICATOR) {
        return action.payload
    }

    return state
}

export default indicator
