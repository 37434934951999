import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'
import './MenuVertical.css'

function MenuVertical({ menuSecondary }) {
    return (
        <nav className="menu-vertical__container">
            {menuSecondary.map((menuItem, index) => (
                <NavLink
                    to={menuItem.slug}
                    activeClassName="active"
                    className="menu-vertical__item"
                    key={index}
                >
                    <span>{menuItem.titulo}</span>
                </NavLink>
            ))}
        </nav>
    )
}

MenuVertical.propTypes = {
    menuSecondary: PropTypes.arrayOf(
        PropTypes.shape({
            titulo: PropTypes.string.isRequired,
            descripcion: PropTypes.string,
            icono: PropTypes.string,
            slug: PropTypes.string.isRequired,
        })
    ).isRequired,
}

export default MenuVertical
