import React from 'react'
import ExternalLink from '../../atoms/ExternalLink/ExternalLink'

function AppModule({ lang }) {
    const apple = {
        url: `https://apps.apple.com/es/app/balmaseda-zabaltzen/id979878227`,
        img: 'https://api.qdea.eus/uploads/77/appstore.png',
    }
    const android = {
        url: `https://play.google.com/store/apps/details?id=com.biscaytik.balmaseda&amp;hl=${
            lang || 'es'
        }`,
        img: 'https://api.qdea.eus/uploads/77/googleplay.png',
    }
    return (
        <div className="modulo-app">
            <ExternalLink url={apple.url}>
                <img alt="Appstore" src={apple.img} />
            </ExternalLink>
            <ExternalLink url={android.url}>
                <img alt="Googleplay" src={android.img} />
            </ExternalLink>
        </div>
    )
}

export default AppModule
