import React from 'react'

function ExternalLink({
    className,
    children,
    onBlur,
    onFocus,
    text,
    title,
    url,
}) {
    return (
        <a
            aria-label={`${text}`}
            className={className}
            href={url}
            onBlur={onBlur}
            onFocus={onFocus}
            rel="noopener noreferrer"
            target="_blank"
            title={title}
        >
            {children}
        </a>
    )
}

export default ExternalLink
