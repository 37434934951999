import React from 'react'
import { Link } from 'react-router-dom'

const getZoneLink = ({ lang, zone }) =>
    lang === 'es'
        ? `/es/planes-de-legislatura/buscador?zona=${zone}`
        : `/eu/legealdiko-planak/bilatzaile?zona=${zone}`

function ActionDetailClasificationZonas({ title, zones, lang }) {
    return (
        <div className="clasification-content_zonas">
            <div className="clasification-content_zonas_container">
                <span>{title}</span>
                {zones.map((zone, index) => (
                    <Link
                        key={index}
                        to={getZoneLink({ lang, zone })}
                        className="enlace-buscador"
                    >
                        <div>{zone}</div>
                    </Link>
                ))}
            </div>
        </div>
    )
}

export default ActionDetailClasificationZonas
