import React from 'react'

function PlanDetailCombinedShapeContainer({
    plan,
    action,
    translations,
    slug,
}) {
    return (
        <div className="combined-shape-container">
            <div className="container-popUp">
                <h5 className="plan">{plan}</h5>
                <p className="accion">► {action}</p>
            </div>
            <div className="opciones">
                <div className="ver-accion">
                    <a href={slug}>
                        {translations.ACCOUNTABILITY.ACTION_MAP_VIEW_ACTION}
                    </a>
                </div>
            </div>
        </div>
    )
}

export default PlanDetailCombinedShapeContainer
