import browserLocale from 'browser-locale'

const getLocaleByLocation = location => {
    const path = location?.pathname

    let locale = browserLocale() === 'eu' ? '/eu' : '/es'

    if (path && path !== '/' && path.length > 3) {
        locale = path.slice(0, 3)
    }
 
    return locale
}

export default getLocaleByLocation
