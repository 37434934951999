import React from 'react'
import CategoryButton from '../../../atoms/CategoryButton/CategoryButton'
import './HartuzCategorySelector.css'
import t from '../../../i18n/translations/translations'

function HartuzCategorySelector({
    action,
    categories,
    lang,
    selectedCategory,
}) {
    return (
        <div className="dropdown">
            <button className="dropbtn" type="button">
                {t.category_selector[lang]}
            </button>

            <div className="dropdown-content">
                <section className="hartuzCategorySelector">
                    <ul>
                        {categories && categories.length
                            ? categories.map((category) => (
                                  <li
                                      key={category.id}
                                      className={`${
                                          selectedCategory ===
                                          category.name[lang]
                                              ? 'selected'
                                              : ''
                                      }`}
                                  >
                                      <CategoryButton
                                          action={action}
                                          category={category}
                                          lang={lang}
                                      />
                                  </li>
                              ))
                            : null}
                    </ul>
                </section>
            </div>
        </div>
    )
}

export default HartuzCategorySelector
