import React from 'react'
import t from '../../i18n/translations/translations'
import DatePicker from '../DatePicker/DatePicker'
import Modal from '../Modal/Modal'
import './CalendarModal.css'

function CalendarModal({
    availableDates,
    handleModal,
    isOpened,
    lang,
    selectDate,
}) {
    return isOpened ? (
        <Modal
            closeModal={handleModal}
            title={t.availability_calendar[lang]}
            isOpened={isOpened}
            lang={lang}
        >
            <div className="calendar-modal">
                <DatePicker
                    availableDates={availableDates}
                    closeModal={handleModal}
                    isModal
                    lang={lang}
                    onChanged={selectDate}
                />
            </div>
        </Modal>
    ) : null
}

export default CalendarModal
