import React from 'react'
import { Link } from 'react-router-dom'

const getPlanLink = ({ lang, tematica }) =>
    lang === 'es'
        ? `/es/planes-de-legislatura/buscador?tematica=${tematica}`
        : `/eu/legealdiko-planak/bilatzaile?tematica=${tematica}`

function ActionDetailClasificationSectors({ title, lang, data }) {
    return (
        <div className="clasificacion-content">
            <span>{title}</span>
            {data.map((tematica, index) => (
                <p key={index}>
                    <Link
                        to={getPlanLink({ lang, tematica })}
                        className="enlace-buscador"
                    >
                        {tematica}
                    </Link>
                </p>
            ))}
        </div>
    )
}

export default ActionDetailClasificationSectors
