import React from 'react'

/* eslint-disable max-len */
export default function Planificacion({ className }) {
    return (
        <svg className={className} viewBox="0 0 37 37">
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fillRule="evenodd"
            >
                <g
                    id="Transparencia_desktop"
                    transform="translate(-611.000000, -1110.000000)"
                >
                    <g
                        id="Group-11"
                        transform="translate(611.000000, 1110.000000)"
                    >
                        <path
                            d="M28.8255814,35.3181818 C32.3897446,35.3181818 35.2790698,32.4945231 35.2790698,29.0113636 C35.2790698,25.5282041 32.3897446,22.7045455 28.8255814,22.7045455 C25.2614182,22.7045455 22.372093,25.5282041 22.372093,29.0113636 C22.372093,32.4945231 25.2614182,35.3181818 28.8255814,35.3181818 Z M28.8255814,37 C24.3109747,37 20.6511628,33.4233657 20.6511628,29.0113636 C20.6511628,24.5993616 24.3109747,21.0227273 28.8255814,21.0227273 C33.3401881,21.0227273 37,24.5993616 37,29.0113636 C37,33.4233657 33.3401881,37 28.8255814,37 Z"
                            id="Oval-Copy-10"
                            fillRule="nonzero"
                        />
                        <path
                            d="M23.6627907,5.78323844 C23.1875689,5.78323844 22.8023256,5.40675062 22.8023256,4.94232935 C22.8023256,4.47790808 23.1875689,4.10142026 23.6627907,4.10142026 L30.3744186,4.10142026 C30.8496404,4.10142026 31.2348837,4.47790808 31.2348837,4.94232935 C31.2348837,5.40675062 30.8496404,5.78323844 30.3744186,5.78323844 L23.6627907,5.78323844 Z"
                            id="Path-9-Copy-24"
                            fillRule="nonzero"
                            transform="translate(27.018605, 4.942329) rotate(-270.000000) translate(-27.018605, -4.942329) "
                        />
                        <path
                            d="M6.45348837,5.78323844 C5.97826661,5.78323844 5.59302326,5.40675062 5.59302326,4.94232935 C5.59302326,4.47790808 5.97826661,4.10142026 6.45348837,4.10142026 L13.1651163,4.10142026 C13.640338,4.10142026 14.0255814,4.47790808 14.0255814,4.94232935 C14.0255814,5.40675062 13.640338,5.78323844 13.1651163,5.78323844 L6.45348837,5.78323844 Z"
                            id="Path-9-Copy-26"
                            fillRule="nonzero"
                            transform="translate(9.809302, 4.942329) rotate(-270.000000) translate(-9.809302, -4.942329) "
                        />
                        <path
                            d="M17.1618004,35.3181818 C17.6370222,35.3181818 18.0222656,35.6946696 18.0222656,36.1590909 C18.0222656,36.6235122 17.6370222,37 17.1618004,37 L1.20465116,37 C0.539340697,37 0,36.472917 0,35.8227273 L0,5.38181818 C0,4.73162841 0.539340697,4.20454545 1.20465116,4.20454545 L35.7953488,4.20454545 C36.4606593,4.20454545 37,4.73162841 37,5.38181818 L37,18.0847753 C37,18.5491965 36.6147566,18.9256844 36.1395349,18.9256844 C35.6643131,18.9256844 35.2790698,18.5491965 35.2790698,18.0847753 L35.2790698,5.88636364 L1.72093023,5.88636364 L1.72093023,35.3181818 L17.1618004,35.3181818 Z"
                            id="Path-10-Copy"
                            fillRule="nonzero"
                        />
                        <path
                            d="M29.255814,28.5909091 L30.9767442,28.5909091 C31.4519659,28.5909091 31.8372093,28.9673969 31.8372093,29.4318182 C31.8372093,29.8962394 31.4519659,30.2727273 30.9767442,30.2727273 L27.5348837,30.2727273 L27.5348837,26.9090909 C27.5348837,26.4446696 27.9201271,26.0681818 28.3953488,26.0681818 C28.8705706,26.0681818 29.255814,26.4446696 29.255814,26.9090909 L29.255814,28.5909091 Z"
                            id="Path-7-Copy-6"
                            fillRule="nonzero"
                        />
                        <ellipse
                            id="Oval-Copy-11"
                            cx="8.60465116"
                            cy="15.1363636"
                            rx="1.72093023"
                            ry="1.68181818"
                        />
                        <ellipse
                            id="Oval-Copy-12"
                            cx="8.60465116"
                            cy="21.8636364"
                            rx="1.72093023"
                            ry="1.68181818"
                        />
                        <ellipse
                            id="Oval-Copy-13"
                            cx="8.60465116"
                            cy="27.75"
                            rx="1.72093023"
                            ry="1.68181818"
                        />
                        <ellipse
                            id="Oval-Copy-14"
                            cx="21.5116279"
                            cy="15.1363636"
                            rx="1.72093023"
                            ry="1.68181818"
                        />
                        <ellipse
                            id="Oval-Copy-15"
                            cx="15.4883721"
                            cy="15.1363636"
                            rx="1.72093023"
                            ry="1.68181818"
                        />
                        <ellipse
                            id="Oval-Copy-16"
                            cx="15.4883721"
                            cy="21.8636364"
                            rx="1.72093023"
                            ry="1.68181818"
                        />
                        <ellipse
                            id="Oval-Copy-17"
                            cx="15.4883721"
                            cy="27.75"
                            rx="1.72093023"
                            ry="1.68181818"
                        />
                        <ellipse
                            id="Oval-Copy-18"
                            cx="28.3953488"
                            cy="15.1363636"
                            rx="1.72093023"
                            ry="1.68181818"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
