import PropTypes from 'prop-types'
import React from 'react'
import ReactIframeResizer from 'react-iframe-resizer-super'

function Iframe({ height, title, url }) {
    return height ? (
        <iframe
            height={height}
            src={url}
            style={{ border: 'none' }}
            title={title}
            width="100%"
        />
    ) : (
        <ReactIframeResizer
            iframeResizerEnable
            iframeResizerOptions={{ checkOrigin: false }}
            src={url}
            title={title}
        />
    )
}

Iframe.propTypes = {
    height: PropTypes.string,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
}

Iframe.defaultProps = {
    height: undefined,
}

export default Iframe
