import React from 'react'

/* eslint-disable max-len */
export default function Nivel2({ className }) {
    return (
        <svg className={className} viewBox="0 0 26 21">
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fillRule="evenodd"
            >
                <g
                    id="Artboard"
                    transform="translate(-2683.000000, -273.000000)"
                    fillRule="nonzero"
                >
                    <g
                        id="Group-7"
                        transform="translate(2683.000000, 273.000000)"
                    >
                        <path
                            d="M0,9.03814664 L8.30737305,0.57222754 L16.6147461,9.03814664 L16.6147461,20.307373 L0,20.307373 L0,9.03814664 Z M2,18.307373 L14.6147461,18.307373 L14.6147461,9.8555216 L8.30737305,3.42777246 L2,9.8555216 L2,18.307373 Z"
                            id="Path-21"
                        />
                        <path
                            d="M7.50976562,12.952381 C6.95748088,12.952381 6.50976562,12.5046657 6.50976562,11.952381 C6.50976562,11.4000962 6.95748088,10.952381 7.50976562,10.952381 L23.7578125,10.952381 C24.3100972,10.952381 24.7578125,11.4000962 24.7578125,11.952381 C24.7578125,12.5046657 24.3100972,12.952381 23.7578125,12.952381 L7.50976562,12.952381 Z"
                            id="Path-23"
                        />
                        <path
                            d="M20.3246898,9.73820376 C19.9169911,9.36564472 19.8885048,8.73312087 20.2610638,8.32542219 C20.6336229,7.91772352 21.2661467,7.88923721 21.6738454,8.26179624 L25.7570008,11.9930236 L21.6807992,15.7892838 C21.2766435,16.1656833 20.6438784,16.1431825 20.2674789,15.7390267 C19.8910794,15.334871 19.9135802,14.702106 20.317736,14.3257064 L22.8074523,12.0069764 L20.3246898,9.73820376 Z"
                            id="Path-24"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
