import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import getIcon from '../../helpers/switchTransparencyIcons'
import './CatergoryCard.css'

function CategoryCard({ icon, title, subtitle, slug }) {
  return <Link to={slug} className="category-card__link">
        <div className="category-card">
          <div className="category-card__icon-container">
            {getIcon(icon)}
          </div>
          <div className="category-card__text">
            <span className="category-card__title">{title}</span>
            <span className="category-card__subtitle">{subtitle}</span>
          </div>
        </div>
      </Link>
}


CategoryCard.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
}

export default CategoryCard
