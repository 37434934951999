import React from 'react'

export default function OkWithCircle() {
    return (
        <svg
            width="53"
            height="52"
            viewBox="0 0 53 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse
                cx="26.5"
                cy="26"
                rx="24.5"
                ry="24"
                stroke="currentColor"
                strokeWidth="3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40.5226 18.9387C39.937 18.3538 38.9882 18.3538 38.4026 18.9387L23.4626 33.8787L14.5226 24.9387C13.9316 24.388 13.0106 24.4042 12.4393 24.9754C11.8681 25.5467 11.8519 26.4677 12.4026 27.0587L22.4026 37.0587C22.9882 37.6436 23.9369 37.6436 24.5226 37.0587L40.5226 21.0587C41.1075 20.473 41.1075 19.5243 40.5226 18.9387Z"
                fill="currentColor"
            />
        </svg>
    )
}
