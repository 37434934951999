import React from 'react'
import './TimePicker.css'

function TimePicker({ id, name, onChange, onBlur }) {
    return (
        <input
            className="timePicker"
            id={id}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            type="time"
        />
    )
}

export default TimePicker
