import { MDBCol } from 'mdb-react-ui-kit'
import PropTypes from 'prop-types'
import React from 'react'
import createPlainHTMLClassName from '../../utils/createPlainHTMLClassName'
import './PlainHTML.css'

function PlainHTML({ content, customClass }) {
    return (
        <MDBCol
            size="12"
            className={createPlainHTMLClassName(customClass)}
            dangerouslySetInnerHTML={{ __html: content.html }}
        />
    )
}

PlainHTML.propTypes = {
    content: PropTypes.shape({
        html: PropTypes.string.isRequired,
    }).isRequired,
    customClass: PropTypes.string,
}

PlainHTML.defaultProps = {
    customClass: '',
}

export default PlainHTML
