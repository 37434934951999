import { HIGH_OCCUPANCY, LOW_OCCUPANCY, MEDIUM_OCCUPANCY } from '../constants'
import t from '../i18n/translations/translations'

const getOccupancyMessage = ({ occupancyState, lang }) => {
    if (occupancyState === LOW_OCCUPANCY) {
        return t.low[lang]
    }

    if (occupancyState === MEDIUM_OCCUPANCY) {
        return t.medium[lang]
    }

    return occupancyState === HIGH_OCCUPANCY
}

export default getOccupancyMessage