export const mapStyle = [
    // {
    //     featureType: 'all',
    //     elementType: 'labels.text',
    //     stylers: [
    //         {
    //             visibility: 'off',
    //         },
    //     ],
    // },
    {
        featureType: 'poi',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
]

export const mapCustomStyles = {
    mapContainer: {
        height: '100vh',
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
    },
    infoWindowContent: {
        padding: '1rem 0.5rem',
    },
    link: {
        color: 'black',
    },
    heading: {
        fontSize: '1rem',
        marginBottom: '1rem',
        textTransform: 'capitalize',
        fontWeight: '500',
    },
    locality: {
        textTransform: 'capitalize',
    },
}
