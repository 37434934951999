import React from 'react'
import { Helmet } from 'react-helmet'

function BaseHelmet({ pageData, cssCustom }) {
    return (
        <Helmet>
            <title>{pageData.seoTitle}</title>
            <meta name="description" content={pageData.seoDescription} />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <link rel="stylesheet" type="text/css" href={cssCustom} />
            <link rel="stylesheet" type="text/css" href="/css_base/base.css" />
        </Helmet>
    )
}

export default BaseHelmet
