import React from 'react'
import InfoBoxHeaderStatusDate from '../InfoBoxHeaderStatusDate/InfoBoxHeaderStatusDate'

function IncidenciaInfoBoxHeaderStatus({ date, name, color }) {
    return (
        <div className="infobox_header-status">
            {date ? (
                <div className="date-bell">
                    <InfoBoxHeaderStatusDate date={date} />
                </div>
            ) : null}
            <div
                className="infobox_header-status_status"
                style={{ color, border: `1px solid ${color}` }}
            >
                <div
                    style={{ backgroundColor: color }}
                    className="infobox_header-status_status_circle"
                />
                <p>{name}</p>
            </div>
        </div>
    )
}

export default IncidenciaInfoBoxHeaderStatus
