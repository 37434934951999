import React, { useState } from 'react'
import './CategoryButton.css'

let currentActiveButton = null

function CategoryButton({ action, category, lang }) {
    const [isActive, setIsActive] = useState(false)

    const handleClick = () => {
        action(category.type)
        if (currentActiveButton !== null) {
            currentActiveButton.setIsActive(false)
        }
        setIsActive(!isActive)
        currentActiveButton = { setIsActive }
    }

    return (
        <button
            className={`categoryButton${isActive ? ' active' : ''}`}
            type="button"
            onClick={handleClick}
            value={category.id}
        >
            {category.name[lang]}
        </button>
    )
}

export default CategoryButton
