import React from 'react'
import DownloadFile from '../DownloadFile/DownloadFile'
import PlanDetailSectionTitle from '../../components/planDetail/PlanDetailSectionTitle/PlanDetailSectionTitle'

function PlanDownloadsContainer({ translations, links, icon, linksClass }) {
    return (
        <div
            className={
                linksClass
                    ? 'planDetail-box_container-links'
                    : 'planDetail-box_container-downloads'
            }
        >
            <PlanDetailSectionTitle
                className={
                    linksClass
                        ? 'planDetail-box_container-links_title'
                        : 'planDetail-box_container-downloads_title'
                }
                text={
                    linksClass
                        ? translations.ACCOUNTABILITY.PLAN_DETAIL_LINKS
                        : translations.ACCOUNTABILITY.PLAN_DETAIL_DOWNLOADS
                }
            />
            <div className="separador" />
            <div className="files-link">
                {links.map((file, i) => (
                    <DownloadFile
                        className="planDetail-box_container-downloads_files"
                        key={i}
                        file={file}
                        icon={icon && icon === 'link' ? 'link' : 'paper'}
                    />
                ))}
            </div>
        </div>
    )
}

export default PlanDownloadsContainer
