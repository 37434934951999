import React from 'react'
import IncidenciaInfoBoxHeaderStatus from '../../atoms/IncidenciaInfoBoxHeaderStatus/IncidenciaInfoBoxHeaderStatus'
import InfoBoxHeaderStatusDate from '../../atoms/InfoBoxHeaderStatusDate/InfoBoxHeaderStatusDate'
import Category from '../../assets/svg/Category'

function InforBoxHeaderTitle({ title }) {
    return <h1 className="infobox_header-title">{title}</h1>
}

function InfoBoxHeaderCategory({ category }) {
    return (
        <div className="categoria">
            <Category />
            <p>{category}</p>
        </div>
    )
}

function DesktopContent({ title, date, color, stateName }) {
    return (
        <>
            <InforBoxHeaderTitle title={title} />
            <IncidenciaInfoBoxHeaderStatus
                date={date}
                color={color}
                name={stateName}
            />
        </>
    )
}

function TabletContent({ title, date, color, stateName, category }) {
    return (
        <>
            <div className="title-button">
                <InforBoxHeaderTitle title={title} />
                <IncidenciaInfoBoxHeaderStatus color={color} name={stateName} />
            </div>
            <div className="date-bell-cat">
                <InfoBoxHeaderStatusDate date={date} />
                <InfoBoxHeaderCategory category={category} />
            </div>
        </>
    )
}

function MobileContent({ title, date, color, stateName, category }) {
    return (
        <>
            <div className="title-button">
                <IncidenciaInfoBoxHeaderStatus color={color} name={stateName} />
                <InforBoxHeaderTitle title={title} />
            </div>
            <div className="date-bell-cat">
                <div className="date-bell">
                    <InfoBoxHeaderStatusDate date={date} />
                </div>
                <InfoBoxHeaderCategory category={category} />
            </div>
        </>
    )
}

function IncidenciaInfoboxHeader({
    isMobile,
    isTablet,
    title,
    color,
    stateName,
    date,
    category,
}) {
    let content

    if (!isMobile && !isTablet) {
        content = (
            <DesktopContent
                title={title}
                date={date}
                color={color}
                stateName={stateName}
            />
        )
    } else if (isTablet) {
        content = (
            <TabletContent
                title={title}
                date={date}
                color={color}
                stateName={stateName}
                category={category}
            />
        )
    } else {
        content = (
            <MobileContent
                title={title}
                date={date}
                color={color}
                stateName={stateName}
                category={category}
            />
        )
    }

    return <div className="incidenciaDetail-infobox_header">{content}</div>
}

export default IncidenciaInfoboxHeader
