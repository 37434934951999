import { SET_PLAN } from '../constants/constants'

// eslint-disable-next-line default-param-last
const plan = (state = {}, action) => {
    if (action.type === SET_PLAN) {
        return action.payload
    }

    return state
}

export default plan
