import React from 'react'
import { Link } from 'react-router-dom'
import t from '../../i18n/translations/translations'

function HeaderLogo({ lang, link, logo, logoInstancia }) {
    return (
        <div className="logo-container">
            <Link to={link}>
                <img
                    aria-roledescription={t.back_to_main_screen[lang]}
                    src={logoInstancia}
                    className="logo-header"
                    alt={
                        logo?.alt
                            ? `${logo.alt} ${t.logo_header[lang]}`
                            : 'altpordefecto'
                    }
                />
            </Link>
        </div>
    )
}

export default HeaderLogo
