import { SET_UA } from '../constants/constants'

// eslint-disable-next-line default-param-last
const analyticsUA = (state = {}, action) => {
    if (action.type === SET_UA) {
        return action.payload
    }

    return state
}

export default analyticsUA
