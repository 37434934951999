import { SET_PLAN_DATA } from '../constants/constants'

// eslint-disable-next-line default-param-last
const plans = (state = {}, action) => {
    if (action.type === SET_PLAN_DATA) {
        const { plan, data } = action.payload

        return {
            ...state,
            [plan]: data,
        }
    }

    return state
}

export default plans
