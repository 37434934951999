import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HartuzCancelationButton from '../../atoms/HartuzCancelationButton/HartuzCancelButton'
import PageRow from '../../atoms/PageRow/PageRow'
import HartuzBookingGrid from '../../components/Hartuz/HartuzBookingGrid/HartuzBookingGrid'
import ThankYouPage from '../../compositions/ThankYouPage/ThankYouPage'
import STATES from '../../constants/states'
import t from '../../i18n/translations/translations'
import {
    getAvailableDatesByFacilityAndType,
    getAvailableHoursByFacilityAndType,
    saveHartuzFacilityBooking as saveHartuzFacilityBookingAction,
    setHartuzFacilityDetail as setHartuzFacilityDetailAction,
} from '../../store/actions'

function HartuzBookingContainer({ isMobile, isTablet, match }) {
    const dispatch = useDispatch()

    const {
        availableDates,
        bookingCode,
        bookingFormState,
        date,
        datesLoading,
        errorBookingCode,
        facility,
        facilityHoursAvailable,
        facilityData,
        facilityName,
        formValuesBooking,
        hoursLoading,
        instanceID,
    } = useSelector((state) => ({
        availableDates: state.hartuz.dates?.data,
        bookingCode: state.hartuz.booking?.data?.data?.booking_code,
        bookingFormState: state.hartuz.bookingFormState,
        date: state.hartuz.booking?.data?.data?.date,
        datesLoading: state.hartuz.datesLoading,
        errorBookingCode: state.hartuz.booking?.data?.code,
        facility: state.hartuz.facility,
        facilityHoursAvailable: state.hartuz.hours?.data?.data,
        facilityData: state.hartuz.facility?.data?.data,
        facilityName: state.hartuz.booking?.data?.data?.facility_name,
        formValuesBooking: state.hartuz.booking?.bookingSave,
        hoursLoading: state.hartuz.hoursLoading,
        instanceID: state.pages.hartuz?.data[0]?.idinstancia,
    }))

    const getAvailableDates = (payload) =>
        dispatch(getAvailableDatesByFacilityAndType(payload))
    const getAvailableHours = (payload) =>
        dispatch(getAvailableHoursByFacilityAndType(payload))
    const saveHartuzFacilityBooking = (payload) =>
        dispatch(saveHartuzFacilityBookingAction(payload))
    const setHartuzFacilityDetail = (payload) =>
        dispatch(setHartuzFacilityDetailAction(payload))

    const userId = null

    useEffect(() => {
        const { lang, facilityID, facilityType } = match

        if (facilityID && instanceID && lang) {
            setHartuzFacilityDetail({
                facilityID,
                facilityType,
                idInstancia: instanceID,
                lang,
                userId,
            })
        }
    }, [])

    const { lang, date: paramsDate } = match

    return bookingFormState === STATES.SUCCESS ? (
        <ThankYouPage
            message={t.booking_success_thank_you[lang]}
            textName={t.thank_you_instalation_name[lang]}
            facilityName={facilityName}
            textDate={t.thank_you_reservation_date[lang]}
            date={date}
            textId={t.thank_you_id[lang]}
            bookingCode={bookingCode}
        >
            <a href={`/${lang}/hartuz`}>{t.go_back[lang]}</a>
        </ThankYouPage>
    ) : (
        <>
            {facilityData ? (
                <div className={`${isMobile || isTablet ? '' : 'pageRow'}`}>
                    <HartuzBookingGrid
                        availableDates={availableDates}
                        bookingFormState={bookingFormState}
                        data={facilityData}
                        datesLoading={datesLoading}
                        errorBookingCode={errorBookingCode}
                        facility={facility}
                        facilityHoursAvailable={facilityHoursAvailable}
                        formValuesBooking={formValuesBooking || facilityData['user_prize']}
                        getAvailableDates={getAvailableDates}
                        getAvailableHours={getAvailableHours}
                        hoursLoading={hoursLoading}
                        instanceID={instanceID}
                        lang={lang}
                        paramsDate={paramsDate}
                        saveHartuzFacilityBooking={saveHartuzFacilityBooking}
                    />
                </div>
            ) : null}
            <PageRow>
                <HartuzCancelationButton lang={lang} />
            </PageRow>
        </>
    )
}

export default HartuzBookingContainer
