import React from 'react'
import Abierta from '../../assets/svg/incidences-status/Abierta'
import EnProceso from '../../assets/svg/incidences-status/EnProceso'
import Rechazada from '../../assets/svg/incidences-status/Rechazada'
import Resuelta from '../../assets/svg/incidences-status/Resuelta'
import Unificada from '../../assets/svg/incidences-status/Unificada'
import {
    isOpen,
    isProcessing,
    isRejected,
    isResolved,
    isTramitted,
    isUnified,
} from '../../compositions/IncidenciaDetail/IncidenciaDetail'

function IncidenciaInfoBoxStatusBar({ state }) {
    return (
        <div className="infobox_status-bar">
            {isOpen(state) && <Abierta />}
            {isProcessing(state) && <EnProceso />}
            {isResolved(state) && <Resuelta />}
            {isTramitted(state) && <Resuelta />}
            {isRejected(state) && <Rechazada />}
            {isUnified(state) && <Unificada />}
        </div>
    )
}

export default IncidenciaInfoBoxStatusBar
