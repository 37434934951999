import classNames from 'classnames'
import React from 'react'

import FormError from '../FormError/FormError'
import './FormGroup.css'

function FormGroup({ children, errors, id, label, required, className }) {
    return (
        <div
            className={classNames(
                'form-group',
                { 'form-group--has-errors': errors },
                className
            )}
        >
            {label ? (
                <div
                    className={classNames('form-group__label', {
                        'form-group__label-red': errors,
                    })}
                >
                    <label className="form-label" htmlFor={id}>
                        {label}
                        {required && (
                            <span className="form-label__required">*</span>
                        )}
                    </label>
                </div>
            ) : null}
            {children}
            {errors ? (
                <FormError id={id} className="form-group__errors">
                    {errors}
                </FormError>
            ) : null}
        </div>
    )
}

export default FormGroup
