import t from '../../../i18n/translations/translations'
import pageHasBreadcrumbs from '../../../utils/pageHasBreadcrumbs'

const getTransparenciaPageObject = lang => ({
    titulo: t.transparency[lang],
    slug: `/${lang}/${t.transparency[lang].toLowerCase()}`,
}
)

const isTransparencyPage = page =>
    ['transparencia', 'gardetasuna'].includes(page)

const getBreadcrumbsByPage = ({
    id,
    lang,
    location,
    match,
    pageData,
}) => {
    let breadcrumb
    const { page, urlID } = match

    if (pageData && location && pageData.breadcrums !== undefined) {
        breadcrumb = [...pageData.breadcrums]

        if (isTransparencyPage(page) && urlID) {
            breadcrumb = [
                ...breadcrumb.slice(0, 1),
                getTransparenciaPageObject(lang),
                ...breadcrumb.slice(1),
            ]
        }
        if (location.pathname.includes('cancel')) {
            breadcrumb = [
                ...breadcrumb,
                { titulo: t.cancelation[lang], slug: location.pathname },
            ]
        }
    } else if (pageData && pageData[0]) {
        breadcrumb = [...pageData[0].breadcrums]
    } else {
        breadcrumb = null
    }

    const showBreadcrumbs =
        breadcrumb &&
        pageHasBreadcrumbs({
            breadcrumb,
            id,
            pageData,
        })

    return showBreadcrumbs && breadcrumb ? breadcrumb : null
}

export default getBreadcrumbsByPage;