import React from 'react'

/* eslint-disable max-len */
export default function Face({ className }) {
    return (
        <svg className={className} viewBox="0 0 53 53">
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="404_desktop"
                    transform="translate(-267.000000, -559.000000)"
                >
                    <g
                        id="Group-2"
                        transform="translate(269.000000, 561.000000)"
                    >
                        <circle
                            id="Oval"
                            stroke="#1C3561"
                            strokeWidth="4"
                            cx="24.5"
                            cy="24.5"
                            r="24.5"
                        />
                        <circle
                            id="Oval"
                            fill="#1C3561"
                            cx="16"
                            cy="17"
                            r="3"
                        />
                        <circle
                            id="Oval-Copy"
                            fill="#1C3561"
                            cx="34"
                            cy="17"
                            r="3"
                        />
                        <circle
                            id="Oval-Copy-2"
                            fill="#1C3561"
                            cx="24.5"
                            cy="35.5"
                            r="5.5"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
