import React from 'react'
import ActionDetailDates from '../../../atoms/ActionDetails/ActionDetailDates/ActionDetailDates'
import './ActionDetailHitosDatesContainer.css'

function ActionDetailHitosDates({ translations, startDate, endDate, isReal }) {
    return (
        <div className="hitos-dates">
            <ActionDetailDates
                className="date-inicio"
                title={
                    isReal
                        ? translations.ACCOUNTABILITY
                              .ACTION_DETAIL_START_REAL_DATE
                        : translations.ACCOUNTABILITY
                              .ACTION_DETAIL_START_PLANNED_DATE
                }
                text={startDate}
            />
            <ActionDetailDates
                className="date-fin"
                title={
                    isReal
                        ? translations.ACCOUNTABILITY
                              .ACTION_DETAIL_END_REAL_DATE
                        : translations.ACCOUNTABILITY
                              .ACTION_DETAIL_END_PLANNED_DATE
                }
                text={endDate}
            />
        </div>
    )
}

function ActionDetailHitosDatesContainer({ translations, hito }) {
    return (
        <div className="hitos-dates-container">
            {hito.start_planned_date && hito.end_planned_date ? (
                <ActionDetailHitosDates
                    translations={translations}
                    startDate={hito.start_planned_date}
                    endDate={hito.end_planned_date}
                />
            ) : null}
            {hito.start_real_date && hito.end_real_date ? (
                <ActionDetailHitosDates
                    translations={translations}
                    startDate={hito.start_planned_date}
                    endDate={hito.end_planned_date}
                    isReal
                />
            ) : null}
        </div>
    )
}

export default ActionDetailHitosDatesContainer
