import React from 'react'
import { Link } from 'react-router-dom'
import PlanDetailActionsTitle from '../../../atoms/PlanDetail/PlanDetailActionsTitle/PlanDetailActionsTitle'
import './PlanDetailActionContainer.css'

function PlanDetailActionContainer({ actions, translations }) {
    return (
        <div className="planDetail-box_container-actions">
            <div className="container-actions-title_container">
                <PlanDetailActionsTitle translations={translations} />
                <div className="actions-content">
                    {actions.map((action) => (
                        <div className="actions-content-link" key={action}>
                            <Link to={action.slug}>{action.title}</Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default PlanDetailActionContainer
