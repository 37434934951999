import React from 'react'

export default function Occupancy({ className }) {
    return (
        <svg
            className={className}
            width="42px"
            height="40px"
            viewBox="0 0 42 40"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="AurreTIK_desktop_detalle"
                    transform="translate(-639.000000, -658.000000)"
                >
                    <g
                        id="Group-4"
                        transform="translate(595.000000, 512.000000)"
                    >
                        <g
                            id="Group-8-Copy-8"
                            transform="translate(30.000000, 136.000000)"
                        >
                            <g
                                id="ocupacion-copy-5"
                                transform="translate(15.000000, 10.000000)"
                            >
                                <ellipse
                                    id="Oval"
                                    fill="#FFFFFF"
                                    opacity="0.7"
                                    cx="20"
                                    cy="19.5"
                                    rx="20"
                                    ry="19.5"
                                />
                                <g
                                    id="alta"
                                    transform="translate(0.000000, 0.975000)"
                                    fill="#E04747"
                                    stroke="#FFFFFF"
                                >
                                    <path
                                        d="M8.14262885,34.203752 L6.72684911,33.2008434 L8.78409749,30.4400729 L10.1998772,31.4429815 C16.3353245,35.7892033 24.6951158,35.3981138 30.3586418,30.6330843 L31.6835469,29.5183703 L33.9701397,32.1019351 L32.6452346,33.2166491 C25.7581725,39.0111055 15.5999078,39.4863315 8.14262885,34.203752 Z M39.3319809,21.1674687 L39.1023702,22.8589684 L35.6326273,22.4112275 L35.862238,20.7197279 C36.6351864,15.0255605 34.2067067,9.320909 29.3840297,5.86506025 C28.1229042,4.97140908 26.8417807,4.29519331 25.4838854,3.79523633 L23.846727,3.19245883 L25.0831937,0 L26.7203521,0.602777496 C28.3721147,1.21093208 29.9291935,2.03280545 31.4492463,3.10996557 C37.3187919,7.31594657 40.2708991,14.250632 39.3319809,21.1674687 Z M12.7321257,0.623925917 L14.3684241,0.0189327267 L15.6094358,3.20971464 L13.9731374,3.81470783 C11.0697028,4.88820282 8.53771867,6.74764453 6.66389283,9.23362986 C4.10715509,12.6643035 3.07916582,16.7598284 3.65584171,20.8045362 L3.89679407,22.4945355 L0.43012883,22.9643926 L0.189176479,21.2743933 C-0.511283735,16.3614831 0.739694243,11.3775678 3.838015,7.22023114 C6.12018111,4.19245398 9.20225987,1.92903309 12.7321257,0.623925917 Z"
                                        id="3bar"
                                        fillRule="nonzero"
                                    />
                                    <path
                                        d="M22.4142504,16.3605 C22.9665351,16.3605 23.4142504,16.8082153 23.4142504,17.3605 C23.4142504,17.4169683 23.4094674,17.4733352 23.3999527,17.5289961 L22.7270542,21.4618395 C22.2931336,21.522549 21.9420489,21.8628499 21.8769434,22.3071944 L21.8769434,22.3071944 L21.0410735,28.0191874 C20.950453,28.0461154 20.8547446,28.0605 20.7561307,28.0605 L18.4432605,28.0605 C18.3466848,28.0605 18.2528956,28.0467039 18.163943,28.0208409 L17.327501,22.3071944 C17.2621029,21.8608526 16.9081488,21.5194918 16.4715339,21.4610378 L15.7994385,17.5289961 C15.7063807,16.9846078 16.0722564,16.4678555 16.6166447,16.3747977 C16.6723057,16.365283 16.7286725,16.3605 16.7851409,16.3605 L22.4142504,16.3605 Z M19.5996956,9.5355 C21.2565499,9.5355 22.5996956,10.8450671 22.5996956,12.4605 C22.5996956,14.0759329 21.2565499,15.3855 19.5996956,15.3855 C17.9428414,15.3855 16.5996956,14.0759329 16.5996956,12.4605 C16.5996956,10.8450671 17.9428414,9.5355 19.5996956,9.5355 Z"
                                        id="monigote-rojo"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
