import React from 'react'
import ArrowDown from '../../assets/svg/ArrowDown'
import ArrowUp from '../../assets/svg/ArrowUp'

function ArrowsUpDown({ isActive, isBudget, className }) {
    function renderArrow(isBudgetParam, isActiveParam) {
        if (isBudgetParam) {
            return isActiveParam ? <ArrowUp /> : <ArrowDown />
        }

        // why is the alt here??
        return isActiveParam ? <ArrowUp alt="abrir" /> : <ArrowDown />
    }

    return <div className={className}>{renderArrow(isBudget, isActive)}</div>
}

export default ArrowsUpDown
