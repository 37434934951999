import React from 'react'
import t from '../../i18n/translations/translations'

export function HeaderSearchIcon({ action, lang }) {
    return (
        <button
            aria-label={t.search_button[lang]}
            className="button-no-style"
            id="searchButton"
            onClick={action}
            tabIndex="0"
            title="search"
            type="button"
        >
            <i
                title="search"
                className="fa fa-search search-icon"
                aria-hidden="true"
            />
            <p className="hiddenParagraph">Search</p>
        </button>
    )
}

export function HeaderSearch({ action, collapseSearch, lang, trads }) {
    return (
        <div className={!collapseSearch ? 'search-form' : 'search-form-hidden'}>
            <input
                aria-label="Search"
                className={!collapseSearch ? 'search-input' : 'search-hidden'}
                id="headerSearch"
                placeholder={`${trads.buscar}...`}
                type="text"
            />
            <HeaderSearchIcon action={action} lang={lang}/>
        </div>
    )
}
