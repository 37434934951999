import React from 'react'

function KalezainduNoResultMessage({ className, translations }) {
    return (
        <p className={className || ''}>
            {translations.KALEZAINDU.KALEZAINDU_SEARCH_NO_RESULT}
        </p>
    )
}

export default KalezainduNoResultMessage
