import React from 'react'
import './PlanDoneText.css'

function PlanDoneText({ translations }) {
    return (
        <div className="done-text">
            <p>
                {translations.ACCOUNTABILITY.PLAN_DETAIL_PERCENTAGE.toUpperCase()}
            </p>
        </div>
    )
}

export default PlanDoneText
