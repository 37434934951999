import React from 'react'
import biscaytik from '../../assets/img/footer/biscaytik_2016.png'
import ExternalLink from '../ExternalLink/ExternalLink'

function BiscaytikLogo({ className }) {
    return (
        <ExternalLink url="http://www.biscaytik.eus/es-ES/Paginas/default.aspx">
            <img src={biscaytik} className={className} alt="logotipo biscaytik" />
        </ExternalLink>
    )
}

export default BiscaytikLogo
