import {
    InfoWindow,
    Map,
    Marker,
    useMarkerRef,
} from '@vis.gl/react-google-maps'
import React, { useEffect, useState } from 'react'
import t from '../../i18n/translations/translations'
import './MapDetail.css'
import { mapCustomStyles, mapStyle } from './MapDetailStyles'
import MapInfoWindowContent from './MapInfoWindowContent/MapInfoWindowContent'
import useSetIframeTitle from '../../hooks/useSetIframeTitle'

function MapDetail({ defaultProps, lang, resources }) {
    const [markerRef, marker] = useMarkerRef()

    const [selectedPlace, setSelectedPlace] = useState(null)
    const [showingInfoWindow, setShowingInfoWindow] = useState(false)

    useSetIframeTitle(t.map[lang])

    const onInfoWindowClose = () => {
        setSelectedPlace(null)
        setShowingInfoWindow(false)
    }

    const onMarkerClick = (resource) => {
        setSelectedPlace(resource)
        setShowingInfoWindow(true)
    }

    useEffect(() => {
        setShowingInfoWindow(false)
    }, [resources])

    return (
        <div style={mapCustomStyles.mapContainer}>
            {defaultProps ? (
                <Map
                    defaultZoom={defaultProps.zoom}
                    defaultCenter={defaultProps.center}
                    styles={mapStyle}
                >
                    {resources?.length &&
                        resources.map((resource) => (
                            <Marker
                                ref={markerRef}
                                visible={resource.visible}
                                id={resource.id}
                                key={resource.id}
                                onClick={() => onMarkerClick(resource)}
                                title={resource.name[lang]}
                                name={resource.name[lang]}
                                description={resource.description[lang]}
                                position={resource.coordinates}
                                contact={resource.contact}
                                address={resource.address}
                            />
                        ))}
                    <InfoWindow
                        onClose={onInfoWindowClose}
                        anchor={showingInfoWindow && marker}
                    >
                        {selectedPlace ? (
                            <MapInfoWindowContent
                                lang={lang}
                                selectedPlace={selectedPlace}
                            />
                        ) : (
                            <div>
                                <p>{t.map_resource_no_results[lang]}</p>
                            </div>
                        )}
                    </InfoWindow>
                </Map>
            ) : null}
        </div>
    )
}

export default MapDetail
