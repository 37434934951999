import React from 'react'
import CustomButton from '../atoms/CustomButton/CustomButton'
import FinderSearchInput from '../atoms/FinderSearchInput/FinderSearchInput'
import t from '../i18n/translations/translations'

// TO FIX why is there an option null here in the first place??
function OptionNull({ translations, isMobile, isTablet }) {
    return (
        <option value="null">
            {!isMobile && !isTablet
                ? translations.ACCOUNTABILITY.FINDER_TOPIC_LONG
                : translations.ACCOUNTABILITY.FINDER_TOPIC_SHORT}
        </option>
    )
}

export default function FinderForm({
    isMobile,
    isTablet,
    translations,
    handleChange,
    busqueda,
    filterComplete,
    lang,
    zonas,
    handleSubmit,
    tematica,
    zona,
}) {
    const isDesktop = !isMobile && !isTablet

    return (
        <form className="finder-form" onSubmit={handleSubmit}>
            <div className="finder-infobox_search">
                <FinderSearchInput
                    action={handleChange}
                    translations={translations}
                    value={busqueda}
                />
            </div>
            <div className="desplegables_boton">
                <div className="select">
                    <label htmlFor="tematica">{t.theme[lang]}</label>
                    <select
                        className="search_select_finder"
                        onChange={handleChange}
                        name="tematica"
                        id="tematica"
                    >
                        <OptionNull
                            isMobile={isMobile}
                            isTablet={isTablet}
                            translations={translations}
                        />

                        {filterComplete.map((topic) => (
                            <option
                                key={topic}
                                selected={tematica === topic}
                                value={topic}
                            >
                                {topic}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="select">
                    <label htmlFor="zona">{t.zone[lang]}</label>
                    <select
                        className="search_select_finder"
                        onChange={handleChange}
                        name="zona"
                        id="zona"
                    >
                        <OptionNull
                            isMobile={isMobile}
                            isTablet={isTablet}
                            translations={translations}
                        />

                        {lang === 'es' &&
                            zonas.data.map((zone) => (
                                <option
                                    key={zone}
                                    selected={zona === zone.titulo_es}
                                    value={zone.titulo_es}
                                >
                                    {zone.titulo_es}
                                </option>
                            ))}

                        {lang === 'eu' &&
                            zonas.data.map((zone) => (
                                <option
                                    key={zone}
                                    selected={zona === zone.titulo_eu}
                                    value={zone.titulo_eu}
                                >
                                    {zone.titulo_eu}
                                </option>
                            ))}
                    </select>
                </div>
                {isDesktop ? (
                    <CustomButton
                        className="finder-reportBoton"
                        action={handleSubmit}
                        text={translations.ACCOUNTABILITY.FINDER_BUTTON}
                    />
                ) : null}
            </div>
            {!isDesktop ? (
                <div className="submit-button">
                    <CustomButton
                        className="finder-reportBoton"
                        action={handleSubmit}
                        text={translations.ACCOUNTABILITY.FINDER_BUTTON}
                    />
                </div>
            ) : null}
        </form>
    )
}
