import React from 'react'

import './FormError.css'

function FormError({ children, className, id }) {
    return (
        <div className={`form-error ${className}`} id={id}>
            {children}
        </div>
    )
}

export default FormError
