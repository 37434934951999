import React from 'react'
import Crowd from '../../assets/svg/aurretik/Crowd'

import './WysiwygAurretik.css'
import PageTitle from '../../atoms/PageTitle/PageTitle'

function WysiwygAurretik({ content }) {
    return (
        <div>
            <div className="aurretikContainer__title aurretikContainer_row">
                <Crowd />
                <PageTitle
                    text={content.titulo}
                    className="wysiwyg-functionality__title"
                />
            </div>
            <p
                className="wysiwyg-functionality__subtitle aurretikContainer_row"
                dangerouslySetInnerHTML={{ __html: content.descripcion }}
            />
        </div>
    )
}

export default WysiwygAurretik
