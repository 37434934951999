import PropTypes from 'prop-types'
import React from 'react'
import Kalezaindu from '../../compositions/Kalezaindu/Kalezaindu'
import { FUNCTIONALITY } from '../../constants'
import ActionMap from '../Accountability/ActionMap/ActionMap'
import PlanList from '../Accountability/PlanList'
import ColumnsRender from '../ColumnsRender/ColumnsRender'
import FunctionalityHome from '../FunctionalityHome/FunctionalityHome'

import './RowsRender.css'

const TYPES = {
    functionality: 3,
    map: 8,
    plan: 9,
    kalezaindu: 10,
    aurretik: 12,
}
// to do : why is kalenzaidu not included here?

const typeIsFunctionality = (boxType) => boxType === FUNCTIONALITY

const checkIsMap = (data) =>
    data.tipo === TYPES.map && typeIsFunctionality(data.tipo_caja)
const checkIsPlan = (data) =>
    data.tipo === TYPES.plan && typeIsFunctionality(data.tipo_caja)
const checkIsKalezaindu = (data) =>
    data.tipo === TYPES.kalezaindu && typeIsFunctionality(data.tipo_caja)
const checkIsFunctionality = (data) =>
    data.tipo === TYPES.functionality && typeIsFunctionality(data.tipo_caja)
const checkIsDefault = (type) =>
    type !== TYPES.plan && type !== TYPES.map && type !== TYPES.functionality

function RowsRender({
    filas,
    lang,
    isMobile,
    isTablet,
    menuSecondary,
    translations,
    isAurretik,
}) {
    return !filas
        ? null
        : Object.values(filas).map((fila, index) => (
              <div className="row" key={index}>
                  {checkIsPlan(fila.columnas[1]) && (
                      <PlanList
                          contenido={fila.columnas[1].contenido}
                          translations={translations}
                          lang={lang}
                          isMobile={isMobile}
                      />
                  )}

                  {checkIsMap(fila.columnas[1]) && (
                      <ActionMap
                          contenido={fila.columnas[1].contenido}
                          translations={translations}
                          lang={lang}
                      />
                  )}

                  {checkIsKalezaindu(fila.columnas[1]) && (
                      <Kalezaindu
                          contenido={fila.columnas[1].contenido}
                          columns={fila}
                          lang={lang}
                          isMobile={isMobile}
                          isTablet={isTablet}
                          translations={translations}
                      />
                  )}

                  {checkIsFunctionality(fila.columnas[1]) && (
                      <FunctionalityHome
                          contenido={fila.columnas[1].contenido}
                          menuSecondary={menuSecondary}
                      />
                  )}

                  {checkIsDefault(fila.columnas[1].tipo) && (
                      <ColumnsRender
                          isAurretik={isAurretik}
                          columns={fila}
                          lang={lang}
                          isMobile={isMobile}
                          isTablet={isTablet}
                      />
                  )}
              </div>
          ))
}

RowsRender.propTypes = {
    filas: PropTypes.shape({}).isRequired,
    lang: PropTypes.string.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isTablet: PropTypes.bool.isRequired,
}

export default RowsRender
