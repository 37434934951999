import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ExternalLink from '../ExternalLink/ExternalLink'
import StatusLabel from '../StatusLabel/StatusLabel'
import './IndicatorCard.css'

function IndicatorCard({ indicador, status, slug, level, isOwnLink, url }) {
    const [isFocused, setIsFocused] = useState(false)

    const handleFocus = () => {
        setIsFocused(true)
    }

    const handleBlur = () => {
        setIsFocused(false)
    }

    if (isOwnLink === 1) {
        return (
            <div className="link-container">
                <Link
                    className="indicator-card__link"
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    to={slug}
                >
                    <div
                        className={classNames('indicator-card__container', {
                            'indicator-card__container--level2': level === 1,
                            'indicator-card__container--level3': level === 2,
                        })}
                        style={isFocused ? { outline: '2px solid black' } : {}}
                    >
                        <span className="indicator-card__description">
                            {indicador}
                        </span>
                        {status ? <StatusLabel /> : ''}
                    </div>
                </Link>
            </div>
        )
    }
    if (isOwnLink === 2) {
        return (
            <div
                onBlur={handleBlur}
                onFocus={handleFocus}
                className={classNames('indicator-card__container', {
                    'indicator-card__container--level2': level === 1,
                    'indicator-card__container--level3': level === 2,
                })}
                style={isFocused ? { outline: '2px solid black' } : {}}
            >
                <span className="indicator-card__description">{indicador}</span>
                {status ? <StatusLabel /> : ''}
            </div>
        )
    }
    return (
        <ExternalLink onBlur={handleBlur}
        onFocus={handleFocus} className="indicator-card__link" url={url}>
            <div
            
                className={classNames('indicator-card__container', {
                    'indicator-card__container--level2': level === 1,
                    'indicator-card__container--level3': level === 2,
                })}
                style={isFocused ? { outline: '2px solid black' } : {}}
            >
                <span className="indicator-card__description">{indicador}</span>
                {status ? <StatusLabel /> : ''}
            </div>
        </ExternalLink>
    )
}

IndicatorCard.propTypes = {
    indicador: PropTypes.string.isRequired,
    status: PropTypes.string,
    slug: PropTypes.string,
    level: PropTypes.number.isRequired,
    isOwnLink: PropTypes.number,
    url: PropTypes.string,
}

IndicatorCard.defaultProps = {
    status: undefined,
    slug: '',
    url: '',
    isOwnLink: 0,
}

export default IndicatorCard
