import React from 'react'

export default function Status({ className }) {
    return (
        <svg
            className={className}
            width="26px"
            height="26px"
            viewBox="0 0 26 26"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="AurreTIK_desktop_detalle"
                    transform="translate(-642.000000, -603.000000)"
                >
                    <g
                        id="Group-4"
                        transform="translate(595.000000, 512.000000)"
                    >
                        <g
                            id="Group-7"
                            transform="translate(48.000000, 92.000000)"
                        >
                            <circle
                                id="Oval-Copy-38"
                                fill="#01A901"
                                cx="12"
                                cy="12"
                                r="5"
                            />
                            <circle
                                id="Oval-Copy-56"
                                stroke="#01A901"
                                cx="12"
                                cy="12"
                                r="12"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
