import { SET_PAGE_DATA } from '../constants/constants'

// eslint-disable-next-line default-param-last
const pages = (state = {}, action) => {
    if (action.type === SET_PAGE_DATA) {
        const { page, data } = action.payload

        return { ...state, [page]: data }
    }
    return state
}

export default pages
