import React from 'react'

/* eslint-disable max-len */
export default function Normativa({ className }) {
    return (
        <svg className={className} viewBox="0 0 31 37">
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fillRule="evenodd"
            >
                <g
                    id="Transparencia_desktop"
                    transform="translate(-614.000000, -1197.000000)"
                    fillRule="nonzero"
                >
                    <g
                        id="Group-3"
                        transform="translate(614.000000, 1197.000000)"
                    >
                        <path
                            d="M6.7027027,13.4545455 C6.23997764,13.4545455 5.86486486,13.0780576 5.86486486,12.6136364 C5.86486486,12.1492151 6.23997764,11.7727273 6.7027027,11.7727273 L24.8378378,11.7727273 C25.3005629,11.7727273 25.6756757,12.1492151 25.6756757,12.6136364 C25.6756757,13.0780576 25.3005629,13.4545455 24.8378378,13.4545455 L6.7027027,13.4545455 Z"
                            id="Path-9-Copy-25"
                        />
                        <path
                            d="M6.7027027,18.5 C6.23997764,18.5 5.86486486,18.1235122 5.86486486,17.6590909 C5.86486486,17.1946696 6.23997764,16.8181818 6.7027027,16.8181818 L24.8378378,16.8181818 C25.3005629,16.8181818 25.6756757,17.1946696 25.6756757,17.6590909 C25.6756757,18.1235122 25.3005629,18.5 24.8378378,18.5 L6.7027027,18.5 Z"
                            id="Path-9-Copy-29"
                        />
                        <path
                            d="M6.7027027,22.7045455 C6.23997764,22.7045455 5.86486486,22.3280576 5.86486486,21.8636364 C5.86486486,21.3992151 6.23997764,21.0227273 6.7027027,21.0227273 L24.8378378,21.0227273 C25.3005629,21.0227273 25.6756757,21.3992151 25.6756757,21.8636364 C25.6756757,22.3280576 25.3005629,22.7045455 24.8378378,22.7045455 L6.7027027,22.7045455 Z"
                            id="Path-9-Copy-31"
                        />
                        <path
                            d="M6.7027027,27.75 C6.23997764,27.75 5.86486486,27.3735122 5.86486486,26.9090909 C5.86486486,26.4446696 6.23997764,26.0681818 6.7027027,26.0681818 L24.8378378,26.0681818 C25.3005629,26.0681818 25.6756757,26.4446696 25.6756757,26.9090909 C25.6756757,27.3735122 25.3005629,27.75 24.8378378,27.75 L6.7027027,27.75 Z"
                            id="Path-9-Copy-33"
                        />
                        <path
                            d="M1.67567568,1.68181818 L1.67567568,35.3181818 L29.3243243,35.3181818 L29.3243243,7.70300719 C29.3243243,7.60532098 29.2820119,7.51246018 29.2083878,7.44856749 L22.657713,1.76374212 C22.5968209,1.7108986 22.5190123,1.68181818 22.4385144,1.68181818 L1.67567568,1.68181818 Z M1.67567568,0 L22.4385144,0 C22.9215021,0 23.3883537,0.174482524 23.7537061,0.491543634 L30.3043809,6.17636901 C30.7461255,6.5597251 31,7.1168899 31,7.70300719 L31,35.3181818 C31,36.2470244 30.2497744,37 29.3243243,37 L1.67567568,37 C0.750225554,37 0,36.2470244 0,35.3181818 L0,1.68181818 C0,0.752975648 0.750225554,0 1.67567568,0 Z"
                            id="Rectangle-Copy-31"
                        />
                        <path
                            d="M22.6216216,7.56818182 L28.4864865,7.56818182 C28.9492115,7.56818182 29.3243243,7.94466964 29.3243243,8.40909091 C29.3243243,8.87351218 28.9492115,9.25 28.4864865,9.25 L20.9459459,9.25 L20.9459459,1.68181818 C20.9459459,1.21739691 21.3210587,0.840909091 21.7837838,0.840909091 C22.2465088,0.840909091 22.6216216,1.21739691 22.6216216,1.68181818 L22.6216216,7.56818182 Z"
                            id="Path-7-Copy-10"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
