import mapCategoryTransformer from './mapCategoryTransformer'
import mapLocalityTransformer from './mapLocalityTransformer'

const tranformCoordinateString = coordinates =>
    coordinates.replace(/lat|lng/g, match => `"${match}"`)


const defaultProps = {
    center: {
        lat: 43.4009902637236,
        lng: -2.6022715584970815,
    },
    zoom: 10,
}

const convertCoordinates = coordinates => {
    // object keys need to have double quote for json parse to work
    const validString = tranformCoordinateString(coordinates)
    const jsonCoordinates = JSON.parse(validString)
    return jsonCoordinates
}

const transformMap = map => ({
    id: map.idmapa,
    active: map.activo,
    type: map.tipo_mapa,
    locality: mapLocalityTransformer(map.municipio),
    date: map.fecha_creacion,
    default_map: map.mapa_por_defecto,
    name: {
        es: map.name_es,
        eu: map.name_eu,
    },
    defaultProps: {
        center: map.default_coordinates
            ? convertCoordinates(map.default_coordinates)
            : defaultProps.center,
        zoom: map.zoom ? parseInt(map.zoom, 10) : defaultProps.zoom,
    },
    categories: map?.categorias?.map(category => mapCategoryTransformer(category)) ?? []
})

const mapsDataTransformer = data => {
    const transformedData = {
        code: data.code,
        error: data.error,
        maps: data?.data?.map(map => transformMap(map))
    }
    return transformedData
}

export default mapsDataTransformer