import React from 'react'
import Calendar from '../../../assets/svg/aurretik/Calendar'

function PlanDetailDate({ title, date }) {
    return (
        <div className="planDetail-box_container-dates_svg">
            <Calendar /> <p className="date-literal">{`${title}: `}</p>
            <p> {date?.toLowerCase()} </p>
        </div>
    )
}

export default PlanDetailDate
