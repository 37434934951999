
const getTranslatesHome = (locale) => {
  const translates = {
    es: {
      lang: 'es',
      buscar: 'Buscar',
      castellano: 'Castellano',
      idioma: 'Idioma'
    },
    eu: {
      lang: 'eu',
      buscar: 'Bilatu',
      castellano: 'Gaztelera',
      idioma: 'Hizkuntza',
    }
  }
  
  return translates[locale] ? translates[locale] : translates.es
}

export default getTranslatesHome;
