import Moment from 'moment'
import React from 'react'
import Imagen from '../../../assets/img/Placeholder.png'
import Ubication2 from '../../../assets/svg/Ubication2'
import createIncidenceUrlById from '../../../compositions/Kalezaindu/utils/createIncidenceUrlById'
import './IncidenceCard.css'

function IncidenceCardImg({ alt, ariaLabel, isPlacerholder, src }) {
    const addDefaultSrc = (ev) => {
        const { target } = ev
        target.src = Imagen
    }

    return (
        <img
            alt={alt}
            // aria-hidden
            className={`incidenceCardImg ${
                isPlacerholder ? 'placeholder' : ''
            }`}
            onError={addDefaultSrc}
            src={isPlacerholder ? Imagen : src}
        />
    )
}

function IncidenceCard({ card, translations }) {
    return (
        <article>
            <div className="incidencia-card_photo-container">
                <div className="incidencia-card_photo">
                    {card.images?.[0]?.url ? (
                        <IncidenceCardImg
                            alt=""
                            ariaLabel={card.title}
                            isPlacerholder={
                                card.images[0].url === 'placeholder'
                            }
                            src={card.images[0].url}
                        />
                    ) : null}
                </div>
                <div className="incidencia-card_photo-title">{card.title}</div>
            </div>
            <div className="incidencia-card_content">
                <div>
                    <p>{Moment(card.created_at).format('D MMM YYYY')}</p>
                </div>
                <span className="incidencia-text-overflow">
                    {card.description}
                </span>
                <div className="incidencia-card_location">
                    <div className="incidencia-card_location__icon">
                        <Ubication2 />
                    </div>

                    <a href={createIncidenceUrlById(card.id)}>
                        {translations.KALEZAINDU.KALEZAINDU_VIEW_INCIDENCE}
                    </a>
                </div>
            </div>
        </article>
    )
}

export default IncidenceCard
