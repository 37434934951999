import React from 'react'
import Pdf from '../../../assets/svg/Pdf'
import t from '../../../i18n/translations/translations'
import ExternalLink from '../../ExternalLink/ExternalLink'
import PageTitle from '../../PageTitle/PageTitle'

function PlanDetailTitle({ lang, title, translations, plan }) {
    const href = `${process.env.REACT_APP_API_URL}accountability/pdfexport/?_lang=${plan.lang}&_dominio=${plan.domain}&_slug=${plan.slug}`
    const text = translations?.ACCOUNTABILITY?.PLAN_DETAIL_RESUME
    return (
        <div className="planDetail_title__container">
            <PageTitle text={title} className="planDetail_title" />
            <ExternalLink text={`${t.download[lang]} ${title}`} url={href}>
                <div className="planDetail-title_pdf">
                    <Pdf />
                    <p>{text}</p>
                </div>
            </ExternalLink>
        </div>
    )
}

export default PlanDetailTitle
