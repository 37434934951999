import React from 'react'

/* eslint-disable max-len */
export default function Nivel3({ className }) {
    return (
        <svg className={className} viewBox="0 0 25 23">
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fillRule="evenodd"
            >
                <g
                    id="Artboard"
                    transform="translate(-2730.000000, -272.000000)"
                    fillRule="nonzero"
                >
                    <g
                        id="Group-5"
                        transform="translate(2730.000000, 272.000000)"
                    >
                        <path
                            d="M0,11.0381466 L8.30737305,2.57222754 L16.6147461,11.0381466 L16.6147461,22.307373 L0,22.307373 L0,11.0381466 Z M2,20.307373 L14.6147461,20.307373 L14.6147461,11.8555216 L8.30737305,5.42777246 L2,11.8555216 L2,20.307373 Z"
                            id="Path-21"
                        />
                        <polygon
                            id="Path-25-Copy"
                            points="13.7217636 6.80166725 12.2782364 5.41738792 16.9416998 0.554325971 24.2484436 8.1916085 24.2484436 18.3208618 17.3830261 18.3208618 17.3830261 16.3208618 22.2484436 16.3208618 22.2484436 8.99424355 16.9400141 3.44567403"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
