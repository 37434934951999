import React from 'react'

/* eslint-disable max-len */
export default function CalendarioInput({ className }) {
    return (
        <svg className={className} viewBox="0 0 27 25">
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Kalezaindu_home_desktop"
                    transform="translate(-385.000000, -1237.000000)"
                    stroke="#B5BBC6"
                    strokeWidth="2.37999986"
                >
                    <g
                        id="Group-3"
                        transform="translate(190.000000, 1131.000000)"
                    >
                        <g
                            id="Group"
                            transform="translate(0.000000, 95.000000)"
                        >
                            <g
                                id="icons/calendar/black"
                                transform="translate(195.000000, 12.000000)"
                            >
                                <rect
                                    id="Rectangle"
                                    x="1.24910158"
                                    y="3.07235287"
                                    width="24.0975415"
                                    height="18.7964707"
                                    rx="2.95119985"
                                />
                                <path
                                    d="M25.212766,8.94117647 L1.38297872,8.94117647"
                                    id="Path-2"
                                    strokeLinecap="round"
                                />
                                <path
                                    d="M8.00236407,5.41176471 L8.00236407,0.705882353"
                                    id="Path-17"
                                    strokeLinecap="round"
                                />
                                <path
                                    d="M18.5933806,5.41176471 L18.5933806,0.705882353"
                                    id="Path-17"
                                    strokeLinecap="round"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
