import React from 'react'

function PlanListBoxTitle({ title }) {
    return (
        <div className="planList-box_container_title">
            <p>{title}</p>
        </div>
    )
}

export default PlanListBoxTitle
