import React from 'react'
import t from '../../i18n/translations/translations'
import InternalLink from '../InternalLink/InternalLink'
import './HartuzCancelationButton.css'

export default function HartuzCancelationButton({ lang }) {
    return (
        <aside className="hartuzCancelationButton__container">
            <InternalLink
                url={`/${lang}/hartuz/cancel`}
                className="hartuzCancelationButton"
            >
                {t.make_cancelation_title[lang]}
            </InternalLink>
        </aside>
    )
}
