import { SET_IRUDIZ_DATA } from '../constants/constants'

// eslint-disable-next-line default-param-last
const irudizPostals = (state = {}, action) => {
    if (action.type === SET_IRUDIZ_DATA) {
        const { data } = action.payload
        return {
            ...state,
            postals: data,
        }
    }
    return state
}

export default irudizPostals
