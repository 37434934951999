import React from 'react'

/* eslint-disable max-len */
export default function Twitter({ className }) {
    return (
        <svg className={className} viewBox="0 0 22 18">
            <g id="Symbols">
                <g
                    id="Icon-/-Solid-/-Twitter"
                    transform="translate(-1.000000, -3.000000)"
                >
                    <path
                        d="M23,5.13092308 C22.181875,5.49230769 21.310125,5.73184615 20.40125,5.84815385 C21.33625,5.286 22.049875,4.40261538 22.385375,3.33784615 C21.513625,3.86123077 20.551125,4.23092308 19.525375,4.43723077 C18.697625,3.54969231 17.517875,3 16.230875,3 C13.733875,3 11.723625,5.04092308 11.723625,7.54292308 C11.723625,7.90292308 11.753875,8.24907692 11.828125,8.57861538 C8.0785,8.39446154 4.760625,6.58476923 2.53175,3.828 C2.142625,4.50784615 1.914375,5.286 1.914375,6.12369231 C1.914375,7.69661538 2.71875,9.09092308 3.91775,9.89815385 C3.193125,9.88430769 2.48225,9.67246154 1.88,9.33876923 C1.88,11.5956923 3.443375,13.4289231 5.4935,13.8512308 C5.126375,13.9523077 4.72625,14.0007692 4.311,14.0007692 C4.02225,14.0007692 3.73075,13.9841538 3.457125,13.9232308 C4.0415,15.7218462 5.69975,17.0441538 7.6715,17.0870769 C6.137,18.2958462 4.188625,19.0241538 2.079375,19.0241538 C1.7095,19.0241538 1.35475,19.0075385 1,18.9618462 C2.997875,20.2592308 5.365625,21 7.919,21 C16.2185,21 20.756,14.0769231 20.756,8.076 C20.756,7.87523077 20.749125,7.68138462 20.7395,7.48892308 C21.634625,6.84923077 22.38675,6.05030769 23,5.13092308 Z"
                        id="Twitter"
                    />
                </g>
            </g>
        </svg>
    )
}
