const getInstanciaId = ({ match, page }) => {
    if (!page || !match) {
        return undefined
    }

    const { instanceID: urlID } = match
    let pageId =
        !page.idinstancia && page?.[0]?.idinstancia
            ? page[0].idinstancia
            : page.idinstancia

    if (!pageId && urlID) {
        pageId = urlID
    }

    return pageId
}

export default getInstanciaId