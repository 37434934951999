import React, { useEffect, useState } from 'react'
import './BackToTop.css'
import t from '../../i18n/translations/translations'

const toTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    document.getElementById('searchButton').focus()
}

function BackToTop({ lang }) {
    const [show, setShow] = useState(false)
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        const handleScroll = () => {
            const rootElement = document.getElementById('root')
            const footerElement = document.querySelector('.footer-wrapper')
            const scrollTop = window.scrollY
            const rootHeight = rootElement ? rootElement.offsetHeight : 0
            const footerHeight = footerElement ? footerElement.offsetHeight : 0
            const windowHeight = window.innerHeight

            if (rootHeight - windowHeight - footerHeight <= scrollTop) {
                setShow(false)
                setDisabled(true)
            } else {
                setShow(true)
                setDisabled(false)
            }

            if (scrollTop === 0) {
                setShow(false)
                setDisabled(true)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const classes = `backtotop ${show ? '' : 'not-show'} ${disabled ? 'disabled' : ''}`

    return (
        <nav aria-label={lang ? t.back_to_top[lang] : t.back_to_top.es}>
            <button
                aria-label={t.back_to_top[lang]}
                className={classes}
                id="backtotop"
                type="button"
                onClick={toTop}
            >
                <i className="fa fa-arrow-up" aria-hidden="true" />
            </button>
        </nav>
    )
}

export default BackToTop
