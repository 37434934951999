import moment from 'moment'
import React, { useEffect, useState } from 'react'
import InternalLink from '../../../atoms/InternalLink/InternalLink'
import { DATE_FORMAT } from '../../../constants'
import t from '../../../i18n/translations/translations'
import './HartuzFacilityCard.css'
import Imagen from '../../../assets/img/Placeholder.png'
import useImageExists from '../../../hooks/useImageExists'

const createUrl = (lang, card, date) => {
    const urlDate = date ? moment(date).format(DATE_FORMAT) : null
    return urlDate
        ? `/${lang}/hartuz/booking/${card.id}/${urlDate}`
        : `/${lang}/hartuz/booking/${card.id}`
}

function HartuzFacilityCard({
    card,
    censed,
    description,
    disability,
    image,
    lang,
    selectedDate,
    canBeBooked
}) {
    const imageExists = useImageExists(image)
    const imageUrl = imageExists ? image : Imagen

    return (
        <article className="hartuzFacilityCard">
            <div className="hartuzFacilityCard__top">
                <h3>{card.name[lang]}</h3>
            </div>
            <div
                className="hartuzFacilityCard__middle"
                style={{
                    backgroundImage: `url("${imageUrl}")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100% 250px',
                }}
            >
                <div className="hartuzFacilityCard__logo">
                    {censed ? (
                        <div className='hartuzFacilityCard__logo-div'>
                            <i
                                aria-hidden="true"
                                className="fa fa-lock lock-icon"
                                title={t.only_census[lang]}
                            />
                        </div>
                    ) : null}
                    {disability ? (
                        <div className='hartuzFacilityCard__logo-div'>
                            <i
                                aria-hidden="true"
                                className="fa fa-wheelchair wheelchair-icon"
                                title={t.moviliy_reduced[lang]}
                            />
                        </div>
                    ) : null}
                </div>
                {canBeBooked ? (
                    <div className="hartuzFacilityCard__button">
                        <InternalLink url={createUrl(lang, card, selectedDate)}>
                            {t.make_booking[lang]}
                        </InternalLink>
                    </div>
                ) : null}
            </div>
            <div className="hartuzFacilityCard__bottom">
                <p
                    dangerouslySetInnerHTML={{
                        __html: description[lang],
                    }}
                />
            </div>
        </article>
    )
}

export default HartuzFacilityCard
