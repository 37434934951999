import { MDBCol } from 'mdb-react-ui-kit'
import PropTypes from 'prop-types'
import React from 'react'
import getImagesRoute from '../../utils/getImagesRoute'
import './Banner.css'

/**
 * Banner component used to place an image.
 */
function Banner({ content, customClass }) {
    const classname = `banner ${customClass}`
    const imgPath = `${getImagesRoute()}${content.imagen}`
    return (
        <MDBCol xs="12" className={classname}>
            <img src={imgPath} alt={content.alt} />
        </MDBCol>
    )
}

Banner.propTypes = {
    /** Object that contains img url and alt prop */
    content: PropTypes.shape({
        idimagen: PropTypes.string.isRequired,
        descripcion: PropTypes.string,
        imagen: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
    }).isRequired,
    /** Custom class to attack from css */
    customClass: PropTypes.string,
}

Banner.defaultProps = {
    customClass: '',
}

export default Banner
