import React from 'react'
import ActionDetailDates from '../../../atoms/ActionDetails/ActionDetailDates/ActionDetailDates'
import './ActionDetailDatesContainer.css'

function ActionDetailDatesContainer({
    startPlannedDate,
    endPlannedDate,
    startRealDate,
    endRealDate,
    responsive,
    translations,
}) {
    return (
        <div
            className={
                responsive
                    ? 'actionDetail_action-descriptionContainer-dates-responsive'
                    : 'actionDetail_action-descriptionContainer-dates'
            }
        >
            <div className="planned-dates">
                {startPlannedDate !== null ? (
                    <ActionDetailDates
                        title={
                            translations.ACCOUNTABILITY
                                .ACTION_DETAIL_START_PLANNED_DATE
                        }
                        text={startPlannedDate}
                    />
                ) : (
                    ''
                )}
                {endPlannedDate !== null ? (
                    <ActionDetailDates
                        title={
                            translations.ACCOUNTABILITY
                                .ACTION_DETAIL_END_PLANNED_DATE
                        }
                        text={endPlannedDate}
                    />
                ) : (
                    ''
                )}
            </div>

            <div className="real-dates">
                {startRealDate !== null ? (
                    <ActionDetailDates
                        title={
                            translations.ACCOUNTABILITY
                                .ACTION_DETAIL_START_REAL_DATE
                        }
                        text={startRealDate}
                    />
                ) : (
                    ''
                )}
                {endRealDate !== null ? (
                    <ActionDetailDates
                        title={
                            translations.ACCOUNTABILITY
                                .ACTION_DETAIL_END_REAL_DATE
                        }
                        text={endRealDate}
                    />
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}

export default ActionDetailDatesContainer
