import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import PropTypes from 'prop-types'
import React from 'react'
import NewsAttachments from '../../atoms/NewsAttachments/NewsAttachment'
import NewsBody from '../../atoms/NewsBody/NewsBody'
import NewsDate from '../../atoms/NewsDate/NewsDate'
import NewsTitle from '../../atoms/NewsTitle/NewsTitle'
import SocialBar from '../../atoms/SocialBar/SocialBar'

import getImagesRoute from '../../utils/getImagesRoute'
import './NewsRender.css'

function NewsRender({ pagina, slug, params, trads }) {
    let route
    const imagesRoute = getImagesRoute()

    imagesRoute === 'undefined'
        ? (route = 'http://pru.back.jamenet.eus/uploads/instancia/')
        : (route = imagesRoute)

    const imageSrc = `${route}${pagina.imagen}`

    return (
        <div className="single-news">
            <MDBRow className="background-container">
                <div className="layer-news" />
                <MDBCol lg="3" className="background-img-1">
                    <img src={imageSrc} alt={pagina.imagen} />
                </MDBCol>

                <MDBCol size="12" lg="9" className="background-img-2">
                    <img src={imageSrc} alt={pagina.imagen} />
                </MDBCol>
            </MDBRow>
            <MDBRow className="first-row">
                <MDBCol size="3" sm="2" lg="3" className="first-column">
                    <NewsDate Day={pagina.diames} Year={pagina.ano} />
                    <div className="triangle-news" />
                </MDBCol>
                <MDBCol size="9" sm="10" lg="9" className="second-column">
                    <NewsTitle title={pagina.titulo} />
                </MDBCol>
            </MDBRow>
            <MDBRow className="second-row">
                <MDBCol
                    size="3"
                    className="d-none d-lg-flex d-xl-flex first-column"
                >
                    <SocialBar
                        params={params}
                        titulo={pagina.titulo}
                        url={window.location.href}
                        img={imageSrc}
                        slug={slug}
                    />
                    {pagina.adjuntos ? (
                        <NewsAttachments
                            adjuntos={pagina.adjuntos}
                            trads={trads}
                        />
                    ) : null}
                </MDBCol>
                <MDBCol
                    size="12"
                    lg="9"
                    className="second-column single-news__content"
                >
                    <NewsBody
                        body={pagina.contenido}
                        titulo={pagina.titulo}
                        url={window.location.href}
                        img={imageSrc}
                        slug={slug}
                    />
                </MDBCol>
                <MDBCol
                    size="12"
                    lg="9"
                    className="d-lg-none d-xl-none d-xs-flex d-sm-flex d-md-flex second-column single-news__social-responsive"
                >
                    <SocialBar
                        params={params}
                        titulo={pagina.titulo}
                        url={window.location.href}
                        img={imageSrc}
                        slug={slug}
                    />
                </MDBCol>
            </MDBRow>
        </div>
    )
}

NewsRender.propTypes = {
    pagina: PropTypes.shape({
        ano: PropTypes.string.isRequired,
        contenido: PropTypes.string.isRequired,
        diames: PropTypes.string.isRequired,
        entradilla: PropTypes.string.isRequired,
        idnoticia: PropTypes.number.isRequired,
        imagen: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        titulo: PropTypes.string.isRequired,
    }).isRequired,
    slug: PropTypes.string.isRequired,
    trads: PropTypes.shape({
        lang: PropTypes.string.isRequired,
        buscar: PropTypes.string.isRequired,
        castellano: PropTypes.string.isRequired,
        idioma: PropTypes.string.isRequired,
        adjuntos: PropTypes.string.isRequired,
    }).isRequired,
}

export default NewsRender
