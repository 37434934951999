import React from 'react'
import Clasificaciones from '../../../assets/svg/Clasificaciones'
import './ActionDetailClassificationTitle.css'

function ActionDetailClassificationTitle({ title }) {
    return (
        <div className="actionDetail_action-descriptionContainer-clasificacion-title">
            <div className="clasificacion-title">
                <Clasificaciones />
                <p>{title}</p>
            </div>
        </div>
    )
}

export default ActionDetailClassificationTitle
