import PropTypes from 'prop-types'
import React from 'react'
import Iframe from '../../atoms/Iframe/Iframe'
import NewsList from '../NewsList/NewsList'
import NewsSlider from '../NewsSlider/NewsSlider'
import WysiwygAurretik from '../WysiwygAurretik/WysiwygAurretik'
import WysiwygFunctionality from '../WysiwygFunctionality/WysiwygFunctionality'

// FIXME: Tipo must be int
function FunctionalitiesRender({ tipo, contenido, lang, isAurretik }) {
    switch (tipo) {
        case 0:
            if (isAurretik) {
                return <WysiwygAurretik content={contenido} />
            }

            return <WysiwygFunctionality content={contenido} />
        case 1:
            if (contenido.presentacion === '1') {
                return contenido.noticias.map((news) => (
                    <NewsList noticias={news} lang={lang} key={news} />
                ))
            }
            return <NewsSlider content={contenido.noticias} lang={lang} />

        case 2:
            return (
                <Iframe
                    url={contenido.url}
                    title={contenido.titulo}
                    height={contenido.altura}
                />
            )
        case 4:
        case 5:
        case 6:
        case 7:
            return <WysiwygFunctionality content={contenido} />
        case undefined:
            return ''
        default:
            return ''
    }
}

FunctionalitiesRender.propTypes = {
    tipo: PropTypes.number.isRequired,
    contenido: PropTypes.shape({}).isRequired,
    lang: PropTypes.string.isRequired,
}

export default FunctionalitiesRender
