import React from 'react'

export default function Clip({ className }) {
    return (
        <svg viewBox="0 0 13 15" className={className}>
            <defs>
                <path
                    d="M10.1917214,8.95323404 C10.5008865,8.64406897 11.0021421,8.64406897 11.3113071,8.95323404 C11.6204722,9.2623991 11.6204722,9.76365471 11.3113071,10.0728198 L7.90632345,13.4778035 C6.1357699,15.248357 3.26513539,15.248357 1.49458183,13.4778035 C-0.275971723,11.7072499 -0.275971723,8.8366154 1.49458183,7.06606184 L7.22307567,1.33756801 C8.50649973,0.054143983 10.5873412,0.0541439985 11.8707653,1.33756804 C13.1541893,2.62099209 13.1541894,4.7018336 11.8704608,5.98556199 L6.13603809,11.7137515 C5.33974353,12.5100461 4.04869496,12.5100461 3.2524004,11.7137515 C2.45610583,10.9174569 2.45610583,9.62640836 3.25273037,8.82978403 L6.70017853,5.38639646 C7.00952572,5.07741363 7.51078124,5.07770901 7.81976407,5.3870562 C8.1287469,5.6964034 8.12845152,6.19765892 7.81910433,6.50664175 C6.47843004,7.84573691 6.47843004,7.84573691 4.37198613,9.94969954 C4.1940217,10.127664 4.1940217,10.4162013 4.37198613,10.5941658 C4.54995057,10.7721302 4.83848792,10.7721302 5.01675684,10.5938614 L10.7511796,4.86567195 C11.4162735,4.20057801 11.4162735,3.12224771 10.7511796,2.45715378 C10.0860856,1.79205986 9.00775532,1.79205985 8.34266139,2.45715376 L2.61416757,8.18564758 C1.46194414,9.33787101 1.46194414,11.2059943 2.61416757,12.3582177 C3.766391,13.5104412 5.63451429,13.5104412 6.78673772,12.3582177 L10.1917214,8.95323404 Z"
                    id="path-1"
                />
            </defs>
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Noticia_desktop_large"
                    transform="translate(-221.000000, -1307.000000)"
                >
                    <g
                        id="Icon-/-Line-/-Attachment"
                        transform="translate(221.000000, 1307.000000)"
                    >
                        <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1" />
                        </mask>
                        <use
                            id="Mask"
                            fillOpacity="0"
                            fill="#051033"
                            fillRule="nonzero"
                            xlinkHref="#path-1"
                        />
                        <g
                            id="Color-/-Black-/-Black-Base-900"
                            mask="url(#mask-2)"
                            fill="#1C3561"
                        >
                            <g
                                transform="translate(-3.000000, -2.000000)"
                                id="Black-/-Black-Base-900"
                            >
                                <polygon points="0 0 19 0 19 19 0 19" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
