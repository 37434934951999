import {
    MDBProgress,
    MDBProgressBar,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
} from 'mdb-react-ui-kit'
import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import './PlanProgress.css'
import PageRow from '../PageRow/PageRow'

export default function PlanProgress({ plan }) {
    const {
        percentage: valorGlobalPlan,
        planes_no_iniciadas: valorPlanesNoIniciadas,
        planes_en_diseno: valorPlanesEnDiseno,
        planes_iniciadas: valorPlanesIniciado,
        planes_en_ejecucion_avanzada: valorPlanesEnEjecucionAvanzada,
        planes_finalizados: valorPlanesFinalizadas,
        slug: slugPlan,
        title,
    } = plan.plan

    const { slug } = slugPlan

    return (
        <PageRow className="planProgress">
            <div className="planProgress-box-title">
                <a href={slug}>
                    <h1>{title}</h1>
                </a>
                <div className="planProgress-box-title_chart-progress">
                    <div className="chart-title">
                        <CircularProgressbar
                            value={valorGlobalPlan}
                            text={`${valorGlobalPlan}%`}
                            styles={{
                                path: {
                                    stroke: getComputedStyle(
                                        document.documentElement
                                    ).getPropertyValue('--color-main'),
                                    strokeWidth: '5px',
                                    strokeLinecap: 'round',
                                },
                                trail: {
                                    stroke: '#f1f2f4',
                                    strokeWidth: '3px',
                                },
                                text: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    stroke: '0px',
                                    strokeWidth: '0px',
                                    fill: getComputedStyle(
                                        document.documentElement
                                    ).getPropertyValue('--color-main'),
                                    fontFamily: getComputedStyle(
                                        document.documentElement
                                    ).getPropertyValue('--font-main'),
                                    fontWeight: getComputedStyle(
                                        document.documentElement
                                    ).getPropertyValue('--font-weight-medium'),
                                    fontSize: '20px',
                                    dominantBaseline: 'middle',
                                    textAnchor: 'middle',
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="planProgress-box">
                <div className="planProgress-box_container">
                    <MDBTable>
                        <MDBTableHead>
                            <tr>
                                <th>
                                    <p>
                                        {
                                            plan.translations.ACCOUNTABILITY
                                                .DETALLES_ESTADO
                                        }
                                    </p>
                                </th>
                                <td />
                                <td />
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            <tr>
                                <td className="task" />
                                <td />
                                <td />
                            </tr>
                            <tr>
                                <td className="task">
                                    <p className="Acciones">
                                        {
                                            plan.translations.ACCOUNTABILITY
                                                .PLANES_FINALIZADAS
                                        }
                                    </p>
                                </td>
                                <td>
                                    <MDBProgress>
                                        <MDBProgressBar
                                            className="finalizadas"
                                            valuemax={100}
                                            valuemin={0}
                                            width={valorPlanesFinalizadas}
                                        />
                                    </MDBProgress>
                                </td>
                                <td className="partial-percentage">
                                    <p>{valorPlanesFinalizadas} %</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="task">
                                    <p>
                                        {
                                            plan.translations.ACCOUNTABILITY
                                                .PLANES_EN_EJECUCCION_AVANZADA
                                        }
                                    </p>
                                </td>
                                <td>
                                    <MDBProgress>
                                        <MDBProgressBar
                                            className="en_ejecucion_avanzada"
                                            valuemax={100}
                                            valuemin={0}
                                            width={
                                                valorPlanesEnEjecucionAvanzada
                                            }
                                        />
                                    </MDBProgress>
                                </td>
                                <td className="partial-percentage">
                                    <p>{valorPlanesEnEjecucionAvanzada} %</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="task">
                                    <p>
                                        {
                                            plan.translations.ACCOUNTABILITY
                                                .PLANES_INICIADA
                                        }
                                    </p>
                                </td>
                                <td>
                                    <MDBProgress>
                                        <MDBProgressBar
                                            className="iniciada"
                                            valuemax={100}
                                            valuemin={0}
                                            width={valorPlanesIniciado}
                                        />
                                    </MDBProgress>
                                </td>
                                <td className="partial-percentage">
                                    <p>{valorPlanesIniciado} %</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="task">
                                    <p>
                                        {
                                            plan.translations.ACCOUNTABILITY
                                                .PLANES_EN_DISENO
                                        }
                                    </p>
                                </td>
                                <td>
                                    <MDBProgress>
                                        <MDBProgressBar
                                            className="en_diseno"
                                            valuemax={100}
                                            valuemin={0}
                                            width={valorPlanesEnDiseno}
                                        />
                                    </MDBProgress>
                                </td>
                                <td className="partial-percentage">
                                    <p>{valorPlanesEnDiseno} %</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="task">
                                    <p>
                                        {
                                            plan.translations.ACCOUNTABILITY
                                                .PLANES_NO_INICIADAS
                                        }
                                    </p>
                                </td>
                                <td>
                                    <MDBProgress>
                                        <MDBProgressBar
                                            className="no_iniciadas"
                                            valuemax={100}
                                            valuemin={0}
                                            width={parseInt(
                                                valorPlanesNoIniciadas,
                                                10
                                            )}
                                        />
                                    </MDBProgress>
                                </td>
                                <td className="partial-percentage">
                                    <p>{valorPlanesNoIniciadas} %</p>
                                </td>
                            </tr>
                        </MDBTableBody>
                    </MDBTable>
                </div>
            </div>
        </PageRow>
    )
}
