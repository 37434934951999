import React from 'react'
import t from '../../i18n/translations/translations'
import './MapsSelectors.css'

function MapsSelector({ onChange, data, lang, id, label, name }) {
    return (
        <div className="mapsSelectors__select-container">
            <label htmlFor={id}>{label}:</label>
            <select
                className="mapsSelectors__select"
                name={name}
                id={id}
                onChange={onChange}
            >
                {data.map((item, i) =>
                    item.visible ? (
                        <option key={i} value={item.id}>
                            {item.name[lang]}
                        </option>
                    ) : null
                )}
            </select>
        </div>
    )
}

function MapsSelectors({ categories, handleCategorySelect, lang }) {
    return (
        <div className="mapsSelectors">
            {categories && lang ? (
                <MapsSelector
                    onChange={handleCategorySelect}
                    data={categories}
                    lang={lang}
                    id="map_category"
                    label={t.category[lang]}
                    name="resource"
                />
            ) : null}
        </div>
    )
}

export default MapsSelectors
