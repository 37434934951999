import { SET_NEWS_DATA } from '../constants/constants'

// eslint-disable-next-line default-param-last
const news = (state = {}, action) => {
    if (action.type === SET_NEWS_DATA) {
        // eslint-disable-next-line no-shadow
        const { news, data } = action.payload
        return {
            ...state,
            [news]: data,
        }
    }

    return state
}

export default news
