import React from 'react'

export default function Category() {
    return (
        <svg aria-hidden="true" focusable="false" viewBox="0 0 20 20">
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Kalezaindu_incidencia_resuelta_desktop"
                    transform="translate(-785.000000, -1363.000000)"
                    fill="#E1E4E8"
                >
                    <g
                        id="Group-2"
                        transform="translate(769.000000, 1354.000000)"
                    >
                        <g
                            id="Group-4"
                            transform="translate(16.000000, 9.000000)"
                        >
                            <rect
                                id="Rectangle"
                                x="0"
                                y="0"
                                width="13.6170213"
                                height="10.2"
                            />
                            <polygon
                                id="Path-7"
                                points="13.6170213 2.55351296e-14 13.6170213 10.2 20.3633943 5.1"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
