import React from 'react'
import BudgetNumber from '../../BudgetNumber/BudgetNumber'
import BudgetTitle from '../../BudgetTitle/BudgetTitle'
import Separator from '../../Separator/Separator'
import './ActionDetailBudgetOverview.css'

function BudgetData({ translations, num }) {
    return (
        <div>
            <BudgetTitle
                text={translations?.ACCOUNTABILITY?.ACTION_DETAIL_EXPENDITURE}
            />

            <Separator />
            <div className="presupuesto-number">
                <p>
                    <BudgetNumber num={num} />
                </p>
            </div>
        </div>
    )
}

function ActionDetailBudgetOverview({ translations, budget, expenditure }) {
    return (
        <div className="presupuesto-container">
            {budget !== null ? (
                <BudgetData
                    text={translations?.ACCOUNTABILITY?.ACTION_DETAIL_BUDGET}
                    num={budget}
                />
            ) : null}
            {expenditure !== null ? (
                <BudgetData
                    text={translations?.ACCOUNTABILITY?.ACTION_DETAIL_EXPENDITURE}
                    num={expenditure}
                />
            ) : null}
        </div>
    )
}

export default ActionDetailBudgetOverview
