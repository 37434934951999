import {
    ACTION_CONTAINER,
    AURRETIK_CONTAINER,
    CATEGORIES_CONTAINER,
    FINDER_CONTAINER,
    HARTUZ_CONTAINER,
    INCIDENCE_CONTAINER,
    INDICATORS_CONTAINER,
    IRUDIZ_CONTAINER,
    NEWS_CONTAINER,
    NEW_INCIDENCE_CONTAINER,
    PAGE_CONTAINER,
    PLAN_CONTAINER,
    PLAN_PROGRESS_CONTAINER,
    PLAN_SUMMARY_CONTAINER,
    USER_BOOKINGS_CONTAINER
} from '../../../constants/containersId'

const selectData = ({
    acciones,
    buscador,
    categories,
    containerId,
    incidencias,
    indicators,
    infrastructures,
    irudiz,
    irudizPostals,
    match,
    news,
    pages,
    plans,
    tematicas,
    zonas,
}) => {
    const { instanceID } = match

    switch (containerId) {
        case ACTION_CONTAINER:
            return {
                actions: acciones,
            }
        case CATEGORIES_CONTAINER:
            return {
                categories,
            }
        case INCIDENCE_CONTAINER:
            return {
                incidencias,
            }
        case INDICATORS_CONTAINER:
            return {
                indicators,
            }
        case NEW_INCIDENCE_CONTAINER:
            return {
                incidencias: pages !== undefined ? pages : incidencias,
            }
        case NEWS_CONTAINER:
            return {
                news,
            }
        case PLAN_CONTAINER:
        case PLAN_PROGRESS_CONTAINER:
        case PLAN_SUMMARY_CONTAINER:
            return {
                plans,
            }
        case FINDER_CONTAINER:
            return {
                plans,
                zonas,
                tematicas,
                buscador,
            }
        case IRUDIZ_CONTAINER:
            return {
                irudiz,
                irudizPostals,
                pages,
            }
        case AURRETIK_CONTAINER:
            return {
                infrastructures,
                pages,
                instanceId: instanceID,
            }
        case PAGE_CONTAINER:
        case HARTUZ_CONTAINER:
        case USER_BOOKINGS_CONTAINER:
        default:
            return {
                pages,
            }
    }
}

export default selectData
