const transformPostal = postal => ({
    id: postal.id,
    description: {
        es: postal.descripcionEs,
        eu: postal.descripcionEu,
    },
    title: {
        es: postal.nombreEs,
        eu: postal.nombreEu,
    },
    urlPostal: postal.imagenFicha,
    img: {
        id: postal.id_gif,
        original: postal.imagenFicha,
        small: postal.imagenListado,
        medium: postal.imagenPostal,
        gif: postal.gif,
    },
    imagenFicha: postal.imagenFicha,
    location: {
        latitude: postal.latitude, // to show on map not in use on the web
        longitude: postal.longitude, // to show on map not in use on the web
    },
})

const postalsTransformer = postals =>
    postals.map(postal => transformPostal(postal))

export default postalsTransformer