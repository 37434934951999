import { HIGH_OCCUPANCY, LOW_OCCUPANCY, MEDIUM_OCCUPANCY } from '../constants'

const LOW_END = 74
const MEDIUM_START = 75
const HIGH_START = 89

// la ocupacion viene por estos % Menor o igual 74, entre 75 y 89, mayor que 89
const getOccupancyRate = percentage => {
    if (percentage <= LOW_END) {
        return LOW_OCCUPANCY
    }

    if (percentage <= HIGH_START && percentage > MEDIUM_START) {
        return MEDIUM_OCCUPANCY
    }

    return HIGH_OCCUPANCY
}

export default getOccupancyRate;