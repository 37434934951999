import React from 'react'
import IndicatorDetail from '../atoms/IndicatorDetail/IndicatorDetail'
import { SUCCESS_CODE } from '../constants'
import PageRow from '../atoms/PageRow/PageRow'

function IndicatorsContainer(props) {
    const { data, trads } = props
    return data.indicators.code === SUCCESS_CODE ? (
        <PageRow className="fix-footer">
            <IndicatorDetail
                contenido={data.indicators.data[0].indicador}
                titulo={data.indicators.data[0].indicador.titulo}
                descripcion={data.indicators.data[0].indicador.contenido}
                menuSecondary={data.indicators.data[0].menu_secundario}
                trads={trads}
                adjuntos={data.indicators.data[0].indicador.adjuntos}
                status={data.indicators.data[0].indicador.etiqueta}
            />
        </PageRow>
    ) : null
}

export default IndicatorsContainer
