import { InfoWindow, Map, Marker } from '@vis.gl/react-google-maps'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ArrowsUpDown from '../../atoms/ArrowsUpDown/ArrowsUpDown'
import PageRow from '../../atoms/PageRow/PageRow'
import PlanDetailCombinedShapeContainer from '../../atoms/PlanDetail/PlanDetailCombinedShapeContainer/PlanDetailCombinedShapeContainer'
import PlanDetailDatesContainer from '../../atoms/PlanDetail/PlanDetailDatesContainer/PlanDetailDatesContainer'
import PlanDetailEjesTitle from '../../atoms/PlanDetail/PlanDetailEjesTitle/PlanDetailEjesTitle'
import PlanDetailMapTitle from '../../atoms/PlanDetail/PlanDetailMapTitle/PlanDetailMapTitle'
import PlanDetailTitle from '../../atoms/PlanDetail/PlanDetailTitle/PlanDetailTitle'
import PlanDownloadsContainer from '../../atoms/PlanDownloadsContainer/PlanDownloadsContainer'
import PlanDetailActionContainer from '../../components/planDetail/PlanDetailActionContainer/PlanDetailActionContainer'
import PlanDetailDescriptionChart from '../../components/planDetail/PlanDetailDescriptionChart/PlanDetailDescriptionChart'
import PlanDetailEjesDescriptionContainer from '../../components/planDetail/PlanDetailEjesDescriptionContainer/PlanDetailEjesDescriptionContainer'
import './PlanDetail.css'
import './custom.css'
import useSetIframeTitle from '../../hooks/useSetIframeTitle'
import t from '../../i18n/translations/translations'

const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '499px',
}

function PlanDetail({
    actions,
    axis: axisProp,
    defaultCoordenates,
    description,
    endDate,
    files,
    isActive,
    lang,
    links,
    mapActions,
    percentage,
    percentageColor,
    presentation,
    startDate,
    showActionsMap,
    title,
    translations,
}) {
    const [action, setAction] = useState('')
    const [actionSlug, setActionSlug] = useState('')
    const [axis, setAxis] = useState(axisProp)
    const [lat, setLat] = useState('')
    const [lng, setLng] = useState('')
    const [status, setStatus] = useState(false)

    useSetIframeTitle(t.plan_map[lang])

    const plan = useSelector((state) => state.plan)

    const clickedPointUpdater = (
        newAction,
        newActionSlug,
        newlat,
        newlng,
        newStatus
    ) => {
        setAction(newAction)
        setActionSlug(newActionSlug)
        setLat(newlat)
        setLng(newlng)
        setStatus(newStatus)
    }

    const stateEje = (index) => {
        const updatedAxis = axis.map((axi, i) => {
            if (i === index) {
                return { ...axi, isActive: !axi.isActive }
            }

            return axi
        })

        setAxis(updatedAxis)
    }

    useEffect(() => {
        const updatedAxis = axis.map((axi) => ({
            ...axi,
            isActive: false,
        }))

        setAxis(updatedAxis)
    }, [])

    const planpercentageSinTexto = presentation !== 2 ? '' : `${percentage}%`

    return (
        <PageRow className="planDetail">
            {plan && (
                <PlanDetailTitle
                    lang={lang}
                    title={title}
                    translations={translations}
                    plan={plan}
                />
            )}

            <div className="planDetail-box">
                <div className="planDetail-box_container">
                    <PlanDetailDescriptionChart
                        description={description}
                        isActive={isActive}
                        translations={translations}
                        percentage={percentage}
                        percentageColor={percentageColor}
                        presentation={presentation}
                        text={planpercentageSinTexto}
                    />

                    <PlanDetailDatesContainer
                        startDate={startDate}
                        endDate={endDate}
                        translations={translations}
                    />

                    {links?.length ? (
                        <PlanDownloadsContainer
                            translations={translations}
                            links={links}
                            icon="paper"
                            linksClass
                        />
                    ) : null}

                    {files?.length ? (
                        <PlanDownloadsContainer
                            translations={translations}
                            links={files}
                            icon="link"
                        />
                    ) : null}

                    {showActionsMap === true ? (
                        <div className="planDetail-box_container-map">
                            <PlanDetailMapTitle translations={translations} />

                            <div className="mapa">
                                <Map
                                    defaultCenter={{
                                        lat: defaultCoordenates.latitude,
                                        lng: defaultCoordenates.longitude,
                                    }}
                                    style={containerStyle}
                                    zoom={15}
                                >
                                    {mapActions?.map((action) => (
                                        <Marker
                                            key={action.title}
                                            name="Current location"
                                            position={{
                                                lat: action.latitude,
                                                lng: action.longitude,
                                            }}
                                            onClick={() =>
                                                clickedPointUpdater(
                                                    action.title,
                                                    action.slug,
                                                    action.latitude,
                                                    action.longitude,
                                                    true,
                                                    action.plan_title
                                                )
                                            }
                                        />
                                    ))}

                                    {status ? (
                                        <InfoWindow
                                            position={{
                                                lat,
                                                lng,
                                            }}
                                            onCloseClick={() => {
                                                setStatus(false)
                                            }}
                                        >
                                            <PlanDetailCombinedShapeContainer
                                                plan={plan}
                                                action={action}
                                                slug={actionSlug}
                                                translations={translations}
                                            />
                                        </InfoWindow>
                                    ) : null}
                                </Map>
                            </div>
                        </div>
                    ) : null}
                    {axis?.length ? (
                        <div className="planDetail-box_container-ejes">
                            <PlanDetailEjesTitle translations={translations} />
                            <div className="planDetail-box_container-ejes_ejes">
                                {axis.map((eje, index) => (
                                    <div key={eje.title}>
                                        <button
                                            className="ejes-title"
                                            id={index}
                                            onClick={() => stateEje(index)}
                                            type='button'
                                        >
                                            <p>{eje.title}</p>
                                            <ArrowsUpDown
                                                isActive={eje.isActive}
                                                className="arrows-axis"
                                            />
                                        </button>
                                        <div className="separador-ejes" />
                                        <PlanDetailEjesDescriptionContainer
                                            eje={eje}
                                            translations={translations}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}

                    {actions?.length ? (
                        <PlanDetailActionContainer
                            actions={actions}
                            translations={translations}
                        />
                    ) : null}
                </div>
            </div>
        </PageRow>
    )
}

export default PlanDetail
