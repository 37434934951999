const convertDateToString = ({ lang, date }) => {
    const dateNum = new Date(date)
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }

    const locale = lang === 'es' ? 'es-ES' : 'eu-EU'

    return dateNum.toLocaleDateString(locale, options)
}

export default convertDateToString