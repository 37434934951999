import React from 'react'
import WysiwygFunctionality from '../../../components/WysiwygFunctionality/WysiwygFunctionality'

function PlanDetailEjeDescription({ description, responsive }) {
    return (
        <div
            className={
                responsive ? 'ejes-description-responsive' : 'ejes-description'
            }
        >
            <WysiwygFunctionality
                className="description"
                content={{
                    descripcion: description,
                }}
            />
        </div>
    )
}

export default PlanDetailEjeDescription
