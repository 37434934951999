import React from 'react'
import Circle from '../Circle/Circle'
import './PlanState.css'

function PlanState({ active, translations }) {
    return (
        <div className="plan-state">
            <div className="state">
                <Circle />
                <p>
                    {active
                        ? translations?.ACCOUNTABILITY
                              ?.PLAN_LIST_PLANS_STAGE_VIGENTE_1
                        : translations?.ACCOUNTABILITY
                              ?.PLAN_LIST_PLANS_STATE_NO_VIGENTE_1}
                </p>
            </div>
        </div>
    )
}

export default PlanState
