import { SET_MAPS_DATA, SET_MAP_DATA } from '../constants/constants'

// eslint-disable-next-line default-param-last
const maps = (state = {}, action) => {
    switch (action.type) {
        case SET_MAPS_DATA:
            return {
                ...state,
                maps: action.payload.data,
            }
        case SET_MAP_DATA:
            return {
                ...state,
                map: action.payload.data,
            }
        default:
            return state
    }
}

export default maps
