import React from 'react'
import IncidenciaDetail from '../compositions/IncidenciaDetail/IncidenciaDetail'

export default function IncidenceContainer(props) {
    const { data, match, isMobile, isTablet } = props
    const { params: lang } = match
    const { incidencias } = data

    const listaActualizaciones = []
    for (let index = 1; index < incidencias.data.incidence.length; index += 1) {
        listaActualizaciones[index] = incidencias.data.incidence[index]
    }

    const incidenciasData =
        incidencias.data &&
        incidencias.data.incidence &&
        incidencias.data.incidence[0] &&
        incidencias.data.incidence[0]

    return (
        <IncidenciaDetail
            title={incidenciasData.title}
            description={incidenciasData.description}
            longitude={incidenciasData.longitude}
            latitude={incidenciasData.latitude}
            date={incidenciasData.created_at}
            stateName={
                lang === 'es'
                    ? incidenciasData.state_es
                    : incidenciasData.state_eu
            }
            stateNameConfig={incidenciasData.state_es}
            category={
                lang === 'es'
                    ? incidenciasData.category_es
                    : incidenciasData.category_eu
            }
            actualizaciones={listaActualizaciones}
            address={incidenciasData.address}
            color={incidenciasData.color}
            idFatherIncidence={incidenciasData.father_incidence}
            isMobile={isMobile}
            isTablet={isTablet}
            translations={incidencias.data.translations}
            images={incidenciasData.photos[0]}
            lang={lang}
        />
    )
}
