import React from 'react'

/* eslint-disable max-len */
export default function Lock() {
    return (
        <svg viewBox="0 0 14 16">
            <g
                id="Symbols"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Footer-/-desktop-/-grey"
                    transform="translate(-943.000000, -96.000000)"
                >
                    <g
                        id="Group-9"
                        transform="translate(943.000000, 96.000000)"
                    >
                        <polygon
                            id="Fill-1"
                            fill="#FEFEFE"
                            points="0 15.3305 13.421 15.3305 13.421 7.0775 0 7.0775"
                        />
                        <g
                            id="Group-8"
                            transform="translate(1.000000, 0.330500)"
                            stroke="#FEFEFE"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        >
                            <path
                                d="M9.9571,4.4038 C9.9571,4.4038 9.9571,0.6698 5.6951,0.6698 C1.3891,0.6698 1.2061,4.4038 1.2061,4.4038"
                                id="Stroke-2"
                            />
                            <path
                                d="M1.2061,4.4038 L1.2061,7.3058"
                                id="Stroke-4"
                            />
                            <path
                                d="M9.9571,4.4038 L9.9571,7.3058"
                                id="Stroke-6"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
