import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Imagen from '../../../assets/img/Placeholder.png'
import { Info } from '../../../assets/svg/hartuz'
import {
    DATE_FORMAT,
    DATE_FORMAT_DATE_PICKER,
    DATE_HOUR_FORMAT_ES,
    DATE_HOUR_FORMAT_EU,
} from '../../../constants'
import STATES from '../../../constants/states'
import HartuzBookingDateForm from '../../../forms/HartuzBookingDateForm/HartuzBookingDateForm'
import HartuzFacilityBookingForm from '../../../forms/HartuzFacilityBookingForm/HartuzFacilityBookingForm'
import t from '../../../i18n/translations/translations'
import './HartuzBookingGrid.css'

const transformDateForDatePicker = (date) => {
    let transformed = moment(date, DATE_FORMAT).format(DATE_FORMAT_DATE_PICKER)
    transformed = moment(transformed).format(DATE_FORMAT_DATE_PICKER)
    return transformed
}

const transformDateForApi = (date) =>
    moment(date).format(DATE_FORMAT_DATE_PICKER)

const addDefaultSrc = (ev) => {
    ev.target.src = Imagen
}

export default function HartuzBookingGrid({
    availableDates,
    bookingFormState,
    data,
    datesLoading,
    errorBookingCode,
    facility,
    facilityHoursAvailable,
    formValuesBooking,
    getAvailableDates,
    getAvailableHours,
    hoursLoading,
    instanceID,
    lang,
    paramsDate,
    saveHartuzFacilityBooking,
}) {
    const dateTransformed = paramsDate
        ? transformDateForDatePicker(paramsDate)
        : null

    const [selectedHour, setSelectedHour] = useState(null)
    const [maxPeople, setMaxPeople] = useState(null)
    const [concurrenceError, setConcurrenceError] = useState('')
    const [dayFocused, setDayFocused] = useState(false)
    const [date, setDate] = useState(paramsDate ? moment(paramsDate) : null)
    const [time, setTime] = useState(null)
    const [isOpenedBookingForm, setIsOpenedBookingForm] = useState(false)

    const facilityPvp = data && parseFloat(data.pvp)
    const availableDaysMessage = data && data.available_days_message[lang]
    const description = data && data.description[lang]
    const textInfo = data && data.text_info[lang]
    const timeToPay = data && data.time_to_pay

    const formattedTimeToPay =
        lang === 'es'
            ? moment(timeToPay).format(DATE_HOUR_FORMAT_ES)
            : moment(timeToPay).format(DATE_HOUR_FORMAT_EU)

    const facilityID =
        facility && facility.facility && facility.facility.facilityID

    const handleDateFocus = (value) => {
        setDayFocused(value)
    }

    const confirmDateAndTime = (formData) => {
        setDate(formData.date)
        setTime(formData.time)
        setIsOpenedBookingForm(true)
    }

    const closeForm = () => {
        setIsOpenedBookingForm(false)
    }

    const handleCloseConcurrenceError = () => {
        setConcurrenceError('')
    }

    const handleDateChange = (date) => {
        const formattedDate = transformDateForApi(date)
        setDate(formattedDate)
        setTime(null)
        if (formattedDate && facilityID && instanceID) {
            getAvailableHours({
                facilityID,
                date: formattedDate,
                instanceID,
            })
        }
    }

    const handleTimeChange = (time) => {
        setTime(time)
    }

    const handleBookingSubmit = (formData) => {
        if (formData) {
            saveHartuzFacilityBooking(formData)
        }
    }

    const handleSelectedHour = (hour) => {
        setSelectedHour(hour)
    }

    useEffect(() => {
        if (dateTransformed && facilityID && instanceID) {
            handleDateChange(dateTransformed)
        }
    }, [dateTransformed, facilityID, instanceID])

    useEffect(() => {
        if (facilityID) {
            getAvailableDates({
                facilityID,
                instanceID,
            })
        }
    }, [facilityID, instanceID])

    useEffect(() => {
        if (errorBookingCode === 702 || errorBookingCode === 611) {
            setDate(null)
            setTime(null)
            setIsOpenedBookingForm(false)
            setConcurrenceError(t.dates_no_availables[lang])
        }
    }, [errorBookingCode])

    useEffect(() => {
        if (isOpenedBookingForm === false) {
            setDate(null)
            setTime(null)
        }
    }, [isOpenedBookingForm])

    useEffect(() => {
        if (!dayFocused && date) {
            setTime(null)
        }
    }, [dayFocused])

    useEffect(() => {
        const availability = selectedHour && selectedHour.available_capacity
        setMaxPeople(availability)
    }, [selectedHour])

    return data ? (
        <div className="hartuzBooking">
            <div className="hartuzBookingGrid">
                <div className="hartuzBookingGrid-img">
                    <img
                        onError={addDefaultSrc}
                        src={`${data.image}`}
                        alt={data.name[lang]}
                    />
                </div>

                <div className="hartuzBookingGrid__row hartuzBookingGrid__title-wrapper">
                    <div className="hartuzBookingGrid-title">
                        <h1>{data.name[lang]}</h1>
                    </div>
                </div>
                <div className="hartuzBookingGrid__content hartuzBookingGrid__row">
                    {date && time && isOpenedBookingForm ? (
                        <HartuzFacilityBookingForm
                            closeForm={closeForm}
                            date={date}
                            errorBookingCode={errorBookingCode}
                            facilityID={facilityID}
                            facilityName={data.name[lang]}
                            has_light={data.has_light}
                            handleSubmit={handleBookingSubmit}
                            initialValues={formValuesBooking}
                            instanceID={instanceID}
                            lang={lang}
                            maxPeople={maxPeople}
                            state={bookingFormState}
                            time={time}
                        />
                    ) : (
                        <>
                            <HartuzBookingDateForm
                                availableDates={availableDates}
                                availableDaysMessage={availableDaysMessage}
                                dateFocused={dayFocused}
                                datesLoading={datesLoading}
                                facilityHoursAvailable={facilityHoursAvailable}
                                facilityID={facilityID}
                                getAvailableHours={handleDateChange}
                                handleCloseConcurrenceError={
                                    handleCloseConcurrenceError
                                }
                                handleSelectedHour={handleSelectedHour}
                                handleSubmit={confirmDateAndTime}
                                handleTime={handleTimeChange}
                                hoursLoading={hoursLoading}
                                instanceID={instanceID}
                                lang={lang}
                                paramsDate={dateTransformed}
                                setDayFocused={handleDateFocus}
                                state={bookingFormState}
                            />

                            <div className="hartuzBookingGrid__content--info">
                                <Info />
                                {date &&
                                    time &&
                                    (facilityPvp ? (
                                        <div>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: description,
                                                }}
                                                className="hartuzBookingGrid__content--payment"
                                            />
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: textInfo,
                                                }}
                                                className="hartuzBookingGrid__content--payment"
                                            />
                                            <hr />
                                            <p>
                                                {t.limit_date_for_payment[lang]}
                                            </p>
                                            <p>{formattedTimeToPay}</p>
                                        </div>
                                    ) : (
                                        <p>{t.you_can_make_booking[lang]}</p>
                                    ))}
                                {!date && !time && (
                                    <p>{t.pick_a_day_to_book[lang]}</p>
                                )}
                                {date && !time && !dayFocused && (
                                    <p>
                                        {t.pick_a_hour_interval_to_book[lang]}
                                    </p>
                                )}
                                {date && !time && dayFocused && (
                                    <p>{t.pick_a_day_to_book[lang]}</p>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
            {bookingFormState === STATES.ERROR_CONCURRENCE &&
                concurrenceError && (
                    <div className="hartuzBookingGrid__concurrence-error">
                        {concurrenceError}
                        <span
                            className="hartuzBookingGrid__closeBtn"
                            onClick={handleCloseConcurrenceError}
                        >
                            &times;
                        </span>
                    </div>
                )}
        </div>
    ) : null
}
