import React from 'react'

/* eslint-disable max-len */
export default function Ubication({ className }) {
    return (
        <svg className={className} viewBox="0 0 26 32">
            <g
                id="Udala-Zabaltzen-0"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Rendicion_accion_desktop"
                    transform="translate(-319.000000, -1496.000000)"
                    stroke="#1C3561"
                    strokeWidth="1.5"
                >
                    <g
                        id="Group-13"
                        transform="translate(218.000000, 1461.000000)"
                    >
                        <g
                            id="Group-12"
                            transform="translate(102.000000, 25.000000)"
                        >
                            <g
                                id="Group-25"
                                transform="translate(0.000000, 11.000000)"
                            >
                                <path
                                    d="M11.994873,29.6726152 C16.0114746,27.138916 23.9824219,20.0112301 23.9824219,12.0632322 C23.9824219,4.11523438 18.0291785,8.70414851e-13 12.0324707,8.70414851e-13 C6.03576293,8.70414851e-13 0,4.04602051 0,12.0632322 C0,20.080444 7.99084473,27.1282959 11.994873,29.6726152 Z"
                                    id="Path-30-Copy"
                                />
                                <circle
                                    id="Oval-Copy-29"
                                    cx="11.994873"
                                    cy="12.0632324"
                                    r="5"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
