import React from 'react'
import Banner from '../../atoms/Banner/Banner'
import ImageSlider from '../ImageSlider/ImageSlider'
import LastNews from '../LastNews/LastNews'
import PlainHTML from '../PlainHTML/PlainHTML'
import Swipe from '../Swiper/Swiper'

// FIXME: Opciones object
function ModulesRender({
    tipo,
    opciones,
    customClass,
    lang,
    isMobile,
    isTablet,
}) {
    switch (tipo) {
        case 1:
            if (opciones.contenido.presentacion === 1) {
                return (
                    <LastNews
                        title={opciones.titulo}
                        lastNews={opciones.contenido.noticias}
                        customClass={customClass}
                        lang={lang}
                    />
                )
            }
            return (
                <Swipe
                    noticias={opciones.contenido.noticias}
                    numero={opciones.tamano}
                    customClass={customClass}
                    lang={lang}
                    isMobile={isMobile}
                    isTablet={isTablet}
                />
            )

        case 2:
            return (
                <ImageSlider
                    content={opciones.contenido}
                    customClass={customClass}
                    lang={lang}
                />
            )
        case 3:
            return (
                <Banner
                    content={opciones.contenido}
                    customClass={customClass}
                    lang={lang}
                />
            )
        case 4:
            return (
                <PlainHTML
                    content={opciones.contenido}
                    customClass={customClass}
                    lang={lang}
                />
            )
        case undefined:
            return ''
        default:
            return ''
    }
}

export default ModulesRender
