import { SET_UA_DATA } from '../constants/constants'

// eslint-disable-next-line default-param-last
const analyticsUAs = (state = {}, action) => {
    if (action.type === SET_UA_DATA) {
        const { analyticsUA, data } = action.payload
        return { ...state, [analyticsUA]: data }
    }

    return state
}

export default analyticsUAs
