import React from 'react'
import { facilityTransformer } from '../../../transformers/aurretikDataTransformer'
import getOccupancyRate from '../../../utils/getOccupancyRate'
import OccupancyIcon from '../../../atoms/OccupancyIcon/OccupancyIcon'
import openingClosingTimeMessage from '../../../utils/openingClosingTimeMessage'
import t from '../../../i18n/translations/translations'
import AurretikStatusIcon from '../AurretikStatusIcon/AurretikStatusIcon'
import InternalLink from '../../../atoms/InternalLink/InternalLink'

import './AurretikCard.css'

function AurretikCard({ data, lang, instanceID }) {
    const { listBackgroundImage: bgImage, letterColor: fontColor } = data.theme
    const dataTransformed = data && facilityTransformer(data)
    const { occupancy, open, id, season } = dataTransformed
    const percent = occupancy && occupancy.percent && occupancy.percent
    const time = open ? season.closing_time : season.opening_time
    const occupancyState = getOccupancyRate(percent)

    return (
        <div className="aurretikCard">
            <InternalLink url={`/${lang}/aurretik/${instanceID}/${id}`}>
                <div className="aurretikCard-content aurretikCard-top-content">
                    {bgImage && <img src={bgImage.image} alt="bg" />}
                    <p style={{ color: fontColor }}>{data[`title_${lang}`]}</p>
                    <OccupancyIcon occupancy={occupancyState} />
                </div>

                <div className="aurretikCard-content aurretikCard-middle-content">
                    <AurretikStatusIcon
                        open={open}
                        className="aurretikCard_icon-status"
                    />
                    <p style={{ color: fontColor }}>
                        <span className="aurretikCard-content--uppercase">
                            {' '}
                            {data.open ? t.opened[lang] : t.closed[lang]}{' '}
                        </span>
                        | {openingClosingTimeMessage({ time, open, lang })}
                    </p>
                </div>
                <div className="aurretikCard-content aurretikCard-bottom-content">
                    <div className="button--grey">
                        <span>{t.see_facility[lang]}</span>
                    </div>
                </div>
            </InternalLink>
        </div>
    )
}

export default AurretikCard
