import React from 'react'
import Moment from 'moment'
import CalendarioInput from '../../assets/svg/CalendarioInput'
import { DATE_FORMAT } from '../../constants'

function InfoBoxHeaderStatusDate({ date }) {
    return (
        <div className="infobox_header-status_date">
            <CalendarioInput className="calendar" />
            {Moment(date).format(DATE_FORMAT)}
        </div>
    )
}

export default InfoBoxHeaderStatusDate
