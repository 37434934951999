import React from 'react'
import Circle from '../../Circle/Circle'
import './ActionDetailHitoState.css'

function ActionDetailHitoState({ state, translations }) {
    let message
    switch (state) {
        case 0:
            message = <p>{translations.ACCOUNTABILITY.ACTION_STATE_0}</p>
            break
        case 1:
            message = <p>{translations.ACCOUNTABILITY.ACTION_STATE_1}</p>
            break
        case 2:
            message = <p>{translations.ACCOUNTABILITY.ACTION_STATE_2}</p>
            break
        case 3:
            message = <p>{translations.ACCOUNTABILITY.ACTION_STATE_3}</p>
            break
        case 4:
            message = <p>{translations.ACCOUNTABILITY.ACTION_STATE_4}</p>
            break
        default:
            message = null
    }

    return (
        <div className="box-estado">
            <Circle />
            {message}
        </div>
    )
}

export default ActionDetailHitoState
