import React from 'react'

export default function Info({ className }) {
    return (
        <svg
            className={className}
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="15.5"
                cy="15.5"
                r="14.5"
                stroke="#1C3561"
                strokeWidth="2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.5265 10.5904C18.9263 10.2218 19.1253 9.77359 19.1253 9.24873C19.1253 8.72504 18.9266 8.27592 18.5265 7.90267C18.1276 7.53045 17.6469 7.34375 17.0848 7.34375C16.521 7.34375 16.0383 7.53001 15.6357 7.90267C15.233 8.27592 15.0312 8.7249 15.0312 9.24873C15.0312 9.77359 15.233 10.2217 15.6357 10.5904C16.0391 10.9603 16.5208 11.1454 17.0848 11.1454C17.647 11.1454 18.1276 10.9603 18.5265 10.5904ZM18.6787 22.8279L18.9079 21.8962C18.7893 21.9516 18.5979 22.0149 18.3356 22.087C18.0725 22.1591 17.8357 22.1958 17.6276 22.1958C17.1844 22.1958 16.8723 22.1236 16.6911 21.9784C16.5113 21.8332 16.4216 21.5601 16.4216 21.1601C16.4216 21.0016 16.4486 20.7654 16.5052 20.4562C16.5601 20.1449 16.6232 19.8683 16.6933 19.6263L17.5492 16.6139C17.6331 16.3374 17.6906 16.0335 17.7214 15.7017C17.7531 15.3707 17.7678 15.1392 17.7678 15.0077C17.7678 14.3725 17.5438 13.857 17.0957 13.4593C16.6476 13.062 16.0095 12.8633 15.1826 12.8633C14.7224 12.8633 14.2359 12.9446 13.7211 13.107C13.2062 13.269 12.668 13.4642 12.1049 13.6921L11.875 14.6245C12.0428 14.5628 12.2427 14.4965 12.4765 14.4278C12.7093 14.3593 12.9377 14.324 13.1599 14.324C13.6136 14.324 13.9192 14.4009 14.0793 14.5523C14.2395 14.7042 14.3199 14.9744 14.3199 15.3607C14.3199 15.5743 14.2945 15.8115 14.2417 16.0694C14.1896 16.3289 14.1246 16.6034 14.0481 16.893L13.1886 19.9176C13.1121 20.2354 13.0562 20.5197 13.0211 20.7722C12.9862 21.0244 12.9694 21.2719 12.9694 21.5126C12.9694 22.1342 13.2005 22.6467 13.6624 23.051C14.1243 23.4538 14.772 23.6566 15.6046 23.6566C16.1469 23.6566 16.6227 23.5861 17.0323 23.4445C17.4414 23.3032 17.9909 23.0978 18.6787 22.8279Z"
                fill="#1C3561"
            />
        </svg>
    )
}
