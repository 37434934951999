import { MDBCard, MDBCardBody, MDBCardText } from 'mdb-react-ui-kit'
import PropTypes from 'prop-types'
import React from 'react'
import ClampLines from 'react-clamp-lines'
import './RelatedNews.css'

function RelatedNews({ title, body, date }) {
    return (
        <div className="related-card__container">
            <MDBCard className="related-card">
                <MDBCardBody>
                    <MDBCardText>
                        <small>{date}</small>
                    </MDBCardText>
                    <ClampLines
                        text={title}
                        lines={2}
                        ellipsis="..."
                        className="related-card__title"
                    />
                    <ClampLines
                        text={body}
                        lines={3}
                        ellipsis="..."
                        className="related-card__text"
                    />
                </MDBCardBody>
            </MDBCard>
        </div>
    )
}

RelatedNews.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
}

export default RelatedNews
