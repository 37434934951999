const mapIconTransformer = icon => ({
    id: icon.idicono,
    name: {
        es: icon.nombre_es,
        eu: icon.nombre_eu,
    },
    path: icon.path,
    type: icon.tipo,
})

export default mapIconTransformer