import React from 'react'

/* eslint-disable max-len */
export default function EnProceso() {
  return <svg viewBox="0 0 933 28">
        <g
            id="Udala-Zabaltzen-0"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="Kalezaindu_incidencia_proceso_desktop"
                transform="translate(-251.000000, -1184.000000)"
            >
                <g id="Group-5" transform="translate(251.000000, 1184.000000)">
                    <path
                        d="M906.872939,21 L481.127049,21 C478.706381,25.1846113 474.181975,28 469,28 C463.818025,28 459.293619,25.1846113 456.872953,21.0000039 L26.1270607,21 C23.7063805,25.1846113 19.1819749,28 14,28 C6.2680135,28 0,21.7319865 0,14 C0,6.2680135 6.2680135,0 14,0 C19.1819749,0 23.7063805,2.81538866 26.127047,6.9999961 L456.872939,7 C459.293619,2.81538866 463.818025,0 469,0 C474.181975,0 478.706381,2.81538866 481.127047,6.9999961 L906.872939,7 C909.293619,2.81538866 913.818025,0 919,0 C926.731986,0 933,6.2680135 933,14 C933,21.7319865 926.731986,28 919,28 C913.818025,28 909.293619,25.1846113 906.872953,21.0000039 Z"
                        id="Combined-Shape"
                        fill="#FFFFFF"
                    />
                    <path
                        d="M23.5421977,11 L459.457802,11 C460.732066,6.94289305 464.522358,4 469,4 C474.522847,4 479,8.4771525 479,14 C479,19.5228475 474.522847,24 469,24 C464.522358,24 460.732066,21.057107 459.457802,17 L23.5421977,17 C22.2679341,21.057107 18.4776418,24 14,24 C8.4771525,24 4,19.5228475 4,14 C4,8.4771525 8.4771525,4 14,4 C18.4776418,4 22.2679341,6.94289305 23.5421977,11 Z M14,19 C16.7614237,19 19,16.7614237 19,14 C19,11.2385763 16.7614237,9 14,9 C11.2385763,9 9,11.2385763 9,14 C9,16.7614237 11.2385763,19 14,19 Z M469,19 C471.761424,19 474,16.7614237 474,14 C474,11.2385763 471.761424,9 469,9 C466.238576,9 464,11.2385763 464,14 C464,16.7614237 466.238576,19 469,19 Z"
                        id="Combined-Shape"
                        fill="#FF7700"
                    />
                </g>
            </g>
        </g>
    </svg>
}
